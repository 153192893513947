import React from 'react';
import {type Plugin} from '@englex/slate-react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type MultipleChoiceProperties} from 'store/exercise/player/widgets/List/interface';

import SlatePlayer from '../../../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../../../Slate/plugins/renderers/Bold';
import Icon from '../../../../Slate/plugins/renderers/Icon';
import Highlight from '../../../../Slate/plugins/renderers/Highlight';
import Italic from '../../../../Slate/plugins/renderers/Italic';
import StrikeThrough from '../../../../Slate/plugins/renderers/StrikeThrough';
import Underline from '../../../../Slate/plugins/renderers/Underline';
import QuestionsList from '../../../../Slate/plugins/renderers/QuestionsList/QuestionsList';
import OddWordAnswerRenderer from '../../../../Slate/plugins/renderers/QuestionsList/OddWord/OddWordAnswerRender';
import OddWordQuestionRenderer from '../../../../Slate/plugins/renderers/QuestionsList/OddWord/OddWordQuestionRender';
import {SelectionPointer} from '../../../../Slate/plugins/renderers/Pointer/SelectionPointer';

interface StateProps {}

interface DispatchProps {}

type OwnProps = WidgetTypeComponentProps<MultipleChoiceProperties>;

type Props = OwnProps & StateProps & DispatchProps;

class OddWord extends React.PureComponent<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.plugins = [
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new Icon(),
      new Highlight(),
      new OddWordAnswerRenderer(props.widget.element, props.preview),
      new OddWordQuestionRenderer(props.widget.horizontal, props.preview),
      new QuestionsList(),
      new SelectionPointer()
    ];
  }

  public render() {
    const {widget} = this.props;
    const {content} = widget;
    return (
      <div className="x-content">
        <SlatePlayer value={content} plugins={this.plugins} getWidgetProps={this.getWidgetProps} />
      </div>
    );
  }

  private getWidgetProps = () => this.props;
}

export default OddWord;
