import {type ElementType} from 'react';

import {WidgetType, type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import FormattedText, {SelectableFormattedText} from '../widgets/FormattedText/FormattedText';
import AudioWidget from '../widgets/Audio/AudioWidget';
import Essay from '../widgets/Essay/Essay';
import GapFill from '../widgets/GapFill/GapFill';
import ImageMatching from '../widgets/ImageMatching';
import ImageLabeling from '../widgets/ImageLabeling';
import {Matching} from '../widgets/Matching/Matching';
import MultipleChoice from '../widgets/List/MultipleChoice/MultipleChoice';
import OddWord from '../widgets/List/OddWord/OddWord';
import Quests from '../widgets/Writing/Quests/Quests';
import ScrambledSentences from '../widgets/ScrambledSentences/ScrambledSentences';
import VideoWidget from '../widgets/Video/VideoWidget';
import VocabularyWidgetComponent from '../widgets/Vocabulary/VocabularyWidgetComponent';
import GrammarRules from '../widgets/GrammarRules/GrammarRules';
import Questions from '../widgets/Writing/Questions/Questions';
import Cards from '../widgets/Cards/Cards';
import Quiz from '../widgets/Quiz/Quiz';
import PictureChoice from '../widgets/PictureChoice/PictureChoice';
import {Dialogue} from '../widgets/Dialogue/Dialogue';
import {UnknownWidget} from '../widgets/Unknown/UnknownWidget';
import {WordSet} from '../widgets/WordPictureSet/WordSet/WordSet';
import {PictureSet} from '../widgets/WordPictureSet/PictureSet/PictureSet';
import {WordPictureSet} from '../widgets/WordPictureSet/WordPictureSet/WordPictureSet';
import {FlipCards} from '../widgets/FlipCards/FlipCards';
import {StyledList} from '../widgets/StyledList/StyledList';
import {Spelling} from '../widgets/Spelling/Spelling';

const map: Record<WidgetType, ElementType<WidgetTypeComponentProps>> = {
  [WidgetType.FORMATTED_TEXT]: SelectableFormattedText,
  [WidgetType.AUDIO]: AudioWidget,
  [WidgetType.ESSAY]: Essay,
  [WidgetType.GAP_FILL]: GapFill,
  [WidgetType.IMAGE_MATCHING]: ImageMatching,
  [WidgetType.IMAGE_LABELING]: ImageLabeling,
  [WidgetType.MATCHING]: Matching,
  [WidgetType.MULTIPLE_CHOICE]: MultipleChoice,
  [WidgetType.FREE_CHOICE]: MultipleChoice,
  [WidgetType.ODD_WORD]: OddWord,
  [WidgetType.QUESTS]: Quests,
  [WidgetType.QUESTIONS]: Questions,
  [WidgetType.SCRAMBLED_SENTENCES]: ScrambledSentences,
  [WidgetType.VIDEO]: VideoWidget,
  [WidgetType.VOCABULARY]: VocabularyWidgetComponent,
  [WidgetType.COMMENT]: FormattedText,
  [WidgetType.GRAMMAR_RULES]: GrammarRules,
  [WidgetType.NOTE]: FormattedText,
  [WidgetType.SPEAKING]: SelectableFormattedText,
  [WidgetType.CARDS]: Cards,
  [WidgetType.Quiz]: Quiz,
  [WidgetType.PICTURE_CHOICE]: PictureChoice,
  [WidgetType.DIALOGUE]: Dialogue,
  [WidgetType.WORD_SET]: WordSet,
  [WidgetType.PICTURE_SET]: PictureSet,
  [WidgetType.WORD_PICTURE_SET]: WordPictureSet,
  [WidgetType.FLIP_CARDS]: FlipCards,
  [WidgetType.HORIZONTAL_STYLED_LIST]: StyledList,
  [WidgetType.VERTICAL_STYLED_LIST]: StyledList,
  [WidgetType.SPELLING]: Spelling,
  [WidgetType.UNKNOWN]: UnknownWidget
};

function xplayerWidgetTypeComponentFactory(
  type: WidgetType
): ElementType<WidgetTypeComponentProps> {
  return map[type];
}

export default xplayerWidgetTypeComponentFactory;
