import React, {type FC, useCallback, useEffect} from 'react';
import {toPng} from 'html-to-image';
import {type Options} from 'html-to-image/lib/types';
import {useIntl} from 'react-intl';

import {useLoadable} from 'hooks/useLoadable';
import * as toastr from 'components/toastr';

import {ExerciseFeedbackModal} from './ExerciseFeedbackModal';

interface Props {
  close: () => void;
  exerciseId: string;
}

const htmlToImgOptions: Options = {backgroundColor: '#fff', cacheBust: true};

export const ExerciseFeedback: FC<Props> = ({exerciseId, close}) => {
  const intl = useIntl();
  const [exerciseToImg, exerciseImgUrl] = useLoadable(toPng, {
    onError: useCallback(() => {
      toastr.error(
        intl.formatMessage({id: 'Common.SomethingWrong'}),
        intl.formatMessage({id: 'Common.PleaseTryAgainLater'})
      );
      close();
    }, [close, intl])
  });

  const takeExerciseShot = useCallback(() => {
    const element = document.getElementById(exerciseId);
    element && exerciseToImg(element, htmlToImgOptions);
  }, [exerciseId, exerciseToImg]);

  useEffect(() => {
    takeExerciseShot();
  }, [exerciseId, takeExerciseShot]);

  return exerciseImgUrl ? (
    <ExerciseFeedbackModal exerciseImgUrl={exerciseImgUrl} exerciseId={exerciseId} close={close} />
  ) : null;
};
