import classNames from 'classnames';
import {type FC, useMemo, memo} from 'react';

import {type SentenceProps} from 'components/XPlayer/widgets/ScrambledSentences/components/interface';
import {
  checkCorrectness,
  choicesAndPunctuationBuilder,
  resolveTemplateArray
} from 'components/XPlayer/widgets/ScrambledSentences/components/utils';
import {wordTemplateLiteral} from 'config/static';
import FullSentence from 'components/XPlayer/widgets/ScrambledSentences/components/FullSentence';

import {Provider} from '../../../../contexts/dndContext';
import {Pool} from '../Pool/Pool';

import './Sentence.scss';

export const Sentence: FC<SentenceProps & {ordinal?: string; isOneSentence: boolean}> = memo(
  ({
    template,
    answers,
    choices,
    countExample,
    index,
    preview,
    sentenceId,
    isOneSentence,
    role,
    closed,
    values,
    widgetId,
    isModal,
    ordinal
  }) => {
    const templateArray: string[] = resolveTemplateArray(template);

    const checking = role === 'student' ? closed : closed || (answers && values);
    const correct = checking && checkCorrectness({answers, choices, values});
    const incorrect = checking && !correct;
    const isStudent = role === 'student';

    const className = classNames('spelling-sentence', {
      correct,
      incorrect,
      student: isStudent && !closed,
      pristine: values === undefined && !isStudent && !closed && !correct && !incorrect
    });

    const fullSentence = useMemo(() => {
      return answers && (role !== 'student' || closed)
        ? answers.reduce((r: string, a: string) => {
            return r.replace(wordTemplateLiteral, choices.get(a)?.value);
          }, template)
        : undefined;
    }, [answers, choices, closed, role, template]);

    const isNeedFullSentence = incorrect || !isStudent;

    const choicesAndPunctuation = useMemo(() => {
      return choicesAndPunctuationBuilder(templateArray, choices, values);
    }, [choices, templateArray, values]);

    const addingToIndex = countExample ? 2 : 1;

    const sentenceNumber =
      ordinal && isOneSentence && !countExample ? ordinal : index + addingToIndex;

    const shouldShowNumbering = !(isOneSentence && !ordinal && !countExample);

    return (
      <Provider isModal={isModal}>
        <div className={className}>
          {shouldShowNumbering && <div className="sentence-number">{sentenceNumber}.</div>}
          <div>
            <Pool
              answers={answers}
              values={values}
              preview={preview}
              sentenceId={sentenceId}
              choices={choices}
              choicesAndPunctuation={choicesAndPunctuation}
              role={role}
              closed={closed}
              widgetId={widgetId}
            />
            {isNeedFullSentence && fullSentence && <FullSentence sentence={fullSentence} />}
          </div>
        </div>
      </Provider>
    );
  }
);
