import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import * as toastr from 'components/toastr';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {completeExercise, uncompleteExercise} from 'store/exercise/player/actions';
import './CompleteExercise.scss';
import {type Role} from 'store/interface';

interface Props {
  role: Role;
  exerciseId: string;
  isCompleted?: boolean;
  preview?: boolean;
}

export const CompleteExercise: FC<Props> = ({exerciseId, isCompleted, preview, role}) => {
  const axiosDispatch = useAxiosDispatch();
  const intl = useIntl();

  const complete = useCallback(async () => {
    const result = await axiosDispatch(completeExercise(exerciseId, preview));

    if (!result) {
      toastr.error('', intl.formatMessage({id: 'XPlayer.Check.Completed.Error'}));
    }
  }, [axiosDispatch, exerciseId, intl, preview]);

  const uncomplete = useCallback(async () => {
    const result = await axiosDispatch(uncompleteExercise(exerciseId, preview));

    if (!result) {
      toastr.error('', intl.formatMessage({id: 'XPlayer.Check.Activate.Error'}));
    }
  }, [axiosDispatch, exerciseId, intl, preview]);

  const isStudent = role === 'student';
  const disabled = isCompleted && isStudent;
  const shouldComplete = !isCompleted || isStudent;
  const onClick = shouldComplete ? complete : uncomplete;
  const bsStyle = shouldComplete ? 'primary' : 'success';

  return (
    <Button className="complete-exercise" bsStyle={bsStyle} onClick={onClick} disabled={disabled}>
      {shouldComplete ? (
        <FormattedMessage id="XPlayer.Teacher.Completed" />
      ) : (
        <FormattedMessage id="XPlayer.Teacher.Activate" />
      )}
    </Button>
  );
};
