import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';

import {type Role} from 'store/interface';
import {type ChoicesMap} from 'store/exercise/player/interface';
import {matchingAnswerReturn} from 'store/exercise/player/widgets/Matching/actions';
import {
  type MatchingNoCategoriesProperties,
  type MatchingProperties
} from 'store/exercise/player/widgets/Matching/interface';

import Choice from '../../GapFill/component/Pool/Choice';
import DragGap from '../../../components/DragGap';
import {type DndContextShape, useDndContext} from '../../../contexts/dndContext';
import {PointerElementListener} from '../../../../Pointer/element/PointerElementListener';
import {type InputChoiceDragObject} from '../../GapFill/component/dnd-input/DNDInputChoice';
import {DndTypes} from '../../../../dnd/interface';

// todo: reconsider using same pool component as in GapFill widget, 80% of the code is similar

interface OwnProps {
  role: Role;
  widget: MatchingProperties | MatchingNoCategoriesProperties;
  preview?: boolean;
  closed?: boolean;
  inPortal?: boolean;
}

interface Props extends OwnProps, DndContextShape {}

const Pool: FC<Props> = ({role, widget, selectedCardId, inPortal, preview, closed, selectCard}) => {
  const dispatch = useDispatch();

  const values = widget.values ? widget.values : undefined;

  const [{isOver, canDrop, dropChoiceId}, collectDropTarget] = useDrop({
    accept: DndTypes.X_GAP,
    canDrop({widgetId}) {
      return widgetId === widget.id && !!values;
    },
    drop({choiceId}, monitor: DropTargetMonitor) {
      if (monitor.didDrop()) {
        return;
      }
      clearGap(choiceId);
      return {
        choiceId: choiceId
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropChoiceId: monitor.getItem<InputChoiceDragObject>()
        ? monitor.getItem<InputChoiceDragObject>().choiceId
        : undefined
    })
  });

  const clearGap = (choiceId: string) => {
    dispatch(matchingAnswerReturn(widget.id, choiceId, preview));
  };

  const choiceIsUsed = (choiceId?: string) => {
    return widget.choiceIsUsed(choiceId);
  };

  const renderChoice = (choiceId: string) => {
    const answer = widget.choices!.get(choiceId).value;
    const isUsed = choiceIsUsed(choiceId);
    const value = answer;
    if (role !== 'student' || closed) {
      return (
        <PointerElementListener preview={preview} key={choiceId}>
          <DragGap
            id={choiceId}
            className={classNames('x-dnd-card static', {
              disabled: isUsed
            })}
            answer={value}
            flexHandle={true}
          />
        </PointerElementListener>
      );
    }

    return (
      <PointerElementListener preview={preview} key={choiceId}>
        <Choice
          id={choiceId}
          choiceId={choiceId}
          key={choiceId}
          widgetId={widget.id}
          answer={value}
          disabled={isUsed}
          isOver={
            (isOver && canDrop && dropChoiceId === choiceId) ||
            (selectedCardId === choiceId && isUsed)
          }
          selected={choiceId === selectedCardId && !isUsed}
          onSelect={selectCard}
          flexHandle={true}
        />
      </PointerElementListener>
    );
  };

  const renderExample = (exampleId: string, index: number) => {
    const {matchingExamples} = widget;
    const value = matchingExamples!.get(exampleId).value;
    const id = `${widget.id}-example-${index + 1}`;
    return (
      <PointerElementListener preview={preview} key={exampleId}>
        <DragGap
          id={id}
          key={exampleId}
          className={classNames('x-dnd-card static', {
            disabled: true
          })}
          answer={value}
          flexHandle={true}
        />
      </PointerElementListener>
    );
  };

  const returnSelectedCardToPool = () => {
    if (selectedCardId) {
      clearGap(selectedCardId);
    }
  };

  const renderPool = (choices: ChoicesMap, matchingExamples?: ChoicesMap) => {
    return (
      <div
        className={classNames('x-gap-drag-pool', {
          'is-over': choiceIsUsed(selectedCardId) || (isOver && canDrop),
          'in-portal': inPortal
        })}
        onClick={returnSelectedCardToPool}
      >
        {matchingExamples && matchingExamples.keySeq().map(renderExample)}
        {choices.keySeq().map(renderChoice)}
      </div>
    );
  };

  if (!widget.choices) {
    return null;
  }
  const pool = renderPool(widget.choices, widget.matchingExamples);
  return role !== 'teacher' && values ? collectDropTarget(pool) : pool;
};

const ConnectedPoolWithContext: FC<OwnProps> = props => {
  const {selectCard, selectedCardId} = useDndContext();

  const onSelectCard = useCallback(
    (id?: string | undefined, e?: React.MouseEvent<HTMLDivElement>) => {
      e && !selectedCardId && e.stopPropagation();
      selectCard(id);
    },
    [selectCard, selectedCardId]
  );
  return <Pool {...props} selectCard={onSelectCard} selectedCardId={selectedCardId} />;
};

export default ConnectedPoolWithContext;
