import {xplayerPrefix} from '../../actionTypes';
import {type WidgetAction} from '../../interface';
import {DROP_CARD} from './actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';

export interface DropCardAction extends WidgetAction {
  sentenceId: string;
  cardIds: string[];
}

export const dropCard = (
  sentenceId: string,
  cardIds: string[],
  widgetId: string,
  preview?: boolean
): DropCardAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${DROP_CARD}`,
    sentenceId,
    cardIds,
    widgetId,
    preview
  };
};
