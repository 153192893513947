import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Block, type Next} from '@englex/slate';

import {type MultipleChoiceElement} from 'store/exercise/player/widgets/List/interface';

import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';
import PlayerAnswerComponent from '../../../../../XPlayer/widgets/List/MultipleChoice/components/MCAnswerComponent';

export default class MCAnswerRenderer implements Plugin {
  constructor(
    public readonly element: MultipleChoiceElement,
    private readonly preview?: boolean
  ) {}

  public renderBlock = (
    {node, attributes, children, parent}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ANSWER)) {
      return next();
    }
    const answerId = node.data.get('id');
    const questionId = (parent as Block).data.get('id');

    return (
      <div {...attributes} className="mc-answer">
        <PlayerAnswerComponent
          element={this.element}
          answerId={answerId}
          questionId={questionId}
          preview={this.preview}
          editor={editor}
        >
          {children}
        </PlayerAnswerComponent>
      </div>
    );
  };
}
