import {xplayerPrefix} from '../../actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';
import {PICTURE_CHOICE_TOGGLE_VALUE, type SelectCard} from './actionTypes';

export const selectCard = (
  widgetId: string,
  questionId: string,
  cardId: string,
  preview?: boolean
): SelectCard => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;

  return {type: prefix + PICTURE_CHOICE_TOGGLE_VALUE, widgetId, questionId, cardId, preview};
};
