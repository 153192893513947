import React from 'react';
import {type Plugin} from '@englex/slate-react';

import {WidgetType, type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type MultipleChoiceProperties} from 'store/exercise/player/widgets/List/interface';

import SlatePlayer from '../../../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../../../Slate/plugins/renderers/Bold';
import Icon from '../../../../Slate/plugins/renderers/Icon';
import Highlight from '../../../../Slate/plugins/renderers/Highlight';
import Italic from '../../../../Slate/plugins/renderers/Italic';
import StrikeThrough from '../../../../Slate/plugins/renderers/StrikeThrough';
import Underline from '../../../../Slate/plugins/renderers/Underline';
import MCAnswerRenderer from '../../../../Slate/plugins/renderers/QuestionsList/MultipleChoice/MCAnswerRenderer';
import QuestionsList from '../../../../Slate/plugins/renderers/QuestionsList/QuestionsList';
import MCQuestionRenderer from '../../../../Slate/plugins/renderers/QuestionsList/MultipleChoice/MCQuestionRenderer';
import {SelectionPointer} from '../../../../Slate/plugins/renderers/Pointer/SelectionPointer';

import './MultipleChoice.scss';

interface StateProps {}

interface DispatchProps {}

type OwnProps = WidgetTypeComponentProps<MultipleChoiceProperties>;

type Props = OwnProps & StateProps & DispatchProps;

class MultipleChoice extends React.PureComponent<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.plugins = [
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new Icon(),
      new Highlight(),
      new MCAnswerRenderer(props.widget.element, props.preview),
      new MCQuestionRenderer(props.widget.horizontal, props.preview),
      new QuestionsList(),
      new SelectionPointer({isAvailable: this.isAvailableSelection})
    ];
  }

  private isAvailableSelection = () => {
    const {widget, preview} = this.props;

    return !preview && widget.type !== WidgetType.FREE_CHOICE;
  };

  public render() {
    const {widget} = this.props;
    const {content} = widget;
    return (
      <div className="x-content">
        <SlatePlayer value={content} plugins={this.plugins} getWidgetProps={this.getWidgetProps} />
      </div>
    );
  }

  private getWidgetProps = () => this.props;
}

export default MultipleChoice;
