import React, {type FC, PureComponent} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

import {dropCard} from 'store/exercise/player/widgets/ScrambledSentences/actions';
import {isIOS, isSafari} from 'helpers/browser';

import {type DndContextShape, useDndContext} from '../../../contexts/dndContext';
import {isSpace, sentenceBreaks, moveCardVertically} from './utils';
import {type Choice, type ChoiceOrPunctuation, type PoolProps as OwnProps} from './interface';
import CardContainer from './CardContainer';

type Props = OwnProps & DndContextShape & {dropCard: typeof dropCard};

interface State {
  dragIndex: number | null;
  hoverIndex: number | null;
  markup?: ChoiceOrPunctuation[];
  selectedIndex?: number;
  selectedCardIndex?: number;
}

class Pool extends PureComponent<Props, State> {
  public state: State = {
    dragIndex: null,
    hoverIndex: null
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedCardId && !this.props.selectedCardId) {
      this.setState({
        selectedIndex: undefined,
        selectedCardIndex: undefined
      });
    }
  }

  public render() {
    const {choicesAndPunctuation, selectedCardId} = this.props;
    const {markup} = this.state;
    let capitalize = true;
    let prevItemIsPunctuation = false;
    return (
      <div
        className={classNames('pool-block', {
          'selection-mod-on': !!selectedCardId,
          ios: isIOS || isSafari()
        })}
      >
        {(markup ? markup : choicesAndPunctuation).map((item: Choice | string, i: number) => {
          if (typeof item === 'string') {
            capitalize =
              (capitalize && prevItemIsPunctuation) || sentenceBreaks.test(item[item.length - 1]);
            prevItemIsPunctuation = true;
            return this.renderPunctuation(item, i);
          }
          prevItemIsPunctuation = false;
          return this.renderCard(item, i, capitalize);
        })}
      </div>
    );
  }

  private renderPunctuation = (item: string, i: number) => {
    const {selectedIndex} = this.state;
    return (
      <span
        key={i}
        className={classNames('punctuation', {
          space: isSpace(item),
          neighbour: selectedIndex !== undefined ? Math.abs(selectedIndex - i) === 1 : false,
          left: selectedIndex && selectedIndex > i,
          right: selectedIndex && selectedIndex < i
        })}
      >
        {item}
      </span>
    );
  };

  private renderCard = (item: Choice, i: number, capitalize: boolean) => {
    const {selectedCardId, sentenceId, role, closed, updateDraggingState, widgetId, preview} =
      this.props;
    const answer = this.getItemAnswer(item.index);
    const value = this.getItemValue(item.id);
    return (
      <CardContainer
        key={i}
        id={item.id}
        index={i}
        cardIndex={item.index}
        selectedCardIndex={this.state.selectedCardIndex}
        selected={selectedCardId === item.id}
        sentenceId={sentenceId}
        widgetId={widgetId}
        answer={answer ? answer.value : undefined}
        dragCaseSensitive={item.capitalize}
        gapCaseSensitive={answer ? answer.capitalize : undefined}
        value={value}
        role={role}
        closed={closed}
        dispatchDragging={updateDraggingState}
        endDrag={this.endDrag}
        moveCard={this.moveCard}
        onSelect={this.onCardSelect}
        capitalize={capitalize}
        preview={preview}
      />
    );
  };

  private onCardSelect = (index: number, cardIndex: number, cardId?: string) => {
    const {selectCard} = this.props;
    selectCard(cardId);
    this.setState({
      selectedIndex: cardId ? index : undefined,
      selectedCardIndex: cardId ? cardIndex : undefined
    });
  };

  private endDrag = () => {
    const {choicesAndPunctuation, dropCard, preview, sentenceId, widgetId} = this.props;
    const {markup} = this.state;
    dropCard(
      sentenceId,
      (markup ? markup : choicesAndPunctuation)
        .filter(item => typeof item !== 'string')
        .map(({id}: Choice) => id),
      widgetId,
      preview
    );
    this.setState({dragIndex: null, hoverIndex: null, markup: undefined});
  };

  private moveCard = (dragIndex: number, hoverIndex: number, cb?: () => void) => {
    this.setState({selectedCardIndex: undefined});
    if (!this.state.markup) {
      this.setState({markup: this.props.choicesAndPunctuation}, () => {
        this.moveCardBody(dragIndex, hoverIndex, cb);
      });
    } else {
      this.moveCardBody(dragIndex, hoverIndex, cb);
    }
  };

  private moveCardBody = (dragIndex: number, hoverIndex: number, cb?: () => void) => {
    let cards: Choice[] = this.state.markup!.filter(item => typeof item !== 'string') as Choice[];
    if (Math.abs(dragIndex - hoverIndex) === 1) {
      cards = cards.map(card => {
        if (card.index === dragIndex) {
          return {...card, index: hoverIndex};
        }
        if (card.index === hoverIndex) {
          return {...card, index: dragIndex};
        }
        return card;
      });
    } else {
      cards = moveCardVertically(cards, dragIndex, hoverIndex);
    }
    this.setState(
      ({markup}) => ({
        dragIndex,
        hoverIndex,
        markup: markup!.map(item =>
          typeof item === 'string' ? item : cards.find(c => c.index === item.index)!
        )
      }),
      () => {
        cb?.();
      }
    );
  };

  private getItemAnswer = (index: number) => {
    const answerId = this.props.answers?.get(index);
    const answer = answerId ? this.props.choices.get(answerId) : undefined;
    return answer && {value: answer.value, capitalize: answer.capitalize};
  };

  private getItemValue = (id: string) => {
    const card = this.props.choices.get(id);
    return card.capitalize ? card.value : card.value.toLowerCase();
  };
}

const ConnectedPool = connect(null, {dropCard})(Pool);

const ConnectedPoolWithContext: FC<OwnProps> = props => {
  const {selectedCardId, selectCard} = useDndContext();
  return <ConnectedPool {...props} selectedCardId={selectedCardId} selectCard={selectCard} />;
};

export default ConnectedPoolWithContext;
