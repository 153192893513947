import {type PropsWithChildren, type ReactChildren} from 'react';
import {connect} from 'react-redux';

import {
  type MatchingNoCategoriesProperties,
  type MatchingProperties
} from 'store/exercise/player/widgets/Matching/interface';
import {type AppState} from 'store/interface';

import GapChecked, {type GapCheckedProps} from '../../GapFill/component/GapChecked';
import {GapFillType} from '../../../../Slate/interface';

interface OwnProps {
  id?: string;
  questionId: string;
  choiceId: string;
  widget: MatchingProperties | MatchingNoCategoriesProperties;
  value: string;
  flexHandle?: boolean;
  className?: string;
}

const mapStateToProps = (
  state: AppState,
  {value, widget, questionId, choiceId, id, className, ...rest}: PropsWithChildren<OwnProps>
): GapCheckedProps => {
  const isFreeChoice = !widget.answers?.size;

  const wasExpectingChoiceWithThisValue = !isFreeChoice
    ? widget.answers!.get(questionId).find(id => widget.choices!.get(id!).value === value)
    : false;

  const receivedChoicesWithThisValue = widget.receivedChoicesWithThisValue(questionId, value);

  return {
    ...rest,
    id,
    className,
    gap: GapFillType.DND,
    value,
    dirty: true,
    isFreeChoice,
    correct:
      // highlight only first selected choice with this value as correct if choice with this value was expected
      !!wasExpectingChoiceWithThisValue && receivedChoicesWithThisValue.indexOf(choiceId) < 1,
    neverChangeCursor: true,
    flexHandle: true
  };
};

// pass only state props in component bc props deconstruction is used there
const mergeProps = (
  stateProps: GapCheckedProps,
  dispatchProps: {},
  ownProps: OwnProps & {children: ReactChildren}
) => ({
  ...stateProps,
  children: ownProps.children
});

export default connect(mapStateToProps, null, mergeProps)(GapChecked);
