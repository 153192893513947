import React, {type FC} from 'react';

import Icon from 'components/Icon';

const FullSentence: FC<{sentence: string}> = ({sentence}) => (
  <div className="full-sentence">
    <Icon name="check-circle" />
    {sentence}
  </div>
);

export default FullSentence;
