import React, {type FC, useRef} from 'react';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import classNames from 'classnames';

import {type Role} from 'store/interface';

import {DndTypes, type DragObject} from '../../../../dnd/interface';
import Card, {type CardProps} from './Card';
import Checked from '../../GapFill/component/dnd/Checked';
import {hover} from './utils';

import './Card.scss';

interface CardContainerProps extends CardProps {
  answer?: string;
  capitalize: boolean;
  gapCaseSensitive?: true;
  dragCaseSensitive?: true;
  role: Role;
  closed?: boolean;

  endDrag: () => void;
  moveCard: (dragIndex: number, hoverIndex: number, cb?: () => void) => void;
  dispatchDragging: (dragging: boolean) => void;

  selected: boolean;
  selectedCardIndex?: number;
  onSelect(index: number, cardIndex: number, id?: string): void;
}

export interface ScrambledSentencesDragObject extends DragObject {
  value: string;
  cardIndex: number;
  sentenceId: string;
  tokenGroupId?: string;
  widgetId: string;
  considerClientOffset: true;
}

const CardContainer: FC<CardContainerProps> = ({
  id,
  answer,
  capitalize,
  value,
  closed,
  role,
  dragCaseSensitive,
  gapCaseSensitive,
  cardIndex,
  index,
  preview,
  selected,
  selectedCardIndex,
  endDrag,
  moveCard,
  sentenceId,
  widgetId,
  onSelect,
  dispatchDragging
}) => {
  const [
    {dragItemIndex, dragItemSentenceId, dragItemWidgetId},
    connectDragSource,
    connectDragPreview
  ] = useDrag({
    type: DndTypes.SCRAMBLED_SENTENCES_CARD,
    item: () => {
      return {
        value: value,
        cardIndex: cardIndex,
        sentenceId: sentenceId,
        widgetId: widgetId,
        considerClientOffset: true
      };
    },
    collect: monitor => {
      const item = monitor.getItem();
      return {
        dragItemIndex: item ? item.cardIndex : null,
        dragItemSentenceId: item ? item.sentenceId : null,
        dragItemWidgetId: item ? item.widgetId : null
      };
    },
    end: () => endDrag()
  });

  const [, connectDropTarget] = useDrop({
    accept: DndTypes.SCRAMBLED_SENTENCES_CARD,
    hover(item: ScrambledSentencesDragObject, monitor: DropTargetMonitor) {
      hover({item, monitor, dropTarget, widgetId, sentenceId, cardIndex, moveCard});
    }
  });

  const dropTarget = useRef<HTMLDivElement | null>(null);

  if (role !== 'student' || closed) {
    return (
      <Checked
        id={id}
        value={value}
        answer={answer}
        preview={preview}
        capitalize={capitalize && closed}
        className={classNames('x-dnd-card static')}
        closed={closed}
        dragCaseSensitive={dragCaseSensitive}
        gapCaseSensitive={gapCaseSensitive}
        useSVGHack={true}
      />
    );
  }

  return (
    <Card
      id={id}
      value={value}
      cardIndex={cardIndex}
      index={index}
      preview={preview}
      selected={selected}
      selectedCardIndex={selectedCardIndex}
      endDrag={endDrag}
      moveCard={moveCard}
      sentenceId={sentenceId}
      widgetId={widgetId}
      onSelect={onSelect}
      dispatchDragging={dispatchDragging}
      dragItemIndex={dragItemIndex}
      dragItemSentenceId={dragItemSentenceId}
      dragItemWidgetId={dragItemWidgetId}
      connectDragSource={connectDragSource}
      connectDragPreview={connectDragPreview}
      connectDropTarget={connectDropTarget}
      dropTarget={dropTarget}
    />
  );
};

export default CardContainer;
