import {type FC} from 'react';
import type React from 'react';
import {useCallback, useEffect} from 'react';
import {useDrag} from 'react-dnd';
import classNames from 'classnames';

import {DndTypes} from 'components/dnd/interface';
import {usePrevious} from 'hooks/usePrevious';
import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';

import DragGap from '../../../../components/DragGap';
import {type PointerListenerProps} from '../../../../../Pointer/element/PointerElementListener';

import './Choice.scss';

interface Props extends PointerListenerProps {
  id: string;
  choiceId: string;
  selectedCardId?: string;
  answer: string;
  widgetId: string;
  isOver?: boolean;
  disabled?: boolean;
  selected?: boolean;
  startOfSentence?: true;
  flexHandle?: true;
  className?: string;
  onSelect?(id?: string, e?: React.MouseEvent<HTMLDivElement>): void;
  useSVGHack?: true;
}

const Choice: FC<Props> = ({
  id,
  answer,
  isOver,
  disabled,
  selected,
  startOfSentence,
  flexHandle,
  useSVGHack,
  onAnimationStart,
  onAnimationEnd,
  choiceId,
  widgetId,
  onSelect,
  onClick,
  className: classNameProps
}) => {
  const [{isDragging}, connectDragSource, connectDragPreview] = useDrag({
    type: DndTypes.X_GAP,
    item: () => {
      return {
        answer,
        choiceId,
        widgetId,
        considerClientOffset: true
      };
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    },
    canDrag: () => !disabled
  });

  useDndEmptyImage(connectDragPreview);

  const className = classNames('x-dnd-card', classNameProps, {
    dragging: isDragging,
    disabled,
    'is-over': isOver,
    selected
  });

  const prevIsDragging = usePrevious(isDragging);

  useEffect(() => {
    if (!prevIsDragging && isDragging) {
      if (onSelect) onSelect();
    }
  });

  const onSelectHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (onSelect) onSelect(choiceId, e);
      if (onClick) onClick(e);
    },
    [choiceId, onClick, onSelect]
  );

  return connectDragSource(
    DragGap({
      id,
      answer,
      className,
      onSelect: onSelectHandler,
      startOfSentence,
      flexHandle,
      useSVGHack,
      onAnimationStart,
      onAnimationEnd
    })
  );
};

export default Choice;
