import React, {type FC, memo, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {type DropTargetMonitor, useDrop} from 'react-dnd';

import {type ImageMatchingProperties} from 'store/exercise/player/widgets/ImageMatching/interface';
import {type Role} from 'store/interface';
import {answerDropped, clearValue} from 'store/exercise/player/widgets/actions';
import {DndTypes} from 'components/dnd/interface';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import Checked from './Checked';
import LoadableImage from '../../../LoadableImage/LoadableImage';
import Choice from '../GapFill/component/Pool/Choice';
import {type DndContextShape, useDndContext} from '../../contexts/dndContext';
import {
  PointerElementListener,
  type PointerListenerRef
} from '../../../Pointer/element/PointerElementListener';

import './Card.scss';

interface OwnProps {
  id: string;
  imageId: number;
  cardSizeType: CardSizeType;
  preview?: boolean;
  widget: ImageMatchingProperties;
  role: Role;
  closed?: boolean;
}

type Props = OwnProps & DndContextShape;

const Card: FC<Props> = memo(
  ({id, role, closed, imageId, preview, selectedCardId, cardSizeType, selectCard, widget}) => {
    const dispatch = useDispatch();

    const pointerListenerRef: PointerListenerRef = useRef(null);
    const shouldShowTooltip = useRef<boolean>(false);

    const [{isOver, canDrop}, collectDropTarget] = useDrop({
      accept: DndTypes.X_GAP,
      canDrop({widgetId, choiceId}) {
        return widgetId === widget.id && choiceId !== chosenId;
      },
      drop({id, choiceId}, monitor: DropTargetMonitor) {
        if (monitor.didDrop()) {
          return;
        }
        onAnswer(choiceId);
        // return drop result, which will be available as monitor.getDropResult() in the drag source's endDrag() method
        return {gapId: id};
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });

    const {choices, values, preFillValues}: ImageMatchingProperties = widget;
    const currentValues = preFillValues && !values ? preFillValues : values;
    const chosenId = currentValues ? currentValues.get(id) : undefined;

    const chosenValue = chosenId
      ? choices && choices.get(chosenId).value
      : chosenId === null
        ? null
        : undefined;
    const choiceIds = widget.answers ? widget.answers.get(id) : undefined;
    const answer = choiceIds ? widget.choices && widget.choices.get(choiceIds[0]).value : undefined;
    const value = chosenValue || '';

    const loadableImageClicable = isOver || !!selectedCardId;

    const uniqId = `${id}-${widget.id}`;

    const imCardClasses = classNames('im-card', {
      clickable: selectedCardId,
      'is-over': isOver || selectedCardId,
      'can-drop': canDrop || selectedCardId,
      'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
    });
    const valueClasses = classNames('x-gap-core', 'x-gap-drop-target', {
      clickable: selectedCardId,
      'is-over': isOver || selectedCardId,
      'can-drop': canDrop || selectedCardId,
      dirty: !!value
    });

    const onAnswer = useCallback(
      (choiceId: string) => {
        dispatch(answerDropped(widget.id, id, choiceId, preview));
      },
      [dispatch, id, preview, widget.id]
    );

    const clearGap = useCallback(
      (choiceId: string) => {
        dispatch(clearValue(widget.id, choiceId, preview));
      },
      [dispatch, preview, widget.id]
    );

    const onCardClick = useCallback(() => {
      if (selectedCardId) {
        onAnswer(selectedCardId);
        selectCard();
      }
    }, [onAnswer, selectCard, selectedCardId]);

    const onMouseDown = useCallback(() => {
      if (pointerListenerRef.current) {
        const {isVisibleTooltip} = pointerListenerRef.current;
        shouldShowTooltip.current = !isVisibleTooltip();
      }
    }, []);

    const onClick = useCallback(() => {
      if (pointerListenerRef.current) {
        const {showTooltip} = pointerListenerRef.current;

        if (shouldShowTooltip.current) {
          showTooltip();
        }
      }
    }, []);

    const choiceOnSelect = useCallback(
      (choiceId?: string, e?: React.MouseEvent<HTMLDivElement>) => {
        if (selectedCardId === choiceId && selectedCardId) {
          e && e.stopPropagation();
          clearGap(selectedCardId);
          selectCard();
          return;
        }
        selectCard(choiceId, e);
      },
      [clearGap, selectCard, selectedCardId]
    );

    if (role !== 'student' || closed) {
      return (
        <div
          className={classNames('im-card', {
            'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
          })}
        >
          <PointerElementListener preview={preview} passive={true}>
            <div
              id={uniqId}
              className="img-block clickable"
              onMouseDown={onMouseDown}
              onClick={onClick}
            >
              <LoadableImage imageId={imageId} width="100%" height="100%" />
            </div>
          </PointerElementListener>
          <Checked
            id={id}
            className="dnd"
            value={chosenValue}
            answer={answer}
            closed={closed}
            flexHandle={true}
            preview={preview}
            relatedElement={uniqId}
            pointerListenerRef={pointerListenerRef}
          />
        </div>
      );
    }

    return collectDropTarget(
      <div className={imCardClasses}>
        <PointerElementListener preview={preview} passive={true}>
          <div id={uniqId} className="img-block">
            <LoadableImage
              imageId={imageId}
              onClick={onCardClick}
              clickable={loadableImageClicable}
              width="100%"
              height="100%"
            />
          </div>
        </PointerElementListener>
        <PointerElementListener preview={preview}>
          <span id={id} className={valueClasses} onClick={onCardClick}>
            {value ? (
              <Choice
                id={chosenId as string}
                choiceId={chosenId as string}
                widgetId={widget.id}
                answer={value}
                selected={chosenId === selectedCardId}
                onSelect={choiceOnSelect}
                flexHandle={true}
                selectedCardId={selectedCardId}
              />
            ) : null}

            <input type="hidden" value={value} />
          </span>
        </PointerElementListener>
      </div>
    );
  }
);

const ConnectedCardWithContext: FC<OwnProps> = props => {
  const {selectCard, selectedCardId} = useDndContext();
  const onSelect = (id?: string, e?: React.MouseEvent<HTMLDivElement>) => {
    e && !selectedCardId && e.stopPropagation();
    selectCard(id);
  };
  return <Card {...props} selectCard={onSelect} selectedCardId={selectedCardId} />;
};

export default ConnectedCardWithContext;
