import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';
import {type Map} from 'immutable';

import {type WidgetComponentProps, type Answer} from 'store/exercise/player/interface';
import {type DndContextShape, useDndContext} from 'components/XPlayer/contexts/dndContext';
import {getWidgetProps} from 'components/Slate/interface';
import {answerDropped, clearValue} from 'store/exercise/player/widgets/actions';
import {
  type GapFillProperties,
  type GapProps as OwnProps
} from 'store/exercise/player/widgets/GapFill/interface';
import {DndTypes} from 'components/dnd/interface';

import Choice from '../Pool/Choice';
import Checked from './Checked';
import {type PointerListenerProps} from '../../../../../Pointer/element/PointerElementListener';
import {getAnswer, getValue, getDragCaseSensitive} from './helpers';

import './DropTarget.scss';

type Props = OwnProps & DndContextShape & PointerListenerProps;

const DropTarget: FC<Props> = ({
  id,
  preview,
  gapCaseSensitive,
  example,
  startOfSentence,
  className,
  selectedCardId,
  onAnimationStart,
  onAnimationEnd,
  selectCard,
  editor,
  answer: exampleAnswer
}) => {
  const dispatch = useDispatch();

  const [{isOver, canDrop}, collectDropTarget] = useDrop({
    accept: DndTypes.X_GAP,
    canDrop({widgetId: itemWidgetId, choiceId: itemChoiceId}) {
      return itemWidgetId === widgetId && itemChoiceId !== choiceId;
    },
    drop({choiceId: itemChoiceId}, monitor: DropTargetMonitor) {
      if (monitor.didDrop()) {
        return;
      }
      onAnswer(itemChoiceId, widgetId);
      return {
        gapId: id
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const {widget, closed, role} =
    editor.query<WidgetComponentProps<GapFillProperties>>(getWidgetProps);
  const values = (widget.values || widget.preFillValues) as Map<string, Answer> | undefined;
  const choiceId: string | undefined | null = values ? values.get(id) : undefined;

  const dragCaseSensitive: true | undefined = getDragCaseSensitive(widget, choiceId);
  const value: string | undefined | null = getValue({example, choiceId, widget, exampleAnswer});
  const answer: Answer | undefined = getAnswer({example, widget, id, exampleAnswer});

  const widgetId = widget.id;
  const orphan = choiceId ? widget.orphanIds?.includes(choiceId) : undefined;
  const propsValue = value || '';

  const classes = classNames('x-gap-core', 'x-gap-drop-target', className, {
    clickable: selectedCardId,
    'is-over': isOver || selectedCardId,
    'can-drop': canDrop || selectedCardId,
    'has-answer': value
  });

  const onAnswer = useCallback(
    (choiceId: string, widgetId: string) => {
      dispatch(answerDropped(widgetId, id, choiceId, preview));
    },
    [dispatch, id, preview]
  );

  const clearGap = useCallback(
    (gapId: string) => {
      dispatch(clearValue(widgetId, gapId, preview));
    },
    [dispatch, widgetId, preview]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (selectedCardId) {
        selectedCardId === choiceId ? clearGap(selectedCardId) : onAnswer(selectedCardId, widgetId);
        selectCard();
      } else if (choiceId) {
        selectCard(choiceId, e);
      }
    },
    [selectCard, onAnswer, clearGap, selectedCardId, widgetId, choiceId]
  );

  if (role !== 'student' || closed || example) {
    return (
      <Checked
        key={id}
        id={id}
        preview={preview}
        value={value}
        answer={answer}
        closed={closed}
        example={example}
        dragCaseSensitive={dragCaseSensitive}
        gapCaseSensitive={gapCaseSensitive}
        startOfSentence={startOfSentence}
        useSVGHack={true}
        orphan={orphan}
      />
    );
  }

  return collectDropTarget(
    <span
      id={id}
      className={classes}
      onClick={onClick}
      onAnimationStart={onAnimationStart}
      onAnimationEnd={onAnimationEnd}
    >
      {value ? (
        <Choice
          key={id}
          id={id}
          choiceId={choiceId!}
          widgetId={widgetId}
          answer={dragCaseSensitive ? value : value.toLowerCase()}
          selected={choiceId === selectedCardId}
          startOfSentence={startOfSentence}
          onAnimationStart={onAnimationStart}
          onAnimationEnd={onAnimationEnd}
          onClick={onClick}
          useSVGHack={true}
        />
      ) : (
        <span className="sizer">{'\uFEFF'}</span>
      )}
      <input type="hidden" value={propsValue} />
    </span>
  );
};

const ConnectedDropTargetWithContext: FC<OwnProps> = props => {
  const {selectedCardId, selectCard} = useDndContext();
  const onSelectCard = useCallback(
    (id?: string, e?: React.MouseEvent<HTMLDivElement>) => {
      e && e.stopPropagation();
      selectCard(id);
    },
    [selectCard]
  );
  return <DropTarget {...props} selectedCardId={selectedCardId} selectCard={onSelectCard} />;
};

export default ConnectedDropTargetWithContext;
