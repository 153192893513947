import React, {type FC} from 'react';
import {type Text} from 'slate';
import {useSlateStatic} from 'slate-react';

import {CreatePointerSelection} from './CreatePointerSelection';
import {PointerSelection} from './PointerSelection';
import {type PointerSelectionDecoration} from '../../../interface';
import {SelectionPointerEditor} from './SelectionPointerEditor';

interface Props {
  leaf: PointerSelectionDecoration;
  text: Text;
}

export const Pointer: FC<Props> = ({leaf, children, text}) => {
  const editor = useSlateStatic();
  return SelectionPointerEditor.isCreatePointerDecoration(editor, leaf) ? (
    <CreatePointerSelection rangeRef={leaf.rangeRef} text={text}>
      {children}
    </CreatePointerSelection>
  ) : SelectionPointerEditor.isPointerDecoration(editor, leaf) ? (
    <PointerSelection id={leaf.elementId}>{children}</PointerSelection>
  ) : (
    <>{children}</>
  );
};
