import React, {type FC, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type CardsProperties} from 'store/exercise/player/widgets/Cards/interface';
import type CardRecord from 'store/exercise/player/widgets/Cards/CardRecord';
import {selectCard} from 'store/exercise/player/widgets/actions';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import Icon from 'components/Slate/plugins/renderers/Icon';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import {Example} from 'components/Slate/plugins/renderers/Example';

import {CardCarousel} from './components';

import './Cards.scss';

const TextPlugins: Plugin[] = [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontSize(),
  new Color(),
  new Highlight(),
  new TextAlignment(),
  new Link(),
  new Lists(),
  new Image(),
  new Icon(),
  new Dialog(),
  new SelectionPointer(),
  Example()
];

type Props = WidgetTypeComponentProps<CardsProperties>;

interface SelectedCard {
  textValue: Value;
  selectedCard: string;
}

const Cards: FC<Props> = React.memo(props => {
  const {widget, preview, closed, role} = props;

  const dispatch = useDispatch();

  const [focused, setFocused] = useState(false);

  const textBlock = useRef<HTMLDivElement>(null);

  const {cardsList, cardsType, values} = widget;

  const firstCard = cardsList.first();

  const teacher = role === 'teacher';

  const {selectedCard, textValue} = cardsList.reduce(
    (result: SelectedCard, card: CardRecord) => {
      if (card.id === values) {
        return {textValue: card.text, selectedCard: card.id};
      }

      return result;
    },
    {textValue: firstCard.text, selectedCard: firstCard.id}
  );

  const onChange = (index: number) => {
    const card = cardsList.get(index);

    if (!closed && card.id !== selectedCard) {
      dispatch(selectCard(widget.id, card.id, preview));
    }
  };

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  const getWidgetProps = () => props;

  return (
    <div className="x-content">
      <div
        tabIndex={1}
        className={classNames('x-cards', {closed, teacher})}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <CardCarousel
          type={cardsType}
          items={cardsList}
          selected={selectedCard}
          focused={focused}
          closed={closed}
          preview={preview}
          onChange={onChange}
        />

        <div ref={textBlock} className="selected-card-text">
          {textValue && (
            <SlatePlayer
              key={selectedCard}
              value={textValue}
              plugins={TextPlugins}
              trimEmptyTrailingParagraphs={true}
              getWidgetProps={getWidgetProps}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Cards;
