import React, {type FC, useRef, memo} from 'react';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import {type ScrambledSentencesDragObject} from 'components/XPlayer/widgets/ScrambledSentences/components/CardContainer';
import {hover} from 'components/XPlayer/widgets/ScrambledSentences/components/utils';

import {DndTypes} from '../../../../../dnd/interface';
import Checked from '../../../GapFill/component/dnd/Checked';
import {SpellingCard} from '../SpellingCard/SpellingCard';

import './CardContainer.scss';

interface SpellingDragObject extends ScrambledSentencesDragObject {
  tokenGroupId?: string;
}

interface CardContainerProps {
  id: string;
  cardIndex: number;
  index: number;
  value: string;
  tokenGroupId?: string;
  sentenceId: string;
  widgetId: string;
  preview?: boolean;
  answer?: string;
  capitalize: boolean;
  gapCaseSensitive?: true;
  dragCaseSensitive?: true;
  role: Role;
  closed?: boolean;
  endDrag: () => void;
  moveCard: (dragIndex: number, hoverIndex: number, cb?: () => void) => void;
  selected: boolean;
  onSelect(index: number, cardIndex: number, id?: string): void;
  moveSelectedCard: (hoverIndex: number) => void;
  unselectCard: () => void;
  selectedCardGroupId?: string;
}

const CardContainer: FC<CardContainerProps> = memo(
  ({
    id,
    answer,
    capitalize,
    value,
    closed,
    role,
    dragCaseSensitive,
    gapCaseSensitive,
    cardIndex,
    index,
    preview,
    selected,
    endDrag,
    moveCard,
    sentenceId,
    widgetId,
    onSelect,
    moveSelectedCard,
    unselectCard,
    tokenGroupId,
    selectedCardGroupId
  }) => {
    const dropTarget = useRef<HTMLDivElement | null>(null);
    const {current} = dropTarget;

    const [{isDragging}, drag, dragPreview] = useDrag(
      {
        type: DndTypes.SPELLING_CARD,
        item: (): SpellingDragObject => {
          return {
            value,
            cardIndex,
            sentenceId,
            widgetId,
            tokenGroupId,
            considerClientOffset: true
          };
        },
        collect: monitor => {
          const isDragging = monitor.isDragging();
          return {
            isDragging
          };
        },
        end: () => endDrag()
      },
      [endDrag, value, cardIndex, widgetId]
    );

    const [{isOver}, drop] = useDrop(
      {
        accept: DndTypes.SPELLING_CARD,
        collect(monitor) {
          return {
            isOver: monitor.isOver()
          };
        },
        hover(item: ScrambledSentencesDragObject, monitor: DropTargetMonitor) {
          hover({
            item,
            monitor,
            dropTarget,
            widgetId,
            sentenceId,
            tokenGroupId,
            cardIndex,
            moveCard
          });
        }
      },
      [moveCard, current, cardIndex, widgetId, sentenceId]
    );

    if (role !== 'student' || closed) {
      return (
        <Checked
          id={id}
          value={value}
          answer={answer}
          preview={preview}
          capitalize={capitalize && closed}
          className={classNames('spelling-checked-card static')}
          closed={closed}
          dragCaseSensitive={dragCaseSensitive}
          gapCaseSensitive={gapCaseSensitive}
          useSVGHack={true}
        />
      );
    }

    return drop(
      <div ref={dropTarget}>
        <SpellingCard
          id={id}
          value={value}
          cardIndex={cardIndex}
          index={index}
          preview={preview}
          selected={selected}
          dragging={isDragging}
          isOver={isOver}
          onSelect={onSelect}
          moveSelectedCard={moveSelectedCard}
          drag={drag}
          dragPreview={dragPreview}
          tokenGroupId={tokenGroupId}
          selectedCardGroupId={selectedCardGroupId}
          unselectCard={unselectCard}
        />
      </div>
    );
  }
);

export default CardContainer;
