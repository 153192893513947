import React from 'react';
import classNames from 'classnames';
import {
  pipe,
  trim,
  removeHidden,
  convertTicks,
  toLowerCase,
  collapseSpaces,
  replaceEmpty,
  pipeline
} from '@englex/utils';

import {GapFillSize, GapFillType} from 'components/Slate/interface';
import XPlayerTooltip from 'components/XPlayer/components/XPlayerTooltip';
import {type Answer} from 'store/exercise/player/interface';

import GapChecked from '../GapChecked';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

interface Props {
  id: string;
  value?: Answer;
  answers?: Answer[];
  closed?: boolean;
  editable?: true;
  example?: boolean;
  indefiniteForm?: string;
  size?: GapFillSize;
  gapType: GapFillType;
  preview?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends React.PureComponent<Props, State> {
  public state: State = {
    visible: false
  };

  public render() {
    const {id, closed, editable, example, indefiniteForm, size, gapType, preview} = this.props;
    const {visible} = this.state;
    const {showTooltip} = this;

    const gap = (
      <GapChecked
        id={id}
        className={classNames(gapType, {
          'with-placeholder': indefiniteForm && editable && !this.isDirty
        })}
        isLarge={size && size === GapFillSize.LARGE ? true : undefined}
        gap={GapFillType.INPUT}
        value={this.value || ''}
        dirty={this.isDirty}
        correct={this.isCorrect}
        multipleAnswers={this.showTooltip}
        empty={this.isEmpty}
        closed={closed}
        example={example}
        dot={showTooltip}
        placeholder={this.placeholder}
      />
    );

    return (
      <PointerElementListener
        elementId={id}
        preview={preview}
        tooltipClassName="x-player-tooltip"
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={this.showTooltipOverlay}
        render={({isAvailable, isTeacher, pointerInlineClassName}) =>
          (isAvailable && isTeacher) || !showTooltip ? (
            <span id={id} className={pointerInlineClassName}>
              {gap}
            </span>
          ) : (
            <span id={id} className={pointerInlineClassName}>
              <XPlayerTooltip
                overlay={this.answers()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          )
        }
      />
    );
  }

  private get placeholder(): string | undefined {
    const {indefiniteForm, editable} = this.props;
    return editable ? undefined : indefiniteForm;
  }

  private get showTooltip(): boolean {
    const {answers, example, closed, indefiniteForm} = this.props;
    const isMultipleAnswers = !!answers && answers.length > 1;
    const shouldShowTooltip = !this.isCorrect || (!closed && !this.isDirty);

    return Boolean(
      indefiniteForm || (example && isMultipleAnswers) || shouldShowTooltip || isMultipleAnswers
    );
  }

  private get showTooltipOverlay(): (() => JSX.Element) | undefined {
    return this.showTooltip ? this.answers : undefined;
  }

  private get isDirty(): boolean {
    return this.props.value !== undefined;
  }

  private get isEmpty(): boolean {
    const {editable, indefiniteForm} = this.props;
    return (!this.isDirty && !(editable && indefiniteForm)) || this.value!.length === 0;
  }

  private get isCorrect(): boolean {
    const {editable, indefiniteForm, example, answers = []} = this.props;
    if (example) {
      return true;
    }
    const {value} = this;
    const mutate = pipe(trim, removeHidden, convertTicks, toLowerCase, collapseSpaces);
    if (this.isDirty) {
      return answers.map(mutate).includes(mutate(value!));
    } else if (editable && indefiniteForm) {
      return answers.map(mutate).includes(mutate(indefiniteForm));
    }
    return answers.includes('');
  }

  private get value(): string | undefined {
    const {value, indefiniteForm, editable} = this.props;
    if (!this.isDirty) {
      return indefiniteForm && editable ? indefiniteForm : undefined;
    }
    return pipeline(value!, trim, removeHidden, collapseSpaces);
  }

  private answers = () => {
    const {answers = [], indefiniteForm, example} = this.props;
    const {value} = this;
    const dirty = this.isDirty;
    const single = answers.length <= 1;
    const mutate = pipe(convertTicks, trim, replaceEmpty('(none)'));
    const onlyPlaceholder = example && answers.length <= 1;

    return (
      <>
        {!indefiniteForm ? null : (
          <span className={classNames('placeholder', {'only-placeholder': onlyPlaceholder})}>
            {indefiniteForm}
          </span>
        )}
        {!onlyPlaceholder && (
          <ul className={classNames('x-gap-answers-hint', 'input', {single})}>
            {answers.map((a: Answer, key: number) => {
              const empty = a === '';
              const answer = dirty && !example && a.toLowerCase() === value!.toLowerCase();
              return (
                <li
                  key={key}
                  className={classNames('correct', {
                    answer,
                    empty
                  })}
                >
                  {mutate(a ? a : '')}
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  };

  private onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible});
  };
}

export default Checked;
