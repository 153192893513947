import React, {type FC} from 'react';
import classNames from 'classnames';

import DragGap from '../../../components/DragGap';
import {type PointerListenerProps} from '../../../../Pointer/element/PointerElementListener';

interface Props extends PointerListenerProps {
  id?: string;
  dragging: boolean;
  activeZoneClickHandler(): void;
  isAfterSelected: boolean;
  selected: boolean;
  selectionIsActive: boolean;
  selectHandler(e: React.MouseEvent<HTMLDivElement>): void;
  value: string;
}

const ActiveZone: FC<{onClick(): void; shouldRender: boolean}> = ({onClick, shouldRender}) =>
  shouldRender ? <span className="card-active-zone" onClick={onClick} /> : null;

const DragComponent: FC<Props> = ({
  id,
  dragging,
  activeZoneClickHandler,
  isAfterSelected,
  selected,
  selectionIsActive,
  selectHandler,
  value,
  onAnimationEnd,
  onAnimationStart
}) => {
  const shouldRenderActiveZone = selectionIsActive && !selected;
  const shouldRenderBefore = shouldRenderActiveZone && !isAfterSelected;
  const shouldRenderAfter = shouldRenderActiveZone && isAfterSelected;
  return (
    <>
      <ActiveZone onClick={activeZoneClickHandler} shouldRender={shouldRenderBefore} />
      {DragGap({
        id,
        answer: value,
        className: classNames('x-dnd-card', {dragging, selected}),
        onSelect: selectHandler,
        useSVGHack: true,
        onAnimationEnd,
        onAnimationStart
      })}
      <ActiveZone onClick={activeZoneClickHandler} shouldRender={shouldRenderAfter} />
    </>
  );
};

export default DragComponent;
