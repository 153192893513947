import React from 'react';
import classNames from 'classnames';

import {type Answer} from 'store/exercise/player/interface';

import XPlayerTooltip from '../../../../components/XPlayerTooltip';
import GapChecked from '../GapChecked';
import {GapFillSize, GapFillType} from '../../../../../Slate/interface';
import Icon from '../../../../../Icon';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

interface Choices {
  [key: string]: Answer;
}

interface Props {
  id: string;
  value?: Answer;
  choices: Choices;
  answer?: Answer;
  closed?: boolean;
  example?: boolean;
  size?: GapFillSize;
  preview?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends React.PureComponent<Props, State> {
  public state: State = {
    visible: false
  };

  public render() {
    const {id, closed, example, size, preview} = this.props;
    const {visible} = this.state;
    const multipleAnswers = Object.keys(this.props.choices).length > 1;

    const gap = (
      <GapChecked
        isLarge={size && size === GapFillSize.LARGE ? true : undefined}
        gap={GapFillType.DROPDOWN}
        value={this.printValue}
        dirty={this.isDirty}
        correct={this.isCorrect}
        empty={this.isEmpty}
        closed={closed}
        example={example}
        dot={true}
        childPullRight={true}
        multipleAnswers={multipleAnswers}
      >
        <Icon name="angle-down" className="arrow" />
      </GapChecked>
    );

    return (
      <PointerElementListener
        elementId={id}
        preview={preview}
        tooltipClassName="x-player-tooltip"
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={this.choices}
        render={({isAvailable, isTeacher, pointerInlineClassName}) =>
          isAvailable && isTeacher ? (
            <span id={id} className={pointerInlineClassName}>
              {gap}
            </span>
          ) : (
            <span id={id} className={pointerInlineClassName}>
              <XPlayerTooltip
                overlay={this.choices()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          )
        }
      />
    );
  }

  private get isDirty(): boolean {
    return this.props.value !== undefined;
  }

  private get isEmpty(): boolean {
    const {value} = this.props;
    return !this.isDirty || value!.length === 0;
  }

  private get isCorrect(): boolean {
    const {value, answer, example} = this.props;
    if (example) {
      return true;
    }
    if (this.isDirty) {
      return answer === value;
    }
    return answer !== undefined && answer === '';
  }

  private get printValue(): string {
    const {value} = this.props;
    return value ? value : this.isCorrect ? '  ' : '';
  }

  private fullValue(value?: string): string {
    return value ? value : '(none)';
  }

  private choices = () => {
    const {choices, value, answer} = this.props;
    return (
      <ul className="x-gap-answers-hint dropdown">
        {Object.keys(choices).map((a: Answer, key: number) => (
          <li
            key={key}
            className={classNames({
              answer: choices[a] === value,
              correct: choices[a] === answer,
              empty: choices[a].length === 0
            })}
          >
            {this.fullValue(a)}
          </li>
        ))}
      </ul>
    );
  };

  private onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible});
  };
}

export default Checked;
