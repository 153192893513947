import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/lib/Collapse';
import {connect} from 'react-redux';
import type Button from 'react-bootstrap/lib/Button';
import {Editor, type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Bold from 'components/Slate/plugins/renderers/Bold';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import Underline from 'components/Slate/plugins/renderers/Underline';
import Italic from 'components/Slate/plugins/renderers/Italic';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import IconPlugin from 'components/Slate/plugins/renderers/Icon';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Dialogs from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import Label from 'components/Slate/plugins/renderers/Label/Label';
import {insertLabel} from 'components/Slate/plugins/renderers/Label/utils';
import Icon from 'components/Icon';
import {type WidgetComponentProps} from 'store/exercise/player/interface';
import {type CommentProperties, CommentType} from 'store/exercise/player/widgets/Comment/interface';
import {commentIconSet} from 'store/exercise/player/widgets/Comment/commentDataByType';
import {toggleCollapsible} from 'store/exercise/player/widgets/actions';
import {genKey} from 'components/Slate/utils';

import ExerciseActions from '../../components/ExerciseActions/ExerciseActions';
import ExerciseLabelComponent from '../../components/ExerciseLabelComponent';
import {VisibilityButton} from '../../components/Transcript/VisibilityButton';
import {ShowGrammarRules} from '../ShowGrammarRules';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

import './Comment.scss';

interface State {
  key?: string;
  animate?: true;
  expanded: boolean;
}

type Props = WidgetComponentProps<CommentProperties> &
  WrappedComponentProps & {
    toggleCollapsible(widgetId: string, preview?: boolean): void;
  };

class CommentWidgetComponent extends Component<Props, State> {
  static animationTime = 350;

  public static titlePlugins: Plugin[] = [
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new Highlight(),
    new IconPlugin(),
    new IconPlugin({iconset: commentIconSet}),
    new Label()
  ];

  private readonly plugins: Plugin[] = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new IconPlugin(),
    new Dialogs()
  ];

  private readonly titlePlugins = CommentWidgetComponent.titlePlugins;

  public state: State = {
    expanded: this.isExpanded
  };

  constructor(props: Props) {
    super(props);

    this.plugins = this.plugins.concat(
      new Image({
        isModal: this.props.isModal,
        pointerOptions: {
          isAvailable: this.isPointerAvailable,
          onSelected: this.onSelected
        }
      })
    );

    if (this.isSuggestedAnswers) {
      this.titlePlugins = CommentWidgetComponent.titlePlugins.concat(
        new SelectionPointer({
          isAvailable: this.isPointerAvailable
        })
      );

      this.plugins = this.plugins.concat(
        new SelectionPointer({
          isAvailable: this.isPointerAvailable,
          onSelected: this.onSelected
        })
      );
    }
  }

  private get isExpanded(): boolean {
    const isCollapsed = this.props.widget.isCollapsed;
    const isWidgetExpanded = isCollapsed !== undefined ? !isCollapsed : false;

    return isWidgetExpanded || this.isStudent || this.isTeachingNotes || this.isTeachingTipsWithBtn;
  }

  private isPointerAvailable = () => {
    const {
      widget: {values}
    } = this.props;

    const show = values?.get('showCollapsible');

    return this.props.role === 'teacher' ? Boolean(show) : true;
  };

  private onSelected = (showSelection: Function) => {
    if (this.state.expanded) {
      return showSelection();
    }

    this.setState(
      state => ({...state, expanded: true}),
      () => setTimeout(showSelection, CommentWidgetComponent.animationTime)
    );
  };

  private get isStudent(): boolean {
    return this.props.role === 'student';
  }

  private get isTeachingNotes(): boolean {
    return this.props.widget.commentType === CommentType.TEACHING_NOTES;
  }

  private get isSuggestedAnswers(): boolean {
    return this.props.widget.commentType === CommentType.SUGGESTED_ANSWERS;
  }

  private get isTeachingTips(): boolean {
    return this.props.widget.commentType === CommentType.TEACHING_TIPS;
  }

  private get isTeachingTipsWithBtn(): boolean {
    return this.isTeachingTips && Boolean(this.props.widget.displayButton);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !prevProps.widget.values?.get('showCollapsible') &&
      this.props.widget.values?.get('showCollapsible') &&
      this.isStudent
    ) {
      this.setState({animate: true});
    }

    if (this.props.role !== prevProps.role) {
      this.setState(prevState => ({
        ...prevState,
        key: genKey(),
        expanded: this.isExpanded
      }));
    }
  }

  public render() {
    const {
      widget: {content, commentLabel, values, id, commentType},
      closed,
      isExtra,
      exerciseId,
      preview,
      role,
      isHeadWidget,
      isHomeworkPlayer,
      isGrammarPlayer,
      showPreviewExerciseNumber
    } = this.props;
    const {key, animate, expanded} = this.state;
    const shouldRenderExerciseActions = !isHomeworkPlayer && isHeadWidget;
    const show = values?.get('showCollapsible');
    const className = classNames(`x-widget Comment ${commentType}`, {
      animate,
      'teaching-tips-modal': this.isTeachingTipsWithBtn
    });
    return (
      <>
        {(isExtra || shouldRenderExerciseActions) && (
          <div
            className={classNames('x-exercise-controls comment-is-first', {'is-extra': isExtra})}
          >
            {!!commentLabel && (
              <ExerciseLabelComponent
                exerciseId={exerciseId}
                getTooltipContainer={() => ReactDOM.findDOMNode(this) as HTMLElement}
                preview={preview}
              >
                {commentLabel}
              </ExerciseLabelComponent>
            )}
            {shouldRenderExerciseActions && (
              <ExerciseActions
                preview={preview}
                role={role}
                exerciseId={exerciseId}
                showPreviewHomeworkControl={showPreviewExerciseNumber}
                isMain={!isExtra}
              />
            )}
          </div>
        )}
        <ShowGrammarRules show={isHeadWidget && !isGrammarPlayer} exerciseId={exerciseId} />
        {(show || !this.isStudent) && (
          <div id={`x-widget-${id}`} className={className}>
            <div className={classNames('title', 'collapsible')} onClick={this.toggle}>
              <SlatePlayer
                value={this.task}
                plugins={this.titlePlugins}
                getWidgetProps={this.getWidgetProps}
              />
              <VisibilityButton
                nullPredicate={this.isStudent || !this.isSuggestedAnswers}
                disabled={closed}
                show={show}
                toggleVisibility={this.toggleVisibility}
              />
              {!this.isTeachingTipsWithBtn && (
                <Icon
                  name="angle-down"
                  size="lg"
                  className={`expand-arrow${expanded ? ' expanded' : ''}`}
                />
              )}
            </div>
            <Collapse key={key} in={expanded}>
              <div className="comment-content">
                <div className="sizer">
                  <SlatePlayer
                    className="x-content sm"
                    value={content}
                    plugins={this.plugins}
                    key="content"
                    trimEmptyTrailingParagraphs={true}
                    getWidgetProps={this.getWidgetProps}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </>
    );
  }

  private toggle = () => {
    const selection = window.getSelection();

    if (selection?.toString().length || this.isTeachingTipsWithBtn) return;

    this.setState({animate: undefined, expanded: !this.state.expanded});
  };

  private toggleVisibility = (e: React.MouseEvent<Button, MouseEvent>) => {
    const {preview, toggleCollapsible, widget} = this.props;
    e.stopPropagation();
    toggleCollapsible(widget.id, preview);
  };

  private get task(): Value {
    const {intl} = this.props;
    const {task, values} = this.props.widget;
    if (!this.isSuggestedAnswers) return task;
    const show = values?.get('showCollapsible');
    return new Editor({value: task})
      .moveToEndOfDocument()
      .command(
        insertLabel,
        this.isStudent ? 'hidden' : show ? 'label-success' : 'label-danger',
        show
          ? intl.formatMessage({id: 'Common.Visible'})
          : intl.formatMessage({id: 'CoursebookLibrary.CoursebookStatus.Hidden'})
      ).value;
  }

  private getWidgetProps = () => this.props;
}

export default connect(null, {toggleCollapsible})(injectIntl(CommentWidgetComponent));
