import React, {useCallback, useState} from 'react';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';

import {ExerciseTitle} from './ExerciseTitle';
import {Widgets} from './Widgets';
import {ExerciseFooter} from './ExerciseFooter/ExerciseFooter';
import {MediaSources} from './MediaSources/MediaSources';
import {WidgetPreviewModal} from './WidgetPreviewModal';

interface Props {
  isHomeworkPlayer?: boolean;
  isGrammarPlayer?: boolean;
  isWidgetPreviewPlayer?: boolean;
  exercise: ExerciseProperties;
  key: number;
  role: Role;
  preview?: boolean;
  showPreviewExerciseNumber?: boolean;
  showCompleteButton: boolean;
  hasPointer?: boolean;
  isModal?: boolean;
}

export const Exercise: React.FC<Props> = React.memo(
  ({
    isHomeworkPlayer,
    isGrammarPlayer,
    isWidgetPreviewPlayer,
    exercise,
    role,
    showCompleteButton,
    preview,
    showPreviewExerciseNumber,
    hasPointer,
    isModal
  }) => {
    const {
      id,
      title,
      widgets,
      completedAt,
      mainId,
      comments,
      homeworkId,
      mediaSources,
      headWidgetIndex
    } = exercise;

    const [previewWidget, setPreviewWidget] = useState<string>();

    const onHide = useCallback(() => setPreviewWidget(undefined), []);

    const show = Boolean(previewWidget);

    return (
      <>
        <div
          id={id}
          className={classNames('x-exercise', {
            'with-footer': showCompleteButton,
            extra: mainId && !isHomeworkPlayer
          })}
        >
          {!isWidgetPreviewPlayer && <ExerciseTitle title={title} />}
          <Widgets
            widgets={widgets}
            exerciseId={id}
            completedAt={completedAt}
            mainId={mainId}
            role={role}
            preview={preview}
            showPreviewExerciseNumber={showPreviewExerciseNumber}
            isHomeworkPlayer={isHomeworkPlayer}
            isGrammarPlayer={isGrammarPlayer}
            isWidgetPreviewPlayer={isWidgetPreviewPlayer}
            homeworkId={homeworkId}
            headWidgetIndex={headWidgetIndex}
            isPointerAvailable={hasPointer}
            onShowPreviewModal={setPreviewWidget}
            isModal={isModal}
          />
          {!isWidgetPreviewPlayer && <MediaSources mediaSources={mediaSources} />}
        </div>
        {!isWidgetPreviewPlayer && (
          <ExerciseFooter
            comments={comments}
            role={role}
            preview={preview}
            exerciseId={id}
            isCompleted={!!completedAt}
            canCloseExercise={showCompleteButton}
          />
        )}
        <WidgetPreviewModal
          role={role}
          show={show}
          onHide={onHide}
          exercise={exercise}
          widgetId={previewWidget}
        />
      </>
    );
  }
);
