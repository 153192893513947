import {type FC} from 'react';
import {useSlateSelector} from 'slate-react';
import {FormattedMessage} from 'react-intl';

import Count from 'components/Slate/plugins/renderers/Counters/Count';

import {SlateEditor} from '../core';

export const WordCount: FC = () => {
  const count = useSlateSelector(SlateEditor.wordCount);

  return (
    <Count
      count={count}
      message={<FormattedMessage id={'Slate.Plugin.WordCount.Label'} values={{count}} />}
    />
  );
};
