import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {type Value} from '@englex/slate';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import Icon from '../../Icon';
import SlatePlayer from '../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../Slate/plugins/renderers/Bold';
import Italic from '../../Slate/plugins/renderers/Italic';
import Underline from '../../Slate/plugins/renderers/Underline';
import StrikeThrough from '../../Slate/plugins/renderers/StrikeThrough';

import './DisplayButton.scss';

interface Props {
  title: Value;
  icon: string;
  className?: string;
  onClick: () => void;
  getWidgetProps: () => WidgetTypeComponentProps;
}

const plugins = [new Bold(), new Italic(), new Underline(), new StrikeThrough()];

export const DisplayButton: React.FC<Props> = ({
  title,
  className,
  icon,
  getWidgetProps,
  onClick
}) => {
  return (
    <Button onClick={onClick} className={classNames('btn-sm widget-display-btn', className)}>
      <Icon name={icon} />

      <SlatePlayer
        value={title}
        plugins={plugins}
        getWidgetProps={getWidgetProps}
        trimEmptyTrailingParagraphs={true}
      />
    </Button>
  );
};
