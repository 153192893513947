import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import {type AppState, type Role} from 'store/interface';
import {type PointerListenerProps} from 'components/Pointer/element/PointerElementListener';

import './CardNumber.scss';

interface Props extends PointerListenerProps {
  index: number;
  id: string;
  role: Role;
  size?: 'small' | 'big';
  isAbsolutePosition?: boolean;
  disabled?: boolean;
  closed?: boolean;
  isPointer?: boolean;
  widgetTheme?: string;
  onClick?: (e?: React.MouseEvent) => void;
  onMouseDown?: () => void;
}

export const CardNumber: React.FC<Props> = ({
  index,
  id,
  role,
  size = 'small',
  widgetTheme,
  isAbsolutePosition = false,
  disabled = false,
  closed = false,
  isPointer = true,
  onClick,
  onMouseDown,
  ...rest
}) => {
  const inCall = useSelector<AppState, boolean>(state => Boolean(state.rtc.callInProgress));

  const makeCardsNumber = () => {
    return index + 1;
  };

  return (
    <div {...rest} id={id}>
      <div
        className={classNames('card-number', size, widgetTheme, {
          'is-absolute-position': isAbsolutePosition,
          'is-teacher': role === 'teacher',
          disabled: disabled,
          'is-closed': closed,
          'in-call': inCall
        })}
      >
        <div
          className={classNames('card-number__container')}
          onClick={role === 'teacher' ? onClick : undefined}
          onMouseDown={role === 'teacher' ? onMouseDown : undefined}
        >
          {makeCardsNumber()}
        </div>
      </div>
    </div>
  );
};
