import React from 'react';
import {Editor, Element, type NodeEntry, type Range} from 'slate';

import {pointerAnimationTimeout} from 'config/static';

import {type EditableProps} from '../../../interface';
import {type ContainerEditor} from '../../core';
import {type SlatePlugin} from '../../withPlugins';
import {Listener} from '../../../../Pointer/selection/SelectionListener';
import {SlateLeaf} from '../../../components/SlateLeaf';
import {Pointer} from './Pointer';
import {SelectionPointerEditor} from './SelectionPointerEditor';

const isDefaultAvailable = () => true;

interface Options {
  id?: string | number;
  isAvailable?: () => boolean;
  onSelected?: (callback: Function) => void;
  animationTimeout?: number;
}

export const withSelectionPointer =
  ({
    id,
    isAvailable = isDefaultAvailable,
    onSelected,
    animationTimeout = pointerAnimationTimeout
  }: Options = {}): SlatePlugin =>
  (editor: Editor & SelectionPointerEditor & ContainerEditor) => {
    editor.pointer = {decorations: [], animationTimeout};

    const {renderContainer: Container, editableProps = {} as EditableProps} = editor;

    const {decorate, renderLeaf: Leaf = SlateLeaf} = editableProps;

    editableProps.decorate = ([node, path]: NodeEntry) => {
      const decorations: Range[] = decorate?.([node, path]) || [];
      const {pointer} = editor;

      const hasDecorations = pointer.decorations.length !== 0;
      const isValidNode =
        Element.isElement(node) && Editor.isBlock(editor, node) && !Editor.isEmpty(editor, node);

      if (hasDecorations && isValidNode) {
        const ranges: Range[] = pointer.decorations.reduce<Range[]>((result, decoration) => {
          const {current: range} = decoration.rangeRef;

          return range ? result.concat({...decoration, ...range}) : result;
        }, []);

        return decorations.concat(ranges);
      }

      return decorations;
    };

    editableProps.renderLeaf = props => {
      const {leaf, text} = props;

      const children = SelectionPointerEditor.isPointer(editor, leaf) ? (
        <Pointer leaf={leaf} text={text}>
          {props.children}
        </Pointer>
      ) : (
        props.children
      );

      return <Leaf {...props}>{children}</Leaf>;
    };

    editor.renderContainer = props => {
      return (
        <Container {...props}>
          <Listener id={id} editor={editor} isAvailable={isAvailable} onSelected={onSelected}>
            {props.children}
          </Listener>
        </Container>
      );
    };

    return editor;
  };
