import React from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';

import './ExpandButton.scss';

const messages = defineMessages({
  ExpandButton: {
    id: 'Slate.Plugin.Image.ExpandButton.Title'
  }
});

interface Props {
  onClick?: () => void;
}

export function ExpandButton({onClick}: Props) {
  const intl = useIntl();

  return (
    <Button
      className="expand-button"
      bsStyle="success"
      onClick={onClick}
      title={intl.formatMessage(messages.ExpandButton)}
    >
      <Icon tag="i" className="expand-button__icon" name="search-plus" />
      <FormattedMessage id="Slate.Plugin.Image.ExpandButton.Title" />
    </Button>
  );
}
