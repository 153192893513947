import React, {type FC, type MouseEvent, useCallback} from 'react';
import {Editor, Path, Range, type RangeRef} from 'slate';
import {ReactEditor, type RenderLeafProps, useSlateSelector} from 'slate-react';

import {SelectionTooltip} from '../../../../Pointer/selection/SelectionTooltip';
import {SlateEditor} from '../../core';

const stopPropagation = (e: MouseEvent<HTMLElement>) => e.stopPropagation();

interface Props extends Pick<RenderLeafProps, 'children' | 'text'> {
  exerciseId: string;
  hasTooltip: boolean;
}

const CreatePointer: FC<Props> = ({exerciseId, hasTooltip, children, text}) => {
  const closestBlockElement = useSlateSelector(editor => {
    try {
      const textPath = ReactEditor.findPath(editor, text);
      const [parentBlock] = Editor.parent(editor, textPath, {edge: 'end'});
      return ReactEditor.toDOMNode(editor, parentBlock);
    } catch {
      // ReactEditor API could throw Error, so use fallback to editor container or body
      return document.getElementById(SlateEditor.id(editor)) || document.body;
    }
  });

  const getTooltipContainer = useCallback(() => closestBlockElement, [closestBlockElement]);

  const content = <span className="slate-create-pointer bg">{children}</span>;

  return (
    <span onClick={stopPropagation}>
      {hasTooltip ? (
        <SelectionTooltip exerciseId={exerciseId} getTooltipContainer={getTooltipContainer}>
          {content}
        </SelectionTooltip>
      ) : (
        content
      )}
    </span>
  );
};

interface CreatePointerProps extends Pick<RenderLeafProps, 'children' | 'text'> {
  rangeRef: RangeRef;
}

export const CreatePointerSelection: FC<CreatePointerProps> = ({rangeRef, text, children}) => {
  const widgetProps = useSlateSelector(editor => editor.getWidgetProps());
  const path = useSlateSelector(editor => ReactEditor.findPath(editor, text));

  const {exerciseId} = widgetProps;
  const {current: range} = rangeRef;

  if (range && Range.isExpanded(range)) {
    const hasTooltip = Path.equals(range.focus.path, path);

    return (
      <CreatePointer exerciseId={exerciseId} hasTooltip={hasTooltip} text={text}>
        {children}
      </CreatePointer>
    );
  }
  return children;
};
