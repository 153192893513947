import React, {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {type List} from 'immutable';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {switchIsHiddenCard} from 'store/exercise/player/widgets/WordPictureSet/actions';
import {
  type PictureSetCardMetaDataCardJSON,
  type PictureSetProperties
} from 'store/exercise/player/widgets/WordPictureSet/PictureSet/interface';

import {ControlPanel} from '../components/ControlPanel/ControlPanel';
import {PictureSetCard} from './components/PictureSetCard/PictureSetCard';

import './PictureSet.scss';

type Props = WidgetTypeComponentProps<PictureSetProperties>;

export const PictureSet: React.FC<Props> = memo(
  ({
    widget: {cards, values, id, defaultValues, isNotInteractive, widgetTheme},
    preview,
    role,
    closed
  }) => {
    const dispatch = useDispatch();

    const onSwitchIsImageHidden = useCallback(
      (cardId: string) => {
        dispatch(switchIsHiddenCard(id, cardId, preview));
      },
      [dispatch, id, preview]
    );

    const cardIds = values.get('cardIds') as List<PictureSetCardMetaDataCardJSON>;
    const isCollaborativeManagement = values.get('isCollaborativeManagement') as boolean;

    const defaultCardIds = defaultValues.get('cardIds') as List<PictureSetCardMetaDataCardJSON>;

    const haveHiddenCards = cardIds?.some(value => value!.isImageHidden!);
    let index = 0;

    return (
      <div className="picture-set">
        <div className="picture-set__cards">
          {cardIds.size
            ? cardIds.map((item: PictureSetCardMetaDataCardJSON, index: number) => {
                const card = cards.get(item.cardId);
                return (
                  <PictureSetCard
                    key={item.cardId}
                    image={card.image}
                    isImageHidden={item.isImageHidden}
                    isNotInteractive={isNotInteractive}
                    index={index}
                    role={role}
                    cardId={item.cardId}
                    closed={closed || !values}
                    preview={preview}
                    isCollaborativeManagement={isCollaborativeManagement}
                    cardSizeType={card.cardSizeType}
                    onSwitchIsImageHidden={onSwitchIsImageHidden}
                    widgetTheme={widgetTheme}
                  />
                );
              })
            : defaultCardIds.map((item: PictureSetCardMetaDataCardJSON) => {
                const card = cards.get(item.cardId);
                return (
                  <PictureSetCard
                    key={card.id}
                    image={card.image}
                    isImageHidden={false}
                    isNotInteractive={isNotInteractive}
                    index={index++}
                    role={role}
                    cardId={card.id}
                    closed={closed || !values}
                    preview={preview}
                    isCollaborativeManagement={isCollaborativeManagement}
                    cardSizeType={card.cardSizeType}
                    onSwitchIsImageHidden={onSwitchIsImageHidden}
                    widgetTheme={widgetTheme}
                  />
                );
              })}
        </div>

        {((role === 'teacher' && !isNotInteractive) || !!isCollaborativeManagement) && (
          <ControlPanel
            haveHiddenCards={haveHiddenCards}
            widgetId={id}
            isPreview={preview}
            closed={closed || !values}
            withPadding
            isCollaborativeManagement={isCollaborativeManagement}
            role={role}
          />
        )}
      </div>
    );
  }
);
