import {GapFillType} from '../../../../Slate/interface';
import GapFill from './GapFill';
import Input from '../component/input/Input';

class GapFillInput extends GapFill {
  public gap = GapFillType.INPUT;
  protected Gap = Input;
}

export default GapFillInput;
