import {type ThunkAction} from 'redux-thunk';
import {stageToPngBlob, type useStageRef} from '@englex/paint-react';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {EXERCISE_FEEDBACK_SEND_REQUEST} from './actionTypes';

const exerciseFeedbackSendRequest = (
  exerciseInstanceId: string,
  image: Blob,
  message: string,
  pixelRatio: number = 1
): AxiosRequestAction => {
  const data = new FormData();
  data.append('exerciseInstanceId', exerciseInstanceId);
  data.append('screenshot', image, 'exercise-feedback.png');
  data.append('pixelRatio', String(pixelRatio));
  message.length && data.append('message', message);
  return {
    type: EXERCISE_FEEDBACK_SEND_REQUEST,
    payload: {
      client: 'v2',
      request: {
        method: 'post',
        url: 'v2/exercise-feedback',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }
    }
  };
};

export const exerciseFeedbackSend =
  (
    exerciseInstanceId: string,
    stageRef: ReturnType<typeof useStageRef>,
    message: string,
    pixelRatio = 1
  ): ThunkAction<Promise<void>, AppState, never, Action> =>
  async (dispatch: Dispatch<Action, AppState>) => {
    const stage = stageRef?.current?.getStage();
    if (!stage) {
      throw new Error('Unable to create image from StageRef - Stage is not defined');
    }

    const image = await stageToPngBlob(stage);

    await dispatch(exerciseFeedbackSendRequest(exerciseInstanceId, image, message, pixelRatio));

    return;
  };
