import classNames from 'classnames';
import {type FC, memo} from 'react';

import {isSpace} from 'components/XPlayer/widgets/ScrambledSentences/components/utils';

interface Props {
  item: string;
  index: number;
  selectedIndex?: number;
}

export const Punctuation: FC<Props> = memo(({item, index, selectedIndex}) => {
  return (
    <span
      key={index}
      className={classNames('punctuation', {
        space: isSpace(item),
        neighbour: selectedIndex !== undefined ? Math.abs(selectedIndex - index) === 1 : false,
        left: selectedIndex && selectedIndex > index,
        right: selectedIndex && selectedIndex < index
      })}
    >
      {item.toUpperCase()}
    </span>
  );
});
