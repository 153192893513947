import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {type List} from 'immutable';
import {Node} from 'slate';

import {type AppState, type ExerciseInstanceCommentMap, type Role} from 'store/interface';
import {type DataMap} from 'components/Slate/interface';
import Icon from 'components/Icon';
import {type DraftCommentProperties} from 'store/exercise/player/interface';
import {clearDraftComment, createDraftComment} from 'store/exercise/player/actions';

import {ExerciseFeedback} from '../ExerciseFeedback/ExerciseFeedback';
import {CompleteExercise} from './CompleteExercise';
import {ExerciseComments} from './ExerciseComments';
import {valueFromText} from '../../../SlateJS/utils';

import './ExerciseFooter.scss';

interface Props {
  comments?: List<DataMap<ExerciseInstanceCommentMap>>;
  canCloseExercise?: boolean;
  exerciseId: string;
  isCompleted?: boolean;
  isAutoChecked?: boolean;
  preview?: boolean;
  role: Role;
}

const commentDoesNotExist = (
  commentId: string,
  comments?: List<DataMap<ExerciseInstanceCommentMap>>
): boolean =>
  !comments ||
  !comments
    .map((c: DataMap<ExerciseInstanceCommentMap>) => c.get('id'))
    .toArray()
    .includes(commentId);

const draftCommentIsEmpty = (draftComment: DraftCommentProperties): boolean =>
  !Node.string({children: draftComment.value} as Node).trim().length;

export const ExerciseFooter: FC<Props> = React.memo(
  ({comments, role, preview, isCompleted, canCloseExercise: canCompleteExercise, exerciseId}) => {
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const dispatch = useDispatch();

    const draftComment = useSelector<AppState, DraftCommentProperties | undefined>(s =>
      s.xplayer?.draftComments?.get?.(exerciseId)
    );

    const initialDraftCommentRef = useRef<DraftCommentProperties | undefined>(draftComment);
    const initialCommentsRef = useRef<List<DataMap<ExerciseInstanceCommentMap>> | undefined>(
      comments
    );

    const openCommentEditor = () => {
      dispatch(createDraftComment(exerciseId, null, valueFromText()));
    };
    const closeCommentEditor = () => {
      dispatch(clearDraftComment(exerciseId));
    };

    const closeExerciseFeedback = useCallback(() => setShowReportIssueModal(false), []);

    useEffect(
      function validateDraftCommentOnMount() {
        const draftComment = initialDraftCommentRef.current;
        const comments = initialCommentsRef.current;
        if (draftComment) {
          if (
            draftCommentIsEmpty(draftComment) ||
            (draftComment.id && commentDoesNotExist(draftComment.id, comments))
          ) {
            dispatch(clearDraftComment(exerciseId));
          }
        }
        initialDraftCommentRef.current = undefined;
        initialCommentsRef.current = undefined;
      },
      [dispatch, exerciseId]
    );

    return (
      <div
        className={classNames('x-exercise-footer', {
          visible: role !== 'student' || (!preview && comments)
        })}
      >
        <div className="x-exercise-footer-actions">
          {canCompleteExercise && (
            <CompleteExercise
              exerciseId={exerciseId}
              isCompleted={isCompleted}
              preview={preview}
              role={role}
            />
          )}
          {!preview && (
            <>
              {comments?.size || draftComment?.id === null ? null : (
                <Button
                  bsStyle="success"
                  className={classNames('exercise-add-first-comment-btn', {
                    student: role === 'student'
                  })}
                  onClick={openCommentEditor}
                >
                  <Icon name="comment-o" />
                  <FormattedMessage id="XPlayer.Exercise.AddComment" />
                </Button>
              )}
              <Button
                bsStyle="success"
                onMouseDown={() => setShowReportIssueModal(true)}
                className={classNames('exercise-report-issue-btn', {visible: role !== 'student'})}
              >
                <FormattedMessage id="XPlayer.Exercise.ReportIssue.Button.ModalOpen" />
              </Button>
            </>
          )}
        </div>
        {showReportIssueModal && (
          <ExerciseFeedback exerciseId={exerciseId} close={closeExerciseFeedback} />
        )}
        {!preview && comments && (
          <ExerciseComments
            draftComment={draftComment}
            exerciseId={exerciseId}
            comments={comments}
            canCloseExercise={canCompleteExercise}
            closeCommentEditor={closeCommentEditor}
            openCommentEditor={openCommentEditor}
          />
        )}
      </div>
    );
  }
);
