import React, {memo, useCallback} from 'react';
import classNames from 'classnames';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type FlipCardsMetaData,
  type FlipCardsProperties
} from 'store/exercise/player/widgets/FlipCards/interface';
import type FlipCardsCardRecord from 'store/exercise/player/widgets/FlipCards/FlipCardsCardRecord';

import {FlipCard} from './components/FlipCard/FlipCard';
import {TeacherCard} from './components/TeacherCard/TeacherCard';
import {TextSide} from './components/TextSide/TextSide';
import {TeacherCardText} from './components/TeacherText/TeacherCardText';
import {StudentImageSide} from './components/StudentImageSide/StudentImageSide';

import './FlipCards.scss';

type Props = WidgetTypeComponentProps<FlipCardsProperties>;

export const FlipCards: React.FC<Props> = memo(
  ({widget: {id, cards, values, defaultFlipped}, closed, role, preview}) => {
    const getFrontElement = useCallback(
      (imageId: number) => {
        return role === 'teacher' ? (
          <TeacherCard imageId={imageId} isFlipped={false} />
        ) : (
          <StudentImageSide imageId={imageId} />
        );
      },
      [role]
    );
    const getTeacherText = useCallback(
      (text: string) => {
        return role === 'teacher' ? <TeacherCardText text={text} /> : null;
      },
      [role]
    );

    const getBackElement = useCallback(
      (imageId: number, text: string) => {
        return role === 'teacher' ? (
          <TeacherCard imageId={imageId} isFlipped={true} />
        ) : (
          <TextSide text={text} />
        );
      },
      [role]
    );

    if (cards.size === 0) return <div className="FlipCards" />;

    const classnames = classNames('flip-cards', {'teacher-layout': role === 'teacher'});

    return (
      <div className={classnames}>
        {cards.map((card: FlipCardsCardRecord) => {
          const value = values.find((value: FlipCardsMetaData) => value.cardId === card.id);
          return (
            <FlipCard
              key={card.id}
              cardId={card.id}
              imageId={card.imageId}
              widgetId={id}
              preview={preview}
              text={card.text}
              cardSizeType={card.cardSizeType}
              flipped={value?.flipped}
              defaultFlipped={defaultFlipped}
              role={role}
              closed={closed}
              frontElement={getFrontElement(card.imageId)}
              backElement={getBackElement(card.imageId, card.text)}
              teacherText={getTeacherText(card.text)}
            />
          );
        })}
      </div>
    );
  }
);
