import React, {type FC} from 'react';

import {type CommentHandlers} from '../interface';
import {CommentActions} from './CommentActions';

import './MessageBubble.scss';

interface Props extends CommentHandlers {
  id: string;
  own?: boolean;
  editIsActive: boolean;
}

export const MessageBubble: FC<Props> = ({
  id,
  children,
  editComment,
  deleteComment,
  own,
  editIsActive
}) => (
  <div className="message-bubble">
    {children}
    {own && !editIsActive && (
      <CommentActions id={id} editComment={editComment} deleteComment={deleteComment} />
    )}
    <div className="message-bubble-arrow" />
  </div>
);
