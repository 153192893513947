import React from 'react';

interface Props {
  title: string | null;
}

export const ExerciseTitle: React.FC<Props> = ({title}) => {
  const xTitle = title && title.trim();
  if (!xTitle || xTitle.length <= 0) {
    return null;
  }
  return <div className="x-title">{xTitle}</div>;
};
