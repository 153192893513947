import React, {type PropsWithChildren} from 'react';
import {type Editor, type RenderAttributes} from '@englex/slate-react';

interface QuestionOwnProps {
  attributes: RenderAttributes;
  questionId: string;
  preview?: boolean;
  editor: Editor;
}

export default function OddWordQuestionRenderer({
  attributes,
  children
}: PropsWithChildren<QuestionOwnProps>) {
  return (
    <li {...attributes} className="odd-question-block">
      <div className="odd-question-block__answers">{children}</div>
    </li>
  );
}
