import {type Editor} from 'slate';

import {type SlatePlugin} from '../withPlugins';
import {type ContainerEditor} from '../core';
import {WordCount} from './WordCount';

interface Options {
  enabled?: boolean;
}

export const withWordCount =
  ({enabled = true}: Options = {}): SlatePlugin =>
  (editor: Editor & ContainerEditor) => {
    const {renderContainer: Container} = editor;
    editor.renderContainer = !enabled
      ? Container
      : props => (
          <Container {...props}>
            {props.children}
            <WordCount />
          </Container>
        );
    return editor;
  };
