import {type List, type Map} from 'immutable';

import {type Choice, type ChoiceOrPunctuation} from '../../ScrambledSentences/components/interface';
import {sentenceBreaks} from '../../ScrambledSentences/components/utils';

interface Props {
  markup?: ChoiceOrPunctuation[];
  choicesAndPunctuation: ChoiceOrPunctuation[];
  answers?: List<string>;
  choices: Map<
    string,
    {
      value: string;
      capitalize?: true;
    }
  >;
}

type Type = 'card' | 'punctuation';

export interface CardType {
  type: Type;
  item: Choice;
  answer?: {
    value: string;
    capitalize: true | undefined;
  };
  value: string;
  capitalize: boolean;
}

export interface PunctuationType {
  type: Type;
  item: string;
}

export const useRenderData = ({markup, choicesAndPunctuation, answers, choices}: Props) => {
  const getItemAnswer = (index: number) => {
    const answerId = answers?.get(index);
    const answer = answerId ? choices.get(answerId) : undefined;
    return answer && {value: answer.value, capitalize: answer.capitalize};
  };

  const getItemValue = (id: string) => {
    const card = choices.get(id);
    return card.capitalize ? card.value : card.value.toLowerCase();
  };

  let capitalize = true;
  let prevItemIsPunctuation = false;

  const renderData = (markup ? markup : choicesAndPunctuation).map(
    (item: Choice | string, i: number) => {
      if (typeof item === 'string') {
        capitalize =
          (capitalize && prevItemIsPunctuation) || sentenceBreaks.test(item[item.length - 1]);
        prevItemIsPunctuation = true;
        return {type: 'punctuation', item} as PunctuationType;
      }

      prevItemIsPunctuation = false;
      const answer = getItemAnswer(item.index);
      const value = getItemValue(item.id);
      return {type: 'card', item, answer, value, capitalize} as CardType;
    }
  );

  return renderData;
};
