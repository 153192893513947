import {type Editor as ReactEditor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import {type Block, type Next} from '@englex/slate';
import {Map} from 'immutable';
import classNames from 'classnames';

import {
  type ChoicesMap,
  type DNDChoice,
  type WidgetTypeComponentProps
} from 'store/exercise/player/interface';
import {type MatchingProperties} from 'store/exercise/player/widgets/Matching/interface';

import {isBlockOfType} from '../../../../utils';
import {getWidgetProps as getWidgetPropsQuery, SlateBlock} from '../../../../interface';
import DropTarget from '../../../../../XPlayer/widgets/Matching/component/DropTarget';

import './MatchingQuestion.scss';

export default class MatchingQuestionRenderer implements Plugin {
  private readonly preview?: boolean;

  constructor(preview?: boolean) {
    this.preview = preview;
  }

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: ReactEditor,
    next: Next
  ) => {
    if (
      isBlockOfType(node, SlateBlock.QUESTION_ITEM) ||
      isBlockOfType(node, SlateBlock.LIST_ITEM)
    ) {
      const id = node.data.get('id');
      const exampleAnswersBlocks = node.nodes.filter(block =>
        isBlockOfType(block as Block, SlateBlock.QUESTION_ANSWER)
      );

      const exampleChoicesMap: ChoicesMap | undefined = exampleAnswersBlocks.size
        ? Map<string, DNDChoice>(
            exampleAnswersBlocks.map(block => [
              (block as Block).data.get('id'),
              {value: (block as Block).text}
            ])
          )
        : undefined;

      const className = classNames('matching-question', {
        example: exampleChoicesMap
      });

      const getWidgetProps = () =>
        editor.query<WidgetTypeComponentProps<MatchingProperties>>(getWidgetPropsQuery);

      return (
        <li {...attributes} className={className}>
          {children}
          <DropTarget
            preview={this.preview}
            questionId={id}
            exampleChoices={exampleChoicesMap}
            getWidgetProps={getWidgetProps}
            flexHandle={true}
          />
        </li>
      );
    }

    return next();
  };
}
