import React, {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {type List} from 'immutable';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  switchIsHiddenImage,
  switchIsHiddenText
} from 'store/exercise/player/widgets/WordPictureSet/actions';
import {
  type WordPictureSetCardMetaDataJSON,
  type WordPictureSetProperties
} from 'store/exercise/player/widgets/WordPictureSet/WordPictureSet/interface';

import {ControlPanel} from '../components/ControlPanel/ControlPanel';
import {WordPictureSetCard} from './components/WorldPictureSetCard/WordPictureSetCard';

import './WordPictureSet.scss';

type Props = WidgetTypeComponentProps<WordPictureSetProperties>;

export const WordPictureSet: React.FC<Props> = memo(
  ({
    widget: {cards, values, id, defaultValues, isNotInteractive, widgetTheme},
    preview,
    role,
    closed
  }) => {
    const dispatch = useDispatch();

    const onSwitchIsImageHidden = useCallback(
      (cardId: string) => {
        dispatch(switchIsHiddenImage(id, cardId, preview));
      },
      [dispatch, id, preview]
    );

    const onSwitchIsTextHidden = useCallback(
      (cardId: string) => {
        dispatch(switchIsHiddenText(id, cardId, preview));
      },
      [dispatch, id, preview]
    );

    const cardIds = values.get('cardIds') as List<WordPictureSetCardMetaDataJSON>;
    const isCollaborativeManagement = values.get('isCollaborativeManagement') as boolean;

    const defaultCardIds = defaultValues.get('cardIds') as List<WordPictureSetCardMetaDataJSON>;

    const haveHiddenCards = cardIds?.some(value => value!.isImageHidden! || value!.isTextHidden!);

    let index = 0;

    return (
      <div className="word-picture-set">
        <div className="word-picture-set__cards">
          {cardIds.size
            ? cardIds.map((item: WordPictureSetCardMetaDataJSON, index: number) => {
                const card = cards.get(item.cardId);
                return (
                  <WordPictureSetCard
                    key={item.cardId}
                    image={card.image}
                    text={card.text}
                    isImageHidden={item.isImageHidden}
                    isTextHidden={item.isTextHidden}
                    isNotInteractive={isNotInteractive}
                    isCollaborativeManagement={isCollaborativeManagement}
                    index={index}
                    role={role}
                    cardId={item.cardId}
                    closed={closed}
                    preview={preview}
                    cardSizeType={card.cardSizeType}
                    widgetTheme={widgetTheme}
                    onSwitchIsImageHidden={onSwitchIsImageHidden}
                    onSwitchIsTextHidden={onSwitchIsTextHidden}
                  />
                );
              })
            : defaultCardIds.map((item: WordPictureSetCardMetaDataJSON) => {
                const card = cards.get(item.cardId);
                return (
                  <WordPictureSetCard
                    key={card.id}
                    image={card.image}
                    text={card.text}
                    isImageHidden={false}
                    isTextHidden={false}
                    isNotInteractive={isNotInteractive}
                    isCollaborativeManagement={isCollaborativeManagement}
                    index={index++}
                    role={role}
                    cardId={card.id}
                    closed={closed}
                    preview={preview}
                    cardSizeType={card.cardSizeType}
                    widgetTheme={widgetTheme}
                  />
                );
              })}
        </div>

        {(role === 'teacher' || isCollaborativeManagement) && !isNotInteractive && (
          <ControlPanel
            haveHiddenCards={haveHiddenCards}
            widgetId={id}
            isPreview={preview}
            closed={closed}
            withPadding
            isCollaborativeManagement={isCollaborativeManagement}
            role={role}
          />
        )}
      </div>
    );
  }
);
