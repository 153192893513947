import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Next} from '@englex/slate';

import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';
import OddWordQuestionComponent from '../../../../../XPlayer/widgets/List/OddWord/components/OddWordQestionComponent';

export default class OddWordQuestionRenderer implements Plugin {
  constructor(
    private readonly horizontal?: true,
    private readonly preview?: boolean
  ) {}

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ITEM)) {
      return next();
    }

    return (
      <OddWordQuestionComponent
        attributes={attributes}
        questionId={node.data.get('id')}
        editor={editor}
        preview={this.preview}
      >
        {children}
      </OddWordQuestionComponent>
    );
  };
}
