import React from 'react';
import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';

import {isListItemBlock} from '../../../../utils';

class ListItemAsTask implements Plugin {
  public renderBlock = (
    {attributes, children, node}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isListItemBlock(node)) {
      return next();
    }
    const index = node.get('data').get('index');
    return (
      <div className="list-as-task-wrapper">
        <span contentEditable={false} className="list-as-task-title">
          {index}.
        </span>
        <ol {...attributes} className="list-as-task">
          <li>{children}</li>
        </ol>
      </div>
    );
  };
}

export default ListItemAsTask;
