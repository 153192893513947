import React, {Component} from 'react';
import {type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import Panel from 'react-bootstrap/lib/Panel';
import type Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import I from 'components/Icon';
// Plugins
import Bold from 'components/Slate/plugins/renderers/Bold';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import Underline from 'components/Slate/plugins/renderers/Underline';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import Icon from 'components/Slate/plugins/renderers/Icon';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import {toggleCollapsible} from 'store/exercise/player/widgets/actions';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import {VisibilityButton} from './VisibilityButton';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

import './Transcript.scss';

interface Props {
  isModal?: boolean;
  closed?: boolean;
  content: Value;
  show?: boolean;
  preview?: boolean;
  role?: Role;
  toggleCollapsible(widgetId: string, preview?: boolean): void;
  widgetId: string;
  animateOnMount?: boolean;
  expanded: boolean;
  getWidgetProps: () => WidgetTypeComponentProps;
}

interface State {
  expanded: boolean;
  touched?: true;
}

class Transcript extends Component<Props, State> {
  static animationTime = 300;
  public state: State = {expanded: this.props.expanded};
  static plugins: Plugin[] = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Icon(),
    new Dialog()
  ];

  public plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.plugins = Transcript.plugins.concat(
      new SelectionPointer({
        isAvailable: this.isAvailableSelection,
        onSelected: this.onSelected
      })
    );
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState(prevState => ({...prevState, expanded: this.props.expanded}));
    }
  }

  private isAvailableSelection = () =>
    this.props.role === 'teacher' ? Boolean(this.props.show) : true;

  private onSelected = (showSelection: Function) => {
    if (this.state.expanded) {
      return showSelection();
    }

    this.setState(
      state => ({...state, expanded: true}),
      () => setTimeout(showSelection, Transcript.animationTime)
    );
  };

  private get isEmpty(): boolean {
    return !this.props.content.document.text.length;
  }

  public render() {
    const {animateOnMount, content, getWidgetProps} = this.props;
    const {touched, expanded} = this.state;
    const {Header} = this;

    return this.isEmpty ? null : (
      <div className={classNames('transcript', {animate: animateOnMount && !touched})}>
        <Panel expanded={expanded} onToggle={this.onToggle}>
          <Header />
          <Panel.Body collapsible={true}>
            <SlatePlayer
              className="x-content sm"
              value={content}
              plugins={this.plugins}
              trimEmptyTrailingParagraphs={true}
              getWidgetProps={getWidgetProps}
            />
          </Panel.Body>
        </Panel>
      </div>
    );
  }

  private Header = () => {
    const {closed, role, show} = this.props;
    const hint =
      role === 'student' ? null : (
        <span className={classNames('label', {'label-danger': !show, 'label-success': show})}>
          {show ? (
            <FormattedMessage id="Common.Visible" />
          ) : (
            <FormattedMessage id="CoursebookLibrary.CoursebookStatus.Hidden" />
          )}
        </span>
      );
    return (
      <Panel.Heading className={classNames({show})}>
        <Panel.Title toggle={true} className={classNames({'with-hint': !!hint})}>
          <span>Transcript</span>
          {!this.props.isModal && (
            <>
              {hint}
              <VisibilityButton
                nullPredicate={role === 'student'}
                show={show}
                disabled={closed}
                toggleVisibility={this.toggleVisibility}
              />
            </>
          )}
          <I name="angle-down" className={this.state.expanded ? 'rotated' : ''} />
        </Panel.Title>
      </Panel.Heading>
    );
  };

  private onToggle = () => this.setState(({expanded}) => ({touched: true, expanded: !expanded}));

  private toggleVisibility = (e: React.MouseEvent<Button, MouseEvent>) => {
    const {preview, toggleCollapsible, widgetId} = this.props;
    e.preventDefault();
    e.stopPropagation();
    toggleCollapsible(widgetId, preview);
  };
}

export default connect(null, {toggleCollapsible})(Transcript);
