import {type WidgetAction} from '../../interface';
import {xplayerPrefix} from '../../actionTypes';
import {MC_ANSWER_DESELECTED, MC_ANSWER_SELECTED, STYLED_LIST_SELECT_ITEM} from './actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';

export interface MCAnswerSelectedAction extends WidgetAction {
  questionId: string;
  answerId: string;
}
export interface StyledListAction extends WidgetAction {
  id: string;
}

export const MCAnswerSelected = (
  widgetId: string,
  questionId: string,
  answerId: string,
  preview?: boolean
): MCAnswerSelectedAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MC_ANSWER_SELECTED}`,
    widgetId,
    questionId,
    answerId,
    preview
  };
};

export const MCAnswerDeselected = (
  widgetId: string,
  questionId: string,
  answerId: string,
  preview?: boolean
): MCAnswerSelectedAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MC_ANSWER_DESELECTED}`,
    widgetId,
    questionId,
    answerId,
    preview
  };
};

export const selectItem = (widgetId: string, id: string, preview?: boolean): StyledListAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${STYLED_LIST_SELECT_ITEM}`,
    widgetId,
    id,
    preview
  };
};
