import React, {type FC} from 'react';

import {type WidgetProperties, type WidgetComponentProps} from 'store/exercise/player/interface';
import {type Role} from 'store/interface';

import xplayerWidgetComponentFactory from './xplayerWidgetComponentFactory';
import {withDisplayButton} from './withDisplayButton';

interface Props {
  widget: WidgetProperties;
  exerciseId: string;
  isClosed: boolean;
  isMain: boolean;
  role: Role;
  showPreviewExerciseNumber?: boolean;
  isHeadWidget: boolean;
  homeworkId?: string;
  isHomeworkPlayer?: boolean;
  isWidgetPreviewPlayer?: boolean;
  isGrammarPlayer?: boolean;
  preview?: boolean;
  isPointerAvailable?: boolean;
  onShowPreviewModal: (widgetId: string) => void;
  isModal?: boolean;
}

export const Widget: FC<Props> = React.memo(
  withDisplayButton(
    ({
      widget,
      exerciseId,
      isClosed,
      isMain,
      role,
      preview,
      showPreviewExerciseNumber,
      homeworkId,
      isHeadWidget,
      isGrammarPlayer,
      isHomeworkPlayer,
      isWidgetPreviewPlayer,
      isPointerAvailable,
      isModal
    }) => {
      const WidgetComponent: React.ElementType<WidgetComponentProps> =
        xplayerWidgetComponentFactory(widget.type);

      return (
        <WidgetComponent
          exerciseId={exerciseId}
          isExtra={!isMain && !isHomeworkPlayer && !preview}
          key={`${exerciseId}:${widget.id}`}
          role={role}
          widget={widget}
          preview={preview}
          closed={isClosed}
          showPreviewExerciseNumber={showPreviewExerciseNumber}
          homeworkId={homeworkId}
          isHeadWidget={isHeadWidget}
          isGrammarPlayer={isGrammarPlayer}
          isHomeworkPlayer={isHomeworkPlayer}
          isWidgetPreviewPlayer={isWidgetPreviewPlayer}
          isPointerAvailable={isPointerAvailable}
          isModal={isModal}
        />
      );
    }
  )
);
