import React from 'react';
import {type Plugin} from '@englex/slate-react';

import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import {isDocumentNotEmpty as documentNotEmpty} from 'components/Slate/utils/documentNotEmpty';
import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Icon from 'components/Slate/plugins/renderers/Icon';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type FormattedTextProperties} from 'store/exercise/player/widgets/FormattedText/interface';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import {Example} from 'components/Slate/plugins/renderers/Example';

import './FormattedText.scss';

type Props = WidgetTypeComponentProps<FormattedTextProperties>;

class FormattedText extends React.PureComponent<Props> {
  public static plugins: Plugin[] = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Image(),
    new Icon(),
    new Dialog()
  ];
  public render() {
    const {content} = this.props.widget;
    return documentNotEmpty(content.document) ? (
      <div className="x-content">
        <SlatePlayer
          value={content}
          plugins={FormattedText.plugins}
          trimEmptyTrailingParagraphs={true}
        />
      </div>
    ) : null;
  }
}

export class SelectableFormattedText extends React.PureComponent<Props> {
  private readonly plugins: Plugin[] = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Image({isModal: this.props.isModal}),
    new Icon(),
    new Dialog(),
    new SelectionPointer(),
    Example()
  ];

  private getWidgetProps = () => this.props;

  public render() {
    const {content} = this.props.widget;
    return documentNotEmpty(content.document) ? (
      <div className="x-content">
        <SlatePlayer
          value={content}
          plugins={this.plugins}
          trimEmptyTrailingParagraphs={true}
          getWidgetProps={this.getWidgetProps}
        />
      </div>
    ) : null;
  }
}

export default FormattedText;
