import React, {type FC, useMemo} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import {type AppState, type ExerciseInstanceCommentMap} from 'store/interface';
import {type DataMap} from 'components/Slate/interface';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Avatar from 'components/Avatar';

import {type CommentHandlers} from './interface';
import {playerPlugins} from './playerPlugins';
import {MessageBubble} from './MessageBubble/MessageBubble';
import {DateTime} from './MessageBubble/DateTime';
import './Comment.scss';

interface Props extends CommentHandlers {
  comment: DataMap<ExerciseInstanceCommentMap>;
  editIsActive: boolean;
  isBeingEdited: boolean;
  prevCommentUserId: number | null;
}

export const Comment: FC<Props> = React.memo(
  ({comment, isBeingEdited, editComment, prevCommentUserId, editIsActive, deleteComment}) => {
    const timezone = useSelector<AppState, string>(s => s.user.profile!.timeZone);
    const userId = useSelector<AppState, number | undefined>(s => s.user.id);

    const own = comment.get('createdById') === userId;
    const renderAvatar = prevCommentUserId !== comment.get('createdById');
    return (
      <div className={classNames('exercise-comment', {own, 'is-being-edited': isBeingEdited})}>
        <div className="avatar-container">
          {renderAvatar && <Avatar size={42} url={comment.get('createdBy').profile.avatars!.sm} />}
        </div>

        <div className={classNames('message', {updated: comment.get('updatedAt')})}>
          <MessageBubble
            id={comment.get('id')}
            own={own}
            editIsActive={editIsActive}
            editComment={editComment}
            deleteComment={deleteComment}
          >
            <SlatePlayer plugins={useMemo(playerPlugins, [])} value={comment.get('comment')} />
          </MessageBubble>
          <DateTime
            createdAt={comment.get('createdAt')}
            updatedAt={comment.get('updatedAt')}
            userTimezone={timezone}
          />
        </div>
      </div>
    );
  }
);
