import React, {type FC} from 'react';

import Youtube from 'components/media/VideoPlayer/Youtube';
import EnglexPlayer from 'components/media/VideoPlayer/EnglexPlayer';
import {type VideoData, VideoSourceType} from 'components/media/interface';

const {SELECTEL, YOUTUBE} = VideoSourceType;

interface Props {
  posterId?: number;
  preview?: boolean;
  video: VideoData & {id: number};
  widgetId: string;
  start?: number;
  end?: number;
}

const Player: FC<Props> = ({posterId, preview, video: {id, source}, widgetId, start, end}) => (
  <div className="video-player">
    {source === YOUTUBE && <Youtube id={id} start={start} end={end} />}
    {source === SELECTEL && (
      <EnglexPlayer id={id} posterId={posterId} preview={preview} widgetId={widgetId} />
    )}
  </div>
);

export default Player;
