import React, {memo} from 'react';
import classNames from 'classnames';

import LoadableImage from 'components/LoadableImage/LoadableImage';

import './TeacherCard.scss';

interface Props {
  imageId: number;
  isFlipped: boolean;
}

export const TeacherCard: React.FC<Props> = memo(({imageId, isFlipped}) => {
  return (
    <div className={classNames('teacher-card', {flipped: isFlipped})}>
      <div className="teacher-card__image">
        <LoadableImage imageId={imageId} width="100%" height="100%" />
      </div>
    </div>
  );
});
