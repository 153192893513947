import React, {type FC, memo} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {List} from 'immutable';

import {PointerElementListener} from 'components/Pointer/element/PointerElementListener';
import {CornerCheckbox} from 'components/XPlayer/components/CornerCheckbox/CornerCheckbox';
import {type Role} from 'store/interface';
import LoadableImage from 'components/LoadableImage/LoadableImage';
import {selectCard} from 'store/exercise/player/widgets/PictureChoice/actions';
import {type PictureChoiceCardProperties} from 'store/exercise/player/widgets/PictureChoice/interface';
import {
  type PictureChoiceAnswers,
  type PictureChoiceValues
} from 'store/exercise/editor/widgets/XPictureChoice/interface';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import {BaseCard} from '../BaseCard/BaseCard';

import './MultipleChoiceCard.scss';

const align = {
  offset: [0, 1]
};

interface Props {
  widgetId: string;
  questionId: string;
  card: PictureChoiceCardProperties;
  values?: PictureChoiceValues;
  answers?: PictureChoiceAnswers;
  role: Role;
  closed?: boolean;
  preview?: boolean;
}

const MultipleChoiceCard: FC<Props> = memo(
  ({widgetId, questionId, card, values, answers, role, closed, preview}) => {
    const dispatch = useDispatch();

    const checked = !!values?.getIn([questionId], List()).includes(card.id);
    const answer = !!answers?.getIn([questionId], List()).includes(card.id);

    const correct = checked === answer;
    const incorrect = checked !== answer;
    const cardNotSelected = !checked && role === 'student' && !closed;
    const cardSelected = checked && role === 'student' && !closed;
    const borderCorrect =
      (!checked && incorrect && role === 'teacher') ||
      (!checked && incorrect && role === 'student' && closed);
    const cardIsIncorrect =
      (checked && incorrect && role === 'teacher') ||
      (checked && incorrect && role === 'student' && closed);
    const cardIsCorrect =
      (checked && correct && role === 'teacher') ||
      (checked && correct && role === 'student' && closed);

    const onClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(selectCard(widgetId, questionId, card.id, preview));
    };

    return (
      <BaseCard
        onClick={role === 'student' ? onClick : undefined}
        className={classNames('card-mc', role, {
          selected: cardSelected,
          'not-selected': cardNotSelected,
          'border-correct': borderCorrect,
          incorrect: cardIsIncorrect,
          correct: cardIsCorrect,
          'is-rectangle': card.cardSizeType === CardSizeType.RECTANGLE
        })}
      >
        <PointerElementListener key={card.id} preview={preview} align={align}>
          <div className="pointer-wrap" id={card.id}>
            {role === 'teacher' || closed ? (
              <CornerCheckbox checked={checked} answer={answer} readOnly={true} />
            ) : (
              <CornerCheckbox checked={checked} readOnly={true} />
            )}
            <div className="base-card-wrapper">
              <div className="img-block">
                <LoadableImage imageId={card.imageId} width="100%" height="100%" />

                <div className="mask"></div>
              </div>
            </div>
          </div>
        </PointerElementListener>
      </BaseCard>
    );
  }
);

export default MultipleChoiceCard;
