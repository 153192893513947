import React, {type FC, memo} from 'react';
import classNames from 'classnames';
import {type ConnectDragPreview, type ConnectDragSource} from 'react-dnd';

import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import {useDndContext} from 'components/XPlayer/contexts/dndContext';

import {
  PointerElementListener,
  type PointerListenerProps
} from '../../../../../Pointer/element/PointerElementListener';

import './SpellingCard.scss';

interface Props extends PointerListenerProps {
  id?: string;
  dragging?: boolean;
  isOver?: boolean;
  inLayer?: boolean;
  cardIndex?: number;
  index?: number;
  isAfterSelected?: boolean;
  selected?: boolean;
  selectionIsActive?: boolean;
  preview?: boolean;
  selectHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
  value: string;
  drag?: ConnectDragSource;
  dragPreview?: ConnectDragPreview;
  style?: React.CSSProperties;
  disabled?: boolean;
  startOfSentence?: true;
  onSelect?: (index: number, cardIndex: number, id?: string) => void;
  moveSelectedCard?: (hoverIndex: number) => void;
  unselectCard?: () => void;
  tokenGroupId?: string;
  selectedCardGroupId?: string;
}

export const SpellingCard: FC<Props> = memo(
  ({
    id,
    dragging,
    isOver,
    inLayer = false,
    index,
    cardIndex,
    isAfterSelected,
    selected,
    selectionIsActive,
    startOfSentence,
    value,
    style,
    preview,
    tokenGroupId,
    selectedCardGroupId,
    drag,
    dragPreview,
    onAnimationEnd,
    onAnimationStart,
    onSelect,
    moveSelectedCard,
    unselectCard,
    children,
    ...props
  }) => {
    useDndEmptyImage(dragPreview);

    const {selectedCardId} = useDndContext();

    const onSelectFunc = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onSelect && index !== undefined && cardIndex !== undefined && onSelect(index, cardIndex, id);
    };

    const onMoveSelectedCard = () => {
      moveSelectedCard && cardIndex !== undefined && moveSelectedCard(cardIndex);
    };

    const isSameTokenGroup =
      (!tokenGroupId && !selectedCardGroupId) || tokenGroupId === selectedCardGroupId;

    return (
      <PointerElementListener preview={preview} inline={false}>
        <div id={id}>
          <div
            ref={drag}
            className={classNames('spelling-dnd-card', {
              dragging,
              selected,
              'is-over': isOver,
              'another-card-selected':
                !!selectedCardId && selectedCardId !== id && isSameTokenGroup,
              'in-layer': inLayer
            })}
            style={style}
            onClick={!selectedCardId ? onSelectFunc : onMoveSelectedCard}
            onDragStart={unselectCard}
            {...props}
          >
            {children || (
              <span className="value" title={value}>
                {value}
              </span>
            )}
          </div>
        </div>
      </PointerElementListener>
    );
  }
);
