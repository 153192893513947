import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import './XPlayerTooltip.scss';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

interface Props extends TooltipProps {
  empty?: boolean;
  hideArrow?: boolean;
}

class XPlayerTooltip extends React.PureComponent<Props> {
  public render() {
    const {children, overlayClassName, empty, hideArrow, ...rest} = this.props;
    const overlayClasses = classNames(
      'x-player-tooltip-overlay',
      {'no-arrow': hideArrow},
      overlayClassName
    );
    const {visible, onVisibleChange} = this.props;

    return (
      <div className={`x-player-tooltip${empty ? ' empty' : ''}`}>
        {<div className={classNames('x-player-overlay', {visible})} />}
        <Tooltip
          trigger={['click']}
          destroyTooltipOnHide={true}
          align={{offset: [0, -5]}}
          mouseEnterDelay={0.5}
          getTooltipContainer={() => ReactDOM.findDOMNode(this) as HTMLElement}
          placement="topRight"
          {...rest}
          overlayClassName={overlayClasses}
          visible={visible}
          onVisibleChange={onVisibleChange}
        >
          {children}
        </Tooltip>
      </div>
    );
  }
}

export default XPlayerTooltip;
