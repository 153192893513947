import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {type RenderAttributes} from '@englex/slate-react';
import {useLocation, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'rc-tooltip';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {openingUrlForStudentTimeout, openingUrlForTeacherTimeout} from 'config/static';
import {urlOpenedExercise, publishOpenExercise, setOpeningExerciseForPartner} from 'common/action';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';
import {type ClassroomUrlParams} from 'common/paths';
import './ExerciseLabelComponent.scss';

interface Props {
  exerciseId?: string;
  attributes?: RenderAttributes;
  preview?: boolean;
  getTooltipContainer(): HTMLElement;
}

const ExerciseLabelComponent: FC<PropsWithChildren<Props>> = ({
  exerciseId,
  attributes = {},
  getTooltipContainer,
  children,
  preview
}) => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {courseId, studentTeacherId} = useParams<ClassroomUrlParams>();
  const dispatch = useDispatch();
  const axiosDispatch = useAxiosDispatch();
  const openingExerciseForPartner = useSelector<AppState, boolean | undefined>(
    ({classroom: c}) => c?.openingExerciseForPartner
  );
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');
  const partnerSession = useSelector<AppState, number>(s => s.rtc.partnerSessionId!);
  const canPlayTogether = useCanPlayTogether({
    preview,
    studentTeacherId,
    allowStudentToInitiate: true
  });

  const [visible, setVisible] = useState(false);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleNoResponse = useCallback(() => {
    timeout.current = null;
    dispatch(urlOpenedExercise());
    toastr.error(
      '',
      isStudent
        ? intl.formatMessage({id: 'Exercise.OpenTogetherWithTeacher.FailToast'})
        : intl.formatMessage({id: 'Exercise.OpenTogetherWithStudent.FailToast'})
    );
  }, [dispatch, intl, isStudent]);

  const openExercise = useCallback(() => {
    setVisible(false);
    if (openingExerciseForPartner) {
      return;
    }
    timeout.current = setTimeout(
      handleNoResponse,
      isStudent ? openingUrlForTeacherTimeout : openingUrlForStudentTimeout
    );
    axiosDispatch(
      publishOpenExercise({
        courseId: Number(courseId),
        url: `${pathname}?remote=redirect#${exerciseId}`,
        partnerSession
      })
    );
    dispatch(setOpeningExerciseForPartner());
  }, [
    exerciseId,
    axiosDispatch,
    courseId,
    dispatch,
    handleNoResponse,
    isStudent,
    openingExerciseForPartner,
    partnerSession,
    pathname
  ]);

  useEffect(() => {
    if (!openingExerciseForPartner && timeout.current) {
      clearTimeout(timeout.current);
      toastr.success(
        '',
        isStudent
          ? intl.formatMessage({id: 'Exercise.OpenTogetherWithTeacher.SuccessToast'})
          : intl.formatMessage({id: 'Exercise.OpenTogetherWithStudent.SuccessToast'})
      );
      timeout.current = null;
    }
  }, [intl, isStudent, openingExerciseForPartner]);

  if (!canPlayTogether) {
    return (
      <span className="exercise-label" {...attributes}>
        {children}
      </span>
    );
  }
  return (
    <Tooltip
      overlay={<Overlay isStudent={isStudent} openExercise={openExercise} />}
      overlayClassName="exercise-label-tooltip-overlay"
      trigger={['click']}
      onVisibleChange={visible => setVisible(visible || false)}
      placement="bottomLeft"
      getTooltipContainer={getTooltipContainer}
      destroyTooltipOnHide={true}
      visible={visible}
    >
      <Button
        {...attributes}
        className="exercise-label"
        onClick={() => setVisible(visible => !visible)}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

const Overlay: FC<{isStudent: boolean; openExercise(): void}> = ({isStudent, openExercise}) => {
  const intl = useIntl();

  return (
    <div className="exercise-label-tooltip-overlay-content">
      <p>
        {isStudent
          ? intl.formatMessage({id: 'Exercise.ShowToTeacher'})
          : intl.formatMessage({id: 'Exercise.ShowToStudent'})}
        :
      </p>
      <Button onClick={openExercise} bsStyle="success" bsSize="sm">
        <Icon name="virc-target" />
        {intl.formatMessage({id: 'LessonPage.Display'})}
      </Button>
    </div>
  );
};

export default ExerciseLabelComponent;
