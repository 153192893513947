import React from 'react';
import classNames from 'classnames';
import capitalize from 'react-bootstrap/lib/utils/capitalize';

import DragHandle from 'components/DragHandle/DragHandle';
import {type Answer} from 'store/exercise/player/interface';

import XPlayerTooltip from '../../../../components/XPlayerTooltip';
import GapChecked from '../GapChecked';
import {GapFillType} from '../../../../../Slate/interface';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

interface Props {
  id: string;
  value?: Answer | null;
  answer?: Answer;
  capitalize?: boolean;
  className?: string;
  closed?: boolean;
  example?: boolean;
  dragCaseSensitive?: true;
  gapCaseSensitive?: true;
  startOfSentence?: true;
  useSVGHack?: true;
  orphan?: boolean;
  preview?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends React.PureComponent<Props, State> {
  public state: State = {
    visible: false
  };

  public static fullValue(value?: string | null, caseSensitive?: true): string | null {
    if (value === undefined) {
      return '(none)';
    }
    if (value === null) {
      return null;
    }
    return caseSensitive ? value : value.toLowerCase();
  }

  public render() {
    const {id, className, closed, example, useSVGHack, orphan, preview} = this.props;
    const {visible} = this.state;
    const {showTooltip, showTooltipOverlay} = this;

    const gap = (
      <GapChecked
        className={className}
        gap={GapFillType.DND}
        value={this.printValue}
        dirty={this.isDirty}
        correct={this.isCorrect}
        empty={this.isEmpty}
        closed={closed}
        example={example}
        dot={showTooltip}
        orphan={orphan}
      >
        <DragHandle disabled={true} useSVGHack={useSVGHack} />
      </GapChecked>
    );

    return (
      <PointerElementListener
        elementId={id as string}
        preview={preview}
        tooltipClassName="x-player-tooltip"
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={showTooltipOverlay}
        render={({isAvailable, isTeacher, pointerInlineClassName}) =>
          (isAvailable && isTeacher) || !showTooltip ? (
            <span id={id} className={pointerInlineClassName}>
              {gap}
            </span>
          ) : (
            <span id={id} className={pointerInlineClassName}>
              <XPlayerTooltip
                overlay={this.answers()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          )
        }
      />
    );
  }

  private get showTooltip(): boolean {
    const {example} = this.props;
    return example ? false : !this.isCorrect;
  }

  private get showTooltipOverlay(): (() => JSX.Element) | undefined {
    return !this.isCorrect ? this.answers : undefined;
  }

  private get isDirty(): boolean {
    return this.props.value !== undefined;
  }

  private get isEmpty(): boolean {
    const {value} = this.props;
    return value === null || !this.isDirty || value!.length === 0;
  }

  private get isCorrect(): boolean {
    const {value, answer, example, dragCaseSensitive, gapCaseSensitive} = this.props;

    if (example) return true;
    if (!value || !answer) return false;

    const sensetive = dragCaseSensitive || gapCaseSensitive;
    const stringsStrictlyEqual = value === answer;
    const stringsLowerEqual = value.toLowerCase() === answer.toLowerCase();
    const stringsEqual = sensetive ? stringsStrictlyEqual : stringsLowerEqual;

    return this.isDirty && stringsEqual;
  }

  private get printValue(): string {
    const {value, example, dragCaseSensitive, gapCaseSensitive, startOfSentence} = this.props;
    if (!this.isDirty || value === null) {
      return '\uFEFF';
    } else if (example && gapCaseSensitive) {
      return value!;
    } else if (startOfSentence || this.props.capitalize) {
      return capitalize(dragCaseSensitive ? value! : value!.toLowerCase());
    } else {
      return dragCaseSensitive ? value! : value!.toLowerCase();
    }
  }

  private answers = () => {
    const {answer, gapCaseSensitive} = this.props;
    return (
      <ul className={classNames('x-gap-answers-hint', 'dnd', {single: true})}>
        <li
          className={classNames({
            answer: false,
            correct: true,
            empty: !!answer && answer.length === 0
          })}
        >
          {Checked.fullValue(answer, gapCaseSensitive)}
        </li>
      </ul>
    );
  };

  private onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible});
  };
}

export default Checked;
