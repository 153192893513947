import React, {type FC, useMemo} from 'react';
import {type Dispatch} from 'redux';
import {connect, type MapDispatchToProps} from 'react-redux';
import {type Descendant} from 'slate';
import {withHistory} from 'slate-history';

import {type Role} from 'store/interface';
import {
  contentChange,
  turnEssayReviewOff,
  turnEssayReviewOn
} from 'store/exercise/player/widgets/actions';
import {type WidgetAction, type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import {SlateEditor} from '../../../SlateJS/components/SlateEditor';
import ReviewControl from '../ReviewControl';
import {
  type SlatePlugin,
  textColors,
  textFormats,
  textHighlights,
  withFormat,
  withIcons,
  withList,
  withWordCount,
  withSelectionPointer,
  withWidgetProps,
  withDisabledScrollIntoView
} from '../../../SlateJS/plugins';

interface OwnProps {
  closed?: boolean;
  content: Descendant[];
  readonly?: boolean;
  role: Role;
  shouldCountWords?: boolean;
  widgetId: string;
  preview?: boolean;
  getWidgetProps: () => WidgetTypeComponentProps;
}

interface DispatchProps {
  onChange: (value: Descendant[]) => void;
  turnReviewOn: () => WidgetAction;
  turnReviewOff: () => WidgetAction;
}

type Props = OwnProps & DispatchProps;

const toolbar = {portalId: 'x-player-toolbar-portal'};

const EssayEditor: FC<Props> = ({
  closed,
  content,
  onChange,
  shouldCountWords,
  readonly,
  turnReviewOn,
  turnReviewOff,
  widgetId,
  getWidgetProps,
  role
}) => {
  const plugins: SlatePlugin[] = useMemo(() => {
    return [
      withHistory,
      withFormat([...textFormats, ...textColors, ...textHighlights]),
      withIcons(),
      withList(),
      withWordCount({enabled: !!shouldCountWords}),
      withWidgetProps({getWidgetProps}),
      withSelectionPointer({isAvailable: () => closed || !!readonly}),
      withDisabledScrollIntoView()
      // withFormatPainter,
      // withClearFormatting
    ];
  }, [shouldCountWords, getWidgetProps, closed, readonly]);

  const isNotStudent = role !== 'student';

  return (
    <>
      <SlateEditor
        value={content}
        onChange={onChange}
        plugins={plugins}
        readOnly={readonly}
        toolbar={toolbar}
        skipSelectionChange={true}
        autoCapitalize="sentences"
      />
      {isNotStudent && !closed && (
        <ReviewControl
          pullLeft={shouldCountWords}
          readonly={readonly}
          widgetId={widgetId}
          turnReviewOn={turnReviewOn}
          turnReviewOff={turnReviewOff}
        />
      )}
    </>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<WidgetAction>,
  {widgetId, preview}
) => ({
  onChange: (value: Descendant[]) => dispatch(contentChange(widgetId, value, preview)),
  turnReviewOn: () => dispatch(turnEssayReviewOn(widgetId, preview)),
  turnReviewOff: () => dispatch(turnEssayReviewOff(widgetId, preview))
});

export default connect<null, DispatchProps>(null, mapDispatchToProps)(EssayEditor);
