import {type Editor} from 'slate';
import {type ClipboardEvent, type DragEvent} from 'react';

import {type SlatePlugin} from './withPlugins';

/**
 * This plugin stops propagation of 'copy' and 'dragstart' events
 *
 * It's useful to avoid errors while using copy/drag and paste slate fragments
 * for slate editors that live inside '@englex/slate-react' based
 * editor (e.g. slate-based InlineInput inside '@englex/slate-react' based SlatePlayer in XPlayer)
 */
export const withInsideEnglexSlate: SlatePlugin = (editor: Editor) => {
  const {editableProps = {}} = editor;
  const {onCopy, onDragStart} = editableProps;

  editableProps.onCopy = (e: ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();

    return onCopy?.(e) || false;
  };
  editableProps.onDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();

    return onDragStart?.(e) || false;
  };

  return editor;
};
