import React from 'react';
import {
  collapseSpaces,
  convertTicks,
  pipe,
  pipeline,
  removeHidden,
  toLowerCase,
  trim
} from '@englex/utils';
import classNames from 'classnames';

import XPlayerTooltip from '../../components/XPlayerTooltip';
import GapChecked from '../GapFill/component/GapChecked';
import {
  PointerElementListener,
  type PointerListenerRef
} from '../../../Pointer/element/PointerElementListener';
import {Answers} from './Answers';

import './Checked.scss';

interface Props {
  id: string;
  answers: string[];
  className?: string;
  closed?: boolean;
  editable?: boolean;
  example?: boolean;
  value?: string;
  placeholder?: string;
  relatedElement?: string;
  pointerListenerRef?: PointerListenerRef;
  preview?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends React.PureComponent<Props, State> {
  public state: State = {visible: false};

  public render() {
    const {
      showTooltip,
      showTooltipOverlay,
      props: {
        id,
        className,
        closed,
        relatedElement,
        pointerListenerRef,
        example,
        preview,
        editable,
        placeholder,
        answers
      },
      state: {visible}
    } = this;

    const multipleAnswers = answers.length > 1;

    const gap = (
      <GapChecked
        className={classNames('input', className, {
          'with-placeholder': placeholder && editable && !this.isDirty
        })}
        gap="image-labeling fluid"
        value={this.value}
        title={this.title}
        dirty={this.isDirty}
        correct={this.isCorrect}
        empty={this.isEmpty}
        dot={this.showTooltip}
        placeholder={this.placeholder}
        closed={closed}
        example={example}
        multipleAnswers={multipleAnswers}
      />
    );

    return (
      <PointerElementListener
        elementId={id}
        preview={preview}
        ref={pointerListenerRef}
        relatedElement={relatedElement}
        tooltipClassName="x-player-tooltip fluid"
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={showTooltipOverlay}
        render={({isAvailable, isTeacher}) => {
          return (isAvailable && isTeacher) || !showTooltip ? (
            React.cloneElement(gap, {id})
          ) : (
            <span id={id} className="pointer-target fluid">
              <XPlayerTooltip
                overlay={this.answers()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                empty={this.isEmpty}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          );
        }}
      />
    );
  }

  private get showTooltip(): boolean {
    const {answers, example, closed} = this.props;

    if (example && answers.length > 1) return true;
    if (!this.isCorrect || (!closed && !this.isDirty)) return true;

    return !!answers && answers.length > 1;
  }

  private get showTooltipOverlay() {
    return this.showTooltip ? this.answers : undefined;
  }

  private get isDirty(): boolean {
    return this.props.value !== undefined;
  }

  private get isEmpty(): boolean {
    const {example, editable, placeholder} = this.props;

    if (example) return false;

    return (!this.isDirty && !(editable && placeholder)) || this.value!.length === 0;
  }

  private get isCorrect(): boolean {
    const {value, answers, placeholder, editable, example} = this.props;

    if (example) return true;

    const mutate = pipe(trim, removeHidden, convertTicks, toLowerCase, collapseSpaces);

    if (this.isDirty) {
      return answers.map(mutate).includes(mutate(value!));
    } else if (editable && placeholder) {
      return answers.map(mutate).includes(mutate(placeholder));
    }

    return answers.includes('');
  }

  private get title(): string {
    return '';
  }

  private get value(): string {
    const {answers, value, example, editable, placeholder} = this.props;

    if (example) return answers[0];

    if (this.isDirty) return pipeline(value!, trim, removeHidden, collapseSpaces);

    return placeholder && editable ? placeholder : '\uFEFF';
  }

  private get placeholder(): string | undefined {
    const {placeholder, editable} = this.props;

    return editable ? undefined : placeholder;
  }

  private answers = () => {
    const {value, answers, placeholder} = this.props;

    return (
      <Answers answers={answers} value={value} placeholder={placeholder} dirty={this.isDirty} />
    );
  };

  private onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible});
  };
}

export default Checked;
