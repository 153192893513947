import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Pagination, type SwiperOptions} from 'swiper';
import {Swiper, type SwiperRef, SwiperSlide} from 'swiper/react';
import {type Swiper as SwiperClass} from 'swiper/types';
import {type List} from 'immutable';
import classNames from 'classnames';

import {type CardProperties, CardsType} from 'store/exercise/player/widgets/Cards/interface';

import {Card} from './Card';

import './CardCarousel.scss';

interface Breakpoints {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
}

const pagination = {clickable: true};

const modules = [Pagination];

interface Props {
  type: CardsType;
  items: List<CardProperties>;
  selected: string;
  focused: boolean;
  closed?: boolean;
  preview?: boolean;
  onChange: (index: number) => void;
}

export const CardCarousel: React.FC<Props> = ({
  type,
  items,
  selected,
  focused,
  closed,
  preview,
  onChange
}) => {
  const [locked, setLocked] = useState(false);

  const breakpoints = useRef({
    480: {
      slidesPerView: 'auto',
      spaceBetween: type === CardsType.TEXT ? 10 : 8
    }
  }).current as Breakpoints;

  const swiper = useRef<SwiperRef>(null);

  const swiperContainer = useRef<HTMLDivElement>(null);

  const getTooltipContainer = useCallback(
    () => swiperContainer.current || document.documentElement,
    []
  );

  const onActiveIndexChange = useCallback(
    (swiper: SwiperClass) => onChange(swiper.activeIndex),
    [onChange]
  );

  const onTransitionStart = useCallback(() => setLocked(true), []);

  const onTransitionEnd = useCallback(() => setLocked(false), []);

  const activeIndex = items.findIndex((item: CardProperties) => item.id === selected) || 0;

  useEffect(() => {
    const currentActiveIndex = swiper.current?.swiper?.activeIndex;

    if (currentActiveIndex !== activeIndex) {
      swiper.current?.swiper?.slideTo(activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!focused) return;

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.repeat) return;

      if (event.key === 'ArrowLeft') {
        swiper.current?.swiper?.slidePrev();
      }

      if (event.key === 'ArrowRight') {
        swiper.current?.swiper?.slideNext();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [focused]);

  const className = type === CardsType.IMAGES ? 'swiper-image-slide' : 'swiper-text-slide';

  return (
    <div ref={swiperContainer} className="swiper-container">
      <Swiper
        className={classNames({locked})}
        ref={swiper}
        initialSlide={activeIndex}
        slidesPerView="auto"
        spaceBetween={12}
        threshold={9}
        pagination={pagination}
        modules={modules}
        breakpoints={breakpoints}
        centeredSlides={true}
        slideToClickedSlide={true}
        preventInteractionOnTransition={true}
        onActiveIndexChange={onActiveIndexChange}
        onTransitionStart={onTransitionStart}
        onTransitionEnd={onTransitionEnd}
      >
        {items.map((item: CardProperties, index: number) => (
          <SwiperSlide
            key={item.id}
            className={classNames(className, {
              'before-active': index < activeIndex,
              'after-active': index > activeIndex
            })}
          >
            <Card
              type={type}
              item={item}
              selected={index === activeIndex}
              closed={closed}
              preview={preview}
              getTooltipContainer={getTooltipContainer}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
