import React from 'react';
import {type Plugin} from '@englex/slate-react';

import genKey from 'components/Slate/utils/genKey';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type MatchingProperties} from 'store/exercise/player/widgets/Matching/interface';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import QuestionsList from 'components/Slate/plugins/renderers/QuestionsList/QuestionsList';
import MatchingAnswer from 'components/Slate/SlateEditor/plugins/widget/QuestionsList/Matching/MatchingAnswer';
import MatchingQuestionRenderer from 'components/Slate/plugins/renderers/QuestionsList/Matching/MatchingQuestionRenderer';
import Highlight from 'components/Slate/plugins/renderers/Highlight';

interface Props {
  getWidgetProps: () => WidgetTypeComponentProps<MatchingProperties>;
}

class MatchingDefault extends React.PureComponent<Props> {
  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    const {preview} = this.props.getWidgetProps();

    this.plugins = [
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new Highlight(),
      new MatchingQuestionRenderer(preview),
      new QuestionsList(),
      new MatchingAnswer({genKey}),
      new SelectionPointer()
    ];
  }

  public render() {
    const {
      widget: {content}
    } = this.props.getWidgetProps();

    return (
      <SlatePlayer value={content} plugins={this.plugins} getWidgetProps={this.getWidgetProps} />
    );
  }

  private getWidgetProps = () => this.props.getWidgetProps();
}

export default MatchingDefault;
