import React, {type FC} from 'react';
import {useIntl} from 'react-intl';

import {toLocalDate} from 'services/common-methods';
import Icon from 'components/Icon';

import {type UpdatedAtProps} from '../interface';

export const UpdatedAt: FC<UpdatedAtProps> = ({updatedAt, userTimezone}) => {
  const intl = useIntl();
  if (!updatedAt) {
    return null;
  }
  const localUpdatedAt = toLocalDate(new Date(updatedAt), userTimezone);
  return (
    <Icon
      name="pc-write"
      className="updated-message-icon"
      title={`${intl.formatMessage({id: 'Message.UpdatedPencilTitle'})} ${intl.formatDate(
        localUpdatedAt
      )} ${intl.formatTime(localUpdatedAt)}`}
    />
  );
};
