import React, {type PropsWithChildren} from 'react';
import {type Editor, type RenderAttributes} from '@englex/slate-react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {getWidgetProps} from 'components/Slate/interface';
import {type AppState, type Role} from 'store/interface';
import {type WidgetComponentProps} from 'store/exercise/player/interface';
import {type MultipleChoiceProperties} from 'store/exercise/player/widgets/List/interface';

import './MultipleChoicePlayer.scss';

interface QuestionStateProps {
  isAnswered: boolean;
  isCorrect: boolean;
  closed?: boolean;
  role: Role;
  withoutQuestions: boolean;
}

interface QuestionOwnProps {
  attributes: RenderAttributes;
  questionId: string;
  horizontal?: true;
  preview?: boolean;
  editor: Editor;
}

class MCQuestionRenderer extends React.Component<QuestionStateProps & QuestionOwnProps, {}> {
  public render() {
    const {
      attributes,
      children,
      closed,
      horizontal,
      isAnswered,
      isCorrect,
      role,
      withoutQuestions
    } = this.props;
    const className = classNames('question-block', {
      horizontal: horizontal,
      correct: (closed || (isAnswered && role !== 'student')) && isCorrect,
      incorrect: (closed || (isAnswered && role !== 'student')) && !isCorrect,
      'without-questions': !!withoutQuestions
    });

    return (
      <li {...attributes} className={className}>
        {children}
      </li>
    );
  }
}

function questionIsAnswered({values}: MultipleChoiceProperties, questionId: string) {
  return !(!values || !values.get(questionId));
}

function checkIfQuestionAnsweredCorrectly(
  {values, answers}: MultipleChoiceProperties,
  questionId: string
) {
  if (!values || !answers || !values.get(questionId) || !answers.get(questionId)) {
    return false;
  }
  if (values.get(questionId).size !== answers.get(questionId).size) {
    return false;
  }
  return values.get(questionId).every((value: string) => answers.get(questionId).includes(value));
}

const mapStateToProps = (
  state: AppState,
  {editor, questionId}: PropsWithChildren<QuestionOwnProps>
): QuestionStateProps => {
  const {widget, closed, role} = editor.query(
    getWidgetProps
  ) as WidgetComponentProps<MultipleChoiceProperties>;
  return {
    isAnswered: questionIsAnswered(widget, questionId),
    isCorrect: checkIfQuestionAnsweredCorrectly(widget, questionId),
    closed,
    role,
    withoutQuestions: widget.withoutQuestions
  };
};

export default connect(mapStateToProps)(MCQuestionRenderer);
