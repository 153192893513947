import React, {Component, type FC} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import {type AppState} from 'store/interface';
import {type WidgetAction} from 'store/exercise/player/interface';

import Icon from '../../Icon';
import './ReviewControl.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface OwnProps<W extends WidgetAction> {
  pullLeft?: boolean;
  readonly?: boolean;
  widgetId: string;
  turnReviewOn: () => void;
  turnReviewOff: () => void;
}

interface StateProps {
  isMobile?: boolean;
}

interface DispatchProps {}

type Props<W extends WidgetAction> = OwnProps<W> & StateProps & DispatchProps;

class ReviewControl<W extends WidgetAction> extends Component<Props<W>> {
  public render() {
    const {readonly} = this.props;
    const {Message} = this;
    return (
      <Button
        className="review-control-btn"
        bsStyle="success"
        style={this.style}
        onClick={this.onClick}
      >
        <Icon name={readonly ? 'unlock-alt' : 'lock'} />
        <Message />
      </Button>
    );
  }

  private get style() {
    const {isMobile, pullLeft} = this.props;
    return isMobile ? {left: '7px'} : {right: pullLeft ? '120px' : '45px'};
  }

  private onClick = () =>
    this.props.readonly ? this.props.turnReviewOn() : this.props.turnReviewOff();

  private Message: FC = () =>
    this.props.readonly ? (
      <FormattedMessage id="XPlayer.Teacher.Unlock" />
    ) : (
      <FormattedMessage id="XPlayer.Teacher.Lock" />
    );
}

const mapStateToProps = (state: AppState) => ({
  isMobile: state.layout.isMobile
});

export default connect<StateProps>(mapStateToProps)(ReviewControl);
