import React, {type FC, useEffect, useRef, useState} from 'react';
import {Focusable} from '@englex/paint-react';

import {type VideoData} from 'components/media/interface';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type VideoProperties} from 'store/exercise/player/widgets/Video/interface';

import Transcript from '../../components/Transcript/Transcript';
import Player from './Player';
import './styles.scss';

type Props = WidgetTypeComponentProps<VideoProperties>;

const VideoWidget: FC<Props> = props => {
  const {
    closed,
    preview,
    role,
    widget: {content, id, posterId, video, videoOptions, values, displayButton}
  } = props;

  const [animateTranscript, setAnimateTranscript] = useState(false);
  const mountCall = useRef(true);
  const show = values?.get('showCollapsible') || closed;
  const isExpanded = displayButton ? false : role === 'student';
  const shouldRenderTranscript =
    displayButton || closed || role !== 'student' || !!values?.get('showCollapsible');

  const getWidgetProps = () => props;

  useEffect(() => {
    if (mountCall.current) return;
    if (shouldRenderTranscript) {
      setAnimateTranscript(!preview && role === 'student');
    }
  }, [shouldRenderTranscript, preview, role]);

  useEffect(() => {
    mountCall.current = false;
  }, []);

  return (
    <>
      <Focusable>
        <Player
          posterId={posterId}
          preview={preview}
          video={video as VideoData & {id: number}}
          start={videoOptions?.start}
          end={videoOptions?.end}
          widgetId={id}
        />
      </Focusable>

      {shouldRenderTranscript && (
        <Transcript
          animateOnMount={animateTranscript}
          closed={closed}
          content={content}
          preview={preview}
          role={role}
          show={show}
          widgetId={id}
          expanded={isExpanded}
          getWidgetProps={getWidgetProps}
          isModal={!!displayButton}
        />
      )}
    </>
  );
};

export default VideoWidget;
