import React, {PureComponent} from 'react';
import {type Seq} from 'immutable';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type ScrambledProperties} from 'store/exercise/player/widgets/ScrambledSentences/interface';

import Sentence from './components/Sentence';
import Example from './components/Example';
import './ScrambledSentences.scss';

type Props = WidgetTypeComponentProps<ScrambledProperties>;

export default class ScrambledSentences extends PureComponent<Props> {
  private choicesSequence: Seq.Indexed<string>;

  private templates: {[sentenceId: string]: string} = {};

  constructor(props: Props) {
    super(props);
    this.choicesSequence = this.props.widget.scrambledChoices.keySeq();
  }

  public render() {
    const {
      preview,
      widget: {example, id: widgetId}
    } = this.props;
    return (
      <div className="scrambled-sentences">
        {example && <Example preview={preview} example={example} widgetId={widgetId} />}
        {this.renderSentences()}
      </div>
    );
  }

  private renderSentences = () => {
    const {
      closed,
      preview,
      role,
      widget: {answers, example, scrambledChoices: choices, sentences, values},
      widget,
      isModal
    } = this.props;
    return this.choicesSequence.map((k: string, i: number) => {
      const sentenceAnswers = answers ? answers.get(k) : undefined;
      const sentenceChoices = choices.get(k);
      const sentenceValues = values ? values.get(k) : undefined;
      const size = this.choicesSequence.size;

      if (!this.templates[k]) {
        this.templates[k] = sentences.find(s => s.id === k)!.template;
      }

      return (
        <Sentence
          index={i}
          key={k}
          answers={sentenceAnswers}
          choices={sentenceChoices}
          closed={closed}
          countExample={!!example}
          preview={preview}
          role={role}
          sentenceId={k}
          template={this.templates[k]}
          values={sentenceValues}
          widgetId={widget.id}
          isModal={isModal}
          sentencesSize={size}
        />
      );
    });
  };
}
