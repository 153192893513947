import React, {type KeyboardEvent, type PropsWithChildren} from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Radio from 'react-bootstrap/lib/Radio';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {type Action, type Dispatch} from 'redux';
import {type Editor} from '@englex/slate-react';

import {getWidgetProps} from 'components/Slate/interface';
import {type AppState, type Role} from 'store/interface';
import {
  MCAnswerDeselected,
  MCAnswerSelected,
  type MCAnswerSelectedAction
} from 'store/exercise/player/widgets/List/actions';
import {
  MultipleChoiceElement,
  type MultipleChoiceProperties
} from 'store/exercise/player/widgets/List/interface';
import {type WidgetComponentProps, WidgetType} from 'store/exercise/player/interface';

import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

const align = {
  offset: [124, -5],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
};

interface OwnProps {
  element: MultipleChoiceElement;
  answerId: string;
  questionId: string;
  preview?: boolean;
  editor: Editor;
}

type AnswerInputOwnProps = PropsWithChildren<OwnProps>;

interface AnswerInputStateProps {
  isFeeChoice: boolean;
  closed?: boolean;
  correct?: boolean;
  isChecked: boolean;
  role: Role;
  widgetId: string;
  skipAnswers: boolean;
  teacher: boolean;
}

interface DispatchProps {
  answerSelected: (widgetId: string) => MCAnswerSelectedAction;
  answerDeselected: (widgetId: string) => MCAnswerSelectedAction;
}

interface Props extends AnswerInputOwnProps, AnswerInputStateProps, DispatchProps {}

class MCAnswerComponent extends React.Component<Props, {}> {
  public render() {
    const {
      element,
      isChecked,
      correct,
      closed,
      questionId,
      answerId,
      skipAnswers,
      preview,
      isFeeChoice,
      teacher
    } = this.props;
    const InputElement = element === MultipleChoiceElement.CHECKBOX ? Checkbox : Radio;
    const className = classNames({
      correct,
      closed: skipAnswers ? false : closed,
      showAnswers: closed,
      checked: isChecked,
      teacher,
      'hide-cursor': closed
    });
    return (
      <PointerElementListener preview={preview || isFeeChoice} placement="leftTop" align={align}>
        <div id={answerId} className={`input-wrapper ${className}`}>
          <InputElement
            checked={isChecked}
            onChange={this.handleInputChange}
            onKeyDown={this.onKeyDown}
            name={`${questionId}[]`}
          >
            <span data-slate-editor={true}>{this.props.children}</span>
          </InputElement>
        </div>
      </PointerElementListener>
    );
  }

  public onKeyDown = (e: KeyboardEvent<Checkbox>) => {
    if (e.keyCode === 13 && this.props.element === MultipleChoiceElement.CHECKBOX) {
      this.handleInputChange();
    }
  };

  private handleInputChange = () => {
    const {answerSelected, answerDeselected, isChecked, closed, widgetId} = this.props;
    if (closed) {
      return;
    }
    if (isChecked) {
      answerDeselected(widgetId);
    } else {
      answerSelected(widgetId);
    }
  };
}

const mapStateToProps = (state: AppState, ownProps: AnswerInputOwnProps): AnswerInputStateProps => {
  const {questionId, answerId, editor} = ownProps;
  const {widget, role, closed} = editor.query(
    getWidgetProps
  ) as WidgetComponentProps<MultipleChoiceProperties>;

  const isFeeChoice = widget.type === WidgetType.FREE_CHOICE;
  const isQuiz = widget.type === WidgetType.Quiz;

  const skipAnswers = isFeeChoice || isQuiz;

  const correct =
    (role !== 'student' || closed) &&
    widget.answers &&
    !!widget.answers.get(ownProps.questionId) &&
    widget.answers.get(questionId).includes(answerId);

  const isChecked = !!(
    widget.values &&
    widget.values.get(questionId) &&
    widget.values.get(questionId).includes(answerId)
  );

  const wasClosed = closed || (widget.type !== WidgetType.FREE_CHOICE && role !== 'student');

  const teacher = role === 'teacher';

  return {
    role,
    isChecked,
    skipAnswers,
    isFeeChoice,
    correct: skipAnswers || correct,
    closed: wasClosed,
    widgetId: widget.id,
    teacher
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  {questionId, answerId, preview}: AnswerInputOwnProps
): DispatchProps => ({
  answerSelected: (widgetId: string) =>
    dispatch(MCAnswerSelected(widgetId, questionId, answerId, preview)),
  answerDeselected: (widgetId: string) =>
    dispatch(MCAnswerDeselected(widgetId, questionId, answerId, preview))
});

export default connect(mapStateToProps, mapDispatchToProps)(MCAnswerComponent);
