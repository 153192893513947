import React, {type FC, useEffect, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import {type DndContextShape, useDndContext} from 'components/XPlayer/contexts/dndContext';
import {type ChoicesMap} from 'store/exercise/player/interface';
import DragGap from 'components/XPlayer/components/DragGap';
import {type GapFillProperties} from 'store/exercise/player/widgets/GapFill/interface';
import {clearValue} from 'store/exercise/player/widgets/actions';
import {usePrevious} from 'hooks/usePrevious';

import Choice from './Choice';
import {type InputChoiceDragObject} from '../dnd-input/DNDInputChoice';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';
import {DndTypes} from '../../../../../dnd/interface';

import './Pool.scss';

interface OwnProps {
  widget: GapFillProperties;
  preview?: boolean;
  role: Role;
  closed?: boolean;
  inPortal?: boolean;
  choiceIsUsed(choiceId?: string): boolean;
}

type Props = OwnProps & DndContextShape;

const Pool: FC<Props> = ({
  widget,
  role,
  inPortal,
  selectedCardId,
  choiceIsUsed,
  selectCard,
  preview,
  closed
}) => {
  const dispatch = useDispatch();

  const {choices, gapfillExamples, values, answers} = widget;

  const [orphanIds, setOrphanIds] = useState<string[]>(widget.orphanIds || []);

  const prevAnswers = usePrevious(answers);

  const [{isOver, canDrop, dropChoiceId}, connectDropTarget] = useDrop({
    accept: DndTypes.X_GAP,
    canDrop({widgetId: itemWidgetId}) {
      const {id, values} = widget;

      return itemWidgetId === id && !!values;
    },
    drop({choiceId}, monitor: DropTargetMonitor) {
      if (monitor.didDrop()) {
        // some nested target already handled drop
        return;
      }
      clearGap(widget.id, choiceId, preview);
      // return drop result, which will be available as monitor.getDropResult() in the drag source's endDrag() method
      return {
        choiceId: choiceId
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropChoiceId: monitor.getItem<InputChoiceDragObject>()
        ? monitor.getItem<InputChoiceDragObject>().choiceId
        : undefined
    })
  });

  useEffect(() => {
    if (prevAnswers !== answers) {
      setOrphanIds(widget.orphanIds || []);
    }
  }, [answers, prevAnswers, widget.orphanIds]);

  const clearGap = (id: string, choiceId: string, preview: boolean | undefined) => {
    dispatch(clearValue(id, choiceId, preview));
  };

  const returnSelectedCardToPool = () => {
    if (selectedCardId) {
      clearGap(widget.id, selectedCardId, preview);
      selectCard();
    }
  };

  const renderChoice = (choiceId: string) => {
    const choice = widget.choices!.get(choiceId);
    const answer = choice.value;
    const isUsed = choiceIsUsed(choiceId);
    const value = choice.caseSensitive || choice.isExtra ? answer : answer.toLowerCase();
    if (role !== 'student' || closed) {
      return (
        <PointerElementListener preview={preview} key={choiceId}>
          <DragGap
            id={choiceId}
            flexHandle={true}
            className={classNames('x-dnd-card static', {
              disabled: isUsed,
              orphan: orphanIds.includes(choiceId)
            })}
            answer={value}
          />
        </PointerElementListener>
      );
    }

    return (
      <PointerElementListener preview={preview} key={choiceId}>
        <Choice
          id={choiceId}
          choiceId={choiceId}
          widgetId={widget.id}
          answer={value}
          disabled={isUsed}
          flexHandle={true}
          isOver={
            (isOver && canDrop && dropChoiceId === choiceId) ||
            (selectedCardId === choiceId && isUsed)
          }
          selected={choiceId === selectedCardId && !isUsed}
          onSelect={selectCard}
        />
      </PointerElementListener>
    );
  };

  const renderExample = (exampleId: string, index: number) => {
    const {gapfillExamples} = widget;
    const answer = gapfillExamples!.get(exampleId).value;
    const value = gapfillExamples?.get(exampleId).caseSensitive ? answer : answer.toLowerCase();
    const id = `${widget.id}-example-${index + 1}`;
    return (
      <PointerElementListener preview={preview} key={exampleId}>
        <DragGap
          id={id}
          flexHandle={true}
          key={exampleId}
          className={classNames('x-dnd-card static', {disabled: true})}
          answer={value}
        />
      </PointerElementListener>
    );
  };

  const renderPool = (choices: ChoicesMap, examples?: ChoicesMap) => {
    return (
      <div
        className={classNames('x-gap-drag-pool', {
          'is-over': choiceIsUsed(selectedCardId) || (isOver && canDrop),
          'in-portal': inPortal
        })}
        onClick={returnSelectedCardToPool}
      >
        {examples && examples.keySeq().map(renderExample)}
        {choices.keySeq().map(renderChoice)}
      </div>
    );
  };

  if (!choices) return null;

  const pool = renderPool(choices, gapfillExamples);

  return role === 'student' && values ? connectDropTarget(pool) : pool;
};

const ConnectedPoolWithContext: FC<OwnProps> = props => {
  const {selectCard, selectedCardId} = useDndContext();

  const onSelectCard = useCallback(
    (id?: string | undefined, e?: React.MouseEvent<HTMLDivElement>) => {
      e && !selectedCardId && e.stopPropagation();
      selectCard(id);
    },
    [selectCard, selectedCardId]
  );
  return <Pool {...props} selectCard={onSelectCard} selectedCardId={selectedCardId} />;
};

export default ConnectedPoolWithContext;
