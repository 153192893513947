import React, {type FC} from 'react';

import './TeacherCardText.scss';

interface Props {
  text: string;
}

export const TeacherCardText: FC<Props> = ({text}) => {
  return <div className="teacher-card__text">{text}</div>;
};
