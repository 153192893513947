import React from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import {type VocabularyWordProperties} from 'store/exercise/editor/widgets/XVocabulary/interface';

import PronunciationSoundPlayer, {
  type IntlPronunciationSoundPlayer,
  type PronunciationPlayerStatus
} from './PronunciationSoundPlayer';
import Spinner from '../../../Spinner';
import Icon from '../../../Icon';

interface Props {
  word: VocabularyWordProperties;
}

interface State {
  status: PronunciationPlayerStatus;
}

export default class VocabularyWordOriginal extends React.Component<Props, State> {
  public state: State = {
    status: null
  };
  private titleSpan: HTMLSpanElement | null;
  private player: IntlPronunciationSoundPlayer | null;

  public render() {
    return <div className="td original">{this.renderSoundPlayer()}</div>;
  }

  private playerRef = (el: IntlPronunciationSoundPlayer | null) => (this.player = el);
  private titleSpanRef = (el: HTMLSpanElement | null) => (this.titleSpan = el);

  private renderSoundPlayer = () => {
    const {
      word: {soundId}
    } = this.props;
    if (soundId) {
      return (
        <PronunciationSoundPlayer
          soundId={soundId}
          onStatusChange={this.onPlayStatusChange}
          ref={this.playerRef}
        >
          {this.renderContent()}
        </PronunciationSoundPlayer>
      );
    }
    return this.renderContent();
  };

  private renderContent = () => {
    const {word} = this.props;
    const className = classNames('original-cell-content', {
      'has-pronunciation': word.soundId || word.phoneticSpelling
    });
    const wordSplitByBraces = word.splitByBraces;
    return (
      <div className={className} onClick={this.playSound}>
        <span className="original-text" ref={this.titleSpanRef}>
          {wordSplitByBraces.textBeforeBraces}
        </span>
        {this.renderTooltip(this.state.status)}
        <span className="text-in-braces">{wordSplitByBraces.textInBraces}</span>
      </div>
    );
  };

  private renderTooltip = (status?: PronunciationPlayerStatus) => {
    const {phoneticSpelling} = this.props.word;
    if (phoneticSpelling) {
      return (
        <Tooltip
          destroyTooltipOnHide={true}
          overlay={`[${phoneticSpelling}]`}
          trigger={['hover']}
          placement="top"
          align={{overflow: {adjustX: false, adjustY: false}}}
        >
          <span>{this.renderIcon(status)}</span>
        </Tooltip>
      );
    }
    return this.renderIcon(status);
  };

  private onPlayStatusChange = (status: PronunciationPlayerStatus) => this.setState({status});

  private playSound = (e: React.MouseEvent<HTMLDivElement>) => {
    // if user has selected word title, we shouldn't play sound
    const userSelection = window.getSelection();
    if (!userSelection) {
      return;
    }
    if (userSelection.toString().length) {
      const selectionRange = userSelection.getRangeAt(0);
      const parentNode = selectionRange.startContainer.parentNode;
      if (parentNode && this.titleSpan && parentNode === this.titleSpan) {
        e.stopPropagation();
        return;
      }
    }
    this.player && this.player.playSound();
  };

  private renderIcon = (playStatus?: PronunciationPlayerStatus) => {
    const {
      word: {soundId, phoneticSpelling}
    } = this.props;
    if (playStatus === 'loading') {
      return <Spinner size={15} />;
    }
    if (soundId) {
      const className = classNames({
        playing: playStatus === 'playing'
      });
      return <Icon name="volume-up" className={className} />;
    }
    if (phoneticSpelling) {
      return <Icon name="commenting" />;
    }
    return null;
  };
}
