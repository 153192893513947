import React, {Component, type FC} from 'react';
import {connect, type MapDispatchToProps} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import {
  turnQuestionFieldsOff,
  turnQuestionFieldsOn,
  turnQuestionsReviewOff,
  turnQuestionsReviewOn
} from 'store/exercise/player/widgets/actions';
import {type Role} from 'store/interface';

import Icon from '../../../../Icon';

import './QuestionsFieldControl.scss';

interface OwnProps {
  role: Role;
  homeworkId?: string;
  widgetId: string;
  show: boolean;
  preview?: boolean;
  disabled?: boolean;
  isTouched?: boolean;
  onReview?: boolean;
}

interface DispatchProps {
  turnFieldsOn: (id: string) => void;
  turnFieldsOff: (id: string) => void;
  turnReviewOn: () => void;
  turnReviewOff: () => void;
}

type Props = OwnProps & DispatchProps;

class QuestionsFieldControl extends Component<Props> {
  private controlRef: React.RefObject<HTMLDivElement> = React.createRef();

  private getTooltipContainer: () => HTMLElement = () => {
    return this.controlRef.current || document.body;
  };

  public componentDidUpdate(prevProps: Props) {
    if (this.isTeacher && !prevProps.homeworkId && this.props.homeworkId && this.props.onReview) {
      this.props.turnReviewOff();
    }
  }

  private get isTeacher() {
    return this.props.role === 'teacher';
  }

  public render() {
    const {isTouched, show, disabled, onReview} = this.props;
    const {Control} = this;

    return (
      <div className="question-control">
        {isTouched ? (
          <Tooltip
            overlay={
              <div>
                <FormattedMessage id="XPlayer.Teacher.QuestionsField.Hide.Tooltip" />
              </div>
            }
            overlayClassName="question-control-overlay"
            placement={'top'}
            destroyTooltipOnHide={true}
            trigger={['hover']}
            getTooltipContainer={this.getTooltipContainer}
          >
            <span ref={this.controlRef}>
              <Control />
            </span>
          </Tooltip>
        ) : (
          <Control />
        )}

        {show && (
          <Button
            className={classNames({disabled: disabled})}
            bsStyle="success"
            bsSize="sm"
            onClick={this.onReviewClick}
          >
            {onReview ? (
              <>
                <Icon name="lock" />
                <FormattedMessage id="XPlayer.Teacher.Lock" />
              </>
            ) : (
              <>
                <Icon name="unlock-alt" />
                <FormattedMessage id="XPlayer.Teacher.Unlock" />
              </>
            )}
          </Button>
        )}
      </div>
    );
  }

  private Control: FC = () => {
    const {show, disabled, isTouched} = this.props;
    return (
      <Button
        className={classNames('toggle-collapsible-btn', {disabled: disabled || isTouched})}
        bsStyle={show ? 'danger' : 'success'}
        bsSize="sm"
        onClick={this.onClick}
      >
        {show ? (
          <FormattedMessage id="XPlayer.Teacher.QuestionsField.Hide" />
        ) : (
          <FormattedMessage id="XPlayer.Teacher.QuestionsField.Show" />
        )}
      </Button>
    );
  };

  private onClick = (e: React.MouseEvent<Button>) => {
    if (this.props.disabled || this.props.isTouched) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      return this.props.show
        ? this.props.turnFieldsOff(this.props.widgetId)
        : this.props.turnFieldsOn(this.props.widgetId);
    }
  };
  private onReviewClick = (e: React.MouseEvent<Button>): void => {
    if (this.props.disabled) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      return this.props.onReview ? this.props.turnReviewOff() : this.props.turnReviewOn();
    }
  };
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  {preview, widgetId}
) => ({
  turnFieldsOn: (id: string) => dispatch(turnQuestionFieldsOn(widgetId, id, preview)),
  turnFieldsOff: (id: string) => dispatch(turnQuestionFieldsOff(widgetId, id, preview)),
  turnReviewOn: () => dispatch(turnQuestionsReviewOn(widgetId, preview)),
  turnReviewOff: () => dispatch(turnQuestionsReviewOff(widgetId, preview))
});

export default connect<null, DispatchProps>(null, mapDispatchToProps)(QuestionsFieldControl);
