import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {type WidgetProperties} from 'store/exercise/player/interface';

interface UnitPreview {
  unitId: number;
  coursebookId: string;
}

interface ExerciseInfo {
  given?: boolean;
  isDirty?: boolean;
  additionalAvailable?: number;
  isDraft: boolean;
  unitPreview?: UnitPreview;
}

export const useExerciseInfo = (exerciseId: string, preview?: boolean): ExerciseInfo => {
  const additionalAvailable = useSelector<AppState, number | undefined>(s => {
    const previewExercise = preview
      ? s.unitPreview?.xpreview?.exercises.get(exerciseId)
      : undefined;
    return previewExercise && previewExercise.additionalAvailable;
  });

  const isDraft = useSelector<AppState, boolean>(s =>
    Boolean(
      s.classroom?.courseInstanceState.homeworkDraft?.exercises
        .map(e => e.exerciseInstanceId)
        .includes(exerciseId)
    )
  );

  const unitPreview = useSelector<AppState, UnitPreview | undefined>(
    ({unitPreview: up}) =>
      preview && up?.coursebookId && up.unitId
        ? {
            unitId: up.unitId,
            coursebookId: up.coursebookId
          }
        : undefined,
    (l, r) => (l && r ? l.unitId === r.unitId : l === r)
  );

  const exerciseInfo = useSelector<
    AppState,
    {given?: boolean; isDirty?: boolean; additionalAvailable?: number} | undefined
  >(
    s => {
      if (!s.xplayer) return undefined;
      const exercise = s.xplayer.exercises.find((e: ExerciseProperties) => e.id === exerciseId);
      return !exercise
        ? undefined
        : {
            given: !!exercise.homeworkId,
            isDirty:
              !!exercise.completedAt || exercise.widgets.some((w: WidgetProperties) => !!w.version),
            additionalAvailable: additionalAvailable || exercise.additionalAvailable
          };
    },
    (l, r) => (l && r ? Object.keys(l).every(k => l[k] === r[k]) : l === r)
  );

  return useMemo(
    () => ({
      additionalAvailable,
      isDraft,
      unitPreview,
      ...(exerciseInfo || {})
    }),
    [additionalAvailable, isDraft, exerciseInfo, unitPreview]
  );
};
