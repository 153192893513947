import React from 'react';
import {findDOMNode} from 'react-dom';

import {
  type MatchingNoCategoriesProperties,
  type MatchingProperties,
  MatchingType
} from 'store/exercise/player/widgets/Matching/interface';
import {PoolPortalWrapper} from 'components/PoolPortalWrapper';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type FormattedTextProperties} from 'store/exercise/player/widgets/FormattedText/interface';

import Pool from './component/Pool';
import {Provider} from '../../contexts/dndContext';
import MatchingDefault from './components/MatchingDefault';
import {MatchingNoCategories} from './components/MatchingNoCategories/MatchingNoCategories';

import './Matching.scss';

type WidgetProps<W extends FormattedTextProperties> = () => WidgetTypeComponentProps<W>;
type Props = WidgetTypeComponentProps<MatchingProperties | MatchingNoCategoriesProperties>;

export class Matching extends React.PureComponent<Props> {
  private getParent = () => findDOMNode(this) as HTMLDivElement | null;

  private getWidgetProps = () => this.props;

  private renderMatching() {
    const {widget} = this.props;

    switch (widget.matchingType) {
      case MatchingType.NO_CATEGORIES: {
        return (
          <MatchingNoCategories
            getWidgetProps={this.getWidgetProps as WidgetProps<MatchingNoCategoriesProperties>}
          />
        );
      }
      default:
        return (
          <MatchingDefault
            getWidgetProps={this.getWidgetProps as WidgetProps<MatchingProperties>}
          />
        );
    }
  }

  public render() {
    const {widget, preview, role, closed, isModal} = this.props;

    return (
      <Provider isModal={isModal}>
        <div className="matching">
          <PoolPortalWrapper
            getParent={this.getParent}
            preview={preview}
            role={role}
            closed={closed}
          >
            {inPortal => (
              <Pool
                widget={widget}
                inPortal={inPortal}
                preview={preview}
                role={role}
                closed={closed}
              />
            )}
          </PoolPortalWrapper>
          <div className="x-content">{this.renderMatching()}</div>
        </div>
      </Provider>
    );
  }
}
