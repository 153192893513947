import React, {Component} from 'react';
import classNames from 'classnames';
import {type Value} from '@englex/slate';
import Collapse from 'react-bootstrap/lib/Collapse';
import {type Plugin} from '@englex/slate-react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import SlatePlayer from '../../../Slate/SlatePlayer/SlatePlayer';
import Icon from '../../../Icon';
import Bold from '../../../Slate/plugins/renderers/Bold';
import Italic from '../../../Slate/plugins/renderers/Italic';
import Underline from '../../../Slate/plugins/renderers/Underline';
import StrikeThrough from '../../../Slate/plugins/renderers/StrikeThrough';
import TextAlignment from '../../../Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from '../../../Slate/plugins/renderers/Link/Link';
import Lists from '../../../Slate/plugins/renderers/List/Lists';
import Color from '../../../Slate/plugins/renderers/Color';
import Highlight from '../../../Slate/plugins/renderers/Highlight';
import Image from '../../../Slate/plugins/renderers/Image/Image';
import IconPlugin from '../../../Slate/plugins/renderers/Icon';
import Dialog from '../../../Slate/plugins/renderers/Table/Dialog/Dialog';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

interface Props {
  id: string;
  title: Value;
  text: Value;
  completed: boolean;
  getWidgetProps?: () => WidgetTypeComponentProps;
}

interface State {
  expanded: boolean;
}

export class QuizPossibleResult extends Component<Props, State> {
  static animationTime = 300;

  public state: State = {expanded: false};

  static titlePlugins = [new Italic(), new Underline(), new StrikeThrough(), new Highlight()];

  static textPlugins = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Color(),
    new Highlight(),
    new IconPlugin(),
    new Dialog()
  ];

  public textPlugins: Plugin[];

  public titlePlugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.titlePlugins = [
      ...QuizPossibleResult.titlePlugins,
      new SelectionPointer({
        id: this.props.id
      })
    ];

    this.textPlugins = [
      ...QuizPossibleResult.textPlugins,
      new Image({
        pointerOptions: {
          isAvailable: this.isAvailable,
          onSelected: this.onSelected
        }
      }),
      new SelectionPointer({
        id: this.props.id,
        onSelected: this.onSelected
      })
    ];
  }

  private isAvailable = () => {
    return this.props.completed;
  };

  private onSelected = (showSelection: Function) => {
    if (this.state.expanded) {
      return showSelection();
    }

    this.setState(
      () => ({expanded: true}),
      () => setTimeout(showSelection, QuizPossibleResult.animationTime)
    );
  };

  private toggle = () => {
    const selection = window.getSelection();

    if (selection?.toString().length) return;

    this.setState(prevState => ({expanded: !prevState.expanded}));
  };

  public render() {
    return (
      <div className={classNames('possible-result', {expanded: this.state.expanded})}>
        <div className="possible-result__title" onClick={this.toggle}>
          <SlatePlayer
            value={this.props.title}
            plugins={this.titlePlugins}
            getWidgetProps={this.props.getWidgetProps}
          />
          <Icon name="angle-down" size="lg" className="expand-arrow" />
        </div>

        <Collapse in={this.state.expanded}>
          <div className="possible-result__text">
            <div className="sizer">
              <SlatePlayer
                value={this.props.text}
                plugins={this.textPlugins}
                trimEmptyTrailingParagraphs={true}
                getWidgetProps={this.props.getWidgetProps}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
