import {type ElementType} from 'react';

import {WidgetType, type WidgetComponentProps} from 'store/exercise/player/interface';

import DefaultWidgetComponent from '../widgets/DefaultWidgetComponent';
import Note from '../widgets/Note/Note';
import CommentWidgetComponent from '../widgets/Comment/CommentWidgetComponent';

const map: Partial<Record<WidgetType, ElementType<WidgetComponentProps>>> = {
  [WidgetType.NOTE]: Note,
  [WidgetType.COMMENT]: CommentWidgetComponent
};

function xplayerWidgetComponentFactory(type: WidgetType): ElementType<WidgetComponentProps> {
  return map[type] || DefaultWidgetComponent;
}

export default xplayerWidgetComponentFactory;
