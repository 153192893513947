import React from 'react';

import {type AudioFile} from 'components/media/interface';
import AudioWidgetPlayer from 'components/media/AudioPlayer/containers/Exercise/AudioWidgetPlayer';
import Icon from 'components/Icon';

import './Player.scss';

interface Props {
  widgetId: string;
  isModal: boolean;
  audioId: number;
  audio?: AudioFile;
  parentContainerClass: string;
  preview?: boolean;
  withTogetherButton: boolean;
  setLoadedAudio(audio: AudioFile): void;
}

class Player extends React.Component<Props> {
  private a: HTMLAnchorElement | null;

  public render() {
    const {
      widgetId,
      isModal,
      audio,
      children,
      parentContainerClass,
      preview,
      audioId,
      setLoadedAudio,
      withTogetherButton
    } = this.props;
    return (
      <AudioWidgetPlayer
        widgetId={widgetId}
        isModal={isModal}
        audioFile={audio}
        audioId={audioId}
        compact={true}
        dark={true}
        parentContainerClass={parentContainerClass}
        preview={preview}
        setLoadedFile={setLoadedAudio}
        usePortal={true}
        withTogetherButton={withTogetherButton}
      >
        {audio && (
          <>
            {children}
            <a
              className="download btn btn-default"
              href={audio.url}
              ref={a => (this.a = a)}
              rel="noopener noreferrer"
              onBlur={this.onAnchorBlur}
              onFocus={this.onAnchorFocus}
              target="_blank"
            >
              <Icon name="cloud-download" />
            </a>
          </>
        )}
      </AudioWidgetPlayer>
    );
  }

  private onAnchorFocus = () => {
    if (this.a) {
      this.a.addEventListener('keydown', this.anchorKeyDown);
    }
  };

  private onAnchorBlur = () => {
    if (this.a) {
      this.a.removeEventListener('keydown', this.anchorKeyDown);
    }
  };

  private anchorKeyDown = {
    handleEvent(e: Event & {code: string; currentTarget: EventTarget & {click(): void}}) {
      if (e.code === 'Space' && e.currentTarget) {
        e.preventDefault();
        e.currentTarget.click();
      }
    }
  };
}

export default Player;
