import {type ScrambledSentencesJSON} from 'store/exercise/player/widgets/ScrambledSentences/interface';

export const getNewTemplates = (sentences: ScrambledSentencesJSON) => {
  const newTemplates = {};

  sentences.forEach(item => {
    newTemplates[item.id] = item.template;
  });

  return newTemplates;
};
