import {type Plugin} from '@englex/slate-react';

import {GapFillType} from 'components/Slate/interface';

import GapFillInput from './plugins/GapFillInput';
import GapFillDND from './plugins/GapFillDND';
import GapFillDropDown from './plugins/GapFillDropDown';
import GapFillDNDInput from './plugins/GapFillDNDInput';

function gapPluginFactory(gap: GapFillType, preview?: boolean): Plugin {
  switch (gap) {
    case GapFillType.INPUT:
      return new GapFillInput({preview});
    case GapFillType.DND:
      return new GapFillDND({preview});
    case GapFillType.DROPDOWN:
      return new GapFillDropDown({preview});
    case GapFillType.DND_INPUT:
      return new GapFillDNDInput({preview});
    default:
      throw new Error(`Slate Plugin for GapType=${gap} is not implemented yet`);
  }
}

export default gapPluginFactory;
