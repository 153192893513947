import React, {memo, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import {flipCard} from 'store/exercise/editor/widgets/XFlipCards/actions';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import style from './FlipCard.module.scss';

interface Props {
  cardId: string;
  imageId: number;
  text: string;
  cardSizeType: CardSizeType;
  flipped: boolean;
  defaultFlipped: boolean;
  role: Role;
  widgetId: string;
  closed?: boolean;
  preview?: boolean;
  width?: number;
  height?: number;
  frontElement: JSX.Element;
  backElement: JSX.Element;
  teacherText: JSX.Element | null;
}

export const FlipCard: React.FC<Props> = memo(
  ({
    cardId,
    cardSizeType,
    flipped,
    defaultFlipped,
    closed,
    widgetId,
    width,
    height,
    frontElement,
    backElement,
    preview,
    teacherText,
    role
  }) => {
    const dispatch = useDispatch();
    const styles = width || height ? {width, height} : {};
    const [isFlipping, setIsFlipping] = useState(false);

    const onClick = () => {
      setIsFlipping(true);
      dispatch(flipCard(widgetId, cardId, preview));

      setTimeout(() => {
        setIsFlipping(false);
      }, 300);
    };

    const frontClasses = classNames(style.front, {
      [style.frontFlipped]: ((flipped && !closed) || (defaultFlipped && closed)) && !isFlipping,
      [style.frontFlippedFlipping]:
        ((flipped && !closed) || (defaultFlipped && closed)) && isFlipping,
      [style.frontFlipping]: isFlipping && !flipped,
      [style.frontClosed]: closed
    });

    const backClasses = classNames(style.back, {
      [style.backFlipped]: ((flipped && !closed) || (defaultFlipped && closed)) && !isFlipping,
      [style.backFlippedFlipping]:
        ((flipped && !closed) || (defaultFlipped && closed)) && isFlipping,
      [style.backFlipping]: isFlipping && !flipped,
      [style.backClosed]: closed,
      [style.teacher]: role === 'teacher'
    });

    const teacherTextClasses = classNames(style.teacherText, {
      [style.teacherTextFlipped]: flipped,
      [style.teacherTextClosed]: closed
    });

    return (
      <div
        className={classNames(style.flipCard, {
          [style.flipCardClosed]: closed,
          [style.flipCardRectangle]: cardSizeType === CardSizeType.RECTANGLE
        })}
        onClick={!closed ? onClick : undefined}
        style={styles}
      >
        <div className={style.container}>
          <div className={frontClasses}>{frontElement}</div>
          <div className={backClasses}>{backElement}</div>
        </div>
        <div className={teacherTextClasses}>{teacherText}</div>
      </div>
    );
  }
);
