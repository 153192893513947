import React, {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';

import {InlineInput} from 'components/SlateJS/components/InlineInput';
import {type PlaceholderTooltipOptions, type SlatePlugin} from 'components/SlateJS/plugins';
import {withPointerElement} from 'components/SlateJS/plugins/pointer';

import XPlayerTooltip from '../../components/XPlayerTooltip';
import {Answers} from './Answers';

import './Input.scss';

interface Props {
  id: string;
  defaultValue?: string;
  answers: string[];
  placeholder?: string;
  example?: boolean;
  preview?: boolean;
  editable?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

// Since slate-react@0.90.0 editable has default dynamic min-height, based on placeholder client bounding rect height.
// In our case for inline editor we don't need such behaviour, so reset this calculated height
const style = {minHeight: 'initial'};

export const Input: React.FC<Props> = ({
  id,
  defaultValue,
  placeholder,
  answers,
  example,
  editable,
  preview,
  className,
  onChange
}) => {
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState(() => {
    if (example) return answers[0];

    if (defaultValue !== undefined) return defaultValue;

    return editable ? placeholder : '';
  });

  const hasPlaceholder = Boolean(placeholder?.trim());

  const placeholderTooltip: PlaceholderTooltipOptions | undefined = useMemo(() => {
    return hasPlaceholder
      ? {
          overlayClassName: 'placeholder-tooltip',
          placeholder: () => placeholder,
          destroyTooltipOnHide: {keepParent: false}
        }
      : undefined;
  }, [hasPlaceholder, placeholder]);

  const onChangeHandler = useCallback(
    (value: string) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  const empty = !value?.trim() && !placeholder;
  const dirty = !!defaultValue && defaultValue !== placeholder;
  const visibleTooltip = Boolean(example && visible);
  const multipleAnswers = answers.length > 1 && example;

  const plugins = useMemo<SlatePlugin[]>(() => {
    return [withPointerElement({id, preview})];
  }, [id, preview]);

  const input = (
    <InlineInput
      id={id}
      className={classNames(`x-input x-gap-core`, {
        empty,
        className,
        example,
        'multiple-answers': multipleAnswers
      })}
      preview={preview}
      text={value}
      onChange={onChangeHandler}
      placeholder={hasPlaceholder && !editable ? placeholder : undefined}
      readOnly={example}
      focusHidesPlaceholder={true}
      placeholderTooltip={placeholderTooltip}
      plugins={plugins}
      style={style}
    />
  );

  if (example && answers.length > 1) {
    return (
      <XPlayerTooltip
        overlay={
          <Answers
            answers={answers}
            value={defaultValue}
            placeholder={placeholder}
            example={example}
            dirty={dirty}
          />
        }
        visible={visibleTooltip}
        onVisibleChange={setVisible}
        hideArrow={true}
        placement="top"
      >
        <div>{input}</div>
      </XPlayerTooltip>
    );
  }

  return <div className="input-container">{input}</div>;
};
