import React, {type FC} from 'react';
import {type Value} from '@englex/slate';
import classNames from 'classnames';

import SlatePlayer from '../../../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../../../Slate/SlateEditor/plugins/button/Bold';
import Italic from '../../../../Slate/SlateEditor/plugins/button/Italic';
import Underline from '../../../../Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from '../../../../Slate/SlateEditor/plugins/button/StrikeThrough';
import {type PointerListenerProps} from '../../../../Pointer/element/PointerElementListener';

interface Props extends PointerListenerProps {
  id: string;
  title: Value;
  selected: boolean;
  compact: boolean;
  closed?: boolean;
  className?: string;
}

const TitlePlugins = [new Bold(), new Italic(), new Underline(), new StrikeThrough()];

export const TextCard: FC<Props> = React.memo(
  ({id, title, selected, closed, compact, className, ...rest}) => {
    return (
      <div
        id={id}
        className={classNames('x-card text-card', className, {
          selected,
          compact,
          closed
        })}
        {...rest}
      >
        <SlatePlayer value={title} plugins={TitlePlugins} trimEmptyTrailingParagraphs={true} />

        <div className="x-card__pointer-tooltip-area" />
      </div>
    );
  }
);
