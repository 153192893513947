import React, {type FC, memo} from 'react';
import {useDispatch} from 'react-redux';
import {List} from 'immutable';
import classNames from 'classnames';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import {selectCard} from 'store/exercise/player/widgets/PictureChoice/actions';
import {type PictureChoiceCardProperties} from 'store/exercise/player/widgets/PictureChoice/interface';
import {type PictureChoiceValues} from 'store/exercise/editor/widgets/XPictureChoice/interface';
import {CornerCheckbox} from 'components/XPlayer/components/CornerCheckbox/CornerCheckbox';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import {BaseCard} from '../BaseCard/BaseCard';

import './FreeChoiceCard.scss';

interface Props {
  id?: string;
  widgetId: string;
  questionId: string;
  card: PictureChoiceCardProperties;
  values?: PictureChoiceValues;
  preview?: boolean;
}

const FreeChoiceCard: FC<Props> = memo(({widgetId, questionId, card, values, preview}) => {
  const dispatch = useDispatch();

  const checked = !!values?.getIn([questionId], List()).includes(card.id);

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(selectCard(widgetId, questionId, card.id, preview));
  };

  return (
    <BaseCard
      onClick={onClick}
      className={classNames('card-fc', {
        'not-selected': !checked,
        selected: checked,
        'is-rectangle': card.cardSizeType === CardSizeType.RECTANGLE
      })}
    >
      <div className="base-card-wrapper">
        <div className="img-block">
          <LoadableImage imageId={card.imageId} width="100%" height="100%" />

          <div className="mask"></div>
        </div>

        <CornerCheckbox checked={checked} readOnly={true} />
      </div>
    </BaseCard>
  );
});

export default FreeChoiceCard;
