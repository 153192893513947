import React, {type FC, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {type Descendant} from 'slate';
import {withHistory} from 'slate-history';

import {type Role} from 'store/interface';
import {SlateEditor} from 'components/SlateJS/components/SlateEditor';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {questContentChange} from 'store/exercise/player/widgets/actions';

import {
  type SlatePlugin,
  textColors,
  textFormats,
  textHighlights,
  withFormat,
  withIcons,
  withWordCount,
  withSelectionPointer,
  withWidgetProps,
  withDisabledScrollIntoView
} from '../../../SlateJS/plugins';

interface Props {
  id?: string;
  widgetId: string;
  role: Role;
  content: Descendant[];
  onReview?: boolean;
  preview?: boolean;
  closed?: boolean;
  questsCountWords?: boolean;
  getWidgetProps: () => WidgetTypeComponentProps;
}

export const WritingEditor: FC<Props> = ({
  id,
  widgetId,
  role,
  content,
  onReview,
  closed,
  getWidgetProps,
  preview,
  questsCountWords
}) => {
  const dispatch = useDispatch();
  const contentChange = useCallback(
    (value: Descendant[], id: string) => dispatch(questContentChange(widgetId, id, value, preview)),
    [dispatch, preview, widgetId]
  );
  const plugins: SlatePlugin[] = useMemo(() => {
    return [
      withHistory,
      withFormat([...textFormats, ...textColors, ...textHighlights]),
      withIcons(),
      withWordCount({enabled: !!questsCountWords}),
      withWidgetProps({getWidgetProps}),
      withSelectionPointer({id, isAvailable: () => !onReview}),
      withDisabledScrollIntoView()
      // withFormatPainter,
      // withClearFormatting
    ];
  }, [id, questsCountWords, getWidgetProps, onReview]);

  const isNotStudent = role !== 'student';
  const readonly = closed || (isNotStudent ? !onReview : onReview);

  const onChange = useCallback(
    (value: Descendant[]) => {
      id && contentChange?.(value, id);
    },
    [contentChange, id]
  );

  return (
    <div className={classNames('question-editor', {readonly})}>
      <SlateEditor
        value={content}
        onChange={onChange}
        plugins={plugins}
        readOnly={readonly}
        toolbar={{portalId: 'x-player-toolbar-portal'}}
        skipSelectionChange={true}
        autoCapitalize="sentences"
      />
    </div>
  );
};
