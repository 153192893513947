import React, {useMemo} from 'react';
import {OrderedMap} from 'immutable';

import Icon from 'components/Icon';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {type ExerciseRecordMap, type WidgetProperties} from 'store/exercise/player/interface';
import {type Role} from 'store/interface';
import {useXPlayer} from 'hooks/player/useXPlayer';
import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import ViewContentsModal from 'routes/Library/Common/ViewContentsModal';
import {widgetDisplayAsButtonIconDefault} from 'config/static';
import './WidgetPreviewModal.scss';

interface Props {
  role: Role;
  show: boolean;
  onHide: () => void;
  exercise: ExerciseProperties;
  widgetId?: string;
}

export const WidgetPreviewModal: React.FC<Props> = ({role, show, exercise, widgetId, onHide}) => {
  const resourcesNode = useNode('.page-left');

  const widget = useMemo(
    () => exercise.widgets.find((widget: WidgetProperties) => widget.id === widgetId),
    [exercise.widgets, widgetId]
  );

  const displayButton = widget?.displayButton;

  const title = displayButton?.modalTitle || displayButton?.title.document.text;

  const icon = displayButton?.icon ?? widgetDisplayAsButtonIconDefault;

  const exercises: ExerciseRecordMap = useMemo(() => {
    return OrderedMap([
      [
        exercise.id,
        exercise.set(
          'widgets',
          exercise.widgets.filter((widget: WidgetProperties) => widget.id === widgetId)
        )
      ]
    ]);
  }, [exercise, widgetId]);

  const XPlayer = useXPlayer();
  return (
    <ViewContentsModal
      show={show}
      isToggleGroup={true}
      className="widget-preview-modal"
      title={
        <span className="widget-preview-modal__title">
          <Icon name={icon} />
          <span>{title}</span>
        </span>
      }
      close={onHide}
      renderBody={() => (
        <Resources id="widget-preview-modal" node={resourcesNode}>
          <XPlayer
            role={role}
            exercises={exercises}
            hideCompleteButtons={true}
            preview={true}
            widgetPreview={true}
            isModal={true}
          />
        </Resources>
      )}
    />
  );
};
