import {type FC, memo} from 'react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type SpellingProperties} from 'store/exercise/player/widgets/Selling/interface';

import {Sentences} from './components/Sentences/Sentences';
import {SpellingExample} from './components/SpellingExample/SpellingExample';

import './Spelling.scss';

type Props = WidgetTypeComponentProps<SpellingProperties>;

export const Spelling: FC<Props> = memo(
  ({
    widget: {example, id: widgetId, ordinal, sentences},
    widget,
    preview,
    closed,
    role,
    isModal
  }) => {
    return (
      <div className="spelling-sentences">
        {example && (
          <SpellingExample
            preview={preview}
            example={example}
            widgetId={widgetId}
            ordinal={ordinal}
            isOneSentence={sentences.length < 2}
          />
        )}
        <Sentences
          widget={widget}
          closed={closed}
          preview={preview}
          role={role}
          isModal={isModal}
        />
      </div>
    );
  }
);
