import React, {memo, useCallback} from 'react';
import {Button} from 'react-bootstrap/lib/InputGroup';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {
  hideAll,
  hidePart,
  showAll,
  shuffleCards,
  switchIsCollaborativeManagement
} from 'store/exercise/player/widgets/WordPictureSet/actions';
import Icon from 'components/Icon';
import {type Role} from 'store/interface';

import './ControlPanel.scss';

interface Props {
  haveHiddenCards?: boolean;
  widgetId: string;
  isPreview?: boolean;
  closed?: boolean;
  withPadding?: boolean;
  isCollaborativeManagement: boolean;
  role: Role;
}

export const ControlPanel: React.FC<Props> = memo(
  ({
    haveHiddenCards,
    widgetId,
    isPreview,
    closed,
    withPadding = false,
    isCollaborativeManagement,
    role
  }) => {
    const dispatch = useDispatch();

    const onShuffle = useCallback(() => {
      dispatch(shuffleCards(widgetId, isPreview));
    }, [dispatch, widgetId, isPreview]);

    const onHideAll = useCallback(() => {
      dispatch(hideAll(widgetId, isPreview));
    }, [dispatch, widgetId, isPreview]);

    const onHidePart = useCallback(() => {
      dispatch(hidePart(widgetId, isPreview));
    }, [dispatch, widgetId, isPreview]);

    const onShowAll = useCallback(() => {
      dispatch(showAll(widgetId, isPreview));
    }, [dispatch, widgetId, isPreview]);

    const switchManageControl = useCallback(() => {
      dispatch(switchIsCollaborativeManagement(widgetId, isPreview));
    }, [dispatch, isPreview, widgetId]);

    return (
      <div
        className={classNames('control-panel', {
          'with-padding': withPadding,
          'is-collaborative-management': isCollaborativeManagement,
          'is-teacher': role === 'teacher'
        })}
      >
        <div className="control-panel__card-controls">
          <Button
            className="control-panel__button btn btn-transparent"
            disabled={closed}
            onClick={!closed ? onShuffle : undefined}
          >
            <Icon name="random" />
            <FormattedMessage id="XPlayer.WordPictureSet.Shuffle" />
          </Button>

          {haveHiddenCards ? (
            <Button
              className="control-panel__button btn btn-transparent"
              disabled={closed}
              onClick={!closed ? onShowAll : undefined}
            >
              <Icon name="eye" />
              <FormattedMessage id="XPlayer.WordPictureSet.ShowAll" />
            </Button>
          ) : (
            <div className="control-panel__right-block">
              <Button
                className="control-panel__button btn btn-transparent"
                disabled={closed}
                onClick={!closed ? onHidePart : undefined}
              >
                <Icon name="eye-slash" />
                <FormattedMessage id="XPlayer.WordPictureSet.HidePart" />
              </Button>
              <Button
                className="control-panel__button btn btn-transparent"
                disabled={closed}
                onClick={!closed ? onHideAll : undefined}
              >
                <Icon name="eye-slash" />
                <FormattedMessage id="XPlayer.WordPictureSet.HideAll" />
              </Button>
            </div>
          )}
        </div>

        {role === 'teacher' && (
          <div className="control-panel__manage-control">
            <span className="control-panel__manage-label">
              <FormattedMessage id="XPlayer.WordPictureSet.SharedControl" />
            </span>
            <Button
              className={classNames('control-panel__button', 'btn', 'btn-transparent', {
                'btn-danger': isCollaborativeManagement,
                'btn-success': !isCollaborativeManagement
              })}
              disabled={closed}
              onClick={!closed ? switchManageControl : undefined}
            >
              <Icon name="power-off" />
              {isCollaborativeManagement ? (
                <FormattedMessage id="XPlayer.WordPictureSet.TurnOff" />
              ) : (
                <FormattedMessage id="XPlayer.WordPictureSet.TurnOn" />
              )}
            </Button>
          </div>
        )}
      </div>
    );
  }
);
