import React from 'react';

import LoadableImage from 'components/LoadableImage/LoadableImage';

import styles from './StudentImageSide.module.scss';

interface Props {
  imageId: number;
}

export const StudentImageSide: React.FC<Props> = ({imageId}) => {
  return (
    <div className={styles.studentImageSide}>
      <LoadableImage imageId={imageId} width="100%" height="100%" />
    </div>
  );
};
