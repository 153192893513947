import React, {Component} from 'react';
import classNames from 'classnames';

import {type ScrambledExample} from 'store/exercise/player/widgets/ScrambledSentences/interface';

import Checked from '../../GapFill/component/dnd/Checked';
import FullSentence from './FullSentence';
import {isSpace, sentenceBreaks} from './utils';

interface Props {
  example: ScrambledExample;
  widgetId: string;
  preview?: boolean;
}

class Example extends Component<Props> {
  public render() {
    const {fullSentence} = this.props.example;
    return (
      <div className="sentence-block correct example">
        <div className="index">1.</div>
        <div>
          {this.renderCorrectSequence()}
          <FullSentence sentence={fullSentence.map(({value}) => value).join('')} />
        </div>
      </div>
    );
  }

  private renderCorrectSequence = () => {
    const {widgetId, example, preview} = this.props;
    const {fullSentence} = example;
    let shouldCapitalize = true;
    return (
      <div>
        {fullSentence.map(({capitalize, isStatic, value}, i) => {
          if (!isStatic) {
            const id = `${widgetId}-${i}`;

            return (
              <Checked
                key={i}
                id={id}
                capitalize={shouldCapitalize}
                className={classNames('x-dnd-card')}
                value={value}
                answer={value}
                example={true}
                dragCaseSensitive={capitalize}
                useSVGHack={true}
                preview={preview}
              />
            );
          }
          const trimmed = value.trim();
          shouldCapitalize = sentenceBreaks.test(trimmed[trimmed.length - 1]);
          return (
            <span key={i} className={classNames('punctuation', {space: isSpace(value)})}>
              {value}
            </span>
          );
        })}
      </div>
    );
  };
}

export default Example;
