import React, {type FC} from 'react';
import FormControl, {type FormControlProps} from 'react-bootstrap/lib/FormControl';
import {useIntl} from 'react-intl';
import Modal from 'react-bootstrap/lib/Modal';

import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';

import {ExerciseFeedbackShot} from './ExerciseFeedbackShot';

interface Props {
  url: string | null;
  isSubmitting?: boolean;
  message: string;
  setMessage: (msg: string) => void;
}

export const ExerciseFeedbackModalBody: FC<Props> = ({url, isSubmitting, message, setMessage}) => {
  const intl = useIntl();

  return (
    <Modal.Body>
      <div className="exercise-shot-container">
        <ExerciseFeedbackShot imgSrc={url!} />
      </div>
      <div className="exercise-comment">
        <FormControl
          bsSize="lg"
          value={message}
          onChange={(e: React.SyntheticEvent<FormControlProps>) =>
            setMessage(e.currentTarget.value as string)
          }
          componentClass="textarea"
          rows={2}
          placeholder={intl.formatMessage({
            id: 'XPlayer.Exercise.ReportIssue.Modal.Description.Placeholder'
          })}
        />
      </div>
      {isSubmitting && <SimpleLoadingOverlay />}
    </Modal.Body>
  );
};
