import {GapFillType} from 'components/Slate/interface';

import DropTarget from '../component/dnd-input/DropTarget';
import GapFill from './GapFill';

class GapFillDNDInput extends GapFill {
  public gap = GapFillType.DND_INPUT;
  protected Gap = DropTarget;
}

export default GapFillDNDInput;
