import React, {type FC, useCallback, useState} from 'react';
import {useStageRef, withStageRef} from '@englex/paint-react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useDispatch} from 'react-redux';
import {type ThunkDispatch} from 'redux-thunk';
import {type Action} from 'redux';

import Icon from 'components/Icon';
import {type AppState} from 'store/interface';
import {useLoadable} from 'hooks/useLoadable';
import * as toastr from 'components/toastr';

import {exerciseFeedbackSend} from './actions';
import {ExerciseFeedbackModalBody} from './ExerciseFeedbackModalBody';

import './ExerciseFeedbackModal.scss';

interface Props {
  exerciseImgUrl: string;
  close: () => void;
  exerciseId: string;
}

const FeedbackModal: FC<Props> = ({close, exerciseId, exerciseImgUrl}) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatch<AppState, never, Action>>();
  const stageRef = useStageRef();

  const [message, setMessage] = useState('');

  const sendFeedback = useCallback(
    (exerciseId: string, stageRef: ReturnType<typeof useStageRef>, message: string) =>
      dispatch(exerciseFeedbackSend(exerciseId, stageRef, message, window.devicePixelRatio)),
    [dispatch]
  );

  const [submit, , isSubmitting] = useLoadable(sendFeedback, {
    onSuccess: useCallback(() => {
      toastr.success(
        '',
        intl.formatMessage({id: 'XPlayer.Exercise.ReportIssue.Toast.Success.Message'})
      );
      close();
    }, [close, intl]),
    onError: useCallback(() => {
      toastr.error(
        '',
        intl.formatMessage({id: 'XPlayer.Exercise.ReportIssue.Toast.Error.Message'})
      );
    }, [intl])
  });

  const send = useCallback(() => {
    submit(exerciseId, stageRef, message);
  }, [exerciseId, message, stageRef, submit]);

  return (
    <Modal
      backdrop="static"
      className="exercise-feedback-modal"
      show={true}
      onHide={close}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="XPlayer.Exercise.ReportIssue.Modal.Title" />
        </Modal.Title>
        <Button onClick={close} className="btn-ico modal-close" disabled={isSubmitting}>
          <Icon name="pc-multiply" />
        </Button>
      </Modal.Header>
      <ExerciseFeedbackModalBody
        url={exerciseImgUrl}
        isSubmitting={isSubmitting}
        message={message}
        setMessage={setMessage}
      />
      <Modal.Footer>
        <Button bsSize="sm" onClick={close} disabled={isSubmitting}>
          <FormattedMessage id="Common.Cancel" />
        </Button>
        <Button disabled={isSubmitting} bsStyle="primary" bsSize="sm" onClick={send}>
          <FormattedMessage id="Common.Send" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ExerciseFeedbackModal = withStageRef(FeedbackModal);
