import React from 'react';

import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import {isDocumentNotEmpty as documentNotEmpty} from 'components/Slate/utils/documentNotEmpty';
import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Icon from 'components/Slate/plugins/renderers/Icon';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type GrammarRulesProperties} from 'store/exercise/player/widgets/GrammarRules/interface';

import {noteIconSet} from '../Note/noteDataByType';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

import './GrammarRules.scss';

type Props = WidgetTypeComponentProps<GrammarRulesProperties>;

class GrammarRules extends React.PureComponent<Props> {
  public plugins = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Image({isModal: this.props.isModal}),
    new Dialog(),
    new Icon(),
    new SelectionPointer()
  ];

  public static titlePlugins = [
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new Icon({iconset: noteIconSet}),
    new SelectionPointer()
  ];
  public render() {
    const {content, grammarTitle} = this.props.widget;
    return documentNotEmpty(content.document) ? (
      <div className="x-content">
        <div className="grammar">
          {!!grammarTitle && (
            <SlatePlayer
              value={grammarTitle}
              plugins={GrammarRules.titlePlugins}
              trimEmptyTrailingParagraphs={true}
              className="grammar-title"
              getWidgetProps={this.getWidgetProps}
            />
          )}
          <SlatePlayer
            value={content}
            plugins={this.plugins}
            trimEmptyTrailingParagraphs={true}
            className="grammar-content"
            getWidgetProps={this.getWidgetProps}
          />
        </div>
      </div>
    ) : null;
  }

  private getWidgetProps = () => this.props;
}
export default GrammarRules;
