import React from 'react';
import classNames from 'classnames';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import {type Map} from 'immutable';

import {withInsideEnglexSlate} from 'components/SlateJS/plugins/withInsideEnglexSlate';
import {type SlatePlugin} from 'components/SlateJS/plugins/withPlugins';
import {type AppState, type Role} from 'store/interface';
import {GapFillSize, type GapFillType, getWidgetProps} from 'components/Slate/interface';
import {InlineInput} from 'components/SlateJS/components/InlineInput';
import {type PlaceholderTooltipOptions} from 'components/SlateJS/plugins';
import {type Answer, type WidgetComponentProps} from 'store/exercise/player/interface';
import {inputChanged} from 'store/exercise/player/widgets/GapFill/actions';
import {type GapProps as OwnProps} from 'store/exercise/player/widgets/GapFill/interface';
import type GapFillRecord from 'store/exercise/player/widgets/GapFill/GapFillRecord';
import {withPointerElement} from 'components/SlateJS/plugins/pointer';

import Checked from './Checked';
import {getExampleAnswers} from '../dnd-input/helpers';

import './Input.scss';

interface StateProps {
  gap: GapFillType;
  value?: Answer;
  answers?: Answer[];
  closed?: boolean;
  role: Role;
  widgetId: string;
  gapSize?: GapFillSize;
  indefiniteForm?: string;
}

type Props = OwnProps & StateProps & DispatchProps;

interface DispatchProps {
  onAnswer: (answer: Answer, widgetId: string) => void;
}

interface State {
  inputFocused: boolean;
}

class Input extends React.Component<Props, State> {
  public state: State = {
    inputFocused: false
  };

  private plugins: SlatePlugin[] = [
    withInsideEnglexSlate,
    withPointerElement({id: this.props.id, preview: this.props.preview})
  ];

  public render() {
    const {
      id,
      className,
      gapSize,
      value,
      role,
      closed,
      answers,
      example,
      indefiniteForm,
      editable,
      preview,
      gap
    } = this.props;
    if (role !== 'student' || closed || example) {
      return (
        <Checked
          id={id}
          gapType={gap}
          closed={closed}
          answers={answers}
          value={value}
          example={example}
          size={gapSize}
          indefiniteForm={indefiniteForm}
          editable={editable}
          preview={preview}
        />
      );
    }

    return (
      <InlineInput
        id={id}
        preview={preview}
        className={classNames(`x-input x-gap-core`, {
          className,
          dirty: value?.length,
          large: gapSize === GapFillSize.LARGE
        })}
        text={editable && value === undefined ? indefiniteForm : value}
        onChange={this.onChange}
        placeholder={editable ? undefined : indefiniteForm}
        focusHidesPlaceholder={true}
        placeholderTooltip={this.placeholderTooltip}
        plugins={this.plugins}
      />
    );
  }

  private placeholderTooltip: PlaceholderTooltipOptions = {
    overlayClassName: 'indefinite-form',
    placeholder: this.props.editable ? () => this.props.indefiniteForm : undefined
  };

  private onChange = (text: string) => {
    if (text !== undefined && text !== this.props.value) {
      this.props.onAnswer(text, this.props.widgetId);
    }
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const {example, id, answer, editor} = ownProps;
  const {widget, closed, role} = editor.query<WidgetComponentProps<GapFillRecord>>(getWidgetProps);
  const {
    content: {document},
    gap
  } = widget;

  const values = widget.values as Map<string, Answer> | undefined;
  let value: Answer | undefined;

  if (example) {
    value = answer;
  } else if (values) {
    value = values.get(id);
  }

  const answers: Answer[] | undefined = example
    ? getExampleAnswers(id, document)
    : widget.answers
      ? widget.answers.get(id)
      : undefined;

  return {
    gap,
    closed,
    value,
    answers,
    role,
    widgetId: widget.id,
    gapSize: widget.gapSize
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>,
  ownProps: OwnProps
) => {
  const {id, preview} = ownProps;
  return {
    onAnswer: (answer: Answer, widgetId: string) => {
      dispatch(inputChanged(widgetId, id, answer, preview));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
