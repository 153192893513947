import React, {useCallback} from 'react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {widgetDisplayAsButtonIconDefault} from 'config/static';

import {DisplayButton} from './DisplayButton';

interface Props extends WidgetTypeComponentProps {
  onShowPreviewModal: (widgetId: string) => void;
}

export function withDisplayButton<T extends Props>(
  Component: React.ComponentType<Omit<T, 'onShowPreviewModal'>>
) {
  return ({onShowPreviewModal, ...rest}: T) => {
    const {widget} = rest;
    const {id, displayButton} = widget;
    if (!rest.isWidgetPreviewPlayer && displayButton && widget.hasDisplayButton(rest.role)) {
      const {className, title, icon = widgetDisplayAsButtonIconDefault} = displayButton;

      const getWidgetProps = useCallback(() => rest, [rest]);
      const onClick = useCallback(() => onShowPreviewModal(id), [id, onShowPreviewModal]);

      return (
        <DisplayButton
          title={title}
          icon={icon}
          className={className}
          getWidgetProps={getWidgetProps}
          onClick={onClick}
        />
      );
    }

    return <Component {...rest} />;
  };
}
