import {type FC, useCallback, useEffect, useState, memo} from 'react';
import {useDispatch} from 'react-redux';

import {useDndContext} from 'components/XPlayer/contexts/dndContext';
import {
  type Choice,
  type ChoiceOrPunctuation,
  type CommonProps
} from 'components/XPlayer/widgets/ScrambledSentences/components/interface';
import {moveCardVertically} from 'components/XPlayer/widgets/ScrambledSentences/components/utils';
import {dropCard} from 'store/exercise/player/widgets/ScrambledSentences/actions';

import {Punctuation} from '../Punctuation/Punctuation';
import CardContainer from '../CardContainer/CardContainer';
import {type CardType, type PunctuationType, useRenderData} from '../../hooks/useRenderData';

import './Pool.scss';

interface Props extends CommonProps {
  choicesAndPunctuation: ChoiceOrPunctuation[];
}

export const Pool: FC<Props> = memo(
  ({choicesAndPunctuation, sentenceId, role, closed, widgetId, preview, choices, answers}) => {
    const dispatch = useDispatch();
    const {selectedCardId, selectCard} = useDndContext();

    const [markup, setMarkup] = useState<ChoiceOrPunctuation[] | undefined>(undefined);
    const [selectedCardIndex, setSelectedCardIndex] = useState<number | undefined>(undefined);
    const [selectedCardWordId, setSelectedCardWordId] = useState<string | undefined>(undefined);

    useEffect(() => {
      setMarkup(choicesAndPunctuation);
    }, [choicesAndPunctuation]);

    const dropCardHandle = useCallback(
      (sentenceId: string, cardIds: string[], widgetId: string, preview?: boolean) => {
        dispatch(dropCard(sentenceId, cardIds, widgetId, preview));
      },
      [dispatch]
    );

    const unselectCard = useCallback(() => {
      setSelectedCardIndex(undefined);
      setSelectedCardWordId(undefined);
      selectCard(undefined);
    }, [selectCard]);

    const onCardSelect = useCallback(
      (index: number, cardIndex: number, cardId?: string) => {
        setSelectedCardIndex(cardIndex);
        selectCard(cardId);
        setSelectedCardWordId(cardId ? choices.get(cardId)?.tokenGroupId : undefined);
      },
      [choices, selectCard]
    );

    const endDrag = useCallback(() => {
      dropCardHandle(
        sentenceId,
        (markup ? markup : choicesAndPunctuation)
          .filter(item => typeof item !== 'string')
          .map(({id}: Choice) => id),
        widgetId,
        preview
      );
    }, [choicesAndPunctuation, dropCardHandle, markup, preview, sentenceId, widgetId]);

    const moveCardBody = useCallback(
      (dragIndex: number, hoverIndex: number, cb?: () => void) => {
        let cards: Choice[] = markup!.filter(item => typeof item !== 'string') as Choice[];
        if (Math.abs(dragIndex - hoverIndex) === 1) {
          cards = cards.map(card => {
            if (card.index === dragIndex) {
              return {...card, index: hoverIndex};
            }
            if (card.index === hoverIndex) {
              return {...card, index: dragIndex};
            }
            return card;
          });
        } else {
          cards = moveCardVertically(cards, dragIndex, hoverIndex);
        }
        setMarkup(markup =>
          markup?.map(item =>
            typeof item === 'string' ? item : cards.find(c => c.index === item.index)!
          )
        );
        cb?.();
      },
      [markup]
    );

    const moveCard = useCallback(
      (dragIndex: number, hoverIndex: number, cb?: () => void) => {
        if (!markup) setMarkup(choicesAndPunctuation);
        unselectCard();
        moveCardBody(dragIndex, hoverIndex, cb);
      },
      [choicesAndPunctuation, markup, moveCardBody, unselectCard]
    );

    const moveSelectedCard = useCallback(
      (hoverIndex: number) => {
        if (!markup) setMarkup(choicesAndPunctuation);

        if (selectedCardIndex !== undefined && markup) {
          const choices = markup.filter(item => typeof item !== 'string') as Choice[];
          const hoverWordId = choices[hoverIndex]?.tokenGroupId;
          const selectedWordId = choices[selectedCardIndex]?.tokenGroupId;

          if ((!hoverWordId && !selectedWordId) || hoverWordId === selectedWordId) {
            const cards = markup
              .filter(item => typeof item !== 'string')
              .map((item: Choice) => item.id);

            const hoverValue = cards[hoverIndex];
            cards[hoverIndex] = cards[selectedCardIndex];
            cards[selectedCardIndex] = hoverValue;

            dropCardHandle(sentenceId, cards, widgetId, preview);

            setSelectedCardIndex(undefined);
          }
        }
      },
      [
        choicesAndPunctuation,
        dropCardHandle,
        markup,
        preview,
        selectedCardIndex,
        sentenceId,
        widgetId
      ]
    );

    const renderData = useRenderData({markup, choicesAndPunctuation, answers, choices});

    return (
      <div className={'spelling-pool'}>
        {renderData.map((element, i) => {
          if (element.type === 'punctuation') {
            const {item} = element as PunctuationType;
            return <Punctuation key={item + i} item={item} index={i} />;
          }

          const {item, answer, value, capitalize} = element as CardType;
          return (
            <CardContainer
              key={item.id}
              id={item.id}
              index={i}
              cardIndex={item.index}
              selected={selectedCardId === item.id}
              sentenceId={sentenceId}
              widgetId={widgetId}
              answer={answer ? answer.value : undefined}
              dragCaseSensitive={item.capitalize}
              gapCaseSensitive={answer ? answer.capitalize : undefined}
              value={value}
              tokenGroupId={item.tokenGroupId}
              role={role}
              closed={closed}
              endDrag={endDrag}
              moveCard={moveCard}
              onSelect={onCardSelect}
              moveSelectedCard={moveSelectedCard}
              unselectCard={unselectCard}
              capitalize={capitalize}
              preview={preview}
              selectedCardGroupId={selectedCardWordId}
            />
          );
        })}
      </div>
    );
  }
);
