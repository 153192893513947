import React from 'react';

import {type SlatePlugin} from '../../withPlugins';
import {PointerListener, type PointerListenerProps} from './PointerListener';

export const withPointerElement: (options: PointerListenerProps) => SlatePlugin =
  ({tag = 'span', id, preview}) =>
  editor => {
    const Container = editor.renderContainer;

    editor.renderContainer = props => {
      return (
        <Container {...props}>
          <PointerListener id={id} tag={tag} preview={preview}>
            {props.children}
          </PointerListener>
        </Container>
      );
    };
    return editor;
  };
