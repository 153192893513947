import React, {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {categoryChanged, deleteCategory} from 'store/exercise/player/widgets/Matching/actions';
import {EditLine} from 'components/XPlayer/components/EditLile/EditLine';
import {type MatchingNoCategoriesProperties} from 'store/exercise/player/widgets/Matching/interface';

import DropTarget from '../../component/DropTarget';

import './MatchingQuestion.scss';

interface Props {
  id: string;
  text: string;
  preview?: boolean;
  readOnly?: boolean;
  getWidgetProps: () => WidgetTypeComponentProps<MatchingNoCategoriesProperties>;
}

export const MatchingQuestion: FC<Props> = ({id, text, preview, readOnly, getWidgetProps}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {widget, closed} = getWidgetProps();
  const widgetId = widget.id;

  const onChange = useCallback(
    (text: string) => {
      dispatch(categoryChanged(widgetId, id, text, preview));
    },
    [dispatch, id, preview, widgetId]
  );

  const onDelete = useCallback(() => {
    dispatch(deleteCategory(widgetId, id, preview));
  }, [dispatch, id, preview, widgetId]);

  return (
    <>
      <EditLine
        id={id}
        text={text}
        placeholder={intl.formatMessage({id: 'XEditorXWidget.Matching.EnterCategory'})}
        closed={closed}
        readOnly={readOnly}
        onChange={onChange}
        onDelete={onDelete}
        getWidgetProps={getWidgetProps}
      />

      <div className="matching-question">
        <DropTarget
          preview={preview}
          questionId={id}
          getWidgetProps={getWidgetProps}
          flexHandle={true}
        />
      </div>
    </>
  );
};
