import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {type Role} from 'store/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import * as toastr from 'components/toastr';
import {
  addDraftHomeworkExerciseRequest,
  moveHomeworkExerciseToDraft,
  removeHomeworkExerciseRequest,
  toggleSupplementaryExercisesModal
} from 'store/exercise/player/actions';

import HomeworkButton from './Bookmark/HomeworkButton';
import {setMainExerciseId} from '../../../modals/UnitPreview/actions';
import HideAdditionalButton from './HideAdditionalButton';
import {useExerciseInfo} from './useExerciseInfo';
import {useMovingHomeworkErrorMessage} from './useMovingExerciseErrorMessage';
import {exerciseActionMessages} from './exerciseActionMessages';

import './ExerciseActions.scss';

const noop = () => {};

interface Props {
  exerciseId: string;
  role: Role;
  preview?: boolean;
  isMain?: boolean;
  showPreviewHomeworkControl?: boolean;
}

const ExerciseActions: FC<Props> = ({
  exerciseId,
  role,
  isMain,
  preview,
  showPreviewHomeworkControl
}) => {
  const [awaiting, setAwaiting] = useState(false);
  const {formatMessage} = useIntl();
  const {given, isDirty, additionalAvailable, isDraft, unitPreview} = useExerciseInfo(
    exerciseId,
    preview
  );
  const mounted = useRef(true);
  const dispatch = useAxiosDispatch();

  const openAddExtraExerciseModal = useCallback(
    () =>
      preview && unitPreview
        ? dispatch(setMainExerciseId(exerciseId))
        : dispatch(toggleSupplementaryExercisesModal(exerciseId)),
    [preview, unitPreview, dispatch, exerciseId]
  );

  const getMessage = useMovingHomeworkErrorMessage();
  const toggleHomeworkExercise = useCallback(
    (moving?: boolean) => {
      if (awaiting) return;
      if (moving) {
        setAwaiting(true);
        dispatch(moveHomeworkExerciseToDraft(exerciseId))
          .then(() => {
            toastr.success('', formatMessage(exerciseActionMessages.MoveHomeworkToDraftSuccess));
            setAwaiting(false);
          })
          .catch((e: Error) => {
            toastr.error('', getMessage(e.message));
            setAwaiting(false);
          });
        return;
      }
      !given
        ? (() => {
            setAwaiting(true);
            dispatch(addDraftHomeworkExerciseRequest(exerciseId)).catch(() => {
              toastr.error(
                '',
                formatMessage(exerciseActionMessages.AssigningExerciseToHomeworkError)
              );
              setAwaiting(false);
            });
          })()
        : dispatch(removeHomeworkExerciseRequest(exerciseId))
            .then(() =>
              toastr.success(
                '',
                formatMessage(exerciseActionMessages.DeletingExerciseFromHomeworkSuccess)
              )
            )
            .catch(() =>
              toastr.error(
                '',
                formatMessage(exerciseActionMessages.DeletingExerciseFromHomeworkError)
              )
            );
    },
    [awaiting, given, dispatch, exerciseId, formatMessage, getMessage]
  );

  useEffect(() => {
    if (mounted.current && awaiting && isDraft && given) setAwaiting(false);
  }, [awaiting, given, isDraft]);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  const isStudent = role === 'student';
  const shouldRenderAddAdditionals = !isStudent && !!additionalAvailable;
  const shouldRenderDeleteAdditional = !preview && !isStudent && !isMain && !isDirty;
  const shouldRenderHomeworkControl =
    (!preview || showPreviewHomeworkControl) && (!isStudent || given);

  if (
    !shouldRenderAddAdditionals &&
    !shouldRenderDeleteAdditional &&
    !shouldRenderHomeworkControl
  ) {
    return null;
  }

  return (
    <div className="x-exercise-actions">
      {shouldRenderAddAdditionals && (
        <button className="btn btn-ico additional" onClick={openAddExtraExerciseModal}>
          <span>+{additionalAvailable}</span>
        </button>
      )}
      {shouldRenderDeleteAdditional && <HideAdditionalButton exerciseInstanceId={exerciseId} />}
      {shouldRenderHomeworkControl && (
        <HomeworkButton
          awaiting={awaiting}
          given={given}
          isDraft={isDraft}
          isStudent={isStudent}
          toggle={showPreviewHomeworkControl ? noop : toggleHomeworkExercise}
        />
      )}
    </div>
  );
};

export default ExerciseActions;
