import {xplayerPrefix} from '../../actionTypes';
import {type WidgetAction} from '../../interface';
import {xpreviewPrefix} from '../../preview/actionTypes';
import {
  HIDE_ALL,
  HIDE_PART,
  SHOW_ALL,
  SHUFFLE_CARDS,
  SWITCH_IS_HIDDEN_CARD,
  SWITCH_IS_HIDDEN_IMAGE,
  SWITCH_IS_HIDDEN_TEXT,
  WORD_PICTURE_SET_SWITCH_IS_COLLABORATIVE_MANAGEMENT
} from './actionTyps';

export interface SwitchIsHiddenAction extends WidgetAction {
  cardId: string;
}

export const switchIsHiddenText = (
  widgetId: string,
  cardId: string,
  preview?: boolean
): SwitchIsHiddenAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SWITCH_IS_HIDDEN_TEXT}`,
    cardId,
    widgetId,
    preview
  };
};

export const switchIsHiddenImage = (
  widgetId: string,
  cardId: string,
  preview?: boolean
): SwitchIsHiddenAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SWITCH_IS_HIDDEN_IMAGE}`,
    cardId,
    widgetId,
    preview
  };
};

export const switchIsHiddenCard = (
  widgetId: string,
  cardId: string,
  preview?: boolean
): SwitchIsHiddenAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SWITCH_IS_HIDDEN_CARD}`,
    cardId,
    widgetId,
    preview
  };
};

export const showAll = (widgetId: string, preview?: boolean): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SHOW_ALL}`,
    widgetId,
    preview
  };
};

export const hideAll = (widgetId: string, preview?: boolean): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${HIDE_ALL}`,
    widgetId,
    preview
  };
};

export const hidePart = (widgetId: string, preview?: boolean): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${HIDE_PART}`,
    widgetId,
    preview
  };
};

export const shuffleCards = (widgetId: string, preview?: boolean): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SHUFFLE_CARDS}`,
    widgetId,
    preview
  };
};

export const switchIsCollaborativeManagement = (
  widgetId: string,
  preview?: boolean
): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${WORD_PICTURE_SET_SWITCH_IS_COLLABORATIVE_MANAGEMENT}`,
    widgetId,
    preview
  };
};
