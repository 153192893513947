import React from 'react';
import classNames from 'classnames';

import {type ExerciseMediaSource, type ExerciseMediaSources} from 'store/exercise/player/interface';

import './MediaSources.scss';

interface Props {
  readonly mediaSources?: ExerciseMediaSources;
}

function MediaSourcesComponent({mediaSources}: Props) {
  if (!mediaSources || !mediaSources.size) {
    return null;
  }

  const links = mediaSources.map((source: ExerciseMediaSource, i: number) => (
    <span key={source.get('id')}>
      <a
        className={classNames('media-source-link', {last: i === mediaSources.size - 1})}
        href={source.get('url')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {source.get('title')}
      </a>
      {i !== mediaSources.size - 1 && <span>, </span>}
    </span>
  ));

  return (
    <div className="media-sources-container">
      <div className="media-sources-content">Media content provided by {links}.</div>
    </div>
  );
}

export const MediaSources: React.FC<Props> = React.memo(MediaSourcesComponent);
