import React from 'react';
import classNames from 'classnames';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type EssayProperties} from 'store/exercise/player/widgets/Essay/interface';

import EssayEditor from './EssayEditor';
import './Essay.scss';

type OwnProps = WidgetTypeComponentProps<EssayProperties>;

type Props = OwnProps;

export default class Essay extends React.Component<Props> {
  private getWidgetProps = () => this.props;

  public render() {
    const {
      closed,
      role,
      widget: {id, onReview, shouldCountWords, value},
      preview
    } = this.props;
    const readonly = closed || (this.isStudent ? onReview : !onReview);
    return (
      <div className={classNames('essay-editor', {readonly})}>
        <EssayEditor
          closed={closed}
          content={value}
          readonly={readonly}
          role={role}
          shouldCountWords={shouldCountWords}
          widgetId={id}
          preview={preview}
          getWidgetProps={this.getWidgetProps}
        />
      </div>
    );
  }

  private get isStudent() {
    return this.props.role === 'student';
  }
}
