import React, {Component} from 'react';
import {connect, type MapDispatchToProps} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import {turnQuestsReviewOff, turnQuestsReviewOn} from 'store/exercise/player/widgets/actions';

import Icon from '../../../../Icon';

import './QuestsFieldControl.scss';

interface OwnProps {
  homeworkId?: string;
  widgetId: string;
  show: boolean;
  preview?: boolean;
  disabled?: boolean;
  isTouched?: boolean;
  onReview?: boolean;
}

interface DispatchProps {
  turnReviewOn: () => void;
  turnReviewOff: () => void;
}

type Props = OwnProps & DispatchProps;

class QuestsFieldControl extends Component<Props> {
  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.homeworkId && this.props.homeworkId && this.props.onReview) {
      this.props.turnReviewOff();
    }
  }

  public render() {
    const {show, disabled, onReview} = this.props;

    return (
      <div className="quests-control">
        {show && (
          <Button
            className={classNames({disabled: disabled})}
            bsStyle="success"
            bsSize="sm"
            onClick={this.onReviewClick}
          >
            {onReview ? (
              <>
                <Icon name="lock" />
                <FormattedMessage id="XPlayer.Teacher.Lock" />
              </>
            ) : (
              <>
                <Icon name="unlock-alt" />
                <FormattedMessage id="XPlayer.Teacher.Unlock" />
              </>
            )}
          </Button>
        )}
      </div>
    );
  }

  private onReviewClick = (e: React.MouseEvent<Button>): void => {
    if (this.props.disabled) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      return this.props.onReview ? this.props.turnReviewOff() : this.props.turnReviewOn();
    }
  };
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  {preview, widgetId}
) => ({
  turnReviewOn: () => dispatch(turnQuestsReviewOn(widgetId, preview)),
  turnReviewOff: () => dispatch(turnQuestsReviewOff(widgetId, preview))
});

export default connect<null, DispatchProps>(null, mapDispatchToProps)(QuestsFieldControl);
