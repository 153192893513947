import React, {memo} from 'react';

import './TextSlide.scss';

interface Props {
  text: string;
}

export const TextSide: React.FC<Props> = memo(({text}) => {
  return <div className="text-side">{text}</div>;
});
