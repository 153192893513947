import React, {type FC, useCallback, useState} from 'react';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {removeExtraExercise} from 'store/exercise/player/actions';

import Icon from '../../../Icon';
import Confirm from '../../../modals/Confirm';
import useSendApiRequest from '../../../../hooks/rest/useSendApiRequest';
import {exerciseActionMessages} from './exerciseActionMessages';

interface Props extends WrappedComponentProps {
  exerciseInstanceId: string;
}

const HideAdditionalButton: FC<Props> = ({intl: {formatMessage}, exerciseInstanceId}) => {
  const [isShowingDeleteConfirm, setShowingDeleteConfirm] = useState(false);
  const hideConfirm = useCallback(() => setShowingDeleteConfirm(false), []);
  const showConfirm = useCallback(() => setShowingDeleteConfirm(true), []);
  const {call, isLoading} = useSendApiRequest(
    removeExtraExercise,
    [exerciseInstanceId],
    () => {
      setShowingDeleteConfirm(false);
    },
    () => {
      toastr.error('', formatMessage(exerciseActionMessages.HideAdditionalFail));
      setShowingDeleteConfirm(false);
    }
  );
  return (
    <>
      <button
        className="btn btn-ico"
        title={formatMessage(exerciseActionMessages.HideAdditionalTitle)}
        onClick={showConfirm}
      >
        <Icon name="trash" />
      </button>
      <Confirm
        headerText={formatMessage(exerciseActionMessages.HideAdditionalConfirm)}
        show={isShowingDeleteConfirm}
        onDecline={hideConfirm}
        disableButtons={isLoading}
        onAccept={call}
      />
    </>
  );
};

export default injectIntl(HideAdditionalButton);
