import React, {Fragment, PureComponent} from 'react';
import {type Block, type Value} from '@englex/slate';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import {Node} from 'slate';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type QuestionsProperties,
  type QuestionsValues
} from 'store/exercise/player/widgets/Writing/interface';

import Writing from '../Writing';
import {WritingEditor} from '../WritingEditor';
import QuestionsFieldControl from './QuestionsFieldControl';
import SlatePlayer from '../../../../Slate/SlatePlayer/SlatePlayer';

import './Questions.scss';

type Props = WidgetTypeComponentProps<QuestionsProperties>;

const duration = 300;

class Questions extends PureComponent<Props> {
  private isTouched = (values: QuestionsValues): boolean => {
    let touched = false;
    values.get('questions').forEach(quest => {
      if (quest && Node.string({children: quest.content} as Node).length > 0) {
        touched = true;
        return false;
      }
      return;
    });
    return touched;
  };

  private getWidgetProps = () => this.props;

  public render() {
    const {
      closed,
      preview,
      role,
      homeworkId,
      widget: {id, values}
    } = this.props;

    const show = this.shouldShowFields();

    return (
      <Writing>
        {role !== 'student' ? (
          <QuestionsFieldControl
            role={role}
            homeworkId={homeworkId}
            preview={preview}
            disabled={closed}
            widgetId={id}
            show={show}
            isTouched={this.isTouched(values)}
            onReview={this.onReview()}
          />
        ) : null}
        {this.renderQuestions(show)}
      </Writing>
    );
  }

  private renderQuestions = (show: boolean) => {
    const {
      closed,
      preview,
      role,
      widget: {id, questions, questionsCountWords, questionsExampleContent, values}
    } = this.props;

    const shouldHide = !show;
    return (
      <CSSTransition in={show} classNames="animation" timeout={duration}>
        <div>
          {questions.map((q: Value, k: number) => {
            if (!k && questionsExampleContent) {
              return (
                <Fragment key={k}>
                  <div className="quest-block">
                    <div className="question-title">
                      <SlatePlayer
                        value={q}
                        plugins={Writing.exampleTaskPlugins}
                        getWidgetProps={this.getWidgetProps}
                      />
                    </div>
                    <div className="question-editor example">
                      <SlatePlayer
                        value={questionsExampleContent}
                        plugins={Writing.examplePlayerPlugins}
                        getWidgetProps={this.getWidgetProps}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            }
            const questionId = (q.document.nodes.first() as Block).data.get('id');
            const question = values.get('questions').get(questionId);
            return (
              <Fragment key={k}>
                <div className="quest-block">
                  <div
                    className={classNames('question-title', {
                      'hidden-fields': shouldHide
                    })}
                  >
                    <SlatePlayer
                      value={q}
                      plugins={Writing.regularTaskPlugins}
                      getWidgetProps={this.getWidgetProps}
                    />
                  </div>
                  {!shouldHide && (
                    <WritingEditor
                      id={questionId}
                      content={question.content}
                      role={role}
                      widgetId={id}
                      onReview={this.onReview()}
                      preview={preview}
                      closed={closed}
                      questsCountWords={questionsCountWords}
                      getWidgetProps={this.getWidgetProps}
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </CSSTransition>
    );
  };

  private onReview() {
    const {widget} = this.props;
    return widget.values.get('onReview', false);
  }

  private shouldShowFields = (): boolean => {
    const isExerciseInHomework = Boolean(this.props.homeworkId || this.props.isHomework);
    const opened = this.props.widget.values.get('opened');
    const isTeacherTouchedToggleButton = opened !== undefined;

    return !isTeacherTouchedToggleButton ? isExerciseInHomework : !!opened;
  };
}

export default Questions;
