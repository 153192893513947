import {type FC} from 'react';

import {type SlatePlugin} from './withPlugins';

export const withBeforeEditable =
  (Component: FC): SlatePlugin =>
  editor => {
    const Container = editor.renderContainer;
    editor.renderContainer = props => {
      return (
        <Container {...props}>
          <Component />
          {props.children}
        </Container>
      );
    };
    return editor;
  };
