import React, {type Dispatch, type FC, useCallback, useRef, useState} from 'react';
import {type Action} from 'redux';

import {type ImageV2} from 'store/interface';
import genKey from 'components/Slate/utils/genKey';

import {type Id, type InViewerProps, Type} from './interface';
import {
  activateResource,
  loadResource,
  registerResource,
  unregisterResource
} from './state/actions';
import {useRegistration} from './utils';

import {useResourcesContext, useViewerContext} from '.';

const InViewer: FC<InViewerProps> = React.memo(({children, id, type}) => {
  const viewerContext = useViewerContext();
  const resourcesContext = useResourcesContext();

  if (!(resourcesContext && viewerContext)) {
    return <>{children()}</>;
  }
  return (
    <InViewerIfResources
      dispatch={viewerContext.dispatch}
      id={id}
      providerId={resourcesContext.id}
      type={type}
    >
      {children}
    </InViewerIfResources>
  );
});

const InViewerIfResources: FC<
  {
    dispatch: Dispatch<Action>;
    providerId: Id;
  } & InViewerProps
> = React.memo(({children, dispatch, id, providerId, type}) => {
  // postfix is a way to handle multiple carousel elements with the same original id
  const postfix = useRef(genKey());
  useRegistration(
    dispatch,
    registerResource,
    unregisterResource,
    providerId,
    `${id}::${postfix.current}`,
    type
  );
  const [loaded, setLoaded] = useState(false);
  const activate = useCallback(() => {
    dispatch(activateResource(providerId, `${id}::${postfix.current}`, type));
  }, [dispatch, id, providerId, type]);

  const onLoaded = useCallback<(data: ImageV2) => void>(
    data => {
      dispatch(
        loadResource(
          providerId,
          {
            id: `${data.id}::${postfix.current}`,
            urls: data.urls
          },
          Type.IMAGE
        )
      );
      setLoaded(true);
    },
    [dispatch, providerId]
  );

  return <>{children(onLoaded, loaded ? activate : undefined)}</>;
});

export default InViewer;
