import React, {Component} from 'react';
import {connect} from 'react-redux';
import {type Map} from 'immutable';

import {type AppState} from 'store/interface';
import {allowAudio} from 'store/media/audio/actions';
import {type AudioFile} from 'components/media/interface';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type AudioProperties} from 'store/exercise/player/widgets/Audio/interface';
import {setLoadedAudio} from 'store/exercise/player/widgets/Audio/actions';

import Transcript from '../../components/Transcript/Transcript';
import Player from './Player';

type OwnProps = WidgetTypeComponentProps<AudioProperties>;

interface StateProps {
  audioAllowed: boolean;
}

interface DispatchProps {
  allowAudio(): void;
  setLoadedAudio(widgetId: string, file: AudioFile, preview?: boolean): void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  animateTranscript: boolean;
}

class AudioWidget extends Component<Props, State> {
  state: State = {animateTranscript: false};

  private getWidgetProps = () => this.props;

  public componentDidMount() {
    if (!this.props.audioAllowed) {
      this.props.allowAudio();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {closed, widget} = this.props;
    if (
      !this.shouldRenderTranscript(prevProps.closed, prevProps.widget.values) &&
      this.shouldRenderTranscript(closed, widget.values)
    ) {
      this.setState({animateTranscript: true});
    }
  }

  public render() {
    const {
      audioAllowed,
      closed,
      preview,
      role,
      widget: {audio, content, audioId, id: widgetId, values}
    } = this.props;
    const show = values?.get('showCollapsible') || closed;
    const isExpanded = this.isModal ? false : role === 'student';

    if (!audioAllowed) {
      return null;
    }

    return (
      <div className="x-content">
        <Player
          widgetId={widgetId}
          isModal={this.isModal}
          audio={audio}
          parentContainerClass={'x-player'}
          audioId={audioId}
          setLoadedAudio={this.setLoadedAudio}
          preview={preview}
          withTogetherButton={role === 'teacher'}
        />
        {!this.shouldRenderTranscript(closed, values) ? null : (
          <Transcript
            animateOnMount={this.state.animateTranscript}
            closed={closed}
            content={content}
            preview={preview}
            role={role}
            show={show}
            widgetId={widgetId}
            expanded={isExpanded}
            getWidgetProps={this.getWidgetProps}
            isModal={!!this.props.widget.displayButton}
          />
        )}
      </div>
    );
  }

  private shouldRenderTranscript(
    closed?: boolean,
    values?: Map<'showCollapsible', boolean>
  ): boolean {
    const {role} = this.props;

    if (this.isModal) return true;

    return closed || role !== 'student' || !!values?.get('showCollapsible');
  }

  private setLoadedAudio = (file: AudioFile) => {
    const {
      preview,
      widget: {id, displayButton}
    } = this.props;

    if (displayButton && preview) {
      this.props.setLoadedAudio(id, file, undefined);
    }

    this.props.setLoadedAudio(id, file, preview);
  };

  private get isModal() {
    return !!this.props.widget.displayButton;
  }
}

const mapStateToProps = ({media}: AppState) => ({audioAllowed: !!media.audio});

const mapDispatchToProps = {allowAudio, setLoadedAudio};

export default connect(mapStateToProps, mapDispatchToProps)(AudioWidget);
