import {type List, type Map} from 'immutable';
import {type XYCoord} from 'react-dnd';

import {wordTemplateLiteral} from 'config/static';
import {type ScrambledSentenceChoiceJSON} from 'store/exercise/player/widgets/ScrambledSentences/interface';

import {
  type CheckCorrectnessProps,
  type Choice,
  type ChoiceOrPunctuation,
  type HoverProps
} from './interface';

export const isSpace = (str: string) => str.trim() === '' || str === '\u200b';

export const sentenceBreaks = /[….?!]+/;

export const resolveTemplateArray = (template: string): string[] => {
  const draggables = template.match(new RegExp(wordTemplateLiteral, 'g')) || [];

  const templateArray: string[] = [];
  let temp: string = template;

  for (const d of draggables) {
    const index = temp.indexOf(d);
    templateArray.push(temp.substring(0, index));
    templateArray.push(wordTemplateLiteral);
    temp = temp.substring(index + 1);
  }
  templateArray.push(temp);
  return templateArray.filter(s => s.length);
};

export const choicesAndPunctuationBuilder = (
  templateArray: ChoiceOrPunctuation[],
  choices: Map<string, ScrambledSentenceChoiceJSON>,
  values?: List<string>
): ChoiceOrPunctuation[] =>
  values
    ? fromChoicesAndValues(templateArray, choices, values)
    : fromChoices(templateArray, choices);

const fromChoices = (
  templateArray: ChoiceOrPunctuation[],
  choices: Map<string, ScrambledSentenceChoiceJSON>
): ChoiceOrPunctuation[] => {
  const cap = [...templateArray];
  const ids = choices.keySeq().toArray();
  for (let index = 0; index < choices.size; index++) {
    const id = ids[index];
    cap[cap.indexOf(wordTemplateLiteral)] = {...choices.get(id), id, index};
  }
  return isolateEdgeSpaces(cap);
};

const fromChoicesAndValues = (
  templateArray: ChoiceOrPunctuation[],
  choices: Map<string, ScrambledSentenceChoiceJSON>,
  values: List<string>
): ChoiceOrPunctuation[] => {
  const cap = [...templateArray];
  for (let index = 0; index < choices.size; index++) {
    cap[cap.indexOf(wordTemplateLiteral)] = {
      ...choices.get(values.get(index)),
      id: values.get(index),
      index
    };
  }
  return isolateEdgeSpaces(cap);
};

const isolateEdgeSpaces = (cap: ChoiceOrPunctuation[]): ChoiceOrPunctuation[] => {
  const newCap: ChoiceOrPunctuation[] = [];
  for (const item of cap) {
    if (typeof item === 'string' && !isSpace(item)) {
      let strippedItem = item;
      if (isSpace(strippedItem[0])) {
        newCap.push('\u200b');
        strippedItem = strippedItem.slice(1);
      }
      if (isSpace(strippedItem[strippedItem.length - 1])) {
        strippedItem = strippedItem.slice(0, strippedItem.length - 1);
        if (strippedItem.length) newCap.push(strippedItem);
        newCap.push('\u200b');
      } else {
        newCap.push(strippedItem);
      }
    } else {
      newCap.push(item);
    }
  }

  return newCap;
};

export const moveCardVertically = (cards: Choice[], dragIndex: number, hoverIndex: number) => {
  const movedLeft: boolean = dragIndex > hoverIndex;
  const sequencesToShift = Array.from(
    {length: Math.abs(dragIndex - hoverIndex) + 1},
    (v, k) => k + Math.min(dragIndex, hoverIndex)
  );
  const draggedCard = cards.find(card => card.index === dragIndex);
  cards = cards
    .filter(card => card.index !== dragIndex)
    .map(card =>
      sequencesToShift.includes(card.index)
        ? {...card, index: movedLeft ? card.index + 1 : card.index - 1}
        : card
    )
    .concat({...draggedCard!, index: hoverIndex});

  return cards;
};

export const hover = ({
  item,
  monitor,
  dropTarget,
  widgetId,
  sentenceId,
  tokenGroupId,
  cardIndex,
  moveCard
}: HoverProps) => {
  if (!dropTarget.current) return;

  if (
    item.widgetId !== widgetId ||
    item.sentenceId !== sentenceId ||
    (tokenGroupId && item.tokenGroupId !== tokenGroupId)
  )
    return;
  const dragIndex = item.cardIndex;
  const hoverIndex = cardIndex;
  const neighbours = Math.abs(hoverIndex - dragIndex) === 1;
  if (dragIndex === hoverIndex) return;
  if (neighbours) {
    const {right, left} = dropTarget.current.getBoundingClientRect();
    const {x} = monitor.getClientOffset() as XYCoord;
    const hoverMiddleX = (right - left) / 2;
    const hoverClientX = x - left;
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
  }

  item.cardIndex = hoverIndex;
  moveCard(dragIndex, hoverIndex);
};

export const checkCorrectness = ({answers, choices, values}: CheckCorrectnessProps) => {
  if (answers && !values) {
    let isCorrect = true;
    const keys = choices.keySeq().toArray();
    answers.forEach((a: string, i: number) => {
      const answer = choices.get(a);
      const value = choices.get(keys[i]);
      if (
        answer.capitalize !== value.capitalize ||
        answer.value.toLowerCase() !== value.value.toLowerCase()
      ) {
        isCorrect = false;
      }
    });
    return isCorrect;
  } else if (answers && values && answers.size === values.size) {
    let isCorrect = true;
    answers.forEach((a: string, i: number) => {
      const answer = choices.get(a);
      const value = choices.get(values.get(i));
      if (
        answer?.capitalize !== value?.capitalize ||
        answer?.value.toLowerCase() !== value?.value.toLowerCase()
      ) {
        isCorrect = false;
      }
    });
    return isCorrect;
  }
  return false;
};
