import classNames from 'classnames';
import React, {type FC} from 'react';

import './BaseCard.scss';

interface Props {
  onClick?: (event: React.MouseEvent) => void;
  className: string;
}

export const BaseCard: FC<Props> = ({onClick, className, children}) => {
  return (
    <div onClick={onClick} className={classNames('base-card', className)}>
      {children}
    </div>
  );
};
