import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import './VisibilityButton.scss';

interface Props {
  disabled?: boolean;
  nullPredicate?: boolean;
  show?: boolean;
  toggleVisibility(e: React.MouseEvent<Button, MouseEvent>): void;
}

export const VisibilityButton: FC<Props> = ({disabled, nullPredicate, show, toggleVisibility}) => {
  const intl = useIntl();
  return nullPredicate ? null : (
    <Button
      className="toggle-collapsible-btn"
      bsStyle={show ? 'danger' : 'success'}
      bsSize="xs"
      onClick={toggleVisibility}
      disabled={disabled}
    >
      <Icon name={show ? 'eye-slash' : 'eye'} />
      {show ? intl.formatMessage({id: 'Common.Hide'}) : intl.formatMessage({id: 'Common.Show'})}
    </Button>
  );
};
