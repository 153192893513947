import {type Plugin} from '@englex/slate-react';

import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Icon from 'components/Slate/plugins/renderers/Icon';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';

export const playerPlugins = (): Plugin[] => [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new Color(),
  new Highlight(),
  new Link(),
  new Icon(),
  new Dialog()
];
