import {type AudioFile} from '../../../../../components/media/interface';
import {SET_LOADED_AUDIO} from './actionTypes';
import {type WidgetAction} from '../../interface';
import {xplayerPrefix} from '../../actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';

export interface SetLoadedAudioAction extends WidgetAction {
  file: AudioFile;
}

export const setLoadedAudio = (
  widgetId: string,
  file: AudioFile,
  preview?: boolean
): SetLoadedAudioAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${SET_LOADED_AUDIO}`,
    file,
    widgetId,
    preview
  };
};
