import React from 'react';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';
import classNames from 'classnames';
import {type Map} from 'immutable';

import {type AppState, type Role} from 'store/interface';
import {default as RcDropDown} from 'components/DropDown';
import {GapFillSize, getWidgetProps} from 'components/Slate/interface';
import {type Answer, type WidgetComponentProps} from 'store/exercise/player/interface';
import {inputChanged} from 'store/exercise/player/widgets/GapFill/actions';
import {
  type GapFillProperties,
  type GapProps as OwnProps
} from 'store/exercise/player/widgets/GapFill/interface';

import Checked from './Checked';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

import './DropDown.scss';

interface StateProps {
  answer?: Answer;
  closed?: boolean;
  value?: string;
  widgetId: string;
  role: Role;
  gapSize?: GapFillSize;
}

interface DispatchProps {
  onChange: (widgetId: string, answer: Answer) => Action;
}

type Props = OwnProps & StateProps & DispatchProps;

class DropDown extends React.Component<Props> {
  public render() {
    const {id, gapSize, value, role, choices, className, answer, closed, example, preview} =
      this.props;

    const classes = classNames('x-dropdown', className, {
      large: gapSize === GapFillSize.LARGE
    });

    if (role !== 'student' || closed || example) {
      return (
        <Checked
          id={id}
          size={gapSize}
          value={value}
          choices={choices!}
          answer={answer}
          closed={closed}
          example={example}
          preview={preview}
        />
      );
    }

    return (
      <PointerElementListener preview={preview} inline={true}>
        <span id={id}>
          <RcDropDown
            className={classes}
            menuClassName="x-dropdown-menu"
            itemClassName="x-dropdown-menu-item"
            overlayClassName="x-dropdown-overlay"
            toggleClassName={classNames('x-gap-core', {dirty: value !== undefined})}
            items={choices!}
            value={value}
            onChange={this.onChange}
            inline={true}
            emptyItem="(none)"
            emptyValue="  "
          />
        </span>
      </PointerElementListener>
    );
  }

  private onChange = (answer: Answer) => this.props.onChange(this.props.widgetId, answer);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  ownProps: OwnProps
): StateProps => {
  const {example, id, editor} = ownProps;
  const {widget, closed, role} =
    editor.query<WidgetComponentProps<GapFillProperties>>(getWidgetProps);
  const values = widget.values as Map<string, Answer> | undefined;

  let value: Answer | undefined;
  if (example) {
    value = ownProps.answer;
  } else if (values) {
    value = values.get(ownProps.id);
  }

  let answer: Answer | undefined;

  if (example) {
    answer = ownProps.answer;
  } else if (widget.answers && widget.answers.size) {
    answer = widget.answers.get(id)[0];
  }

  return {
    closed,
    value,
    answer,
    widgetId: widget.id,
    role,
    gapSize: widget.gapSize
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>,
  ownProps: OwnProps
) => {
  const {id, preview} = ownProps;
  return {
    onChange: (widgetId: string, answer: Answer) => {
      return dispatch(inputChanged(widgetId, id, answer, preview));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDown);
