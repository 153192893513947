import React, {type FC, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import {type CommentHandlers} from '../interface';
import Confirm from '../../../../../modals/Confirm';

interface Props extends CommentHandlers {
  id: string;
}

export const CommentActions: FC<Props> = ({id, editComment, deleteComment}) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const openConfirm = useCallback(() => setIsOpen(true), []);
  const closeConfirm = useCallback(() => setIsOpen(false), []);

  const onAccept = useCallback(() => deleteComment(id), [deleteComment, id]);

  const onChange = useCallback(
    (key: string) => {
      if (key === 'edit') {
        return editComment(id);
      }

      if (key === 'delete') {
        return openConfirm();
      }
    },
    [editComment, id, openConfirm]
  );

  return (
    <>
      <Confirm
        headerText={intl.formatMessage({id: 'Exercise.DeleteCommentTitle'})}
        disableButtons={false}
        onAccept={onAccept}
        onDecline={closeConfirm}
        show={isOpen}
      />

      <DropDown
        items={{
          edit: intl.formatMessage({id: 'Message.Edit'}),
          delete: intl.formatMessage({id: 'Message.DeleteDropdownMessage'})
        }}
        value={
          <Icon
            name="ellipsis-h"
            className="edit-message-icon"
            title={intl.formatMessage({id: 'Message.EditDropdownMessage'})}
          />
        }
        noCaret={true}
        onChange={onChange}
        placement="top"
        trigger={['click']}
      />
    </>
  );
};
