import React, {Component, type FC} from 'react';
import {findDOMNode} from 'react-dom';
import classNames from 'classnames';

import {PoolPortalWrapper} from 'components/PoolPortalWrapper';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type ImageMatchingCardJSON,
  type ImageMatchingProperties
} from 'store/exercise/player/widgets/ImageMatching/interface';

import {Provider} from '../../contexts/dndContext';
import Pool from './Pool';
import Card from './Card';

import './ImageMatching.scss';

type Props = WidgetTypeComponentProps<ImageMatchingProperties>;

class ImageMatching extends Component<Props> {
  public render() {
    const {
      props: {preview, widget, role, closed, isModal},
      Cards
    } = this;

    return (
      <Provider isModal={isModal}>
        <div className="image-matching-widget">
          <PoolPortalWrapper
            getParent={this.getParent}
            preview={preview}
            role={role}
            closed={closed}
          >
            {inPortal => (
              <Pool
                widget={widget}
                preview={preview}
                inPortal={inPortal}
                role={role}
                closed={closed}
                choiceIsUsed={this.choiceIsUsed}
              />
            )}
          </PoolPortalWrapper>
          <Cards />
        </div>
      </Provider>
    );
  }

  private choiceIsUsed = (choiceId?: string) => {
    const {values} = this.props.widget;
    return choiceId && values ? values.includes(choiceId) : false;
  };

  private getParent = () => findDOMNode(this) as HTMLDivElement | null;

  private Cards: FC = () => {
    const {preview, widget, role, closed} = this.props;
    return (
      <div
        className={classNames('im-cards', {
          teacher: role === 'teacher'
        })}
      >
        {widget.cards.map((card: ImageMatchingCardJSON) => (
          <Card
            key={card.id}
            id={card.id}
            imageId={card.imageId}
            cardSizeType={card.cardSizeType}
            preview={preview}
            widget={widget}
            role={role}
            closed={closed}
          />
        ))}
      </div>
    );
  };
}

export default ImageMatching;
