import React, {PureComponent} from 'react';
import {type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import classNames from 'classnames';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import SlatePlayer from '../../Slate/SlatePlayer/SlatePlayer';
import Icon from '../../Slate/plugins/renderers/Icon';
import Italic from '../../Slate/plugins/renderers/Italic';
import Underline from '../../Slate/plugins/renderers/Underline';
import StrikeThrough from '../../Slate/plugins/renderers/StrikeThrough';
import Highlight from '../../Slate/plugins/renderers/Highlight';
import ExerciseLabel from '../../Slate/plugins/renderers/ExerciseLabel';
import {SelectionPointer} from '../../Slate/plugins/renderers/Pointer/SelectionPointer';

interface OwnProps {
  exerciseId: string;
  value: Value;
  plugins?: Plugin[];
  preview?: boolean;
  isHomeworkPlayer?: boolean;
  isAvailableSelection?: boolean;
  getWidgetProps: () => WidgetTypeComponentProps;
}

type Props = OwnProps;

class Task extends PureComponent<Props> {
  public static plugins: Plugin[] = [
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new Highlight(),
    new Icon()
  ];

  private readonly plugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.plugins = Task.plugins.concat(
      new ExerciseLabel(
        this.props.preview,
        !this.props.isHomeworkPlayer ? this.props.exerciseId : undefined
      )
    );

    if (props.isAvailableSelection) {
      const {widget} = this.props.getWidgetProps();
      this.plugins.push(new SelectionPointer({id: `task-${widget.id}`, skipContentHashing: true}));
    }
  }

  public render() {
    const {children, value, getWidgetProps} = this.props;
    return (
      <div className={classNames('x-task', {'with-actions': children})}>
        <SlatePlayer value={value} plugins={this.plugins} getWidgetProps={getWidgetProps} />
        {children}
      </div>
    );
  }
}

export default Task;
