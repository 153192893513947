import {type Editor} from 'slate';

import {type Mutable} from 'components/SlateJS/interface';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import {type SlateEditorProps} from '../components/SlateEditor';
import {type SlatePlugin} from './withPlugins';

export interface WidgetPropsEditor {
  getWidgetProps: () => WidgetTypeComponentProps;
}

interface Options {
  getWidgetProps: () => WidgetTypeComponentProps;
}

export const withWidgetProps =
  ({getWidgetProps}: Options): SlatePlugin =>
  (editor: Editor & SlateEditorProps) => {
    const e = editor as Mutable<Editor & SlateEditorProps>;

    e.getWidgetProps = getWidgetProps;

    return e;
  };
