import React, {type FC, useCallback, useEffect, useMemo, useState, memo} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';

import {push} from 'store/router';
import {isMobileWebView} from 'helpers/browser';
import {requestDictionaryList} from 'store/dictionary/requests';
import {type AppState} from 'store/interface';
import {trainerPathCreator} from 'routes/Dictionary/ListTrainer/utils';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {useWebViewMessage, WebViewMessageType} from 'hooks/webview/useWebViewMessage';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import DropDown from 'components/DropDown';
import {useAddListToTeacherFromWidget} from 'components/Dictionary/hooks/useAddListToTeacherFromWidget';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {type Status} from './VocabularyWidgetComponent';

interface Props {
  exerciseId: string;
  dictionaryListId?: string | null;
  isStudent: boolean;
  status?: Status;
  updatedTimestamp?: number;
  createExerciseList(): void;
}

type DisabledType = 'isOnCall' | 'notEnoughEntries' | false;

export const VocabularyButton: FC<Props> = memo(props => {
  const {dictionaryListId, isStudent, status, updatedTimestamp} = props;
  const isOnCall = useSelector<AppState, boolean>(s => !!s.rtc.call?.status);
  const isLoaded = status === 'loaded';

  const [enoughEntries, setIsEnoughEntries] = useState<boolean>(false);
  const {apiRequest} = useApiRequest2<[string, string[]], DictionaryList>(
    requestDictionaryList,
    ({entryCount}) => setIsEnoughEntries((entryCount || 0) >= 4),
    () => {}
  );

  const disabled = useMemo<DisabledType>(() => {
    if (!isStudent || !dictionaryListId) return false;
    if (isOnCall) return 'isOnCall';
    if (!enoughEntries) return 'notEnoughEntries';
    return false;
  }, [dictionaryListId, enoughEntries, isOnCall, isStudent]);

  useEffect(() => {
    if (!dictionaryListId || !updatedTimestamp) return;
    apiRequest(dictionaryListId, ['overview']);
  }, [apiRequest, dictionaryListId, updatedTimestamp]);

  return (
    <div className="button" id="vocabulary-button-tooltip-anchor">
      {!isLoaded ? null : !disabled ? (
        <VocabularyButtonInner {...props} disabled={disabled} />
      ) : (
        <ButtonTooltip disabled={disabled}>
          <VocabularyButtonInner {...props} disabled={disabled} />
        </ButtonTooltip>
      )}
      {!isLoaded && <Spinner size={24} />}
    </div>
  );
});

const ButtonTooltip: FC<{disabled: Exclude<DisabledType, false>}> = memo(({children, disabled}) => (
  <Tooltip
    overlay={() =>
      disabled === 'isOnCall' ? (
        <FormattedMessage id="Dictionary.InaccessibleAction" />
      ) : (
        <FormattedMessage id="Dictionary.LeastWords" />
      )
    }
    getTooltipContainer={useCallback(
      (): HTMLElement =>
        document.querySelector('#vocabulary-button-tooltip-anchor') || document.body,
      []
    )}
    trigger={['hover']}
    placement="top"
  >
    <div>{children}</div>
  </Tooltip>
));

const VocabularyButtonInner: FC<Props & {disabled: DisabledType}> = memo(
  ({createExerciseList, dictionaryListId, disabled, isStudent, exerciseId}) => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {dictionaryOwnerRole, dictionaryOwnerId} = useDictionaryContext();
    const {addWidgetListToTeacher} = useAddListToTeacherFromWidget(exerciseId);

    const postMessage = useWebViewMessage();
    const onClick = useCallback(() => {
      if (isMobileWebView() && dictionaryListId) {
        return postMessage({
          type: WebViewMessageType.Train,
          payload: {listId: dictionaryListId}
        });
      }

      dictionaryListId
        ? dispatch(
            push(trainerPathCreator(dictionaryOwnerId, dictionaryListId, dictionaryOwnerRole))
          )
        : createExerciseList();
    }, [
      createExerciseList,
      dictionaryListId,
      dictionaryOwnerId,
      dictionaryOwnerRole,
      dispatch,
      postMessage
    ]);

    const onDropdownChange = useCallback(
      (key: string) => {
        if (key === 'create_in_student_dictionary') createExerciseList();
        if (key === 'create_in_my_dictionary') addWidgetListToTeacher();
      },
      [addWidgetListToTeacher, createExerciseList]
    );

    return !isStudent ? (
      <DropDown
        toggleClassName="add btn btn-sm btn-success add-all"
        items={{
          create_in_student_dictionary: {
            value: formatMessage({id: 'Dictionary.CreateInStudentDictionary'}),
            disabled: !!dictionaryListId
          },
          create_in_my_dictionary: formatMessage({id: 'Dictionary.CreateInMyDictionary'})
        }}
        onChange={onDropdownChange}
        value={
          <>
            <Icon name="pc-inc" /> {formatMessage({id: 'Dictionary.CreateWordList'})}
          </>
        }
        placement="bottom"
        trigger={['click']}
        disabled={!!disabled}
        noCaret={true}
      />
    ) : (
      <Button
        className={classNames('add-all', {disabled})}
        bsStyle="success"
        bsSize="sm"
        onClick={!disabled ? onClick : undefined}
      >
        {!!dictionaryListId ? (
          <>
            <Icon name={isStudent ? 'play' : 'virc-note'} />{' '}
            {formatMessage({id: 'Dictionary.Train'})}
          </>
        ) : (
          <>
            <Icon name="pc-inc" /> {formatMessage({id: 'Dictionary.CreateWordList'})}
          </>
        )}
      </Button>
    );
  }
);
