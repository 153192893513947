import React, {Component} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import {type Role} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {type VocabularyWordProperties} from 'store/exercise/editor/widgets/XVocabulary/interface';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';

import VocabularyWordOriginal from './VocabularyWordOriginal';
import {PointerElementListener} from '../../../Pointer/element/PointerElementListener';
import {type AddWordToDictionaryResponse} from '../../../Dictionary/shared/interface';

const trigger = ['hover'];

const align = {
  offset: ['50%', 0],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
} as TooltipProps['align'];

interface Props extends WrappedComponentProps {
  addWordsToDictionary(
    exerciseId: string,
    widgetId: string,
    wordIds: string[]
  ): Promise<AxiosResponseAction<AddWordToDictionaryResponse>>;
  getWordNumber(wordId: string): number;
  exerciseId: string;
  isInactive: boolean;
  isStudent: boolean;
  loading: boolean;
  preview?: boolean;
  role: Role;
  widgetId: string;
  wordsGroup: VocabularyWordProperties[];
}

interface State {
  loadingIds: string[];
}

class VocabularyWordsGroup extends Component<Props, State> {
  public state: State = {loadingIds: []};

  private cancellable: CancellablePromise | undefined;

  public componentWillUnmount() {
    this.cancellable?.cancel();
  }

  public render() {
    return (
      <table className="vocabulary-words-group">
        <tbody>{this.props.wordsGroup.map(this.renderWord)}</tbody>
      </table>
    );
  }

  private renderWord = (word: VocabularyWordProperties) => {
    const {getWordNumber, intl, isInactive, loading, preview} = this.props;
    const {loadingIds} = this.state;
    return (
      <tr key={word.wordId}>
        <td className="container">
          <PointerElementListener
            preview={preview}
            placement="left"
            overlayClassName="minimize"
            trigger={trigger}
            align={align}
          >
            <div className="tr" id={word.wordId}>
              <div className="td word-number">
                <span>{getWordNumber(word.wordId)}</span>
              </div>
              <VocabularyWordOriginal word={word} />
              <div className="td translation">{word.translation}</div>
              <div className="td add-to-dictionary">
                {preview || loading || isInactive ? null : loadingIds.includes(word.wordId) ? (
                  <Spinner size={16} />
                ) : word.dictionaryEntryInstance && !word.dictionaryEntryInstance.otherExercise ? (
                  <Icon
                    name="check"
                    title={intl.formatMessage({id: 'Dictionary.AlreadyInDictionary'})}
                  />
                ) : (
                  <Button className="btn-ico" onClick={() => this.addWordToDictionary(word.wordId)}>
                    <Icon
                      name={word.dictionaryEntryInstance?.otherExercise ? 'check' : 'pc-inc'}
                      title={
                        word.dictionaryEntryInstance?.otherExercise
                          ? intl.formatMessage({id: 'Dictionary.AddedFromAnotherExercise'})
                          : intl.formatMessage({id: 'Dictionary.AddToDictionary'})
                      }
                    />
                  </Button>
                )}
              </div>
            </div>
          </PointerElementListener>
        </td>
      </tr>
    );
  };

  private addWordToDictionary = (wordId: string) => {
    const {addWordsToDictionary, exerciseId, intl, isInactive, preview, widgetId} = this.props;
    const {loadingIds} = this.state;

    if (isInactive || preview || loadingIds.includes(wordId)) return;
    this.setState(s => ({loadingIds: [...s.loadingIds, wordId]}));

    this.cancellable = makeCancellable(
      addWordsToDictionary(exerciseId, widgetId, [wordId]),
      () => toastr.success('', intl.formatMessage({id: 'Dictionary.Entry.CreationSuccess'})),
      () => toastr.error('', intl.formatMessage({id: 'Dictionary.Entry.CreationError'})),
      undefined,
      () => this.setState(s => ({loadingIds: s.loadingIds.filter(id => id !== wordId)}))
    );
  };
}

export default injectIntl(VocabularyWordsGroup);
