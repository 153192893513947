import React, {PureComponent} from 'react';
import {type Action, type Dispatch} from 'redux';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import classNames from 'classnames';

import {
  type ImageLabelingCardJSON,
  type ImageLabelingProperties
} from 'store/exercise/player/widgets/ImageLabeling/interface';
import {type AppState, type Role} from 'store/interface';
import {changeCardLabel} from 'store/exercise/player/widgets/actions';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import Checked from './Checked';
import LoadableImage from '../../../LoadableImage/LoadableImage';
import {
  PointerElementListener,
  type PointerListenerRef
} from '../../../Pointer/element/PointerElementListener';
import {Input} from './Input';

import '../ImageMatching/Card.scss';

interface StateProps {
  value?: string;
  answers: string[];
}

interface DispatchProps {
  changeCardLabel: (label: string) => void;
}

interface OwnProps {
  card: ImageLabelingCardJSON;
  widget: ImageLabelingProperties;
  role: Role;
  preview?: boolean;
  closed?: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

class Card extends PureComponent<Props> {
  pointerListenerRef: PointerListenerRef = React.createRef();

  shouldShowTooltip = false;

  private onMouseDown = () => {
    if (this.pointerListenerRef.current) {
      const {isVisibleTooltip} = this.pointerListenerRef.current;
      this.shouldShowTooltip = !isVisibleTooltip();
    }
  };

  private onClick = () => {
    if (this.pointerListenerRef.current) {
      const {showTooltip} = this.pointerListenerRef.current;

      if (this.shouldShowTooltip) {
        showTooltip();
      }
    }
  };

  public render() {
    const {value, answers, card, role, closed, preview} = this.props;

    const {id, imageId, example, editable, placeholder, cardSizeType} = card;

    const relatedElement = `${id}-${imageId}`;

    if (role !== 'student' || closed) {
      return (
        <div
          className={classNames('il-card', {
            'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
          })}
        >
          <PointerElementListener preview={preview} passive={true}>
            <div
              id={relatedElement}
              className="img-block clickable"
              onMouseDown={this.onMouseDown}
              onClick={this.onClick}
            >
              <LoadableImage imageId={imageId} width="100%" height="100%" />
            </div>
          </PointerElementListener>
          <Checked
            id={id}
            value={value}
            answers={answers}
            closed={closed}
            preview={preview}
            editable={editable}
            example={example}
            placeholder={placeholder}
            relatedElement={relatedElement}
            pointerListenerRef={this.pointerListenerRef}
          />
        </div>
      );
    }
    return (
      <div
        className={classNames('il-card', {
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
        })}
      >
        <PointerElementListener preview={preview} passive={true}>
          <div id={relatedElement} className="img-block">
            <LoadableImage imageId={imageId} width="100%" height="100%" />
          </div>
        </PointerElementListener>

        <Input
          id={id}
          defaultValue={value}
          answers={answers}
          placeholder={placeholder}
          example={example}
          editable={editable}
          preview={preview}
          onChange={this.changeCardLabel}
        />
      </div>
    );
  }

  private changeCardLabel = (label: string) => {
    this.props.changeCardLabel(label);
  };
}

const empty: string[] = [];

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  {widget, card: {id, example}}: OwnProps
) => {
  const answers = (example ? widget.exampleAnswers?.get(id) : widget.answers?.get(id)) || empty;

  const value = example ? answers.find(Boolean) : widget.values?.get(id);

  return {value, answers};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<Action>,
  {widget, card, preview}: OwnProps
) => ({
  changeCardLabel: (label: string) => {
    dispatch(changeCardLabel(widget.id, card.id, label, preview));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
