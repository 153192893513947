import {useSelector} from 'react-redux';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {type AppState} from 'store/interface';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {
  addWordsToDictionaryList,
  createExerciseDictionaryListForTeacher
} from 'store/exercise/player/widgets/Vocabulary/action';
import {type ExerciseRecordMap} from 'store/exercise/player/interface';
import {error, success} from 'components/toastr';

import {type DictionaryList} from '../shared/interface';
import {getExerciseWordIds} from '../shared/utils';

export const useAddListToTeacherFromWidget = (exerciseId: string) => {
  const {formatMessage} = useIntl();
  const exercises = useSelector<AppState, ExerciseRecordMap | undefined>(
    state => state.xplayer?.exercises
  );

  const {isLoading: isAddEntriesLoading, apiRequest: addEntriesToList} = useApiRequest2<
    [string, string, string[]]
  >(
    addWordsToDictionaryList,
    () => success('', formatMessage({id: 'Dictionary.WidgetAddedToDictionarySuccess'})),
    () => error('', formatMessage({id: 'Dictionary.Entry.MassCreationError'}))
  );

  const addToTeacherDictionaryList = useCallback(
    (data: DictionaryList) => {
      if (exercises) {
        const exerciseWordIds = getExerciseWordIds(exercises, exerciseId);
        Promise.all(
          Object.keys(exerciseWordIds).map(key =>
            addEntriesToList(key, data.id, exerciseWordIds[key])
          )
        );
      }
    },
    [addEntriesToList, exerciseId, exercises]
  );

  const {isLoading: isCreateListLoading, apiRequest: createWordsList} = useApiRequest2<
    [string],
    DictionaryList
  >(createExerciseDictionaryListForTeacher, addToTeacherDictionaryList);

  const addWidgetListToTeacher = useCallback(() => {
    createWordsList(exerciseId);
  }, [createWordsList, exerciseId]);

  return {isLoading: isAddEntriesLoading || isCreateListLoading, addWidgetListToTeacher};
};
