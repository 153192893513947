import React, {type FC} from 'react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type PictureChoiceProperties,
  type PictureChoiceQuestionProperties
} from 'store/exercise/player/widgets/PictureChoice/interface';

import {QuestionBlock} from './QuestionBlock/QuestionBlock';

type Props = WidgetTypeComponentProps<PictureChoiceProperties>;

const PictureChoice: FC<Props> = props => {
  const {widget, role, closed, preview} = props;
  const {values, answers, mode, questions: questionBlocks} = widget;

  const getWidgetProps = () => props;

  return (
    <div className="picture-choice-widget">
      {questionBlocks.map((questionBlock: PictureChoiceQuestionProperties, index: number) => (
        <QuestionBlock
          questionBlock={questionBlock}
          questionsSize={questionBlocks.size}
          widgetId={widget.id}
          values={values}
          answers={answers}
          index={index}
          role={role}
          closed={closed}
          mode={mode}
          questionsMode={widget.questionsMode}
          preview={preview}
          getWidgetProps={getWidgetProps}
          key={questionBlock.id}
        />
      ))}
    </div>
  );
};

export default PictureChoice;
