import React, {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {type List} from 'immutable';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {switchIsHiddenCard} from 'store/exercise/player/widgets/WordPictureSet/actions';
import {
  type WordSetCardMetaDataJSON,
  type WordSetProperties
} from 'store/exercise/player/widgets/WordPictureSet/WordSet/interface';

import {ControlPanel} from '../components/ControlPanel/ControlPanel';
import {WordSetCard} from './components/WordSetCard/WordSetCard';

import './WordSet.scss';

type Props = WidgetTypeComponentProps<WordSetProperties>;

export const WordSet: React.FC<Props> = memo(
  ({
    widget: {cards, values, id, defaultValues, isNotInteractive, widgetTheme},
    preview,
    role,
    closed
  }) => {
    const dispatch = useDispatch();

    const onSwitchIsHiddenText = useCallback(
      (cardId: string) => {
        dispatch(switchIsHiddenCard(id, cardId, preview));
      },
      [dispatch, id, preview]
    );

    const cardIds = values.get('cardIds') as List<WordSetCardMetaDataJSON>;
    const isCollaborativeManagement = values.get('isCollaborativeManagement') as boolean;

    const defaultCardIds = defaultValues.get('cardIds') as List<WordSetCardMetaDataJSON>;

    const haveHiddenCards = cardIds?.some(value => value!.isTextHidden!);
    let index = 0;

    return (
      <div className="word-set">
        <div className="word-set__cards">
          {cardIds.size
            ? cardIds
                ?.filter((item: WordSetCardMetaDataJSON) => cards.keySeq().includes(item.cardId))
                ?.map((item: WordSetCardMetaDataJSON, index: number) => {
                  const card = cards.get(item.cardId);
                  return (
                    <WordSetCard
                      key={card.id}
                      text={card.text}
                      isTextHidden={item?.isTextHidden}
                      isNotInteractive={isNotInteractive}
                      isCollaborativeManagement={isCollaborativeManagement}
                      index={index}
                      role={role}
                      cardId={item.cardId}
                      closed={closed}
                      preview={preview}
                      onSwitchIsHiddenText={onSwitchIsHiddenText}
                      widgetTheme={widgetTheme}
                    />
                  );
                })
            : defaultCardIds
                .filter((item: WordSetCardMetaDataJSON) => cards.keySeq().includes(item.cardId))
                .map((item: WordSetCardMetaDataJSON, iter) => {
                  const card = cards.get(item.cardId);
                  return (
                    <WordSetCard
                      key={card.id}
                      text={card.text}
                      isTextHidden={false}
                      isNotInteractive={isNotInteractive}
                      isCollaborativeManagement={isCollaborativeManagement}
                      index={index++}
                      role={role}
                      cardId={card.id}
                      closed={closed}
                      preview={preview}
                      widgetTheme={widgetTheme}
                    />
                  );
                })}
        </div>

        {(role === 'teacher' || isCollaborativeManagement) && !isNotInteractive && (
          <ControlPanel
            haveHiddenCards={haveHiddenCards}
            widgetId={id}
            isPreview={preview}
            closed={closed}
            isCollaborativeManagement={isCollaborativeManagement}
            role={role}
          />
        )}
      </div>
    );
  }
);
