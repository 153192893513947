import React, {type FC, memo, useMemo} from 'react';

import Icon from 'components/Icon';
import {InlineInput} from 'components/SlateJS/components/InlineInput';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type MatchingNoCategoriesProperties} from 'store/exercise/player/widgets/Matching/interface';
import {withWidgetProps} from 'components/SlateJS/plugins/withWidgetProps';
import {type SlatePlugin} from 'components/SlateJS/plugins/withPlugins';
import {withSelectionPointer} from 'components/SlateJS/plugins/pointer';

import './EditLine.scss';

interface Props {
  id: string;
  text: string;
  placeholder?: string;
  readOnly?: boolean;
  closed?: boolean;
  onChange: (text: string) => void;
  onDelete: () => void;
  getWidgetProps: () => WidgetTypeComponentProps<MatchingNoCategoriesProperties>;
}

export const EditLine: FC<Props> = memo(
  ({id, text, placeholder, readOnly, closed, onChange, onDelete, getWidgetProps}) => {
    const plugins = useMemo<SlatePlugin[]>(() => {
      return [withSelectionPointer({id}), withWidgetProps({getWidgetProps})];
    }, [getWidgetProps, id]);

    return (
      <div className="edit-line">
        <InlineInput
          id={id}
          text={text}
          placeholder={placeholder}
          readOnly={readOnly || closed}
          className="edit-line__input x-input"
          onChange={onChange}
          plugins={plugins}
        />

        {!readOnly && !closed && (
          <Icon onClick={onDelete} className="edit-line__delete" name="trash" />
        )}
      </div>
    );
  }
);
