import React, {Component} from 'react';
import {connect} from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import ReactResizeDetector from 'react-resize-detector';
import {persistReducer} from 'redux-persist';
import classNames from 'classnames';
import {type Location} from 'react-router-dom';

import {replace} from 'store/router';
import injectReducer from 'store/injectReducer';
import xplayerReducer from 'store/exercise/player/reducer';
import {type AppState} from 'store/interface';
import {initPlayerTop, storeClientHeight, storeScrollTop} from 'store/exercise/player/actions';
import {persistXPlayerConfig} from 'store/persist';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {labelFirstTask, type LabelFirstTaskParam} from 'store/exercise/player/widgets/actions';
import {xPlayerPortal, xPlayerPortalWidgetPreview} from 'config/static';

import {DragLayer} from '../dnd/DragLayer';
import {Exercise} from './components/Exercise';
import DragGap from './components/DragGap';
import {DndTypes} from '../dnd/interface';
import {SpellingCard} from './widgets/Spelling/components/SpellingCard/SpellingCard';
import {type XPlayerProps} from './interface';

import './XPlayer.scss';

interface StateProps {
  location: Location;
}

interface DispatchProps {
  clearSearch: typeof replace;
  initPlayerTop(top: number): void;
  labelFirstTask(o: LabelFirstTaskParam): void;
  storeScrollTop(top: number): void;
  storeClientHeight(clientHeight: number): void;
}

interface Props extends XPlayerProps, StateProps, DispatchProps {}

class XPlayerView extends Component<Props> {
  private player: HTMLDivElement;
  private scrollbar: Scrollbars;
  private prevHash?: string;

  private get showCompleteButton(): boolean {
    return (
      !this.props.hideCompleteButtons &&
      (this.props.role !== 'student' || !!this.props.selfCheckEnabled)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderPlayerDragItem = (itemType: DndTypes, item: any, style: React.CSSProperties) => {
    switch (itemType) {
      case DndTypes.X_GAP:
        return <DragGap answer={item.answer} style={style} />;
      case DndTypes.SCRAMBLED_SENTENCES_CARD:
        return <DragGap answer={item.value} style={style} />;
      case DndTypes.SPELLING_CARD:
        return <SpellingCard value={item.value} style={style} inLayer={true} />;
      default:
        return null;
    }
  };

  componentDidMount(): void {
    // setTimeout was added because due to the modal window opening animation,
    // we were unable to get the actual value for getBoundingClientRect in <Calculator />
    setTimeout(() => {
      this.onResize();
    }, 500);
    this.scrollToEntity();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    this.scrollToEntity(prevProps.scrollToExerciseId);
  }

  public render() {
    const {
      role,
      preview,
      exercises,
      homework,
      grammar,
      widgetPreview,
      hasPointer,
      suppressToolbarPortal,
      showPreviewExerciseNumber,
      isModal
    } = this.props;

    const portalId = widgetPreview ? xPlayerPortalWidgetPreview : xPlayerPortal;

    return (
      <ReactResizeDetector onResize={this.onResize} refreshRate={16} refreshMode="throttle">
        <div className={classNames('x-player', {homework})} ref={this.playerRef}>
          {!suppressToolbarPortal && (
            <div id="x-player-portal-root" className="x-player-portal-root">
              <Scrollbars autoHide={false} autoHeight={true}>
                <div id={portalId} className={portalId} />
                <div id="x-player-toolbar-portal" className="x-player-toolbar-portal" />
                <div id="x-player-pool-portal" className="x-player-pool-portal" />
              </Scrollbars>
            </div>
          )}
          <Scrollbars onScrollStop={this.onScrollStop} ref={this.scrollbarRef}>
            <div className="x-player-canvas">
              <div className="x-unit-page">
                {exercises.toList().map((e: ExerciseProperties, key: number) => (
                  <Exercise
                    role={role}
                    key={key}
                    exercise={e}
                    preview={preview}
                    showPreviewExerciseNumber={showPreviewExerciseNumber}
                    showCompleteButton={this.showCompleteButton}
                    isHomeworkPlayer={homework}
                    isGrammarPlayer={grammar}
                    isWidgetPreviewPlayer={widgetPreview}
                    hasPointer={hasPointer}
                    isModal={isModal}
                  />
                ))}
              </div>
              <DragLayer renderItem={this.renderPlayerDragItem} />
            </div>
          </Scrollbars>
        </div>
      </ReactResizeDetector>
    );
  }

  private playerRef = (el: HTMLDivElement) => el && (this.player = el);
  private scrollbarRef = (el: Scrollbars) => el && (this.scrollbar = el);

  private onScrollStop = () => {
    if (this.scrollbar) {
      this.props.storeScrollTop(this.scrollbar.getValues().scrollTop);
    }
  };

  private onResize = () => {
    this.props.initPlayerTop(this.player.getBoundingClientRect().top);
    if (this.scrollbar) this.props.storeClientHeight(this.scrollbar.getClientHeight());
  };

  private scrollToEntityById = (
    scrollToExerciseId: string,
    block: ScrollLogicalPosition = 'start'
  ) => {
    Promise.resolve().then(() =>
      document.getElementById(scrollToExerciseId)?.scrollIntoView({block, behavior: 'smooth'})
    );
  };

  private scrollToEntity(exerciseId?: string) {
    const {clearSearch, exercises, location, scrollToExerciseId} = this.props;
    const searchParams = new URLSearchParams(location.search);
    const remote = searchParams.get('remote');

    const isScrollToExercise = scrollToExerciseId && exercises.has(scrollToExerciseId);

    const isScrollToWidget = location.hash?.includes('x-widget');

    const isScrollToElement = location.hash?.includes('x-element');

    if (isScrollToExercise && exerciseId !== scrollToExerciseId) {
      if (remote) {
        searchParams.delete('remote');
        clearSearch({...location, search: searchParams.toString()});
      }
      this.scrollToEntityById(scrollToExerciseId!);
    }

    if (this.prevHash !== location.hash && isScrollToWidget) {
      const id = location.hash.replace('#', '');
      this.scrollToEntityById(id, 'center');
    }

    if (this.prevHash !== location.hash && isScrollToElement) {
      const id = location.hash.replace('#x-element-', '');
      this.scrollToEntityById(id, 'center');
    }

    this.prevHash = location.hash;
  }
}

const mapStateToProps = (s: AppState) => ({location: s.router.location});

const mapDispatchToProps = {
  initPlayerTop,
  labelFirstTask,
  storeScrollTop,
  storeClientHeight,
  clearSearch: replace
};

const ConnectedXPlayer = connect(mapStateToProps, mapDispatchToProps)(XPlayerView);

const XPlayer = injectReducer({
  xplayer: persistReducer(persistXPlayerConfig, xplayerReducer)
})(ConnectedXPlayer);

export default XPlayer;
