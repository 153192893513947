import React, {type FC, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {type Descendant, type Editor, Node} from 'slate';
import {withHistory} from 'slate-history';

import {SlateEditor as Slate} from 'components/SlateJS/components/SlateEditor';
import Loader from 'components/Loader';
import {type DraftCommentProperties} from 'store/exercise/player/interface';
import {
  type SlatePlugin,
  textColors,
  textFormats,
  textHighlights,
  withDisabledScrollIntoView,
  withFormat,
  withIcons,
  withLink
} from 'components/SlateJS/plugins';
import isShortcut from 'helpers/shortcut';
import {isAndroid} from 'helpers/browser';

interface Props {
  draftComment: DraftCommentProperties;
  submitting?: boolean;
  onChange(value: Descendant[]): void;
  saveComment(value: Descendant[]): void;
  closeEditor(): void;
}

const editorPlugins = (): SlatePlugin[] => [
  withHistory,
  withFormat([...textFormats, ...textColors, ...textHighlights]),
  withIcons(),
  withLink(),
  withDisabledScrollIntoView()
  // withFormatPainter,
  // withClearFormatting,
];

const toolbar = {portalId: 'x-player-toolbar-portal'};

const valueIsEmpty = (children: Descendant[]) => !Node.string({children} as Node).trim().length;

export const InputEditor: FC<Props> = ({
  submitting,
  draftComment,
  onChange,
  saveComment,
  closeEditor
}) => {
  const intl = useIntl();

  const plugins = useMemo(editorPlugins, []);

  const save = useCallback(() => saveComment(draftComment.value), [draftComment, saveComment]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => {
      const value = editor.children;
      if (isShortcut(e, 'mod+enter') && !valueIsEmpty(editor.children)) {
        e.preventDefault();
        saveComment(value);
      }
      if (isShortcut(e, 'mod+shift+enter')) {
        e.preventDefault();
        closeEditor();
      }
    },
    [closeEditor, saveComment]
  );

  // When starting to type on Android,
  // a piece of placeholder is added into content before entered text,
  // so placeholder is disabled on Android for now
  const placeholder = !isAndroid
    ? intl.formatMessage({id: 'XPlayer.Exercise.CommentEditor.Placeholder'})
    : undefined;

  const autoFocus = draftComment.autoFocus && 'end';

  return (
    <div className={classNames('exercise-comments-input')}>
      <Slate
        autoFocus={autoFocus}
        toolbar={toolbar}
        value={draftComment.value}
        onChange={onChange}
        plugins={plugins}
        skipSelectionChange={true}
        placeholder={placeholder}
        readOnly={submitting}
        onKeyDown={onKeyDown}
        autoCapitalize="sentences"
      />
      <div className="exercise-comments-input-actions">
        <Button className="cancel" bsSize="sm" onClick={closeEditor} disabled={submitting}>
          {intl.formatMessage({id: 'Common.Cancel'})}
        </Button>
        <Button
          className={classNames({submitting})}
          bsSize="sm"
          bsStyle="success"
          onClick={save}
          disabled={submitting || valueIsEmpty(draftComment.value)}
        >
          {submitting && (
            <div className="loader-button-positioning-helper">
              <Loader shouldRender={true} />
            </div>
          )}
          <span>
            {draftComment.id
              ? intl.formatMessage({id: 'Common.Save'})
              : intl.formatMessage({id: 'Common.Send'})}
          </span>
        </Button>
      </div>
    </div>
  );
};
