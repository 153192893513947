import React from 'react';

import {type CardProperties, CardsType} from 'store/exercise/player/widgets/Cards/interface';
import {PointerElementListener} from 'components/Pointer/element/PointerElementListener';
import {taskMaxLength} from 'store/exercise/editor/widgets/validation';

import {TextCard} from './TextCard';
import {ImageCard} from './ImageCard';

import './Card.scss';

const trigger = ['hover'];

const MaxTitleLength = 100;

interface Props {
  type: CardsType;
  item: CardProperties;
  selected: boolean;
  closed?: boolean;
  preview?: boolean;
  getTooltipContainer: () => HTMLElement;
}

export const Card: React.FC<Props> = ({
  type,
  item,
  selected,
  closed,
  preview,
  getTooltipContainer
}) => {
  const {id, title, image} = item;

  const compact = type === CardsType.TEXT ? !taskMaxLength(title.document, MaxTitleLength) : false;

  const card =
    type === CardsType.TEXT ? (
      <TextCard id={id} title={title} selected={selected} compact={compact} closed={closed} />
    ) : (
      <ImageCard id={id} image={image} selected={selected} closed={closed} />
    );

  return (
    <PointerElementListener
      key={id}
      preview={preview}
      trigger={trigger}
      mouseLeaveDelay={0.1}
      getTooltipContainer={getTooltipContainer}
    >
      {card}
    </PointerElementListener>
  );
};
