import React, {useCallback, useLayoutEffect, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {type List, OrderedMap} from 'immutable';
import classNames from 'classnames';

import GrammarPlayerPage, {
  type GrammarRouterParams
} from 'common/GrammarPlayerPage/GrammarPlayerPage';
import ViewContentsModal from 'routes/Library/Common/ViewContentsModal';
import Icon from 'components/Icon';
import {
  clearGrammarPreviewOpen,
  setGrammarPreviewOpen
} from 'components/modals/UnitPreview/actions';
import {type AppState} from 'store/interface';
import {isMobileWebView} from 'helpers/browser';
import {type ExerciseJSON, type ExerciseRecordMap} from 'store/exercise/player/interface';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';

import './ViewGrammarForExerciseButton.scss';

const isExerciseRecordMap = (
  exercise: ExerciseJSON[] | ExerciseRecordMap
): exercise is ExerciseRecordMap => OrderedMap.isOrderedMap(exercise);

const isExerciseValid = (
  item: ExerciseRecordMap | ExerciseJSON[] | undefined
): item is ExerciseRecordMap | ExerciseJSON[] => {
  return !!(item && isExerciseRecordMap(item) ? item.size : item?.length);
};

const checkAndTransformIntoOrderedMap = (
  mapOrArray: (ExerciseRecordMap | ExerciseJSON[] | undefined)[]
) => {
  return mapOrArray.filter(isExerciseValid).map(exercises => {
    if (isExerciseRecordMap(exercises)) return exercises;

    const exercise = exercises[0];
    const exerciseId = exercise.id as string;
    const exerciseObj = {[exerciseId]: new ExerciseRecord(exercise)};

    return OrderedMap(exerciseObj);
  });
};

interface Props {
  show?: boolean;
  exerciseId: string;
}

export const ShowGrammarRules: React.FC<Props> = ({show, exerciseId}) => {
  const {courseId, coursebookId, coursebookInstanceId, page} = useParams<
    GrammarRouterParams & {coursebookId: string}
  >();
  const dispatch = useDispatch();

  const [modalIsOpened, setModalIsOpened] = useState<boolean>(false);
  const closeModal = useCallback(() => {
    setModalIsOpened(false);
    dispatch(clearGrammarPreviewOpen());
  }, [dispatch]);

  const grammar = useSelector<AppState, List<{id: number}> | undefined>(state => {
    const exerciseLocations = checkAndTransformIntoOrderedMap([
      state.xplayer?.exercises,
      state.unitPreview?.xpreview?.exercises,
      state.xeditor?.xpreview?.exercises,
      state.unitPreview?.extraPreview?.exercises,
      state.coursebookPage?.exerciseViewer.xpreview?.exercises,
      state.xplayer?.supplementaryExercisesModal?.exercises
    ]);

    for (const location of exerciseLocations) {
      const exercise = location?.find(ex => ex?.id === exerciseId);

      if (exercise) return exercise.grammar;
    }

    return undefined;
  });

  const cbiInPreview = useSelector<AppState, string | undefined>(
    state => state.unitPreview?.coursebookId
  );

  const cbiInHomework = useSelector<AppState, string | undefined>(
    state =>
      state.xplayer?.exercises.find(ex => ex?.id === exerciseId)?.location?.coursebookInstanceId
  );

  const cbiFromParams = coursebookId || coursebookInstanceId;

  const cbi = cbiFromParams || cbiInPreview || cbiInHomework;

  const onClickHandler = () => {
    setModalIsOpened(true);
    dispatch(setGrammarPreviewOpen(exerciseId));
  };

  const shouldCloseModal = !cbiInPreview;

  useLayoutEffect(() => {
    if (shouldCloseModal) {
      closeModal();
    }
  }, [closeModal, shouldCloseModal, courseId, cbi, page]);

  return show && grammar?.size ? (
    <>
      <Button
        className={classNames('view-grammar-btn', {'in-webview': isMobileWebView()})}
        bsStyle="success"
        bsSize="sm"
        onClick={onClickHandler}
      >
        <Icon name="pc-course" tag="i" />
        <FormattedMessage id="Exercise.ViewRules" />
      </Button>
      <ViewContentsModal
        show={modalIsOpened}
        isToggleGroup={true}
        title={<FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />}
        close={closeModal}
        renderBody={() => (
          <GrammarPlayerPage
            coursebookId={cbi}
            isInUnitPlayer={!!coursebookInstanceId}
            isInHomeworkPlayer={!!cbiInHomework}
            grammarFilter={grammar}
            isModal={true}
          />
        )}
      />
    </>
  ) : null;
};
