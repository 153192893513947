import {type FC, memo} from 'react';
import classNames from 'classnames';

import {type ScrambledExample} from 'store/exercise/player/widgets/ScrambledSentences/interface';
import {
  isSpace,
  sentenceBreaks
} from 'components/XPlayer/widgets/ScrambledSentences/components/utils';

import Checked from '../../../GapFill/component/dnd/Checked';

import '../Sentence/Sentence.scss';
import '../Pool/Pool.scss';
import '../SpellingCard/SpellingCard.scss';

interface Props {
  widgetId: string;
  example: ScrambledExample;
  isOneSentence: boolean;
  preview?: boolean;
  ordinal?: string;
}

export const SpellingExample: FC<Props> = memo(
  ({widgetId, example, preview, ordinal, isOneSentence}) => {
    const {fullSentence} = example;
    let shouldCapitalize = true;

    return (
      <div className="spelling-sentence correct example">
        {!(!ordinal && isOneSentence) && (
          <div className="sentence-number">{ordinal && isOneSentence ? ordinal : 1}.</div>
        )}
        <div>
          <div className="spelling-pool">
            {fullSentence.map(({capitalize, isStatic, value}, i) => {
              if (!isStatic) {
                const id = `${widgetId}-${i}`;

                return (
                  <Checked
                    key={id}
                    id={id}
                    capitalize={shouldCapitalize}
                    className={classNames('spelling-checked-card')}
                    value={value}
                    answer={value}
                    example={true}
                    dragCaseSensitive={capitalize}
                    useSVGHack={true}
                    preview={preview}
                  />
                );
              }
              const trimmed = value.trim();
              shouldCapitalize = sentenceBreaks.test(trimmed[trimmed.length - 1]);
              return (
                <span
                  key={value + i}
                  className={classNames('punctuation', {space: isSpace(value)})}
                >
                  {value}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
