import React from 'react';
import {type Editor, type RenderMarkProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';

import ExerciseLabelComponent from '../../../XPlayer/components/ExerciseLabelComponent';
import {getEditorId, SlateMark} from '../../interface';
import {type RendererPlugin} from '../interface';

class ExerciseLabel implements RendererPlugin {
  public mark = SlateMark.EXERCISE_LABEL;

  private editorId: string;

  public constructor(
    private readonly preview?: boolean,
    private readonly exerciseId?: string
  ) {}

  public renderMark = (
    {attributes, mark, children}: RenderMarkProps,
    editor: Editor,
    next: Next
  ) => {
    if (mark.type !== this.mark) {
      return next();
    }
    return (
      <ExerciseLabelComponent
        attributes={attributes}
        exerciseId={this.exerciseId}
        preview={this.preview}
        getTooltipContainer={this.getTooltipContainer(editor)}
      >
        {children}
      </ExerciseLabelComponent>
    );
  };

  private getTooltipContainer = (editor: Editor): (() => HTMLElement) => {
    if (!this.editorId) {
      this.editorId = editor.query(getEditorId);
    }
    return () => document.getElementById(this.editorId)!;
  };
}

export default ExerciseLabel;
