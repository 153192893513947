import {type Editor, type RenderBlockProps} from '@englex/slate-react';
import classNames from 'classnames';
import React from 'react';
import {type Next, type SchemaProperties} from '@englex/slate';

import InViewer from 'contexts/Viewer/InViewer';
import {Type} from 'contexts/Viewer/interface';
import LoadableImage from 'components/LoadableImage/LoadableImage';
import {PointerElementListener} from 'components/Pointer/element/PointerElementListener';
import {type WidgetComponentProps} from 'store/exercise/player/interface';

import {type RendererPlugin} from '../../interface';
import {isImageBlock} from '../../../utils';
import {BlockFloat, getWidgetProps, type ImageBlock, SlateBlock} from '../../../interface';
import {ExpandButton} from './ExpandButton';

import './SlateImage.scss';

const defaultPointerOptions = {isAvailable: () => true};

interface PointerOptions {
  isAvailable: () => boolean;
  onSelected?: (callback: () => void) => void;
}

interface Options {
  isModal?: boolean;
  defaultFloat?: BlockFloat;
  pointerOptions?: PointerOptions;
}

class Image implements RendererPlugin {
  static MINIMIZED_BREAKPOINT = 203;

  // TODO: check is isVoid required here or could be deleted
  public schema: SchemaProperties = {
    blocks: {
      [SlateBlock.IMAGE]: {
        isVoid: true
      }
    }
  };
  public isModal?: boolean;
  private defaultFloat: BlockFloat;
  private pointerOptions: PointerOptions;

  constructor({
    isModal = false,
    defaultFloat = BlockFloat.CENTER,
    pointerOptions = defaultPointerOptions
  }: Options = {}) {
    this.isModal = isModal;
    this.defaultFloat = defaultFloat;
    this.pointerOptions = pointerOptions;
  }

  private getWidgetProps = (editor: Editor) => {
    const widgetProps = editor.query<WidgetComponentProps>(getWidgetProps);

    const dummyWidgetProps = {
      widget: {id: ''},
      preview: false
    };

    return widgetProps || dummyWidgetProps;
  };

  public renderBlock = (props: RenderBlockProps, editor: Editor, next: Next) => {
    const {node, attributes} = props;

    if (!isImageBlock(node)) {
      return next();
    }

    const {widget, preview} = this.getWidgetProps(editor);

    const widgetId = widget.id;
    const block: ImageBlock = node;
    const data = block.data;
    const id = data.get('id');
    const width = data.get('width');
    const height = data.get('height');
    const float = data.get('float', this.defaultFloat);
    const style = data.get('style');
    const minimized = width < Image.MINIMIZED_BREAKPOINT;

    const classes = classNames(
      'slate-image-block-adaptive',
      style
        ? Object.keys(style)
            .map(p => `style-${p}-${style[p]}`)
            .join(' ')
        : '',
      `float-${float}`,
      {minimized}
    );
    const paddingBottom = `${(height / width) * 100}%`;

    const elementId = `${widgetId}-${id}-${width}-${height}`;

    const {isAvailable, onSelected} = this.pointerOptions;

    return (
      <div {...attributes} className={classes} style={style}>
        <InViewer id={String(id)} type={Type.IMAGE}>
          {(onLoaded, activate) => (
            <PointerElementListener
              widgetId={widgetId}
              elementId={elementId}
              preview={preview}
              isAvailable={isAvailable}
              onSelected={onSelected}
              renderTooltipOverlay={() => <ExpandButton onClick={activate} />}
              render={({isAvailable, isTeacher}) => {
                const onClick = isAvailable && isTeacher ? undefined : activate;

                return (
                  <div id={elementId} className="width-helper" style={{width: width}}>
                    <div className="aspect-ratio-helper" style={{paddingBottom, maxWidth: width}}>
                      {this.isModal ? (
                        <LoadableImage imageId={id} width="100%" height="100%" />
                      ) : (
                        <LoadableImage
                          imageId={id}
                          onClick={onClick}
                          width="100%"
                          height="100%"
                          onImageLoaded={onLoaded}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            />
          )}
        </InViewer>
      </div>
    );
  };
}

export default Image;
