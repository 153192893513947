import React, {type FC} from 'react';

type Props = React.HTMLAttributes<HTMLSpanElement>;

export const PointerSelection: FC<Props> = ({id, children}) => {
  return (
    <span id={id} className="slate-pointer">
      {children}
    </span>
  );
};
