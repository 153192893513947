import React, {Fragment, PureComponent} from 'react';
import {type Block, type Value} from '@englex/slate';
import {Node} from 'slate';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type QuestsProperties,
  type QuestsValues
} from 'store/exercise/player/widgets/Writing/interface';
import type QuestValueRecord from 'store/exercise/player/widgets/Writing/Quests/QuestValueRecord';

import SlatePlayer from '../../../../Slate/SlatePlayer/SlatePlayer';
import Writing from '../Writing';
import {WritingEditor} from '../WritingEditor';
import QuestsFieldControl from './QuestsFieldControl';

import './Quests.scss';

type Props = WidgetTypeComponentProps<QuestsProperties>;

export default class Quests extends PureComponent<Props> {
  private getWidgetProps = () => this.props;

  public render() {
    const {
      closed,
      preview,
      role,
      homeworkId,
      widget: {id, values}
    } = this.props;

    const show = this.shouldShowFields();
    const isTouched = this.isTouched(values);
    const onReview = this.onReview();

    return (
      <Writing>
        {role !== 'student' ? (
          <QuestsFieldControl
            homeworkId={homeworkId}
            preview={preview}
            disabled={closed}
            widgetId={id}
            show={show}
            isTouched={isTouched}
            onReview={onReview}
          />
        ) : null}
        {this.renderQuestions()}
      </Writing>
    );
  }

  private isTouched = (values: QuestsValues): boolean => {
    return values.some((v: QuestValueRecord) => {
      if (Array.isArray(v.value)) {
        return Node.string({children: v.value} as Node).length > 0;
      }

      return false;
    });
  };

  private shouldShowFields = (): boolean => {
    return !this.props.closed;
  };

  private onReview() {
    const {widget} = this.props;
    return Boolean(widget.values.first()?.onReview);
  }

  protected renderQuestions = () => {
    const {
      closed,
      preview,
      role,
      widget: {id, quests, questsCountWords, questsExampleContent, values}
    } = this.props;

    return (
      <div>
        {quests.map((q: Value, k: number) => {
          if (!k && questsExampleContent) {
            return (
              <div className="quest-block">
                <Fragment key={k}>
                  <div className="question-title">
                    <SlatePlayer
                      value={q}
                      plugins={Writing.exampleTaskPlugins}
                      getWidgetProps={this.getWidgetProps}
                    />
                  </div>
                  <div className="question-editor example">
                    <SlatePlayer
                      value={questsExampleContent}
                      plugins={Writing.examplePlayerPlugins}
                      getWidgetProps={this.getWidgetProps}
                    />
                  </div>
                </Fragment>
              </div>
            );
          }
          const questionId = (q.document.nodes.first() as Block).data.get('id');
          const question = values.get(questionId);
          return (
            <Fragment key={k}>
              <div className="quest-block">
                <div className="question-title">
                  <SlatePlayer
                    value={q}
                    plugins={Writing.regularTaskPlugins}
                    getWidgetProps={this.getWidgetProps}
                  />
                </div>
                <WritingEditor
                  id={questionId}
                  content={question.content}
                  role={role}
                  widgetId={id}
                  onReview={this.onReview()}
                  preview={preview}
                  closed={closed}
                  questsCountWords={questsCountWords}
                  getWidgetProps={this.getWidgetProps}
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };
}
