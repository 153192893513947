import React, {type FC} from 'react';
import classNames from 'classnames';

import LoadableImage from '../../../../LoadableImage/LoadableImage';
import {type PointerListenerProps} from '../../../../Pointer/element/PointerElementListener';

interface Props extends PointerListenerProps {
  id: string;
  image: number;
  selected: boolean;
  closed?: boolean;
  className?: string;
}

export const ImageCard: FC<Props> = React.memo(
  ({id, image, selected, closed, className, ...rest}) => {
    return (
      <div
        id={id}
        className={classNames('x-card image-card', className, {
          selected,
          closed
        })}
        {...rest}
      >
        <div className="image-card__wrapper">
          <div className="image-card__image">
            <LoadableImage imageId={image} width="100%" height="100%" />
          </div>
        </div>

        <div className="x-card__pointer-tooltip-area" />
      </div>
    );
  }
);
