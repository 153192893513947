import React, {PureComponent} from 'react';
import {findDOMNode} from 'react-dom';
import {type Plugin} from '@englex/slate-react';
import {type Map} from 'immutable';

import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import {GapFillType} from 'components/Slate/interface';
import {Provider} from 'components/XPlayer/contexts/dndContext';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {PoolPortalWrapper} from 'components/PoolPortalWrapper';
import {type GapFillProperties} from 'store/exercise/player/widgets/GapFill/interface';
import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Color from 'components/Slate/plugins/renderers/Color';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Icon from 'components/Slate/plugins/renderers/Icon';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';

import gapPluginFactory from './getPluginFactory';
import Pool from './component/Pool/Pool';

import './GapFill.scss';

type Props = WidgetTypeComponentProps<GapFillProperties>;

class GapFill extends PureComponent<Props> {
  private readonly plugins: Plugin[] = [
    gapPluginFactory(this.props.widget.gap, this.props.preview),
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new Color(),
    new Highlight(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Image(),
    new Icon(),
    new Dialog(),
    new SelectionPointer()
  ];

  public render() {
    const {widget, preview, role, closed, isModal} = this.props;
    const {content, choices} = widget;
    const isUsedAll = choices?.keySeq().every(this.choiceIsUsed);
    return (
      <Provider isModal={isModal}>
        <div className="gapfill">
          {[GapFillType.DND, GapFillType.DND_INPUT].includes(widget.gap) && (
            <PoolPortalWrapper
              getParent={this.getParent}
              preview={preview}
              role={role}
              closed={closed || isUsedAll}
            >
              {inPortal => (
                <Pool
                  preview={preview}
                  widget={widget}
                  role={role}
                  closed={closed}
                  inPortal={inPortal}
                  choiceIsUsed={this.choiceIsUsed}
                />
              )}
            </PoolPortalWrapper>
          )}
          <SlatePlayer
            value={content}
            plugins={this.plugins}
            getWidgetProps={this.getWidgetProps}
            trimEmptyTrailingParagraphs={true}
          />
        </div>
      </Provider>
    );
  }

  private get values() {
    const {values, preFillValues} = this.props.widget;

    return values || preFillValues;
  }

  private choiceIsUsed = (choiceId?: string): boolean => {
    const {gap} = this.props.widget;

    return gap === GapFillType.DND_INPUT
      ? this.choiceIsUsedDndInput(
          this.values as Map<string, {choiceId: string; text: string}>,
          choiceId
        )
      : choiceId && this.values
        ? (this.values as Map<string, string>).includes(choiceId)
        : false;
  };

  private choiceIsUsedDndInput = (
    values?: Map<string, {choiceId: string; text: string}>,
    choiceId?: string
  ): boolean =>
    choiceId && values
      ? values
          .valueSeq()
          .map(v => v?.choiceId)
          .includes(choiceId)
      : false;

  private getWidgetProps = () => this.props;

  private getParent = () => findDOMNode(this) as HTMLDivElement | null;
}

export default GapFill;
