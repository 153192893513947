import React, {type FC} from 'react';
import {FormattedDate, FormattedTime} from 'react-intl';

import {toLocalDate} from 'services/common-methods';

import {type TimeProps} from '../interface';
import {UpdatedAt} from './UpdatedAt';

export const DateTime: FC<TimeProps> = ({createdAt, updatedAt, userTimezone}) => {
  const localeCreatedAt = toLocalDate(new Date(createdAt), userTimezone);
  return (
    <div className="message-date-time">
      <div className="date">
        <FormattedDate value={localeCreatedAt} day="numeric" month="long" year="numeric" />
        <span className="comma">,</span>
      </div>
      <div className="time">
        <FormattedTime value={localeCreatedAt} />
        <UpdatedAt updatedAt={updatedAt} userTimezone={userTimezone} />
      </div>
    </div>
  );
};
