import React, {type FC, useCallback, useState} from 'react';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';
import {type Map} from 'immutable';

import {type DndContextShape, useDndContext} from 'components/XPlayer/contexts/dndContext';
import {type DNDInputValue, type WidgetComponentProps} from 'store/exercise/player/interface';
import {getWidgetProps} from 'components/Slate/interface';
import {answerDropped, clearValue} from 'store/exercise/player/widgets/actions';
import {
  type GapFillProperties,
  type GapProps as OwnProps
} from 'store/exercise/player/widgets/GapFill/interface';
import {inputChanged} from 'store/exercise/player/widgets/GapFill/actions';
import {DndTypes} from 'components/dnd/interface';

import DnDContainer from './DNDInputChoice';
import Checked from './Checked';
import {type PointerListenerProps} from '../../../../../Pointer/element/PointerElementListener';
import {
  getValue,
  getDragCaseSensitive,
  getChosenIndefiniteForm,
  getIndefiniteForm,
  getExampleAnswers
} from './helpers';

import './DropTarget.scss';

type Props = OwnProps & DndContextShape & PointerListenerProps;

const DropTarget: FC<Props> = ({
  id,
  preview,
  gapCaseSensitive,
  example,
  startOfSentence,
  className,
  selectedCardId,
  onAnimationStart,
  onAnimationEnd,
  selectCard,
  editor,
  answer: exampleAnswer
}) => {
  const dispatch = useDispatch();

  const [cardJustDropped, setCardJustDropped] = useState<boolean>(false);

  const {widget, closed, role} =
    editor.query<WidgetComponentProps<GapFillProperties>>(getWidgetProps);
  const widgetId = widget.id;
  const values = widget.values as Map<string, DNDInputValue> | undefined;
  const valueObject = values?.get(id);
  const choiceId = valueObject === null ? null : valueObject?.choiceId;
  const value: string | undefined | null = getValue({
    example,
    exampleAnswer,
    values,
    id,
    choiceId,
    widget
  });
  const dragCaseSensitive = getDragCaseSensitive(choiceId, widget);
  const answers = example
    ? getExampleAnswers(id, widget.content.document)
    : widget.answers
      ? widget.answers.get(id)
      : undefined;
  const gapAnswer = answers?.[0] || undefined;
  const indefiniteForm = getIndefiniteForm(example, gapAnswer, widget);
  const chosenIndefiniteForm = getChosenIndefiniteForm(example, choiceId, widget);

  const [{isOver, canDrop}, connectDropTarget] = useDrop({
    accept: DndTypes.X_GAP,
    canDrop({widgetId: itemWidgetId, choiceId: itemChoiceId}) {
      return itemWidgetId === widgetId && itemChoiceId !== choiceId;
    },
    drop({choiceId: itemChoiceId}, monitor: DropTargetMonitor) {
      if (monitor.didDrop()) {
        return;
      }
      onDrop(widgetId, id, itemChoiceId, preview);
      return {gapId: id};
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const clearGap = useCallback(
    (widgetId: string, selectedCardId: string, preview: boolean | undefined) => {
      dispatch(clearValue(widgetId, selectedCardId, preview));
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (widgetId: string, id: string, choiceId: string, preview: boolean | undefined) => {
      dispatch(answerDropped(widgetId, id, choiceId, preview));
      setCardJustDropped(true);
    },
    [dispatch]
  );

  const onInputChange = useCallback(
    (text: string) => {
      if (text !== undefined && text !== value) {
        dispatch(inputChanged(widgetId, id, text, preview));
      }
    },
    [dispatch, id, widgetId, value, preview]
  );

  const clearCardJustDropped = useCallback(() => setCardJustDropped(false), []);

  const onClick = useCallback(
    (forceClear?: true, e?: React.MouseEvent<HTMLDivElement>) => {
      if (forceClear) {
        return selectCard();
      }
      if (selectedCardId) {
        selectedCardId === choiceId
          ? clearGap(widgetId, selectedCardId, preview)
          : onDrop(widgetId, id, selectedCardId, preview);
        selectCard();
      } else if (choiceId) {
        selectCard(choiceId, e);
      }
    },
    [selectCard, onDrop, clearGap, widgetId, id, choiceId, selectedCardId, preview]
  );

  if (role !== 'student' || closed || example) {
    return (
      <Checked
        id={id}
        value={value}
        answers={answers}
        closed={closed}
        example={example}
        preview={preview}
        chosenIndefiniteForm={chosenIndefiniteForm}
        indefiniteForm={indefiniteForm}
        dragCaseSensitive={dragCaseSensitive}
        gapCaseSensitive={gapCaseSensitive}
        startOfSentence={startOfSentence}
        ignorePlaceholder={true}
      />
    );
  }

  return connectDropTarget(
    <span
      id={id}
      className={classNames('x-gap-drop-target', className, {
        clickable: selectedCardId,
        'is-over': isOver || selectedCardId,
        'can-drop': canDrop || selectedCardId,
        'has-answer': value || !!chosenIndefiniteForm,
        'pointer-listener--inline': value || !!chosenIndefiniteForm,
        'x-gap-core': !chosenIndefiniteForm
      })}
      onClick={chosenIndefiniteForm ? undefined : () => onClick()}
      onAnimationStart={onAnimationStart}
      onAnimationEnd={onAnimationEnd}
    >
      {chosenIndefiniteForm ? (
        <DnDContainer
          id={choiceId!}
          widgetId={widgetId}
          preview={preview}
          answer={value === null ? undefined : value}
          dragCaseSensitive={dragCaseSensitive}
          onInputChange={onInputChange}
          selected={choiceId === selectedCardId}
          startOfSentence={startOfSentence}
          chosenIndefiniteForm={chosenIndefiniteForm}
          onSelect={onClick}
          cardJustDropped={cardJustDropped}
          clearCardJustDropped={clearCardJustDropped}
        />
      ) : (
        <span className="sizer">{'\uFEFF'}</span>
      )}
      <input type="hidden" value={value || ''} />
    </span>
  );
};

const ConnectedDropTargetWithContext: FC<OwnProps> = props => {
  const {selectedCardId, selectCard} = useDndContext();

  const onSelect = (id?: string, e?: React.MouseEvent<HTMLDivElement>) => {
    e && e.stopPropagation();
    selectCard(id, e);
  };
  return <DropTarget {...props} selectedCardId={selectedCardId} selectCard={onSelect} />;
};

export default ConnectedDropTargetWithContext;
