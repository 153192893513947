import React, {type FC, memo} from 'react';
import {type Plugin} from '@englex/slate-react';
import classNames from 'classnames';

import {type Role} from 'store/interface';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type PictureChoiceCardRecord} from 'store/exercise/player/widgets/PictureChoice/PictureChoiceCardRecord';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import {
  type PictureChoiceProperties,
  type PictureChoiceQuestionProperties
} from 'store/exercise/player/widgets/PictureChoice/interface';
import {
  type PictureChoiceAnswers,
  PictureChoiceMode,
  PictureChoiceQuestionsMode,
  type PictureChoiceValues
} from 'store/exercise/editor/widgets/XPictureChoice/interface';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Highlight from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';

import FreeChoiceCard from '../FreeChoiceCard/FreeChoiceCard';
import MultipleChoiceCard from '../MultipleChoiceCard/MultipleChoiceCard';

import './QuestionBlock.scss';

const TitlePlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new Highlight(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new SelectionPointer()
];

interface Props {
  questionBlock: PictureChoiceQuestionProperties;
  questionsSize: number;
  widgetId: string;
  values: PictureChoiceValues;
  answers: PictureChoiceAnswers;
  role: Role;
  closed?: boolean;
  mode: PictureChoiceMode;
  questionsMode: PictureChoiceQuestionsMode;
  preview?: boolean;
  getWidgetProps: () => WidgetTypeComponentProps<PictureChoiceProperties>;
  index: number;
}

export const QuestionBlock: FC<Props> = memo(
  ({
    questionBlock,
    questionsSize,
    widgetId,
    values,
    answers,
    role,
    closed,
    mode,
    questionsMode,
    preview,
    getWidgetProps,
    index
  }) => {
    const numbering = questionsSize === 1 ? null : `${index + 1}.`;
    const questionsWithoutTitles =
      questionsMode === PictureChoiceQuestionsMode.WithoutQuestionsTitle;
    const questionsDefault = questionsMode === PictureChoiceQuestionsMode.Default;

    return (
      <div className="pc-question-block">
        <div className="pc-question">
          <div
            className={classNames('pc-question__numbering', {
              'without-titles': questionsWithoutTitles
            })}
          >
            {numbering}
          </div>

          {questionsDefault && questionBlock.content ? (
            <SlatePlayer
              value={questionBlock.content}
              plugins={TitlePlugins}
              getWidgetProps={getWidgetProps}
              trimEmptyTrailingParagraphs={false}
            />
          ) : (
            numbering && <div className="without-titles-line"></div>
          )}
        </div>

        <div
          className={classNames('pc-cards', {
            closed: closed
          })}
        >
          {questionBlock.cards.map((card: PictureChoiceCardRecord) => {
            return mode === PictureChoiceMode.FREE_CHOICE ? (
              <FreeChoiceCard
                widgetId={widgetId}
                card={card}
                questionId={questionBlock.id}
                values={values}
                preview={preview}
                key={card.id}
              />
            ) : (
              <MultipleChoiceCard
                widgetId={widgetId}
                card={card}
                questionId={questionBlock.id}
                values={values}
                answers={answers}
                role={role}
                closed={closed}
                preview={preview}
                key={card.id}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
