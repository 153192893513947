import React, {memo, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {type Value} from '@englex/slate';
import {useIntl} from 'react-intl';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import {type Role} from 'store/interface';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Icon from 'components/Icon';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import {
  PointerElementListener,
  type PointerListenerRef
} from 'components/Pointer/element/PointerElementListener';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import {type WPSTheme} from 'store/exercise/editor/widgets/XWordPictureSet/baseInterface';

import {CardNumber} from '../../../components/CardNumber/CardNumber';

import './WordPictureSetCard.scss';

interface Props {
  index: number;
  image: number;
  text: Value;
  role: Role;
  isImageHidden: boolean;
  isTextHidden: boolean;
  isNotInteractive: boolean;
  isCollaborativeManagement: boolean;
  cardId: string;
  closed?: boolean;
  preview?: boolean;
  cardSizeType: CardSizeType;
  widgetTheme: WPSTheme;
  onSwitchIsImageHidden?: (cardId: string) => void;
  onSwitchIsTextHidden?: (cardId: string) => void;
}

const slatePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new TextAlignment()
];

const trigger = ['click'];

const align = {
  offset: [0, 1],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
};

export const WordPictureSetCard: React.FC<Props> = memo(
  ({
    cardId,
    index,
    image,
    text,
    role,
    isImageHidden,
    isTextHidden,
    isNotInteractive,
    isCollaborativeManagement,
    closed,
    preview,
    cardSizeType,
    widgetTheme,
    onSwitchIsImageHidden,
    onSwitchIsTextHidden
  }) => {
    const {formatMessage} = useIntl();
    const pointerListenerRef: PointerListenerRef = useRef(null);
    const shouldShowTooltip = useRef<boolean>(false);

    const onMouseDown = useCallback(() => {
      if (pointerListenerRef.current) {
        const {isVisibleTooltip} = pointerListenerRef.current;
        shouldShowTooltip.current = !isVisibleTooltip();
      }
    }, []);

    const onClick = useCallback((e: React.MouseEvent) => {
      if (pointerListenerRef.current) {
        const {showTooltip} = pointerListenerRef.current;
        if (shouldShowTooltip.current) {
          showTooltip();
        }
      }
    }, []);

    const onImageClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!closed && !isNotInteractive) {
        onSwitchIsImageHidden && onSwitchIsImageHidden(cardId);
      }
    };

    const onTextClick = () => {
      onSwitchIsTextHidden && onSwitchIsTextHidden(cardId);
    };

    return (
      <div
        className={classNames('word-picture-set-card', widgetTheme, {
          'is-image-hidden': isImageHidden && !closed,
          'is-text-hidden': isTextHidden && !closed,
          'is-teacher': role === 'teacher',
          'is-student': role === 'student',
          disabled: closed,
          'is-not-interactive': isNotInteractive,
          'is-collaborative-management': isCollaborativeManagement,
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
        })}
      >
        <PointerElementListener
          ref={pointerListenerRef}
          preview={preview}
          trigger={trigger}
          align={align}
          elementId={cardId}
          relatedElement={cardId + 'text'}
        >
          <div id={cardId} className="word-picture-set-card__picture">
            <div
              onClick={role === 'teacher' || isCollaborativeManagement ? onImageClick : undefined}
              className="word-picture-set-card__container"
            >
              {(!isImageHidden || role !== 'student' || !!closed) && (
                <LoadableImage imageId={image} width="100%" height="100%" />
              )}

              {(role === 'teacher' || isCollaborativeManagement) && !closed && (
                <div className="word-picture-set-card__hover-block">
                  {isImageHidden ? (
                    <span>
                      <Icon name="eye" /> &nbsp;{' '}
                      {formatMessage({id: 'XPlayer.WordPictureSet.Show'})}
                    </span>
                  ) : (
                    <span>
                      <Icon name="eye-slash" /> &nbsp;
                      {formatMessage({id: 'XPlayer.WordPictureSet.Hide'})}
                    </span>
                  )}
                </div>
              )}
            </div>

            <CardNumber
              index={index}
              id={cardId + 'number'}
              role={role}
              isAbsolutePosition
              onMouseDown={onMouseDown}
              onClick={onClick}
              widgetTheme={widgetTheme}
            />
          </div>
        </PointerElementListener>

        <PointerElementListener preview={preview} passive={true} elementId={cardId + 'text'}>
          <div
            id={cardId + 'text'}
            className="word-picture-set-card__text"
            onClick={
              (role === 'teacher' || isCollaborativeManagement) && !closed && !isNotInteractive
                ? onTextClick
                : undefined
            }
          >
            <SlatePlayer value={text} trimEmptyTrailingParagraphs={true} plugins={slatePlugins} />
            {(role === 'teacher' || isCollaborativeManagement) && !closed && (
              <div className="word-picture-set-card__hover-block">
                {isTextHidden ? (
                  <span>
                    <Icon name="eye" /> &nbsp; {formatMessage({id: 'XPlayer.WordPictureSet.Show'})}
                  </span>
                ) : (
                  <span>
                    <Icon name="eye-slash" /> &nbsp;
                    {formatMessage({id: 'XPlayer.WordPictureSet.Hide'})}
                  </span>
                )}
              </div>
            )}
          </div>
        </PointerElementListener>
      </div>
    );
  }
);
