import React, {PureComponent} from 'react';
import {type Plugin} from '@englex/slate-react';

import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';
import ListItemAsTask from '../../../Slate/plugins/renderers/List/SpecialCases/ListItemAsTask';
import Bold from '../../../Slate/plugins/renderers/Bold';
import StrikeThrough from '../../../Slate/plugins/renderers/StrikeThrough';
import Italic from '../../../Slate/plugins/renderers/Italic';
import Color from '../../../Slate/plugins/renderers/Color';
import Highlight from '../../../Slate/plugins/renderers/Highlight';
import Underline from '../../../Slate/plugins/renderers/Underline';
import Icon from '../../../Slate/plugins/renderers/Icon';
import './Writing.scss';

const defaultTaskPlugins: Plugin[] = [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new Highlight(),
  new Icon(),
  new Color(),
  new SelectionPointer()
];

export default class Writing extends PureComponent {
  public static examplePlayerPlugins = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new SelectionPointer()
  ];

  public static exampleTaskPlugins = defaultTaskPlugins.concat([new ListItemAsTask()]);
  public static regularTaskPlugins = defaultTaskPlugins.concat([new ListItemAsTask()]);

  public render() {
    return <div className="quests">{this.props.children}</div>;
  }
}
