import React from 'react';
import {type Editor, type RenderInlineProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';
import classNames from 'classnames';

import {SlateInline} from '../../../interface';
import {type RendererPlugin} from '../../interface';

class Label implements RendererPlugin {
  public inline = SlateInline.LABEL;

  public renderInline = (
    {attributes, children, node}: RenderInlineProps,
    editor: Editor,
    next: Next
  ) => {
    if (node.type !== this.inline) return next();

    const flavor = node.data.get('className');

    return (
      <div {...attributes} className={classNames('label', flavor)} style={{marginLeft: '10px'}}>
        {children}
      </div>
    );
  };
}

export default Label;
