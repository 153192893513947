import {type Value} from '@englex/slate';
import React, {memo, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {type Role} from 'store/interface';
import {
  PointerElementListener,
  type PointerListenerProps,
  type PointerListenerRef
} from 'components/Pointer/element/PointerElementListener';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import Icon from 'components/Icon';
import {WPSTheme} from 'store/exercise/editor/widgets/XWordPictureSet/baseInterface';

import {CardNumber} from '../../../components/CardNumber/CardNumber';

import './WordSetCard.scss';

interface Props extends PointerListenerProps {
  index: number;
  text: Value;
  role: Role;
  isTextHidden: boolean;
  isNotInteractive: boolean;
  isCollaborativeManagement: boolean;
  cardId: string;
  closed?: boolean;
  preview?: boolean;
  widgetTheme: WPSTheme;
  onSwitchIsHiddenText?: (cardId: string) => void;
}

const slatePlugins = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

const trigger = ['click'];

const align = {
  offset: [0, 1],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
};

export const WordSetCard: React.FC<Props> = memo(
  ({
    index,
    text,
    role,
    isTextHidden,
    isNotInteractive,
    isCollaborativeManagement,
    onSwitchIsHiddenText,
    cardId,
    closed,
    preview,
    widgetTheme
  }) => {
    const {formatMessage} = useIntl();
    const pointerListenerRef: PointerListenerRef = useRef(null);
    const shouldShowTooltip = useRef<boolean>(false);

    const onMouseDown = useCallback(() => {
      if (pointerListenerRef.current) {
        const {isVisibleTooltip} = pointerListenerRef.current;
        shouldShowTooltip.current = !isVisibleTooltip();
      }
    }, []);

    const onClick = useCallback(() => {
      if (pointerListenerRef.current) {
        const {showTooltip} = pointerListenerRef.current;
        if (shouldShowTooltip.current) {
          showTooltip();
        }
      }
    }, []);

    const onSwitch = () => {
      onSwitchIsHiddenText && onSwitchIsHiddenText(cardId);
    };

    return (
      <div
        className={classNames('word-set-card', {
          'is-text-hidden': isTextHidden && !closed,
          'is-teacher': role === 'teacher',
          'is-student': role === 'student',
          disabled: closed,
          'is-not-interactive': isNotInteractive,
          'is-collaborative-management': isCollaborativeManagement
        })}
      >
        <PointerElementListener
          ref={pointerListenerRef}
          preview={preview}
          trigger={trigger}
          align={align}
          elementId={cardId}
          relatedElement={cardId + 'text'}
        >
          <CardNumber
            index={index}
            id={cardId}
            size="big"
            disabled={isTextHidden && role === 'teacher' && !closed}
            role={role}
            closed={closed}
            onClick={role === 'teacher' && !closed ? onClick : undefined}
            onMouseDown={onMouseDown}
            widgetTheme={widgetTheme !== WPSTheme.BLACK ? widgetTheme : WPSTheme.WHITE}
          />
        </PointerElementListener>
        <PointerElementListener overlayClassName="text" preview={preview} passive={true}>
          <div
            className={classNames('word-set-card__text', widgetTheme)}
            onClick={
              (role === 'teacher' || isCollaborativeManagement) && !closed && !isNotInteractive
                ? onSwitch
                : undefined
            }
            id={cardId + 'text'}
          >
            <SlatePlayer value={text} trimEmptyTrailingParagraphs={true} plugins={slatePlugins} />
            {(role === 'teacher' || isCollaborativeManagement) && !closed && (
              <div className="word-set-card__hover-block">
                {isTextHidden ? (
                  <span>
                    <Icon name="eye" /> &nbsp; {formatMessage({id: 'XPlayer.WordPictureSet.Show'})}
                  </span>
                ) : (
                  <span>
                    <Icon name="eye-slash" /> &nbsp;
                    {formatMessage({id: 'XPlayer.WordPictureSet.Hide'})}
                  </span>
                )}
              </div>
            )}
          </div>
        </PointerElementListener>
      </div>
    );
  }
);
