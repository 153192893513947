import {type GapFillProperties} from 'store/exercise/player/widgets/GapFill/interface';

interface GetAnswerProps {
  example: boolean | undefined;
  widget: GapFillProperties;
  id: string;
  exampleAnswer: string | undefined;
}

interface GetValueProps {
  example: boolean | undefined;
  choiceId: string | undefined;
  widget: GapFillProperties;
  exampleAnswer: string | undefined;
}

export const getAnswer = ({example, widget, id, exampleAnswer}: GetAnswerProps) => {
  if (example) {
    return exampleAnswer;
  } else if (widget.answers && widget.answers.size && widget.choices) {
    return widget.choices.get(widget.answers.get(id)[0]).value;
  }

  return undefined;
};

export const getDragCaseSensitive = (widget: GapFillProperties, choiceId: string | undefined) => {
  if (choiceId === null) {
    return undefined;
  } else {
    return choiceId && widget.choices
      ? widget.choices.get(choiceId).caseSensitive || widget.choices.get(choiceId).isExtra
      : undefined;
  }
};

export const getValue = ({example, choiceId, widget, exampleAnswer}: GetValueProps) => {
  if (example) {
    return exampleAnswer;
  } else if (choiceId !== undefined) {
    return choiceId === null ? null : widget.choices && widget.choices.get(choiceId).value;
  }

  return undefined;
};
