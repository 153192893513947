import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Block, type Next} from '@englex/slate';

import {type MultipleChoiceElement} from 'store/exercise/player/widgets/List/interface';
import {PointerElementListener} from 'components/Pointer/element/PointerElementListener';

import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';
import OddWordAnswerComponent from '../../../../../XPlayer/widgets/List/OddWord/components/OddWordAnswerComponent';

const align = {
  offset: [124, -5],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
};

export default class OddWordAnswerRenderer implements Plugin {
  constructor(
    public readonly element: MultipleChoiceElement,
    private readonly preview?: boolean
  ) {}

  public renderBlock = (
    {node, attributes, children, parent}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ANSWER)) {
      return next();
    }

    const answerId = node.data.get('id');
    const questionId = (parent as Block).data.get('id');

    return (
      <PointerElementListener preview={this.preview} placement="leftTop" align={align}>
        <div {...attributes} id={answerId} className="odd-answer">
          <OddWordAnswerComponent
            element={this.element}
            answerId={answerId}
            questionId={questionId}
            preview={this.preview}
            editor={editor}
          >
            {children}
          </OddWordAnswerComponent>
        </div>
      </PointerElementListener>
    );
  };
}
