import React, {Component, type FC} from 'react';
import {type ConnectDragSource, useDrag} from 'react-dnd';
import classNames from 'classnames';
import {Editor, Transforms} from 'slate';
import {ReactEditor, useSlateStatic} from 'slate-react';
import {HistoryEditor} from 'slate-history';

import {InlineInput} from 'components/SlateJS/components/InlineInput';
import DragHandle from 'components/DragHandle/DragHandle';
import {DndTypes, type DragObject} from 'components/dnd/interface';
import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import {
  type PlaceholderTooltipOptions,
  type SlatePlugin,
  withBeforeEditable
} from 'components/SlateJS/plugins';
import {withInsideEnglexSlate} from 'components/SlateJS/plugins/withInsideEnglexSlate';
import {withPointerElement} from 'components/SlateJS/plugins/pointer';

import {withCapitalizeOnBlur} from './plugins';

export interface InputChoiceDragObject extends DragObject {
  widgetId: string;
  answer: string;
  choiceId: string;
}

interface Props {
  id: string;
  answer?: string;
  chosenIndefiniteForm: string;
  widgetId: string;
  isOver?: boolean;
  disabled?: boolean;
  selected?: boolean;
  preview?: boolean;
  dragCaseSensitive?: true;
  startOfSentence?: true;
  flexHandle?: true;
  cardJustDropped: boolean;
  clearCardJustDropped(): void;
  onInputChange(text: string): void;
  onSelect?(forceClear?: true, e?: React.MouseEvent<HTMLDivElement>): void;
}

const DNDInputChoiceContainer: FC<Props> = props => {
  const {answer, id, widgetId, chosenIndefiniteForm, disabled} = props;
  const [{dragging}, connectDragSource, connectDragPreview] = useDrag({
    type: DndTypes.X_GAP,
    item: () => {
      return {
        answer: answer || chosenIndefiniteForm || '',
        choiceId: id,
        widgetId: widgetId,
        considerClientOffset: true
      };
    },
    collect: monitor => {
      return {
        dragging: monitor.isDragging()
      };
    },
    canDrag: () => !disabled
  });

  useDndEmptyImage(connectDragPreview);

  return <DNDInputChoice {...props} dragging={dragging} connectDragSource={connectDragSource} />;
};

interface DNDInputChoiceProps extends Props {
  connectDragSource: ConnectDragSource;
  dragging: boolean;
}

class DNDInputChoice extends Component<DNDInputChoiceProps> {
  private memoPlugins: SlatePlugin[] = [];
  private memoPlaceholderTooltip?: PlaceholderTooltipOptions;
  private id: string;
  private editor: Editor | null = null;

  public componentDidMount() {
    this.handleJustDropped();
  }

  public componentDidUpdate(prevProps: DNDInputChoiceProps) {
    if (!prevProps.dragging && this.props.dragging) {
      this.props.onSelect?.(true);
    }
    this.handleJustDropped();
  }

  public render() {
    const {id, dragging, isOver, disabled, onInputChange, selected, startOfSentence, preview} =
      this.props;
    const {text} = this;
    return (
      <span
        className={classNames('x-gap-core dnd-input-choice', {
          dragging,
          disabled,
          selected,
          'is-over': isOver
        })}
      >
        <InlineInput
          key={id}
          preview={preview}
          className={classNames('x-input', {
            large: true,
            empty: text === '',
            capitalize: startOfSentence
          })}
          text={text}
          onChange={onInputChange}
          placeholderTooltip={this.placeholderTooltip}
          plugins={this.plugins}
        />
      </span>
    );
  }

  private get text() {
    const {answer, chosenIndefiniteForm} = this.props;
    return answer === undefined ? chosenIndefiniteForm : answer;
  }

  private get plugins(): SlatePlugin[] {
    const {connectDragSource, id} = this.props;
    if (this.id === id && this.memoPlugins.length) return this.memoPlugins;
    this.id = id;
    this.memoPlugins = [
      withBeforeEditable(() => {
        this.editor = useSlateStatic();
        return connectDragSource(
          <span className="drag-element" onClick={this.onSelect}>
            <DragHandle useSVGHack={true} />
          </span>
        );
      }),
      withCapitalizeOnBlur({enabled: this.props.startOfSentence}),
      withInsideEnglexSlate,
      withPointerElement({id: this.props.id, preview: this.props.preview})
    ];

    return this.memoPlugins;
  }

  private get placeholderTooltip(): PlaceholderTooltipOptions {
    const {id} = this.props;
    if (this.id === id && this.memoPlaceholderTooltip) return this.memoPlaceholderTooltip;
    this.memoPlaceholderTooltip = {
      overlayClassName: 'indefinite-form',
      placeholder: () => this.props.chosenIndefiniteForm
    };
    return this.memoPlaceholderTooltip;
  }

  private onSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.editor && !ReactEditor.isFocused(this.editor)) {
      this.props.onSelect?.(undefined, e);
    }
  };

  private handleJustDropped = () => {
    if (this.props.cardJustDropped && this.editor) {
      requestAnimationFrame(() => {
        const editor = this.editor;
        if (!editor) return;

        // just after drop card we focus editor
        // and move its selection to the end of document
        ReactEditor.focus(editor);
        HistoryEditor.withoutSaving(editor, () => {
          Transforms.select(editor, Editor.end(editor, []));
        });
      });
      this.props.clearCardJustDropped();
    }
  };
}

export default DNDInputChoiceContainer;
