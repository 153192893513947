import {type Editor as ReactEditor, type RenderInlineProps} from '@englex/slate-react';
import React from 'react';
import classNames from 'classnames';
import {type Editor, type Next, type SchemaProperties} from '@englex/slate';

import {type RendererPlugin} from 'components/Slate/plugins/interface';
import {type GapFillData, GapFillType, SlateInline} from 'components/Slate/interface';
import {isGapFillInlineOfType} from 'components/Slate/utils';
import {getMarkClassNamesForNode} from 'components/Slate/plugins/utils';
import {type GapProps} from 'store/exercise/player/widgets/GapFill/interface';

import {PointerElementListener} from '../../../../Pointer/element/PointerElementListener';

interface Options {
  preview?: boolean;
}

abstract class GapFill implements RendererPlugin {
  public abstract gap: GapFillType;

  public schema: SchemaProperties = {
    inlines: {
      [SlateInline.GAP]: {
        isVoid: true
      }
    }
  };

  protected abstract Gap: React.ComponentType<GapProps>;
  protected markTypes = [];
  private readonly preview?: boolean;

  constructor(options: Options = {}) {
    this.preview = options.preview;
  }

  public renderInline = (props: RenderInlineProps, editor: Editor & ReactEditor, next: Next) => {
    const {node} = props;
    if (!isGapFillInlineOfType(node, this.gap)) {
      return next();
    }

    const gapClasses = classNames(
      getMarkClassNamesForNode(editor, node.getMarks(), this.markTypes)
    );

    const data = node.data as GapFillData;
    const id = data.get('id');
    const example = data.get('example');
    const indefiniteForm = data.get('indefiniteForm');
    const editable = data.get('editable');
    const answer = example
      ? data.get('answer')[this.gap === GapFillType.DND_INPUT ? 1 : 0]
      : undefined;
    const choices = data.has('choices')
      ? data.get('choices')!.reduce((ch, choice) => {
          ch[choice] = choice;
          return ch;
        }, {})
      : undefined;
    const gapCaseSensitive = data.get('caseSensitive');
    const startOfSentence = data.get('startOfSentence');

    const className = classNames('x-gap', this.gap);
    const Gap = this.Gap;
    return (
      <span className={className} {...props.attributes}>
        <PointerElementListener preview={Boolean(this.preview)}>
          <Gap
            id={id}
            className={gapClasses}
            choices={choices}
            preview={this.preview}
            example={example}
            indefiniteForm={indefiniteForm}
            answer={answer}
            gapCaseSensitive={gapCaseSensitive}
            startOfSentence={startOfSentence}
            editor={editor}
            editable={editable}
          />
        </PointerElementListener>
      </span>
    );
  };
}

export default GapFill;
