import {type Map} from 'immutable';
import {type Document, type Inline} from '@englex/slate';

import {isGapFillInline} from 'components/Slate/utils';
import {type DNDInputValue} from 'store/exercise/player/interface';
import {type GapFillProperties} from 'store/exercise/player/widgets/GapFill/interface';

interface PropsGetValue {
  example: boolean | undefined;
  exampleAnswer: string | undefined;
  values: Map<string, DNDInputValue> | undefined;
  id: string;
  choiceId: string | null | undefined;
  widget: GapFillProperties;
}

export const getValue = ({example, exampleAnswer, values, id, choiceId, widget}: PropsGetValue) => {
  if (example) {
    return exampleAnswer;
  } else if (values) {
    return values?.get(id)?.text;
  } else if (choiceId !== undefined) {
    return choiceId === null ? null : widget.choices?.get(choiceId).value;
  }

  return null;
};

export const getDragCaseSensitive = (
  choiceId: string | null | undefined,
  widget: GapFillProperties
) => {
  if (choiceId === null) {
    return undefined;
  } else {
    return choiceId ? widget.choices?.get(choiceId)?.caseSensitive : undefined;
  }
};

export const getChosenIndefiniteForm = (
  example: boolean | undefined,
  choiceId: string | null | undefined,
  widget: GapFillProperties
) => {
  return example
    ? undefined
    : choiceId === null
      ? null
      : choiceId
        ? widget.choices?.get(choiceId).value
        : undefined;
};

export const getIndefiniteForm = (
  example: boolean | undefined,
  gapAnswer: string | undefined,
  widget: GapFillProperties
) => {
  return example ? undefined : (gapAnswer && widget.choices?.get(gapAnswer).value) || undefined;
};

export const getExampleAnswers = (id: string, document: Document) => {
  const inline = document
    .getInlines()
    .find((inline: Inline) => isGapFillInline(inline) && inline.data.get('id') === id);

  return inline ? inline.data.get('answer', []) : undefined;
};
