export const getDragging = (
  dragItemIndex: number | null,
  dragItemWidgetId: string | null,
  dragItemSentenceId: string | null,
  widgetId: string,
  sentenceId: string,
  cardIndex: number
) => {
  if (
    dragItemIndex === null ||
    dragItemWidgetId !== widgetId ||
    dragItemSentenceId !== sentenceId
  ) {
    return false;
  }
  return dragItemIndex === cardIndex;
};
