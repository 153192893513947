import {GapFillType} from '../../../../Slate/interface';
import GapFill from './GapFill';
import DropDown from '../component/dropdown/DropDown';

class GapFillDropDown extends GapFill {
  public gap = GapFillType.DROPDOWN;
  protected Gap = DropDown;
}

export default GapFillDropDown;
