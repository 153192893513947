import React, {type FC, useEffect, memo, useCallback, type PropsWithChildren} from 'react';
import {type ConnectDragPreview, type ConnectDragSource, type ConnectDropTarget} from 'react-dnd';
import classNames from 'classnames';

import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import {usePrevious} from 'hooks/usePrevious';

import {getDragging} from './getDragging';
import DragComponent from './DragComponent';
import {PointerElementListener} from '../../../../Pointer/element/PointerElementListener';

import './Card.scss';

export interface CardProps {
  /*
   * Id is not used in card component, so explanation on why this property is provided might be
   * helpful. When two cards with equal value get replaced one by another we get two cards with
   * actually same props as before.
   * Card with, say, index 1 gets value, say, 'john doe', and card with index 4 gets the same
   * value from another card. What makes them unique and forces rerender - id. So this value
   * is here for react itself, not for usage in component.
   * */
  id: string;
  cardIndex: number;
  index: number;
  value: string;
  sentenceId: string;
  widgetId: string;
  preview?: boolean;

  endDrag: () => void;
  moveCard: (dragIndex: number, hoverIndex: number, cb?: () => void) => void;
  dispatchDragging: (dragging: boolean) => void;

  selected: boolean;
  selectedCardIndex?: number;
  onSelect(index: number, cardIndex: number, id?: string): void;
}

interface DnDProps {
  dragItemIndex: number | null;
  dragItemSentenceId: string | null;
  dragItemWidgetId: string | null;
  connectDragSource: ConnectDragSource;
  connectDragPreview: ConnectDragPreview;
  connectDropTarget: ConnectDropTarget;
  dropTarget: React.MutableRefObject<HTMLDivElement | null>;
}

type Props = CardProps & DnDProps;

const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<Props>>,
  nextProps: Readonly<PropsWithChildren<Props>>
) => {
  if (prevProps.dragItemSentenceId !== null || nextProps.dragItemSentenceId !== null) {
    const dragItemSentenceId =
      prevProps.dragItemSentenceId !== null
        ? prevProps.dragItemSentenceId
        : nextProps.dragItemSentenceId;

    return prevProps.sentenceId !== dragItemSentenceId;
  }

  return false;
};

const Card: FC<Props> = memo(
  ({
    id,
    value,
    cardIndex,
    index,
    preview,
    selected,
    selectedCardIndex,
    endDrag,
    moveCard,
    sentenceId,
    widgetId,
    onSelect,
    dispatchDragging,
    dragItemIndex,
    dragItemSentenceId,
    dragItemWidgetId,
    connectDragSource,
    connectDragPreview,
    connectDropTarget,
    dropTarget
  }) => {
    const prevDragItemSentenceId = usePrevious(dragItemSentenceId);
    const prevDragItemIndex = usePrevious(dragItemIndex);

    useDndEmptyImage(connectDragPreview);

    const dragging = getDragging(
      dragItemIndex,
      dragItemWidgetId,
      dragItemSentenceId,
      widgetId,
      sentenceId,
      cardIndex
    );

    const activeZoneClickHandler = useCallback(() => {
      if (selectedCardIndex !== undefined) moveCard(selectedCardIndex, cardIndex, endDrag);
    }, [cardIndex, endDrag, moveCard, selectedCardIndex]);

    const onSelectFunc = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onSelect(index, cardIndex, id);
      },
      [cardIndex, id, index, onSelect]
    );

    useEffect(() => {
      if (cardIndex === dragItemIndex) {
        dispatchDragging(true);
      } else if (dragItemIndex === cardIndex && !dragItemSentenceId) {
        dispatchDragging(false);
      }
    }, [dragItemSentenceId, cardIndex, dragItemIndex, dispatchDragging]);

    useEffect(() => {
      if (!dragItemSentenceId && prevDragItemSentenceId && prevDragItemIndex === cardIndex) {
        dispatchDragging(false);
      }
      if (!prevDragItemIndex && dragItemIndex) {
        onSelect(index, cardIndex);
      }
    }, [
      cardIndex,
      dispatchDragging,
      dragItemIndex,
      dragItemSentenceId,
      index,
      onSelect,
      prevDragItemIndex,
      prevDragItemSentenceId
    ]);

    return connectDragSource(
      connectDropTarget(
        <div className={classNames('x-dnd-card-wrapper', {first: index === 0})} ref={dropTarget}>
          <PointerElementListener preview={preview} inline={true}>
            <span id={id}>
              <DragComponent
                activeZoneClickHandler={activeZoneClickHandler}
                dragging={dragging}
                isAfterSelected={selectedCardIndex !== undefined && cardIndex > selectedCardIndex}
                selectionIsActive={selectedCardIndex !== undefined}
                selected={selected}
                selectHandler={onSelectFunc}
                value={value}
              />
            </span>
          </PointerElementListener>
        </div>
      )
    );
  },
  propsAreEqual
);

export default Card;
