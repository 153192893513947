import {type FC, memo, useRef} from 'react';

import {type SpellingProperties} from 'store/exercise/player/widgets/Selling/interface';
import {type Role} from 'store/interface';

import {Sentence} from '../Sentence/Sentence';
import {getNewTemplates} from '../../helpers';

import './Sentences.scss';

interface Props {
  widget: SpellingProperties;
  preview?: boolean;
  role: Role;
  isModal?: boolean;
  closed?: boolean;
}

export const Sentences: FC<Props> = memo(
  ({
    closed,
    preview,
    role,
    widget: {answers, example, scrambledChoices: choices, sentences, values, ordinal},
    widget,
    isModal
  }) => {
    const templates = useRef<{[sentenceId: string]: string}>(getNewTemplates(sentences));

    const choicesSequence = choices.keySeq();

    const isOneSentence = sentences.length < 2;

    return (
      <>
        {choicesSequence.map((k: string, i: number) => {
          const sentenceAnswers = answers ? answers.get(k) : undefined;
          const sentenceChoices = choices.get(k);
          const sentenceValues = values ? values.get(k) : undefined;

          return (
            <Sentence
              index={i}
              key={k}
              answers={sentenceAnswers}
              choices={sentenceChoices}
              closed={closed}
              countExample={!!example}
              preview={preview}
              role={role}
              sentenceId={k}
              template={templates.current[k]}
              values={sentenceValues}
              widgetId={widget.id}
              isModal={isModal}
              ordinal={ordinal}
              isOneSentence={isOneSentence}
            />
          );
        })}
      </>
    );
  }
);
