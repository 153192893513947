import React, {memo} from 'react';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type DialogueProperties} from 'store/exercise/player/widgets/Dialogue/interface';
import type MessageRecord from 'store/exercise/player/widgets/Dialogue/MessageRecord';
import type SpeakerRecord from 'store/exercise/player/widgets/Dialogue/SpeakerRecord';

import {MessageCard} from './components/MessageCard/MessageCard';

import './Dialogue.scss';

type Props = WidgetTypeComponentProps<DialogueProperties>;

export const Dialogue: React.FC<Props> = memo(props => {
  const {widget} = props;
  const {speakers, messages, styleName} = widget;

  const getWidgetProps = () => props;

  return (
    <div className={`dialogue ${styleName}`}>
      {messages.map((message: MessageRecord) => {
        const {avatar, name} = speakers.find(
          (speaker: SpeakerRecord) => speaker.id === message.speakerId
        );
        return (
          <MessageCard
            key={message.id}
            cardId={message.id}
            avatar={avatar}
            name={name}
            message={message.message}
            styleName={styleName}
            getWidgetProps={getWidgetProps}
          />
        );
      })}
    </div>
  );
});
