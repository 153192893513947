import React, {memo, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import {type Role} from 'store/interface';
import Icon from 'components/Icon';
import {
  PointerElementListener,
  type PointerListenerProps,
  type PointerListenerRef
} from 'components/Pointer/element/PointerElementListener';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';
import {type WPSTheme} from 'store/exercise/editor/widgets/XWordPictureSet/baseInterface';

import {CardNumber} from '../../../components/CardNumber/CardNumber';

import './PictureSetCard.scss';

interface Props extends PointerListenerProps {
  index: number;
  image: number;
  role: Role;
  isImageHidden: boolean;
  isNotInteractive: boolean;
  isCollaborativeManagement: boolean;
  cardId: string;
  closed?: boolean;
  preview?: boolean;
  cardSizeType: CardSizeType;
  widgetTheme: WPSTheme;
  onSwitchIsImageHidden: (cardId: string) => void;
}

const trigger = ['click'];

const align = {
  offset: [0, 1],
  overflow: {
    adjustX: undefined,
    adjustY: undefined
  }
};

export const PictureSetCard: React.FC<Props> = memo(
  ({
    cardId,
    index,
    image,
    role,
    isImageHidden,
    isNotInteractive,
    isCollaborativeManagement,
    closed,
    preview,
    cardSizeType,
    widgetTheme,
    onSwitchIsImageHidden
  }) => {
    const {formatMessage} = useIntl();
    const pointerListenerRef: PointerListenerRef = useRef(null);
    const shouldShowTooltip = useRef<boolean>(false);

    const onMouseDown = useCallback(() => {
      if (pointerListenerRef.current) {
        const {isVisibleTooltip} = pointerListenerRef.current;
        shouldShowTooltip.current = !isVisibleTooltip();
      }
    }, []);

    const onClick = (e: React.MouseEvent) => {
      if (pointerListenerRef.current) {
        const {showTooltip} = pointerListenerRef.current;
        if (shouldShowTooltip.current) {
          showTooltip();
        }
      }
    };

    const onSwitch = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!closed && !isNotInteractive) onSwitchIsImageHidden(cardId);
    };

    return (
      <div
        className={classNames('picture-set-card', {
          'is-image-hidden': isImageHidden && !closed,
          'is-teacher': role === 'teacher',
          'is-student': role === 'student',
          disabled: closed,
          'is-not-interactive': isNotInteractive,
          'is-collaborative-management': isCollaborativeManagement,
          'is-rectangle': cardSizeType === CardSizeType.RECTANGLE
        })}
      >
        <PointerElementListener
          ref={pointerListenerRef}
          preview={preview}
          trigger={trigger}
          align={align}
          elementId={cardId}
        >
          <div id={cardId} className={classNames('picture-set-card__container')}>
            <div
              onClick={role === 'teacher' || isCollaborativeManagement ? onSwitch : undefined}
              className={classNames(
                'picture-set-card__container',
                'picture-set-card__image-container',
                widgetTheme
              )}
            >
              {(!isImageHidden || role !== 'student' || !!closed) && (
                <LoadableImage imageId={image} width="100%" height="100%" />
              )}

              {(role === 'teacher' || isCollaborativeManagement) && !closed && (
                <div className="picture-set-card__hover-block">
                  {isImageHidden ? (
                    <span>
                      <Icon name="eye" /> &nbsp;{' '}
                      {formatMessage({id: 'XPlayer.WordPictureSet.Show'})}
                    </span>
                  ) : (
                    <span>
                      <Icon name="eye-slash" /> &nbsp;{' '}
                      {formatMessage({id: 'XPlayer.WordPictureSet.Hide'})}
                    </span>
                  )}
                </div>
              )}
            </div>

            <CardNumber
              index={index}
              id={cardId + 'number'}
              role={role}
              isAbsolutePosition
              onClick={role === 'teacher' && !closed ? onClick : undefined}
              onMouseDown={onMouseDown}
              widgetTheme={widgetTheme}
            />
          </div>
        </PointerElementListener>
      </div>
    );
  }
);
