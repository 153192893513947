import React, {type FC, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import {type List} from 'immutable';
import {type Descendant} from 'slate';

import {type ExerciseInstanceComment, type ExerciseInstanceCommentMap} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import * as toastr from 'components/toastr';
import {type DataMap} from 'components/Slate/interface';
import Loader from 'components/Loader';
import {type DraftCommentProperties} from 'store/exercise/player/interface';
import {createDraftComment, setCommentValue} from 'store/exercise/player/actions';

import {requestDeleteComment, requestEditComment, requestPostComment} from './actions';
import {Comment} from './Comment';
import {InputEditor} from './InputEditor';
import {slateMigrateDown, slateMigrateUp} from '../../../../SlateJS/utils';
import './ExerciseComments.scss';

interface Props {
  exerciseId: string;
  comments: List<DataMap<ExerciseInstanceCommentMap>>;
  draftComment?: DraftCommentProperties;
  canCloseExercise?: boolean;
  closeCommentEditor(): void;
  openCommentEditor(): void;
}

export const ExerciseComments: FC<Props> = React.memo<Props>(
  ({
    exerciseId,
    comments,
    draftComment,
    closeCommentEditor,
    openCommentEditor,
    canCloseExercise
  }) => {
    const intl = useIntl();
    const axiosDispatch = useAxiosDispatch();
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);

    const editorOnChange = useCallback(
      (value: Descendant[]) => {
        dispatch(setCommentValue(exerciseId, draftComment!.id, value));
      },
      [dispatch, draftComment, exerciseId]
    );

    const closeEditor = useCallback(() => {
      setSubmitting(false);
      closeCommentEditor();
    }, [closeCommentEditor]);

    const editComment = (id: string) => {
      const comment = comments.find(c => c!.get('id') === id);
      dispatch(createDraftComment(exerciseId, id, slateMigrateUp(comment.get('comment').toJSON())));
    };

    const saveComment = useCallback(
      (value: Descendant[]) => {
        if (submitting) return;
        if (draftComment?.id === null) {
          setSubmitting(true);
          axiosDispatch<AxiosResponseAction<ExerciseInstanceComment>>(
            requestPostComment(exerciseId, slateMigrateDown(value))
          )
            .then(() => {
              closeEditor();
            })
            .catch(() => {
              toastr.error('', intl.formatMessage({id: 'XPlayer.Exercise.AddComment.Error'}));
              setSubmitting(false);
            });
        }
        if (draftComment?.id) {
          setSubmitting(true);
          axiosDispatch<AxiosResponseAction<Omit<ExerciseInstanceComment, 'createdBy'>>>(
            requestEditComment(exerciseId, draftComment.id, slateMigrateDown(value))
          )
            .then(() => {
              closeEditor();
            })
            .catch(() => {
              toastr.error('', intl.formatMessage({id: 'XPlayer.Exercise.EditComment.Error'}));
              setSubmitting(false);
            });
        }
      },
      [axiosDispatch, closeEditor, draftComment, exerciseId, intl, submitting]
    );

    const deleteComment = useCallback(
      (id: string) => {
        setSubmitting(true);
        axiosDispatch<
          AxiosResponseAction<Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>>
        >(requestDeleteComment(exerciseId, id))
          .catch(() =>
            toastr.error('', intl.formatMessage({id: 'XPlayer.Exercise.DeleteComment.Error'}))
          )
          .finally(() => {
            setSubmitting(false);
          });
      },
      [axiosDispatch, exerciseId, intl]
    );

    let prevCommentUserId: null | number = null;
    return (
      <div
        className={classNames('exercise-comments', {
          'with-margin': canCloseExercise,
          'with-list': !!comments.size,
          'with-editor': !!draftComment
        })}
      >
        {!!comments.size ? (
          <div className="exercise-comments-list">
            {comments.map((c: DataMap<ExerciseInstanceCommentMap>) => {
              const component = (
                <Comment
                  prevCommentUserId={prevCommentUserId}
                  comment={c}
                  key={c.get('id')}
                  isBeingEdited={c.get('id') === draftComment?.id}
                  editComment={editComment}
                  editIsActive={!!draftComment}
                  deleteComment={deleteComment}
                />
              );
              prevCommentUserId = c.get('createdById');
              return component;
            })}
          </div>
        ) : null}
        {draftComment ? (
          <InputEditor
            submitting={submitting}
            onChange={editorOnChange}
            draftComment={draftComment}
            saveComment={saveComment}
            closeEditor={closeEditor}
          />
        ) : (
          !!comments.size && (
            <div className="exercise-comments-input-actions">
              <Button
                className={classNames({submitting})}
                bsSize="sm"
                bsStyle="success"
                onClick={openCommentEditor}
                disabled={submitting}
              >
                {submitting && (
                  <div className="loader-button-positioning-helper">
                    <Loader shouldRender={true} />
                  </div>
                )}
                <span>{intl.formatMessage({id: 'XPlayer.Exercise.AddComment'})}</span>
              </Button>
            </div>
          )
        )}
      </div>
    );
  }
);
