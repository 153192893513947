import React, {type PropsWithChildren} from 'react';
import Radio from 'react-bootstrap/lib/Radio';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {type Action, type Dispatch} from 'redux';
import {type Editor} from '@englex/slate-react';

import {getWidgetProps} from 'components/Slate/interface';
import {type Role, type AppState} from 'store/interface';
import {type WidgetComponentProps} from 'store/exercise/player/interface';
import {
  type MultipleChoiceElement,
  type MultipleChoiceProperties
} from 'store/exercise/player/widgets/List/interface';
import {
  type MCAnswerSelectedAction,
  MCAnswerSelected,
  MCAnswerDeselected
} from 'store/exercise/player/widgets/List/actions';

import './OddWordPlayer.scss';

interface OwnProps {
  element: MultipleChoiceElement;
  answerId: string;
  questionId: string;
  preview?: boolean;
  editor: Editor;
}

type AnswerInputOwnProps = PropsWithChildren<OwnProps>;

interface AnswerInputStateProps {
  closed?: boolean;
  correct?: boolean;
  isChecked: boolean;
  role: Role;
  widgetId: string;
}

interface DispatchProps {
  answerSelected: (widgetId: string) => MCAnswerSelectedAction;
  answerDeselected: (widgetId: string) => MCAnswerSelectedAction;
}

interface Props extends AnswerInputOwnProps, AnswerInputStateProps, DispatchProps {}

class OddWordAnswerComponent extends React.Component<Props, {}> {
  public render() {
    const {isChecked, correct, closed, role, questionId} = this.props;
    const className = classNames({
      correct,
      closed: closed || role !== 'student',
      checked: isChecked,
      teacher: role !== 'student'
    });
    return (
      <div className={`input-wrapper ${className}`}>
        <Radio checked={isChecked} onChange={this.handleInputChange} name={`${questionId}[]`}>
          <span>{this.props.children}</span>
        </Radio>
      </div>
    );
  }

  private handleInputChange = () => {
    const {answerSelected, answerDeselected, isChecked, role, closed, widgetId} = this.props;
    if (role !== 'student' || closed) {
      return;
    }
    if (isChecked) {
      answerDeselected(widgetId);
    } else {
      answerSelected(widgetId);
    }
  };
}

const mapStateToProps = (state: AppState, ownProps: AnswerInputOwnProps): AnswerInputStateProps => {
  const {questionId, answerId, editor} = ownProps;
  const {widget, role, closed} = editor.query(
    getWidgetProps
  ) as WidgetComponentProps<MultipleChoiceProperties>;
  const correct =
    (role !== 'student' || closed) &&
    widget.answers &&
    !!widget.answers.get(ownProps.questionId) &&
    widget.answers.get(questionId).includes(answerId);
  const isChecked = !!(
    widget.values &&
    widget.values.get(questionId) &&
    widget.values.get(questionId).includes(answerId)
  );
  return {
    role,
    closed,
    correct,
    isChecked,
    widgetId: widget.id
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  {questionId, answerId, preview}: AnswerInputOwnProps
): DispatchProps => ({
  answerSelected: (widgetId: string) =>
    dispatch(MCAnswerSelected(widgetId, questionId, answerId, preview)),
  answerDeselected: (widgetId: string) =>
    dispatch(MCAnswerDeselected(widgetId, questionId, answerId, preview))
});

export default connect(mapStateToProps, mapDispatchToProps)(OddWordAnswerComponent);
