import React from 'react';
import {convertTicks, pipe, replaceEmpty, trim} from '@englex/utils';
import classNames from 'classnames';

import {type Answer} from 'store/exercise/player/interface';

interface Props {
  value?: string;
  answers: string[];
  example?: boolean;
  placeholder?: string;
  dirty: boolean;
}

export const Answers: React.FC<Props> = ({value, answers, placeholder, example, dirty}) => {
  const single = answers.length <= 1;
  const mutate = pipe(convertTicks, trim, replaceEmpty('(none)'));

  return (
    <>
      {placeholder ? <span className="placeholder">{placeholder}</span> : null}
      <ul className={classNames('x-gap-answers-hint', 'input', {single})}>
        {answers.map((a: Answer, key: number) => {
          const empty = a === '';
          const answer = dirty && !example && a.toLowerCase() === value!.toLowerCase();
          return (
            <li
              key={key}
              className={classNames('correct', {
                answer,
                empty
              })}
            >
              {mutate(a ? a : '')}
            </li>
          );
        })}
      </ul>
    </>
  );
};
