import React from 'react';

import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {type WidgetProperties} from 'store/exercise/player/interface';
import {type Role} from 'store/interface';

import {Widget} from './Widget';

interface Props
  extends Pick<ExerciseProperties, 'widgets' | 'homeworkId' | 'completedAt' | 'mainId'> {
  exerciseId: string;
  role: Role;
  preview?: boolean;
  showPreviewExerciseNumber?: boolean;
  isHomeworkPlayer?: boolean;
  isGrammarPlayer?: boolean;
  isWidgetPreviewPlayer?: boolean;
  headWidgetIndex: number;
  isPointerAvailable?: boolean;
  onShowPreviewModal: (widgetId: string) => void;
  isModal?: boolean;
}

export const Widgets: React.FC<Props> = React.memo(
  ({
    exerciseId,
    widgets,
    completedAt,
    mainId,
    role,
    preview,
    showPreviewExerciseNumber,
    homeworkId,
    isHomeworkPlayer,
    isWidgetPreviewPlayer,
    isGrammarPlayer,
    headWidgetIndex,
    isPointerAvailable,
    onShowPreviewModal,
    isModal
  }) => {
    if (!widgets) {
      return null;
    }
    return (
      <div className="x-widgets">
        {widgets.map((widget: WidgetProperties, index: number) => {
          return (
            <Widget
              widget={widget}
              key={widget.id}
              exerciseId={exerciseId}
              isHeadWidget={!isWidgetPreviewPlayer && headWidgetIndex === index}
              isClosed={!!completedAt}
              isMain={!mainId}
              role={role}
              preview={preview}
              homeworkId={homeworkId}
              showPreviewExerciseNumber={showPreviewExerciseNumber}
              isHomeworkPlayer={isHomeworkPlayer}
              isGrammarPlayer={isGrammarPlayer}
              isWidgetPreviewPlayer={isWidgetPreviewPlayer}
              isPointerAvailable={isPointerAvailable}
              onShowPreviewModal={onShowPreviewModal}
              isModal={isModal}
            />
          );
        })}
      </div>
    );
  }
);
