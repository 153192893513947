import {type Answer, type WidgetAction} from '../../interface';
import {INPUT_CHANGED} from './actionTypes';
import {xplayerPrefix} from '../../actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';

export interface GapFillAnswerAction extends WidgetAction {
  widgetId: string;
  gapId: string;
  answer: Answer;
}

export const inputChanged = (
  widgetId: string,
  gapId: string,
  answer: Answer,
  preview?: boolean
): GapFillAnswerAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${INPUT_CHANGED}`,
    widgetId,
    answer,
    gapId,
    preview
  };
};
