import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {wordTemplateLiteral} from 'config/static';

import {Provider} from '../../../contexts/dndContext';
import FullSentence from './FullSentence';
import Pool from './Pool';
import {checkCorrectness, choicesAndPunctuationBuilder, resolveTemplateArray} from './utils';
import {type ChoiceOrPunctuation, type SentenceProps as Props} from './interface';

interface State {
  dragging: boolean;
}

class Sentence extends PureComponent<Props, State> {
  public state: State = {dragging: false};
  private readonly templateArray: string[] = resolveTemplateArray(this.props.template);

  private get className() {
    const {answers, closed, role, values, choices} = this.props;
    const {dragging} = this.state;
    const checking = role === 'student' ? closed : closed || (answers && values);
    const correct = checking && checkCorrectness({answers, choices, values});
    const incorrect = checking && !correct;
    const student = role === 'student' && !closed;
    return classNames('sentence-block', {
      correct,
      dragging,
      incorrect,
      student,
      pristine: values === undefined && !student && !correct && !incorrect
    });
  }

  private get fullSentence(): string | undefined {
    const {answers, choices, closed, role, template} = this.props;
    return answers && (role !== 'student' || closed)
      ? answers.reduce(
          (r: string, a: string) => r.replace(wordTemplateLiteral, choices.get(a).value),
          template
        )
      : undefined;
  }

  private get choicesAndPunctuation(): ChoiceOrPunctuation[] {
    const {choices, values} = this.props;
    return choicesAndPunctuationBuilder(this.templateArray, choices, values);
  }

  public render() {
    const {
      answers,
      choices,
      countExample,
      index,
      preview,
      sentenceId,
      role,
      closed,
      values,
      widgetId,
      isModal,
      sentencesSize
    } = this.props;
    const numbering = <div className="index">{index + (countExample ? 2 : 1)}.</div>;
    const shouldShowNumbering = sentencesSize !== 1 || countExample;

    return (
      <Provider isModal={isModal}>
        <div className={this.className}>
          {shouldShowNumbering && numbering}
          <div>
            <Pool
              answers={answers}
              values={values}
              preview={preview}
              sentenceId={sentenceId}
              choices={choices}
              choicesAndPunctuation={this.choicesAndPunctuation}
              role={role}
              closed={closed}
              updateDraggingState={this.updateDraggingState}
              widgetId={widgetId}
            />
            {this.fullSentence && <FullSentence sentence={this.fullSentence} />}
          </div>
        </div>
      </Provider>
    );
  }

  private updateDraggingState = (dragging: boolean) => this.setState({dragging});
}

export default Sentence;
