import React, {PureComponent} from 'react';
import classNames from 'classnames';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import {
  pipe,
  trim,
  removeHidden,
  convertTicks,
  collapseSpaces,
  toLowerCase,
  pipeline
} from '@englex/utils';

import {GapFillType} from 'components/Slate/interface';
import {type Answer} from 'store/exercise/player/interface';
import XPlayerTooltip from 'components/XPlayer/components/XPlayerTooltip';
import DragHandle from 'components/DragHandle/DragHandle';

import GapChecked from '../GapChecked';
import {PointerElementListener} from '../../../../../Pointer/element/PointerElementListener';

interface Props {
  id: string;
  preview?: boolean;
  answers?: Answer[];
  className?: string;
  closed?: boolean;
  dragCaseSensitive?: true;
  example?: boolean;
  gapCaseSensitive?: true;
  indefiniteForm?: string;
  startOfSentence?: true;
  chosenIndefiniteForm?: string | null;
  value?: Answer | null;
  ignorePlaceholder?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends PureComponent<Props, State> {
  public state: State = {visible: false};

  public render() {
    const {id, chosenIndefiniteForm, className, closed, example, preview} = this.props;
    const {visible} = this.state;
    const {showTooltip, showTooltipOverlay} = this;
    const multipleAnswers = showTooltip && this.isCorrect;
    const gap = (
      <GapChecked
        className={classNames(className, {'with-card': !!chosenIndefiniteForm})}
        gap={GapFillType.DND}
        value={this.printValue}
        dirty={this.isDirty}
        correct={this.isCorrect}
        empty={this.isEmpty}
        closed={closed}
        example={example}
        dot={showTooltip}
        multipleAnswers={multipleAnswers}
      >
        <DragHandle disabled={true} useSVGHack={true} />
      </GapChecked>
    );

    return (
      <PointerElementListener
        elementId={id}
        preview={preview}
        tooltipClassName="x-player-tooltip"
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={showTooltipOverlay}
        render={({isAvailable, isTeacher, pointerInlineClassName}) =>
          (isAvailable && isTeacher) || !showTooltip ? (
            <span id={id} className={pointerInlineClassName}>
              {gap}
            </span>
          ) : (
            <span id={id} className={pointerInlineClassName}>
              <XPlayerTooltip
                overlay={this.answers()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          )
        }
      />
    );
  }

  private mutator1 = pipe(trim, removeHidden, convertTicks, collapseSpaces, toLowerCase);
  private mutator2 = pipe(convertTicks, trim);
  private mutator3 = pipe(convertTicks, trim, capitalize);
  private mutator4 = pipe(trim, removeHidden, convertTicks, collapseSpaces);

  private get showTooltip(): boolean {
    const {answers = [], ignorePlaceholder} = this.props;
    return !this.isCorrect || answers.length > (ignorePlaceholder ? 2 : 1);
  }

  private get showTooltipOverlay(): (() => JSX.Element) | undefined {
    return this.showTooltip ? this.answers : undefined;
  }

  private get isDirty(): boolean {
    return this.props.chosenIndefiniteForm !== undefined || !!this.props.example;
  }

  private get isEmpty(): boolean {
    const {value, chosenIndefiniteForm, example} = this.props;
    return example ? false : !chosenIndefiniteForm || value === '';
  }

  private get isCorrect(): boolean {
    const {
      answers = [],
      dragCaseSensitive,
      example,
      gapCaseSensitive,
      indefiniteForm,
      chosenIndefiniteForm,
      ignorePlaceholder
    } = this.props;
    const {value} = this;

    if (example) return true;
    if (indefiniteForm !== chosenIndefiniteForm && !ignorePlaceholder) return false;

    const sensetive = dragCaseSensitive || gapCaseSensitive;
    const mutator = sensetive ? this.mutator4 : this.mutator1;

    return answers.slice(1).map(mutator).includes(mutator(value));
  }

  private get printValue(): string {
    const {example, gapCaseSensitive, startOfSentence} = this.props;
    if (example && gapCaseSensitive) return this.value;
    if (this.isEmpty) return '\uFEFF';
    if (startOfSentence) return capitalize(this.value);
    return this.value;
  }

  private get value(): string {
    const val = this.props.value || this.props.chosenIndefiniteForm || '';
    return this.props.value !== ''
      ? pipeline(val, trim, removeHidden, collapseSpaces)
      : this.props.value;
  }

  private answers = () => {
    const {value} = this;
    const {answers = [], startOfSentence, chosenIndefiniteForm, ignorePlaceholder} = this.props;

    return (
      <ul className={classNames('x-gap-answers-hint', 'dnd', {single: answers.length === 2})}>
        {!ignorePlaceholder && chosenIndefiniteForm && (
          <span className="placeholder">{chosenIndefiniteForm}</span>
        )}

        {answers.slice(1).map((a: Answer, key: number) => {
          return (
            <li
              key={key}
              className={classNames('correct', {
                answer: this.mutator1(answers[key + 1]) === this.mutator1(value || ''),
                correct: true
              })}
            >
              {startOfSentence ? this.mutator3(a || '') : this.mutator2(a || '')}
            </li>
          );
        })}
      </ul>
    );
  };

  private onVisibleChange = (visible: boolean = false) => this.setState({visible});
}

export default Checked;
