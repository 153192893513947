import {type Plugin} from '@englex/slate-react';
import React, {memo, useCallback, useMemo, useLayoutEffect, useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

import {WidgetType, type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type StyledListProperties} from 'store/exercise/player/widgets/List/StyledList/interface';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import StyledListItem from 'components/Slate/SlateEditor/plugins/button/List/StylesList/StyledListItem';
import {StyledListPlugin} from 'components/Slate/SlateEditor/plugins/button/List/StylesList/StyledList';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import {selectItem} from 'store/exercise/player/widgets/List/actions';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import {BlockFloat} from 'components/Slate/interface';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';

import './StyledList.scss';

type Props = WidgetTypeComponentProps<StyledListProperties>;

const plugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new Image({defaultFloat: BlockFloat.LEFT}),
  new ImageButton(),
  new SelectionPointer(),
  new FontColorToolbar()
];

const HEIGHT_OF_ONE_LINE_CONTAINER = 62;

export const StyledList: React.FC<Props> = memo(props => {
  const {widget, preview, closed} = props;

  const {id, content, styleName, type, withNumbering, twoColumns} = widget;

  const getWidgetProps = () => props;
  const dispatch = useDispatch();

  const [isOneLine, setIsOneLine] = useState(false);
  const containerRef = useRef<HTMLOListElement | null>(null);

  const onSelectItem = useCallback(
    (itemId: string) => {
      dispatch(selectItem(id, itemId, preview));
    },
    [dispatch, id, preview]
  );

  const slatePlugins = useMemo(() => {
    const currentPlugins = [...plugins];
    currentPlugins.push(
      new StyledListPlugin({
        styleName,
        pluginType: type,
        containerRef: containerRef
      })
    );
    currentPlugins.push(
      new StyledListItem({
        isPlayer: true,
        pluginType: type,
        styleName,
        withNumbering: withNumbering,
        twoColumns,
        selectItem: onSelectItem
      })
    );
    return currentPlugins;
  }, [onSelectItem, styleName, twoColumns, type, withNumbering]);

  useLayoutEffect(() => {
    if (type === WidgetType.HORIZONTAL_STYLED_LIST) {
      const containerHeight = containerRef.current?.offsetHeight || 0;
      setIsOneLine(containerHeight <= HEIGHT_OF_ONE_LINE_CONTAINER);
    }
  }, [id, content, styleName, withNumbering, type]);

  return (
    <div
      className={classNames('styled-list', {
        closed,
        'is-one-line': isOneLine,
        'two-columns': twoColumns
      })}
    >
      <SlatePlayer
        value={content}
        plugins={slatePlugins}
        getWidgetProps={getWidgetProps}
        trimEmptyTrailingParagraphs={true}
      />
    </div>
  );
});
