import React, {type FC, memo, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';
import {type List, OrderedMap} from 'immutable';

import {type Role} from 'store/interface';
import DragHandle from 'components/DragHandle/DragHandle';
import {GapFillType} from 'components/Slate/interface';
import {FlexHandle} from 'components/DragHandle/FlexHandle';
import {
  type ChoicesMap,
  type DNDChoice,
  type WidgetTypeComponentProps
} from 'store/exercise/player/interface';
import {type DndContextShape, useDndContext} from 'components/XPlayer/contexts/dndContext';
import {
  type MatchingNoCategoriesProperties,
  type MatchingProperties
} from 'store/exercise/player/widgets/Matching/interface';
import {answerDropped, matchingAnswerReturn} from 'store/exercise/player/widgets/Matching/actions';
import {usePrevious} from 'hooks/usePrevious';

import Choice from '../../GapFill/component/Pool/Choice';
import GapChecked from '../../GapFill/component/GapChecked';
import CheckedChoice from './CheckedChoice';
import {PointerElementListener} from '../../../../Pointer/element/PointerElementListener';
import {DndTypes} from '../../../../dnd/interface';

interface OwnProps {
  preview?: boolean;
  questionId: string;
  exampleChoices?: ChoicesMap;
  flexHandle?: true;
  getWidgetProps: () => WidgetTypeComponentProps<
    MatchingProperties | MatchingNoCategoriesProperties
  >;
}

interface MappedProps {
  selectedChoices: ChoicesMap;
  missingChoices?: List<DNDChoice>;
  answers?: List<string>;
  isFreeChoice: boolean;
  isCorrect?: boolean;
  role: Role;
  closed?: boolean;
  widget: MatchingProperties | MatchingNoCategoriesProperties;
  dirty: boolean;
}

type Props = OwnProps & MappedProps & DndContextShape;

const DropTarget: FC<Props> = memo(
  ({
    isFreeChoice,
    isCorrect,
    closed,
    selectedChoices,
    exampleChoices,
    dirty,
    selectedCardId,
    flexHandle,
    role,
    missingChoices,
    preview,
    questionId,
    widget,
    selectCard
  }) => {
    const dispatch = useDispatch();

    const [isSelectionSource, setIsSelectionSource] = useState<boolean>(false);

    const prevSelectedCardId = usePrevious(selectedCardId);

    const intl = useIntl();

    const [{isOver, canDrop}, collectDropTarget] = useDrop({
      accept: DndTypes.X_GAP,
      canDrop({widgetId, choiceId}) {
        return widgetId === widget.id && !selectedChoices.keySeq().includes(choiceId);
      },
      drop({choiceId}, monitor: DropTargetMonitor) {
        if (monitor.didDrop()) {
          return;
        }
        onAnswer(choiceId);
        return {
          questionId: questionId
        };
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });

    useEffect(() => {
      if (!selectedCardId && isSelectionSource) {
        setIsSelectionSource(false);
      }
    }, [isSelectionSource, prevSelectedCardId, selectedCardId]);

    const isStudent = role === 'student';
    const empty = !exampleChoices && !selectedChoices.keySeq().size;
    const clickable = selectedCardId && !isSelectionSource && !exampleChoices;
    const className = classNames('matching-drop-target', {
      clickable,
      'is-over': isOver || clickable,
      'can-drop': canDrop || clickable,
      empty,
      flexHandle,
      dirty: !isStudent && dirty,
      correct: isCorrect && !isFreeChoice,
      incorrect: ((closed && !isCorrect) || (!isStudent && dirty && !isCorrect)) && !isFreeChoice
    });

    const clearAnswer = useCallback(
      (choiceId: string) => {
        dispatch(matchingAnswerReturn(widget.id, choiceId, preview));
      },
      [dispatch, preview, widget]
    );

    const onAnswer = (choiceId: string) => {
      dispatch(answerDropped(widget.id, questionId, choiceId, preview));
    };

    const onTargetPoolClick = () => {
      if (selectedCardId) {
        onAnswer(selectedCardId);
        selectCard();
      }
    };

    const onSelectCard = useCallback(
      (id?: string, e?: React.MouseEvent<HTMLDivElement>) => {
        if (selectedCardId === id && id) {
          e && e.stopPropagation();
          clearAnswer(id);
          selectCard();
          return;
        }
        setIsSelectionSource(true);
        selectCard(id, e);
      },
      [clearAnswer, selectCard, selectedCardId]
    );

    const renderSelectedChoice = (choiceId: string) => {
      const value = selectedChoices.get(choiceId).value;
      const selected =
        !!selectedCardId &&
        selectedChoices
          .keySeq()
          .filter(key => key === choiceId)
          .first() === selectedCardId;
      return (
        <PointerElementListener preview={preview} key={choiceId}>
          <Choice
            id={`${choiceId}-checked`}
            choiceId={choiceId}
            widgetId={widget.id}
            answer={value}
            disabled={false}
            isOver={false}
            selected={selected}
            onSelect={onSelectCard}
            flexHandle={flexHandle}
          />
        </PointerElementListener>
      );
    };

    const renderMissingChoice = (choice: DNDChoice, index: number) => {
      return (
        <GapChecked
          gap={GapFillType.DND}
          value={choice.value}
          dirty={true}
          closed={true}
          missing={true}
          key={index}
          neverChangeCursor={true}
          flexHandle={flexHandle}
        >
          {flexHandle ? <FlexHandle /> : <DragHandle />}
        </GapChecked>
      );
    };

    const renderCheckedChoice = (choiceId: string) => {
      return (
        <PointerElementListener preview={preview} key={choiceId}>
          <CheckedChoice
            widget={widget}
            questionId={questionId}
            value={selectedChoices.get(choiceId).value}
            id={`${choiceId}-checked`}
            choiceId={choiceId}
            flexHandle={flexHandle}
          >
            {flexHandle ? <FlexHandle /> : <DragHandle />}
          </CheckedChoice>
        </PointerElementListener>
      );
    };

    const renderExampleChoice = (choiceId: string) => {
      return (
        <PointerElementListener preview={Boolean(preview)} key={choiceId}>
          <GapChecked
            id={`${choiceId}-example`}
            gap={GapFillType.DND}
            value={exampleChoices!.get(choiceId).value}
            dirty={true}
            correct={true}
            closed={true}
            example={true}
            key={choiceId}
            neverChangeCursor={true}
            flexHandle={flexHandle}
          >
            {flexHandle ? <FlexHandle disabled={true} /> : <DragHandle disabled={true} />}
          </GapChecked>
        </PointerElementListener>
      );
    };

    const renderContent = () => {
      if (exampleChoices) {
        const exampleChoicesKeys = exampleChoices.keySeq();
        return exampleChoicesKeys.map(renderExampleChoice);
      }

      const selectedChoicesKeys = selectedChoices.keySeq();
      if (!isStudent || closed) {
        return (
          <>
            {selectedChoicesKeys.map(renderCheckedChoice)}
            {missingChoices ? missingChoices.map(renderMissingChoice) : null}
          </>
        );
      }

      if (selectedChoicesKeys.size) {
        return selectedChoicesKeys.map(renderSelectedChoice);
      }

      return (
        <div className="placeholder">
          {intl.formatMessage({id: 'XPlayerXWidget.Matching.DropTargetPlaceholder'})}
        </div>
      );
    };

    return exampleChoices ? (
      <div className={className}>{renderContent()}</div>
    ) : (
      collectDropTarget(
        <div className={className} onClick={onTargetPoolClick}>
          {renderContent()}
        </div>
      )
    );
  }
);

const DropTargetPropsWrapper: FC<OwnProps & DndContextShape> = props => {
  const {questionId, exampleChoices, getWidgetProps} = props;

  const {widget, closed, role} = getWidgetProps();

  const thisQuestionValues: List<string> | undefined = widget.getMatchingValue(questionId);

  const dirty = !!thisQuestionValues;

  const selectedChoices: ChoicesMap =
    thisQuestionValues && !exampleChoices
      ? OrderedMap(thisQuestionValues.map(choiceId => [choiceId, widget.choices!.get(choiceId!)]))
      : OrderedMap();

  const missingChoices =
    widget.answers && widget.answers.size && !exampleChoices
      ? widget.answers
          .get(questionId)
          .filter(choiceId => {
            const choice = widget.choices!.get(choiceId!);
            return !selectedChoices.find(dndChoice => dndChoice?.value === choice.value);
          })
          .map(choiceId => widget.choices!.get(choiceId!))
          .toList()
      : undefined;

  const isFreeChoice = !widget.answers?.size;

  const shouldCheckIfCorrect = !!(
    (closed || role !== 'student') &&
    (exampleChoices || thisQuestionValues)
  );
  const isCorrect =
    !isFreeChoice &&
    shouldCheckIfCorrect &&
    !missingChoices?.size &&
    selectedChoices.every(selectedChoice => {
      const hasAnswerWithThisValue = widget
        .answers!.get(questionId)
        .find((choiceId: string) => widget.choices!.get(choiceId).value === selectedChoice!.value);
      const isNotDuplicateValue =
        selectedChoices.filter(choice => choice!.value === selectedChoice!.value).size === 1;
      return Boolean(hasAnswerWithThisValue && isNotDuplicateValue);
    });
  const mappedProps: MappedProps = {
    selectedChoices: selectedChoices,
    answers: !exampleChoices ? widget.answers?.get(questionId) : undefined,
    missingChoices,
    isFreeChoice,
    isCorrect,
    closed,
    role,
    widget,
    dirty
  };
  return <DropTarget {...props} {...mappedProps} />;
};

const ConnectedDropTargetWithContext: FC<OwnProps> = props => {
  const {selectedCardId, selectCard} = useDndContext();

  const onSelectCard = useCallback(
    (id?: string | undefined, e?: React.MouseEvent<HTMLDivElement>) => {
      e && !selectedCardId && e.stopPropagation();
      selectCard(id);
    },
    [selectCard, selectedCardId]
  );
  return (
    <DropTargetPropsWrapper {...props} selectedCardId={selectedCardId} selectCard={onSelectCard} />
  );
};

export default ConnectedDropTargetWithContext;
