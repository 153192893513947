import React, {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {addCategory} from 'store/exercise/player/widgets/Matching/actions';
import {
  type MatchingNoCategoriesProperties,
  type QuestionBlockMap
} from 'store/exercise/player/widgets/Matching/interface';

import {MatchingQuestion} from '../MatchingQuestion/MatchingQuestion';

import './MatchingNoCategories.scss';

interface Props {
  getWidgetProps: () => WidgetTypeComponentProps<MatchingNoCategoriesProperties>;
}

export const MatchingNoCategories: FC<Props> = ({getWidgetProps}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    widget,
    widget: {values, choices},
    preview,
    role,
    closed
  } = getWidgetProps();

  const showAddBtn = role === 'student' && !closed;
  const readOnly = role === 'teacher';
  const disableAddBtn = choices && values && choices?.size <= values?.size;

  const onAddCategory = useCallback(() => {
    dispatch(addCategory(widget.id, preview));
  }, [dispatch, preview, widget.id]);

  return (
    <div className="matching-no-categories">
      <ol className="list list-ol list-ol-0-0">
        {!!widget.values &&
          widget.values.map((questionBlock: QuestionBlockMap) => {
            return (
              <li className="matching-question" key={questionBlock.get('id')}>
                <MatchingQuestion
                  id={questionBlock.get('id')}
                  text={questionBlock.get('category')}
                  preview={preview}
                  readOnly={readOnly}
                  getWidgetProps={getWidgetProps}
                />
              </li>
            );
          })}
      </ol>
      {showAddBtn && (
        <Button
          className={'add-category-btn'}
          bsSize="sm"
          onClick={onAddCategory}
          disabled={disableAddBtn}
        >
          {intl.formatMessage({id: 'XEditorXWidget.Vocabulary.AddCategory'})}
        </Button>
      )}
    </div>
  );
};
