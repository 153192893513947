import React from 'react';
import classNames from 'classnames';

import {
  type WidgetComponentProps,
  WidgetType,
  type WidgetTypeComponentProps
} from 'store/exercise/player/interface';

import Task from './Task';
import {isDocumentNotEmpty} from '../../Slate/utils/documentNotEmpty';
import ExerciseActions from '../components/ExerciseActions/ExerciseActions';
import xplayerWidgetTypeComponentFactory from '../components/xplayerWidgetTypeComponentFactory';
import {ShowGrammarRules} from './ShowGrammarRules';

class DefaultWidgetComponent extends React.PureComponent<WidgetComponentProps> {
  private get taskIsEmpty(): boolean {
    return !isDocumentNotEmpty(this.props.widget.task.document);
  }

  private getWidgetProps = () => this.props;

  public render() {
    const {
      widget,
      preview,
      role,
      closed,
      isExtra,
      exerciseId,
      homeworkId,
      showPreviewExerciseNumber,
      isHeadWidget,
      isHomeworkPlayer,
      isGrammarPlayer,
      isPointerAvailable,
      isModal
    } = this.props;

    const {type, task, isAvailableSelection} = widget;
    const Component: React.ElementType<WidgetTypeComponentProps> =
      xplayerWidgetTypeComponentFactory(type);
    const className = classNames('x-widget', type, {extra: isExtra});
    const shouldRenderExerciseActions = !isHomeworkPlayer && isHeadWidget;
    return (
      <div id={`x-widget-${widget.id}`} className={className}>
        {!this.taskIsEmpty && widget.type !== WidgetType.UNKNOWN && (
          <Task
            exerciseId={exerciseId}
            preview={preview}
            value={task}
            isHomeworkPlayer={isHomeworkPlayer}
            isAvailableSelection={isAvailableSelection}
            getWidgetProps={this.getWidgetProps}
          >
            {shouldRenderExerciseActions && (
              <ExerciseActions
                preview={preview}
                role={role}
                exerciseId={exerciseId}
                isMain={!isExtra}
                showPreviewHomeworkControl={showPreviewExerciseNumber}
              />
            )}
          </Task>
        )}
        <ShowGrammarRules show={isHeadWidget && !isGrammarPlayer} exerciseId={exerciseId} />
        <Component
          exerciseId={exerciseId}
          widget={widget}
          preview={preview}
          role={role}
          closed={closed}
          homeworkId={homeworkId}
          isHomework={isHomeworkPlayer}
          isGrammarPlayer={isGrammarPlayer}
          isPointerAvailable={isPointerAvailable}
          isModal={isModal}
        />
      </div>
    );
  }
}

export default DefaultWidgetComponent;
