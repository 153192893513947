import React from 'react';
import ReactDOM from 'react-dom';
import Collapse from 'react-bootstrap/lib/Collapse';
import classNames from 'classnames';
import {type Plugin} from '@englex/slate-react';

import Bold from 'components/Slate/plugins/renderers/Bold';
import Italic from 'components/Slate/plugins/renderers/Italic';
import Underline from 'components/Slate/plugins/renderers/Underline';
import StrikeThrough from 'components/Slate/plugins/renderers/StrikeThrough';
import TextAlignment from 'components/Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from 'components/Slate/plugins/renderers/Link/Link';
import Lists from 'components/Slate/plugins/renderers/List/Lists';
import Image from 'components/Slate/plugins/renderers/Image/Image';
import IconPlugin from 'components/Slate/plugins/renderers/Icon';
import Highlight from 'components/Slate/plugins/renderers/Highlight';
import Color from 'components/Slate/plugins/renderers/Color';
import Dialog from 'components/Slate/plugins/renderers/Table/Dialog/Dialog';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';
import FontSize from 'components/Slate/plugins/renderers/FontSize';
import Icon from 'components/Icon';
import {type WidgetComponentProps} from 'store/exercise/player/interface';
import {type NoteProperties} from 'store/exercise/player/widgets/Note/interface';

import {noteIconSet} from './noteDataByType';
import ExerciseActions from '../../components/ExerciseActions/ExerciseActions';
import ExerciseLabelComponent from '../../components/ExerciseLabelComponent';
import {ShowGrammarRules} from '../ShowGrammarRules';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

import './Note.scss';

interface State {
  expanded: boolean;
}

class Note extends React.PureComponent<WidgetComponentProps<NoteProperties>, State> {
  private static animationTime = 350;

  private readonly contentPlugins: Plugin[] = [
    new Bold(),
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new FontSize(),
    new TextAlignment(),
    new Link(),
    new Lists(),
    new Color(),
    new Highlight(),
    new Image({isModal: this.props.isModal}),
    new IconPlugin(),
    new Dialog()
  ];

  public static titlePlugins: Plugin[] = [
    new Italic(),
    new Underline(),
    new StrikeThrough(),
    new Highlight(),
    new IconPlugin({iconset: noteIconSet}),
    new SelectionPointer()
  ];

  private get isCollapsible() {
    return this.props.widget.isCollapsible && !this.props.isWidgetPreviewPlayer;
  }

  private get isCollapsedDefault(): boolean {
    const isCollapsed = this.props.widget.isCollapsed;
    if (this.props.isWidgetPreviewPlayer) return false;

    return !!isCollapsed;
  }

  constructor(props: WidgetComponentProps<NoteProperties>, context: {}) {
    super(props, context);
    this.state = {expanded: !this.isCollapsedDefault};

    this.contentPlugins = this.contentPlugins.concat(
      new SelectionPointer({
        onSelected: this.onSelected
      })
    );
  }

  public render() {
    const {
      widget: {content, noteLabel, noteType, id},
      isExtra,
      preview,
      role,
      exerciseId,
      isHeadWidget,
      isHomeworkPlayer,
      showPreviewExerciseNumber,
      isGrammarPlayer
    } = this.props;
    const {expanded} = this.state;
    const className = classNames(`x-widget Note ${noteType}`, {
      'show-content': expanded
    });
    const shouldRenderExerciseActions = !isHomeworkPlayer && isHeadWidget;
    return (
      <>
        {(isExtra || shouldRenderExerciseActions) && (
          <div className={classNames('x-exercise-controls note-is-first', {'is-extra': isExtra})}>
            {!!noteLabel && (
              <ExerciseLabelComponent
                getTooltipContainer={() => ReactDOM.findDOMNode(this) as HTMLElement}
                exerciseId={exerciseId}
                preview={preview}
              >
                {noteLabel}
              </ExerciseLabelComponent>
            )}
            {shouldRenderExerciseActions && (
              <ExerciseActions
                preview={preview}
                role={role}
                exerciseId={exerciseId}
                showPreviewHomeworkControl={showPreviewExerciseNumber}
                isMain={!isExtra}
              />
            )}
          </div>
        )}
        <ShowGrammarRules show={isHeadWidget && !isGrammarPlayer} exerciseId={exerciseId} />
        <div id={`x-widget-${id}`} className={className}>
          <div
            className={classNames('title', {collapsible: this.isCollapsible})}
            onClick={(this.isCollapsible && this.toggleCollapsed) || undefined}
          >
            <SlatePlayer
              value={this.props.widget.task}
              plugins={Note.titlePlugins}
              getWidgetProps={this.getWidgetProps}
            />
            {this.isCollapsible ? (
              <Icon name="angle-down" size="lg" className="expand-arrow" />
            ) : null}
          </div>

          <Collapse in={expanded}>
            <div className="note-content">
              <div className="sizer">
                <SlatePlayer
                  className={classNames('x-content', {sm: this.isCollapsible})}
                  value={content}
                  plugins={this.contentPlugins}
                  trimEmptyTrailingParagraphs={true}
                  getWidgetProps={this.getWidgetProps}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </>
    );
  }

  private onSelected = (showSelection: Function) => {
    if (this.state.expanded) {
      return showSelection();
    }

    this.setState(
      state => ({...state, expanded: true}),
      () => setTimeout(showSelection, Note.animationTime)
    );
  };

  private toggleCollapsed = () => {
    const selection = window.getSelection();

    if (selection?.toString().length) return;

    this.setState({expanded: !this.state.expanded});
  };

  private getWidgetProps = () => this.props;
}

export default Note;
