import {type WidgetAction} from '../../interface';
import {xplayerPrefix} from '../../actionTypes';
import {
  MATCHING_CHANGED_CATEGORY,
  MATCHING_ANSWER_DROPPED,
  MATCHING_ANSWER_RETURNED,
  MATCHING_ADD_CATEGORY,
  MATCHING_DELETE_CATEGORY
} from './actionTypes';
import {xpreviewPrefix} from '../../preview/actionTypes';

export interface MatchingAnswerAction extends WidgetAction {
  widgetId: string;
  questionId: string;
  choiceId: string;
}

export interface MatchingAnswerReturnAction extends WidgetAction {
  widgetId: string;
  choiceId: string;
}

export interface MatchingCategoryChangedAction extends WidgetAction {
  widgetId: string;
  id: string;
  category: string;
}
interface MatchingToggleCategoryAction extends WidgetAction {
  id: string;
}

export const answerDropped = (
  widgetId: string,
  questionId: string,
  choiceId: string,
  preview?: boolean
): MatchingAnswerAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MATCHING_ANSWER_DROPPED}`,
    widgetId,
    questionId,
    choiceId,
    preview
  };
};

export const matchingAnswerReturn = (widgetId: string, choiceId: string, preview?: boolean) => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MATCHING_ANSWER_RETURNED}`,
    widgetId,
    choiceId,
    preview
  };
};

export const categoryChanged = (
  widgetId: string,
  id: string,
  category: string,
  preview?: boolean
): MatchingCategoryChangedAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MATCHING_CHANGED_CATEGORY}`,
    widgetId,
    id,
    category,
    preview
  };
};

export const addCategory = (widgetId: string, preview?: boolean): WidgetAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MATCHING_ADD_CATEGORY}`,
    widgetId,
    preview
  };
};

export const deleteCategory = (
  widgetId: string,
  id: string,
  preview?: boolean
): MatchingToggleCategoryAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${MATCHING_DELETE_CATEGORY}`,
    widgetId,
    id,
    preview
  };
};
