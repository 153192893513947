import React from 'react';
import {type Value} from '@englex/slate';

import {type WidgetComponentProps} from 'store/exercise/player/interface';

import SlatePlayer from '../../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../../Slate/plugins/renderers/Bold';
import Italic from '../../../Slate/plugins/renderers/Italic';
import Underline from '../../../Slate/plugins/renderers/Underline';
import StrikeThrough from '../../../Slate/plugins/renderers/StrikeThrough';
import FontSize from '../../../Slate/plugins/renderers/FontSize';
import Color from '../../../Slate/plugins/renderers/Color';
import Highlight from '../../../Slate/plugins/renderers/Highlight';
import TextAlignment from '../../../Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from '../../../Slate/plugins/renderers/Link/Link';
import Lists from '../../../Slate/plugins/renderers/List/Lists';
import Image from '../../../Slate/plugins/renderers/Image/Image';
import Icon from '../../../Slate/plugins/renderers/Icon';
import Dialog from '../../../Slate/plugins/renderers/Table/Dialog/Dialog';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';

interface Props {
  title: Value;
  text: Value;
  getWidgetProps: () => WidgetComponentProps;
}

const TitlePlugins = [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontSize(),
  new Color(),
  new Highlight(),
  new TextAlignment(),
  new Link(),
  new SelectionPointer()
];

const TextPlugins = [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontSize(),
  new Color(),
  new Highlight(),
  new TextAlignment(),
  new Link(),
  new Lists(),
  new Image(),
  new Icon(),
  new Dialog(),
  new SelectionPointer()
];

export function QuizResultBlock({title, text, getWidgetProps}: Props) {
  return (
    <div className="quiz-result__block">
      <SlatePlayer
        className="title"
        value={title}
        plugins={TitlePlugins}
        trimEmptyTrailingParagraphs={true}
        getWidgetProps={getWidgetProps}
      />

      <div className="quiz-result__divider" />

      <SlatePlayer
        value={text}
        plugins={TextPlugins}
        trimEmptyTrailingParagraphs={true}
        getWidgetProps={getWidgetProps}
      />
    </div>
  );
}
