import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Next} from '@englex/slate';

import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';
import PlayerQuestionComponent from '../../../../../XPlayer/widgets/List/MultipleChoice/components/MCQuestionComponent';

export default class MCQuestionRenderer implements Plugin {
  constructor(
    private readonly horizontal?: true,
    private readonly preview?: boolean
  ) {}

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ITEM)) {
      return next();
    }

    return (
      <PlayerQuestionComponent
        attributes={attributes}
        questionId={node.data.get('id')}
        editor={editor}
        horizontal={this.horizontal}
        preview={this.preview}
      >
        {children}
      </PlayerQuestionComponent>
    );
  };
}
