import {type Editor, Text} from '@englex/slate';

import {SlateInline} from '../../../interface';

export const insertLabel = (editor: Editor, className: string, text: string) =>
  editor
    .insertInline({
      type: SlateInline.LABEL,
      data: {className},
      nodes: [Text.create(text)]
    })
    .moveToStartOfNextText();
