import React, {type FC} from 'react';

import {PointerElementListener as Listener} from 'components/Pointer/element/PointerElementListener';

type Tag = 'div' | 'span';

export interface PointerListenerProps {
  id?: string;
  tag?: Tag;
  preview?: boolean;
}

export const PointerListener: FC<PointerListenerProps> = ({children, id, tag, preview}) => {
  const Tag = tag || 'span';
  return (
    <Listener preview={preview}>
      <Tag id={id} className="pointer-listener--inline">
        {children}
      </Tag>
    </Listener>
  );
};
