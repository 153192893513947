import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';
import {type Map} from 'immutable';

import {type Role} from 'store/interface';
import {type ImageMatchingProperties} from 'store/exercise/player/widgets/ImageMatching/interface';
import {type ChoicesMap} from 'store/exercise/player/interface';
import {clearValue} from 'store/exercise/player/widgets/actions';
import {DndTypes} from 'components/dnd/interface';

import DragGap from '../../components/DragGap';
import {type DndContextShape, useDndContext} from '../../contexts/dndContext';
import Choice from '../GapFill/component/Pool/Choice';
import {type InputChoiceDragObject} from '../GapFill/component/dnd-input/DNDInputChoice';
import {PointerElementListener} from '../../../Pointer/element/PointerElementListener';

interface OwnProps {
  preview?: boolean;
  widget: ImageMatchingProperties;
  role: Role;
  closed?: boolean;
  inPortal?: boolean;
  choiceIsUsed: (choiceId?: string) => boolean;
}

interface StateProps {
  values?: Map<string, string>;
}

type Props = OwnProps & StateProps & DndContextShape;

const Pool: FC<Props> = ({widget, selectedCardId, inPortal, selectCard, preview, closed, role}) => {
  const dispatch = useDispatch();

  const isTeacher = role !== 'student';

  const currentValues =
    widget.preFillValues && !widget.values ? widget.preFillValues : widget.values;
  const values = currentValues ? currentValues : undefined;

  const clearGap = (choiceId: string) => {
    dispatch(clearValue(widget.id, choiceId, preview));
  };

  const [{isOver, canDrop, dropChoiceId}, collectDropTarget] = useDrop({
    accept: DndTypes.X_GAP,
    canDrop({widgetId}) {
      return widgetId === widget.id && !!values;
    },
    drop({choiceId}, monitor: DropTargetMonitor) {
      clearGap(choiceId);
      return {
        choiceId: choiceId
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropChoiceId: monitor.getItem<InputChoiceDragObject>()
        ? monitor.getItem<InputChoiceDragObject>().choiceId
        : undefined
    })
  });

  const choiceIsUsed = (choiceId?: string) => {
    return choiceId && values ? values.includes(choiceId) : false;
  };

  const returnSelectedCardToPool = () => {
    if (selectedCardId) {
      selectCard();
      clearGap(selectedCardId);
    }
  };

  const renderChoice = (choiceId: string) => {
    const isUsed = choiceIsUsed(choiceId);
    const value = widget.choices!.get(choiceId).value;

    if (isTeacher || closed) {
      return (
        <PointerElementListener preview={preview} key={choiceId}>
          <DragGap
            id={choiceId}
            className={classNames('x-dnd-card static', {disabled: isUsed})}
            answer={value}
            flexHandle={true}
          />
        </PointerElementListener>
      );
    }

    return (
      <PointerElementListener preview={preview} key={choiceId}>
        <Choice
          id={choiceId}
          choiceId={choiceId}
          selectedCardId={selectedCardId}
          widgetId={widget.id}
          answer={value}
          disabled={isUsed}
          isOver={
            (isOver && canDrop && dropChoiceId === choiceId) ||
            (selectedCardId === choiceId && isUsed)
          }
          selected={choiceId === selectedCardId && !isUsed}
          onSelect={selectCard}
          flexHandle={true}
        />
      </PointerElementListener>
    );
  };

  const renderPool = (choices: ChoicesMap) => {
    return (
      <div
        className={classNames('x-gap-drag-pool', {
          'is-over': choiceIsUsed(selectedCardId) || (isOver && canDrop),
          'in-portal': inPortal
        })}
        onClick={returnSelectedCardToPool}
      >
        {choices.keySeq().map(renderChoice)}
      </div>
    );
  };

  if (!widget.choices) {
    return null;
  }

  const pool = renderPool(widget.choices);

  return !isTeacher && values ? collectDropTarget(pool) : pool;
};

const ConnectedPoolWithContext: FC<OwnProps> = props => {
  const {selectCard, selectedCardId} = useDndContext();

  const onSelectCard = useCallback(
    (id?: string | undefined, e?: React.MouseEvent<HTMLDivElement>) => {
      e && !selectedCardId && e.stopPropagation();
      selectCard(id);
    },
    [selectCard, selectedCardId]
  );
  return <Pool {...props} selectCard={onSelectCard} selectedCardId={selectedCardId} />;
};

export default ConnectedPoolWithContext;
