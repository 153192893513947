import React, {useRef} from 'react';
import classNames from 'classnames';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type ImageLabelingCardJSON,
  type ImageLabelingProperties
} from 'store/exercise/player/widgets/ImageLabeling/interface';

import Card from './Card';

import './ImageLabeling.scss';

type Props = WidgetTypeComponentProps<ImageLabelingProperties>;

const ImageLabeling: React.FC<Props> = ({widget, role, closed, preview}) => {
  const ref = useRef(null);

  return (
    <div className="image-labeling-widget">
      <div
        ref={ref}
        className={classNames('il-cards', {
          teacher: role === 'teacher'
        })}
      >
        {widget.cards.map((card: ImageLabelingCardJSON) => (
          <Card
            key={card.id}
            card={card}
            widget={widget}
            role={role}
            closed={closed}
            preview={preview}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageLabeling;
