import React from 'react';
import classNames from 'classnames';

import DragHandle from 'components/DragHandle/DragHandle';

import XPlayerTooltip from '../../components/XPlayerTooltip';
import GapChecked from '../GapFill/component/GapChecked';
import {FlexHandle} from '../../../DragHandle/FlexHandle';
import {
  PointerElementListener,
  type PointerListenerRef
} from '../../../Pointer/element/PointerElementListener';

interface Props {
  id: string;
  answer?: string;
  className?: string;
  closed?: boolean;
  value?: string | null;
  flexHandle?: true;
  relatedElement?: string;
  pointerListenerRef?: PointerListenerRef;
  preview?: boolean;
}

interface State {
  visible: boolean;
}

class Checked extends React.PureComponent<Props, State> {
  public state: State = {visible: false};

  public render() {
    const {
      showTooltip,
      props: {id, className, closed, flexHandle, relatedElement, pointerListenerRef, preview},
      state: {visible}
    } = this;

    const gap = (
      <GapChecked
        className={className}
        gap="image-matching"
        value={this.printValue}
        dirty={this.isDirty}
        correct={this.isCorrect}
        empty={this.isEmpty}
        closed={closed}
        dot={!this.isCorrect}
        flexHandle={true}
      >
        {flexHandle ? <FlexHandle /> : <DragHandle />}
      </GapChecked>
    );

    return (
      <PointerElementListener
        elementId={id}
        preview={preview}
        ref={pointerListenerRef}
        relatedElement={relatedElement}
        tooltipClassName={classNames('x-player-tooltip', {empty: this.isEmpty})}
        overlayClassName="x-player-tooltip-overlay"
        renderTooltipOverlay={this.showTooltipOverlay}
        render={({isAvailable, isTeacher}) => {
          return (isAvailable && isTeacher) || !showTooltip ? (
            React.cloneElement(gap, {id})
          ) : (
            <span id={id} className={classNames('pointer-target', {empty: this.isEmpty})}>
              <XPlayerTooltip
                overlay={this.answers()}
                visible={visible}
                onVisibleChange={this.onVisibleChange}
                empty={this.isEmpty}
                hideArrow={true}
                placement="top"
              >
                {gap}
              </XPlayerTooltip>
            </span>
          );
        }}
      />
    );
  }

  private get showTooltip(): boolean {
    return !this.isCorrect;
  }

  private get showTooltipOverlay(): (() => JSX.Element) | undefined {
    return this.showTooltip ? this.answers : undefined;
  }

  private get isDirty(): boolean {
    return this.props.value !== undefined;
  }

  private get isEmpty(): boolean {
    const {value} = this.props;
    return value === null || !this.isDirty || value!.length === 0;
  }

  private get isCorrect(): boolean {
    const {value, answer} = this.props;
    return this.isDirty && answer !== undefined && value === answer;
  }

  private get printValue(): string {
    return !this.isDirty ? '\uFEFF' : this.props.value!;
  }

  private answers = () => {
    const {answer} = this.props;
    return (
      <ul className={classNames('x-gap-answers-hint', 'image-matching', {single: true})}>
        <li className={classNames('correct', {empty: !!answer && answer.length === 0})}>
          {answer !== undefined ? answer : '(none)'}
        </li>
      </ul>
    );
  };

  private onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible});
  };
}

export default Checked;
