import type React from 'react';
import {Editor, Transforms} from 'slate';

import {type SlatePlugin} from 'components/SlateJS/plugins/withPlugins';

interface Options {
  enabled?: boolean;
}

export const withCapitalizeOnBlur =
  ({enabled = false}: Options = {}): SlatePlugin =>
  editor => {
    if (!enabled) return editor;

    const {editableProps = {}} = editor;
    const onBlur = editableProps.onBlur;

    editableProps.onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      const text = Editor.string(editor, []);
      if (text.length) {
        const capitalChar = text[0].toUpperCase();
        const range = Editor.range(editor, [0, 0], {path: [0, 0], offset: 1});
        Transforms.insertText(editor, capitalChar, {at: range});
      }

      onBlur?.(e);
    };
    return editor;
  };
