import {GapFillType} from '../../../../Slate/interface';
import DropTarget from '../component/dnd/DropTarget';
import GapFill from './GapFill';

class GapFillDND extends GapFill {
  public gap = GapFillType.DND;
  protected Gap = DropTarget;
}

export default GapFillDND;
