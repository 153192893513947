import React, {type FC, useMemo} from 'react';
import {type PStage} from '@englex/paint';
import {useImage} from '@englex/paint-react';

import {SimplePaint} from '../../../Paint/Simple/SimplePaint';

interface Props {
  imgSrc: string;
}
const createStage = (image: HTMLImageElement, devicePixelRatio: number = 1): PStage => {
  const width = image.width / devicePixelRatio;
  const height = image.height / devicePixelRatio;
  return {
    className: 'Stage',
    attrs: {
      width,
      height
    },
    children: [
      {
        className: 'Layer',
        attrs: {},
        children: [
          {
            attrs: {
              image,
              scale: {x: 1 / devicePixelRatio, y: 1 / devicePixelRatio}
            },
            className: 'Image'
          }
        ]
      },
      {
        className: 'Layer',
        attrs: {},
        children: []
      }
    ]
  };
};

export const ExerciseFeedbackShot: FC<Props> = ({imgSrc}) => {
  const [image, status] = useImage(imgSrc);
  const loading = status !== 'loaded';
  const stage = useMemo(() => {
    if (loading || !image) return;
    return createStage(image, window.devicePixelRatio);
  }, [image, loading]);

  if (loading) {
    return null;
  }

  return stage ? <SimplePaint stage={stage} /> : null;
};
