import React from 'react';
import {type Plugin} from '@englex/slate-react';
import {FormattedMessage} from 'react-intl';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type QuizProperties} from 'store/exercise/player/widgets/Quiz/interface';
import type QuizBlockRecord from 'store/exercise/player/widgets/Quiz/QuizBlockRecord';

import SlatePlayer from '../../../Slate/SlatePlayer/SlatePlayer';
import Bold from '../../../Slate/plugins/renderers/Bold';
import Icon from '../../../Slate/plugins/renderers/Icon';
import Highlight from '../../../Slate/plugins/renderers/Highlight';
import Italic from '../../../Slate/plugins/renderers/Italic';
import StrikeThrough from '../../../Slate/plugins/renderers/StrikeThrough';
import Underline from '../../../Slate/plugins/renderers/Underline';
import MCAnswerRenderer from '../../../Slate/plugins/renderers/QuestionsList/MultipleChoice/MCAnswerRenderer';
import QuestionsList from '../../../Slate/plugins/renderers/QuestionsList/QuestionsList';
import MCQuestionRenderer from '../../../Slate/plugins/renderers/QuestionsList/MultipleChoice/MCQuestionRenderer';
import {SelectionPointer} from '../../../Slate/plugins/renderers/Pointer/SelectionPointer';
import {QuizResultBlock} from './QuizResultBlock';
import {QuizPossibleResult} from './QuizPossibleResult';

import './Quiz.scss';

type Props = WidgetTypeComponentProps<QuizProperties>;

class Quiz extends React.PureComponent<Props> {
  private readonly questionsPlugins: Plugin[];

  constructor(props: Props) {
    super(props);

    this.questionsPlugins = [
      new Bold(),
      new Italic(),
      new Underline(),
      new StrikeThrough(),
      new Icon(),
      new Highlight(),
      new MCAnswerRenderer(props.widget.element, props.preview),
      new MCQuestionRenderer(props.widget.horizontal, props.preview),
      new QuestionsList(),
      new SelectionPointer()
    ];
  }

  public render() {
    const {widget} = this.props;
    const {content} = widget;

    return (
      <div className="x-content">
        <SlatePlayer
          value={content}
          plugins={this.questionsPlugins}
          getWidgetProps={this.getWidgetProps}
        />
        {this.isStudent ? this.renderResultForStudent() : this.renderResultForTeacher()}
        {this.renderOtherResults()}
      </div>
    );
  }

  private renderResultForStudent() {
    const {
      widget: {completed, results}
    } = this.props;

    return (
      <div className="quiz-result">
        <div className="quiz-result__header">
          <FormattedMessage
            values={{count: completed ? results.size : 0}}
            id="XPlayerXWidget.Quiz.YourResults"
          />
          :
        </div>
        {completed ? (
          results.map((result: QuizBlockRecord) => (
            <QuizResultBlock
              key={result.id}
              title={result.title}
              text={result.text}
              getWidgetProps={this.getWidgetProps}
            />
          ))
        ) : (
          <div className="quiz-result__content">Answer all questions to see your results</div>
        )}
      </div>
    );
  }

  private renderResultForTeacher() {
    const {
      widget: {completed, results, resultBlocks}
    } = this.props;

    return (
      <div className="quiz-result">
        <div className="quiz-result__header">
          <FormattedMessage
            values={{count: completed ? results.size : 0}}
            id="XPlayerXWidget.Quiz.StudentResults"
          />
          :
        </div>
        {completed ? (
          results.map((result: QuizBlockRecord) => (
            <QuizResultBlock
              key={result.id}
              title={result.title}
              text={result.text}
              getWidgetProps={this.getWidgetProps}
            />
          ))
        ) : (
          <div className="quiz-result__content">
            The result will be shown once the student answers all questions
          </div>
        )}

        {!completed && (
          <div className="quiz-result__possible">
            <div className="quiz-result__header">All possible results:</div>
            {resultBlocks.map((result: QuizBlockRecord) => (
              <QuizPossibleResult
                key={result.id}
                id={result.id}
                title={result.title}
                text={result.text}
                completed={completed}
                getWidgetProps={this.getWidgetProps}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  private renderOtherResults() {
    const {
      widget: {completed, otherResults}
    } = this.props;

    return (
      <div className="quiz-result">
        {Boolean(completed && otherResults.size) && (
          <div className="quiz-result__possible">
            <div className="quiz-result__header">
              <FormattedMessage
                values={{count: otherResults.size}}
                id="XPlayerXWidget.Quiz.OtherPossibleResults"
              />
              :
            </div>
            {otherResults.map((result: QuizBlockRecord) => (
              <QuizPossibleResult
                key={result.id}
                id={result.id}
                title={result.title}
                text={result.text}
                completed={completed}
                getWidgetProps={this.getWidgetProps}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  private get isStudent() {
    return this.props.role === 'student';
  }

  private getWidgetProps = () => this.props;
}

export default Quiz;
