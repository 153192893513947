import React, {Component} from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {type OrderedMap} from 'immutable';
import {type Dispatch} from 'redux-axios-middleware';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import {type AppState} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {
  type XExerciseProperties,
  type XWidgetProperties
} from 'store/exercise/editor/widgets/interface';
import {
  type VocabularyCategoryProperties,
  type VocabularyWordProperties
} from 'store/exercise/editor/widgets/XVocabulary/interface';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';
import {
  type AddWordToDictionaryResponse,
  type DictionaryList
} from 'components/Dictionary/shared/interface';
import {
  type WidgetProperties,
  WidgetType,
  type WidgetTypeComponentProps
} from 'store/exercise/player/interface';
import {
  type VocabularyProperties,
  type WidgetVocabularyInfo
} from 'store/exercise/player/widgets/Vocabulary/interface';
import {
  isVocabularyWordJSON,
  mapEntryIdsToCategories
} from 'store/exercise/player/widgets/Vocabulary/utils';
import {setWidgetVocabularyInfo} from 'store/exercise/player/widgets/actions';
import {
  addWordsToDictionary,
  addWordsToDictionaryList,
  createExerciseDictionaryList,
  loadDictionaryMetaRequest
} from 'store/exercise/player/widgets/Vocabulary/action';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {getExerciseWordIds} from 'components/Dictionary/shared/utils';
import type VocabularyWordRecord from 'store/exercise/player/widgets/Vocabulary/VocabularyWordRecord';

import VocabularyWordsGroup from './VocabularyWordsGroup';
import {VocabularyButton} from './VocabularyButton';
import './VocabularyWidgetComponent.scss';

type Props = WidgetTypeComponentProps<VocabularyProperties> &
  WrappedComponentProps & {
    isInactive: boolean;
    isStudent: boolean;
    exercises: OrderedMap<string, ExerciseProperties>;
    xexercise?: XExerciseProperties;
    addWordsToDictionaryList(
      widgetId: string,
      dictionaryListId: string,
      wordIds: string[]
    ): Promise<AxiosResponseAction<AddWordToDictionaryResponse>>;
    addWordsToDictionary(
      exerciseId: string,
      widgetId: string,
      wordIds: string[]
    ): Promise<AxiosResponseAction<AddWordToDictionaryResponse>>;
    createExerciseDictionaryList(exerciseId: string): Promise<AxiosResponseAction<DictionaryList>>;
    loadDictionaryMetaRequest(
      widgetId: string,
      exerciseId: string
    ): Promise<AxiosResponseAction<WidgetVocabularyInfo>>;
    setWidgetVocabularyInfo(
      widgetId: string,
      widgetVocabularyInfo: WidgetVocabularyInfo,
      preview?: boolean
    ): void;
    refreshWidget(widgetId: string, exerciseId: string): void;
  };

export type Status = 'loading' | 'loaded' | 'error';

interface State {
  status?: Status;
  loadingCategoryId?: string;
}

class VocabularyWidgetComponent extends Component<Props, State> {
  public state: State = {};
  public componentDidMount() {
    this.loadDictionaryMeta();
  }
  private cancellable: CancellablePromise | undefined;
  private isFirstCache: boolean | null = null;

  private get firstIsNotBtn(): boolean {
    const {xexercise, exercises, widget} = this.props;

    if (this.isFirstCache !== null) return this.isFirstCache as boolean;

    if (xexercise?.widgets.size) {
      this.isFirstCache = xexercise?.widgets.find(this.vocabularyIsNotBtn)?.id === widget.id;
    } else {
      this.isFirstCache =
        exercises
          .find((e: ExerciseProperties) => e.id === this.props.exerciseId)
          ?.widgets.find(this.vocabularyIsNotBtn)?.id === widget.id;
    }
    return this.isFirstCache;
  }

  public componentWillUnmount() {
    this.cancellable?.cancel();
  }

  public render() {
    const {dictionaryListId, vocabularyGroupedWords, vocabularyInfoUpdatedTimestamp, listTitle} =
      this.props.widget;
    const {isInactive, isStudent, preview} = this.props;

    const firstIsNotBtn = this.firstIsNotBtn;

    return (
      <div className="vocabulary-listing">
        {listTitle && firstIsNotBtn ? <div className="list-title">{listTitle}</div> : null}
        <div
          className={classNames('buttons', {invisible: preview || !firstIsNotBtn || isInactive})}
        >
          {!preview && !isInactive && firstIsNotBtn && (
            <VocabularyButton
              dictionaryListId={dictionaryListId}
              isStudent={isStudent}
              status={this.state.status}
              createExerciseList={this.createExerciseList}
              updatedTimestamp={vocabularyInfoUpdatedTimestamp}
              exerciseId={this.props.exerciseId}
            />
          )}
        </div>
        {vocabularyGroupedWords.map(this.renderWordGroupOrCategory)}
      </div>
    );
  }

  private renderWordGroupOrCategory = (
    entry: VocabularyWordProperties[] | VocabularyCategoryProperties
  ) => {
    const {addWordsToDictionary, intl, isInactive, isStudent, preview} = this.props;
    if (entry instanceof Array) {
      return (
        <VocabularyWordsGroup
          exerciseId={this.props.exerciseId}
          widgetId={this.props.widget.id}
          addWordsToDictionary={addWordsToDictionary}
          loading={this.state.status === 'loading'}
          isInactive={isInactive}
          isStudent={isStudent}
          key={entry[0].wordId}
          preview={preview}
          wordsGroup={entry}
          getWordNumber={this.getWordNumber}
          role={this.props.role}
        />
      );
    } else {
      return (
        <div className="vocabulary-category" key={entry.categoryId}>
          {entry.name}
          {this.state.status === 'loading' ||
          preview ? null : this.categoryHasWordsToAddToDictionary(entry.categoryId) ? (
            <Button
              className="btn-ico"
              onClick={() => this.addCategoryToDictionary(entry.categoryId)}
              title={intl.formatMessage({id: 'Dictionary.AddEntireCategory'})}
            >
              <Icon name="plus-circle" />
            </Button>
          ) : (
            <Icon name="check" title={intl.formatMessage({id: 'Dictionary.AlreadyInDictionary'})} />
          )}
        </div>
      );
    }
  };

  private getWordNumber = (wordId: string) => {
    const {vocabulary} = this.props.widget;
    const indexInVocabulary = vocabulary.findIndex(
      entry => !!entry && isVocabularyWordJSON(entry) && entry.wordId === wordId
    );
    const partialArr = vocabulary.slice(0, indexInVocabulary);
    return partialArr.filter(entry => !!entry && isVocabularyWordJSON(entry)).size + 1;
  };

  private get entryIdsToCategoriesMap(): {[categoryId: string]: string[]} {
    return mapEntryIdsToCategories(this.props.widget.vocabularyGroupedWords);
  }

  private categoryHasWordsToAddToDictionary = (categoryId: string): boolean =>
    !!this.entryIdsToCategoriesMap?.[categoryId]?.length;

  private exerciseWordIds = (): {[widgetId: string]: string[]} => {
    const {exercises} = this.props;
    const exercise = exercises.find(e => e?.id === this.props.exerciseId);
    return !exercise
      ? {}
      : exercise.widgets
          .filter(this.vocabularyIsNotBtn)
          .reduce((a: {[widgetId: string]: string[]}, w: VocabularyProperties) => {
            const widgetWords = w.vocabulary.reduce((a: string[], v) => {
              const wordId = (v as VocabularyWordRecord).wordId;
              return wordId ? [...a, wordId] : a;
            }, []);
            return {...a, [w.id]: widgetWords};
          }, {});
  };

  private vocabularyIsNotBtn = (widget: XWidgetProperties | WidgetProperties | undefined) => {
    const isNotBtn = !widget?.displayButton;

    return widget?.type === WidgetType.VOCABULARY && isNotBtn;
  };

  private loadDictionaryMeta = () => {
    const {loadDictionaryMetaRequest, preview, setWidgetVocabularyInfo, widget} = this.props;
    if (preview) return;
    this.setState({status: 'loading'});
    this.cancellable = makeCancellable(
      loadDictionaryMetaRequest(widget.id, this.props.exerciseId),
      ({payload: {data}}) => {
        setWidgetVocabularyInfo(widget.id, data, preview);
        this.setState({status: 'loaded'});
      },
      () => this.setState({status: 'error'})
    );
  };

  private addCategoryToDictionary = (categoryId: string) => {
    const {addWordsToDictionary, intl, preview, widget} = this.props;
    const wordIds = this.entryIdsToCategoriesMap?.[categoryId];
    if (preview || !wordIds || this.state.loadingCategoryId) return;
    this.setState({loadingCategoryId: categoryId});
    this.cancellable = makeCancellable(
      addWordsToDictionary(this.props.exerciseId, widget.id, wordIds),
      () => {
        this.setState({loadingCategoryId: undefined});
        toastr.success('', intl.formatMessage({id: 'Dictionary.CategoryAddedSuccess'}));
      },
      () => this.setState({loadingCategoryId: undefined})
    );
  };

  private createExerciseList = () => {
    const {
      addWordsToDictionaryList,
      createExerciseDictionaryList,
      refreshWidget,
      intl,
      widget: {id, dictionaryListId},
      exerciseId,
      exercises
    } = this.props;
    if (dictionaryListId) return;
    const failHandler = () =>
      toastr.error('', intl.formatMessage({id: 'Dictionary.Entry.MassCreationError'}));
    const exerciseWordIds = getExerciseWordIds(exercises, exerciseId);

    if (!exerciseWordIds.hasOwnProperty(id)) return;

    this.cancellable = makeCancellable(
      createExerciseDictionaryList(exerciseId),
      ({payload: {data}}) => {
        this.cancellable = makeCancellable(
          Promise.all(
            Object.keys(exerciseWordIds).map(key =>
              addWordsToDictionaryList(key, data.id, exerciseWordIds[key])
            )
          ),
          () => {
            toastr.success(
              '',
              intl.formatMessage({id: 'Dictionary.WidgetAddedToDictionarySuccess'})
            );
            refreshWidget(id, exerciseId);
          },
          failHandler
        );
      },
      failHandler
    );
  };
}

const mapStateToProps = (state: AppState) => ({
  isStudent: state.user.role === 'student',
  isInactive: !!state.studentTeachers?.isInactive,
  exercises:
    state.coursebookPage?.exerciseViewer.xpreview?.exercises ||
    state.unitPreview?.extraPreview?.exercises ||
    state.unitPreview?.xpreview?.exercises ||
    state.xplayer?.supplementaryExercisesModal?.exercises ||
    state.xplayer?.exercises,
  xexercise: state?.xeditor?.xexercise
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>) => ({
  addWordsToDictionaryList: (widgetId: string, dictionaryListId: string, wordIds: string[]) =>
    dispatch(addWordsToDictionaryList(widgetId, dictionaryListId, wordIds)),
  addWordsToDictionary: (exerciseId: string, widgetId: string, wordIds: string[]) =>
    dispatch(addWordsToDictionary(exerciseId, widgetId, wordIds)),
  createExerciseDictionaryList: (exerciseId: string) =>
    dispatch(createExerciseDictionaryList(exerciseId)),
  loadDictionaryMetaRequest: (widgetId: string, exerciseId: string) =>
    dispatch(loadDictionaryMetaRequest(widgetId, exerciseId)),
  setWidgetVocabularyInfo: (
    widgetId: string,
    widgetVocabularyInfo: WidgetVocabularyInfo,
    preview?: boolean
  ) => dispatch(setWidgetVocabularyInfo(widgetId, widgetVocabularyInfo, preview)),
  refreshWidget: (widgetId: string, exerciseId: string) => {
    dispatch(loadDictionaryMetaRequest(widgetId, exerciseId)).then(({payload: {data}}) =>
      dispatch(setWidgetVocabularyInfo(widgetId, data))
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VocabularyWidgetComponent));
